import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import logo from "../../../images/logo-full_horizontal.png";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: "flex",
  },
  fullbuttons: {
    width: "50%",
    borderRadius: "0px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center", // this will center items vertically
  },
  logoAbbr: {
    paddingRight: "25px",
    // Add any other necessary styling for your logo
  },
  web3Button: {
    padding: "10px",
    // Add any other necessary styling for your Web3Button
  },
}));

const PageHeader = ({ color, title }) => {
  const classes = useStyles();

  return (
    <header style={{ backgroundColor: color, color: "white", padding: "10px" }}>
      <div className={`container-fluid text-center ${classes.container}`}>
        <div className={classes.container}>
          <Link to="#">
            <img
              className={`${classes.logoAbbr} dark-logo`}
              width="200"
              src={logo}
              alt=""
            />
            <img
              className={`${classes.logoAbbr} light-logo text-center m-auto`}
              width="200"
              src={logo}
              alt=""
            />
          </Link>
          <Web3Button className={classes.web3Button} />
        </div>
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PageHeader;
