import React, { useState, useEffect } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
} from "wagmi";
import { MINI_ABI, MINI_CONTRACT } from "../../components/MiniContractInfo";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { utils } from "ethers";
import { watchContractEvent } from "@wagmi/core";
import axios from "axios";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";

const useStyles = makeStyles((theme) => ({
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));

const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

export function MintMiniNFT({ walletAddress, nftQuantity, totalmaxsupply }) {
  const navigate = useNavigate();
  const [loadingMint, setloadingMint] = useState(false);
  const [loadingMessage, setloadingMessage] = useState("");
  const [mintError, setmintError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  console.log("MintMiniNFT");
  const classes = useStyles();
  console.log("walletAddress", walletAddress);
  console.log("nftQuantity", nftQuantity);
  console.log("totalmaxsupply", totalmaxsupply);

  //BAckdrop Handle
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  let quantity;
  if (nftQuantity === "") {
    quantity = 0;
  } else {
    console.log("nftQuantity is not empty");
    quantity = nftQuantity;
  }

  const { config } = usePrepareContractWrite({
    address: MINI_CONTRACT,
    abi: MINI_ABI,
    functionName: "mint",
    args: [quantity, totalmaxsupply],
    account: walletAddress,
  });

  const { data, write, error } = useContractWrite(config);
  if (error) {
    console.log("useContractWrite Error", error);
    console.log("useContractWrite mintError", mintError);
    if (mintError === false) {
      setmintError(true);
    }
  }

  useEffect(() => {
    if (mintError === true) {
      enqueueSnackbar("Error! Reloading Page . . . ", {
        variant: "error",
      });
      handleClose();
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    }
    setmintError(false);
  }, [mintError]);

  const {
    data: waitData,
    isLoading,
    isSuccess,
    isError,
  } = useWaitForTransaction({
    hash: data?.hash,
  });
  useEffect(() => {
    if (isLoading) {
      setloadingMessage("Minting in Progress...");
    }
  }, [isLoading]);
  console.log("waitData", waitData);

  const handlewritefunction = () => {
    setloadingMessage("Confirm in Wallet...");
    handleOpen();
    write();

    const unwatch = watchContractEvent(
      {
        address: MINI_CONTRACT,
        abi: MINI_ABI,
        eventName: "Minted",
      },
      (log) => {
        console.log("EventLog", log);
        const tokenIds = []; // Array to store unique tokenIds
        const users = []; // Array to store unique user values
        const txhashs = []; // Array to store unique user values

        for (let i = 0; i < log.length; i++) {
          const log_ = log[i];
          console.log("log", log_);
          const { args } = log_;
          const { transactionHash } = log_;
          console.log("transactionHash", transactionHash);
          console.log("args", args);
          const { tokenId, user } = args;
          console.log("tokenId", tokenId);
          console.log("user", user);

          if (!tokenIds.includes(tokenId)) {
            tokenIds.push(Number(tokenId));
          }

          if (!users.includes(user)) {
            users.push(user);
          }

          if (!txhashs.includes(transactionHash)) {
            txhashs.push(transactionHash);
          }
        }

        console.log("TokenIds", tokenIds);
        console.log("Users", users);
        unwatch();

        // Send tokenIds and users to your API using Axios POST
        axios
          .post("https://test.croesus-asset.management/api/submit_mini_nft", {
            tokenIds,
            users,
            txhashs,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            enqueueSnackbar("Mint Successful!", {
              variant: "success",
            });

            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setTimeout(() => {
              window.location.reload(false);
            }, 2000);
          });
      }
    );
  };

  return (
    <div>
      {" "}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <br></br>
          <span>
            <h2 style={{ color: "white" }}>{loadingMessage}</h2>
          </span>
        </div>
      </Backdrop>
      {isLoading ? (
        <Box className={`${loadingStyle} text-center mt-3`}>
          <CircularProgress />
          <Typography variant="h6">Processing...</Typography>
        </Box>
      ) : (
        <Button
          className={classes.nft_buttons + " mt-3"}
          style={{ marginBottom: "20px" }}
          fullWidth={true}
          variant="contained"
          disabled={!write || isLoading}
          onClick={() => handlewritefunction()}
        >
          Reinvest / Mint
        </Button>
      )}
    </div>
  );
}
