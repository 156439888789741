import React from "react";

import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";

function GlucoseRate({ calendarstaking }) {
  // console.log("Calculating Glucose Rate", calendarstaking);
  const reversedData = [...calendarstaking].reverse(); // Create a new reversed array

  const sampleData = reversedData.map((item) => parseFloat(item.equity));

  return (
    <Sparklines data={sampleData} height={50}>
      <SparklinesLine color="#ffd06d" style={{ fill: "#ffd06d9c" }} />
      <SparklinesSpots />
    </Sparklines>
  );
}

export default GlucoseRate;
