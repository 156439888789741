import React, { useState } from "react";
import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Switch,
} from "react-router-dom";
// action
import { checkAutoLogin, isLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";

import {
  WagmiConfig,
  createConfig,
  configureChains,
  useAccount,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { publicProvider } from "wagmi/providers/public";

/// Style
import "./vendor/swiper/css/swiper-bundle.min.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { bsc, bscTestnet } from "wagmi/chains";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { UserProvider } from "./jsx/pages/hooks/UserContext";
import BottomNavbar from "./jsx/pages/components/BottomNavbar"; // path to your BottomNavbar component

import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import BookIcon from "@material-ui/icons/Book";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MintWizard from "./jsx/pages/MintWizard";
import KYCForm from "./jsx/pages/KYCForm";
import CroesusCoinComponent from "./jsx/pages/CroesusCoinComponent";
import StakingPage from "./jsx/pages/StakingPage";
import PartnerDashboard from "./jsx/pages/PartnerDashboard";
import SeriesTwo from "./jsx/pages/SeriesTwo";
import Marketplace from "./jsx/pages/Marketplace";
import ICHARGE from "./jsx/pages/ICharge";
import POOLS from "./jsx/pages/POOLS";
import COMPANY from "./jsx/pages/Company";
import STATISTIC from "./jsx/pages/Statistic";
import STATISTICS from "./jsx/pages/Admin_Statistic";
import MASTERCLASS from "./jsx/pages/Masterclass";
import CARDS from "./jsx/pages/Cards";
import PHYSICAL from "./jsx/pages/Physical";
import VIRTUAL from "./jsx/pages/Virtual";
import FARMING from "./jsx/pages/Admin_FARMING";
import BIRTHDAYS from "./jsx/pages/Admin_DOB";
import MANAGE_USERS from "./jsx/pages/admin/users";
import MANAGE_CARDS from "./jsx/pages/admin/cards";
import CRYPTO_CARD from "./jsx/pages/crypto_card";
import MANAGE_MASTERCLASS from "./jsx/pages/manage_masterclass";

import LogRocket from "logrocket";
import PageHeader from "./jsx/pages/components/PageHeader";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

const darkTheme = createTheme({
  palette: {
    type: "dark",
  },
});

// const chains = [bsc]
const projectId = "806e56451b57aaedf053f9e7569102d1";
// const chains = [bsc];
// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const { chains, publicClient } = configureChains(
  [bsc],
  [
    jsonRpcProvider({
      rpc: () => ({
        http: "https://responsive-chaotic-borough.bsc.discover.quiknode.pro/70cff735d747d5eb9a000ba83941159c878f49b0/", // 👈 Replace this with your HTTP URL from the previous step
      }),
      projectId,
    }),
  ]
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
// console.log("ethereumClient", ethereumClient);

// console.log(wagmiConfig);

const PrintBoard = lazy(() => import("./jsx/pages/Print"));
const KYCBoard = lazy(() => import("./jsx/pages/KYCBoard"));
const UserDepos = lazy(() => import("./jsx/pages/UserDepos"));
const Dashboard = lazy(() => import("./jsx/pages/Dashboard"));
const EpubReader = lazy(() => import("./jsx/pages/testepub"));
// const EpubReader = lazy(() => import('./jsx/pages/EpubReader'));
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Dashboard")), 500);
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  // console.log("props.isAuthenticated", props.isAuthenticated);
  LogRocket.init("byt19w/croesus");
  // const { address, connector, isConnected } = useAccount();
  // const { disconnect } = useDisconnect();
  // const [value, setValue] = useState("home");
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/login" ||
    location.pathname === "/epub-reader" ||
    location.pathname === "/register" ||
    location.pathname === "/printboard" ||
    location.pathname === "/KYCBoard" ||
    location.pathname === "/UserDepos" ||
    location.pathname === "/statistics" ||
    location.pathname === "/farming_dash" ||
    location.pathname === "/birthdays" ||
    location.pathname === "/managemasterclass" ||
    location.pathname === "/manageusers";

  const isPageHeader =
    location.pathname === "/epub-reader" ||
    location.pathname === "/register" ||
    location.pathname === "/statistics";
  const isNFT = location.pathname === "/booknft";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const refParam = queryParams.get("ref");

  // useEffect(() => {
  //   if (isConnected) {
  //     let targetRoute = "/dashboard"; // Default route

  //     if (window.location.pathname === "/dashboard") {
  //       targetRoute = "/dashboard";
  //     } else if (window.location.pathname === "/epub-reader") {
  //       targetRoute = "/epub-reader";
  //     } else if (window.location.pathname === "/booknft") {
  //       targetRoute = "/booknft";
  //     } else if (window.location.pathname === "/kyc") {
  //       targetRoute = "/kyc";
  //     } else if (window.location.pathname === "/staking") {
  //       targetRoute = "/staking";
  //     } else if (window.location.pathname === "/partner_dashboard") {
  //       targetRoute = "/partner_dashboard";
  //     } else if (window.location.pathname === "/PrintBoard") {
  //       targetRoute = "/PrintBoard";
  //     } else if (window.location.pathname === "/series_two") {
  //       targetRoute = "/series_two";
  //     } else if (window.location.pathname === "/marketplace") {
  //       targetRoute = "/marketplace";
  //     }

  //     navigate(targetRoute);
  //   }
  // }, [isConnected]);

  let routeblog = (
    <>
      <SnackbarProvider maxSnack={3}>
        <WagmiConfig config={wagmiConfig}>
          <UserProvider>
            <Routes>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/register"
                element={<SignUp refParam={refParam} />}
              />
              <Route path="/epub-reader" element={<EpubReader />} />
              <Route path="/PrintBoard" element={<PrintBoard />} />
              <Route path="/KYCBoard" element={<KYCBoard />} />
              <Route path="/UserDepos" element={<UserDepos />} />
              <Route path="/booknft" element={<MintWizard />} />
              <Route path="/kyc" element={<KYCForm />} />
              <Route path="/CRG" element={<CroesusCoinComponent />} />
              <Route path="/staking" element={<StakingPage />} />
              <Route path="/partner_dashboard" element={<PartnerDashboard />} />
              <Route path="/series_two" element={<SeriesTwo />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/icharge" element={<ICHARGE />} />
              <Route path="/pools" element={<POOLS />} />
              <Route path="/crypto_card" element={<CRYPTO_CARD />} />
              <Route path="/company" element={<COMPANY />} />
              <Route path="/statistic" element={<STATISTIC />} />
              <Route path="/statistics" element={<STATISTICS />} />
              <Route path="/masterclass" element={<MASTERCLASS />} />
              <Route path="/cards" element={<CARDS />} />
              <Route path="/physical" element={<PHYSICAL />} />
              <Route path="/virtual" element={<VIRTUAL />} />
              {/* ADMIN ROUTES */}
              <Route path="/farming_dash" element={<FARMING />} />
              <Route path="/birthdays" element={<BIRTHDAYS />} />
              <Route path="/manageusers" element={<MANAGE_USERS />} />
              <Route path="/managecards" element={<MANAGE_CARDS />} />
              <Route
                path="/managemasterclass"
                element={<MANAGE_MASTERCLASS />}
              />
            </Routes>
          </UserProvider>
        </WagmiConfig>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      </SnackbarProvider>
    </>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <WagmiConfig config={wagmiConfig}>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
            <BottomNavbar />
          </Suspense>
        </WagmiConfig>
      </>
    );
  } else {
    return (
      <WagmiConfig config={wagmiConfig}>
        <div
          className="vh-100"
          style={{
            overflowX: "hidden",
            maxWidth: "100%",
            ...(isNFT && { paddingBottom: "70px" }),
          }}
        >
          {!isPageHeader && (
            <PageHeader color="black" title="Your Page Title" />
          )}

          {routeblog}
          <ThemeProvider theme={darkTheme}>
            {!isLoginPage && <BottomNavbar />}
          </ThemeProvider>
        </div>
      </WagmiConfig>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
