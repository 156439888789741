// styling
import styles from "./style.module.scss";

// hooks
import { useState } from "react";

// utils
import { memo } from "react";

// constants
import { usdtabi } from "../content/usdtabi";

// assets
import bg from "../../../../images/mountains.svg";
import { useAccount, useBalance, useContractRead } from "wagmi";
import Web3 from "web3";

// Initialize web3
let web3 = new Web3(
  "https://responsive-chaotic-borough.bsc.discover.quiknode.pro/70cff735d747d5eb9a000ba83941159c878f49b0/"
);

// Your contract instance
let contract = new web3.eth.Contract(
  usdtabi,
  "0x55d398326f99059fF775485246999027B3197955"
);
// console.log("USDT contract", contract);

const WalletOverview = ({ withTitle = true }) => {
  const [usdtBalance, setusdtBalance] = useState(0);
  const [bnbBalance, setbnbBalance] = useState(0);
  const { address, connector, isConnected } = useAccount();

  const BNBBalances = () => {
    const { data, isError, isLoading, refetch } = useBalance({
      address,
      watch: true,
    });

    // console.log("data", data);

    if (isLoading) return <div>Fetching balance...</div>;
    if (isError) return <div>Error fetching balance</div>;

    return (
      <div>
        {data?.formatted}{" "}
        <span className="text-large " style={{ fontSize: "1rem" }}>
          {" "}
          {data?.symbol}
        </span>
      </div>
    );
  };

  const UsdtBalances = () => {
    //console.log("usdtabi data", usdtabi);
    // console.log("usdtabi address", address);

    const {
      data: usdtdata,
      isError,
      isLoading,
    } = useContractRead({
      address: "0x55d398326f99059fF775485246999027B3197955",
      abi: usdtabi,
      functionName: "balanceOf",
      args: [address],
    });

    if (isLoading) return <div>Fetching balance...</div>;
    if (isError) return <div>Error fetching balance</div>;

    // Wait for the data to be fetched before accessing it
    if (!usdtdata) return null;
    // console.log("usdtdata", usdtdata);

    // console.log("balanceOf data", usdtdata);
    const formattedBalance = web3.utils.fromWei(usdtdata.toString(), "ether");
    // console.log("formattedBalance", formattedBalance);
    const roundedBalance = parseFloat(formattedBalance).toFixed(2);

    return (
      <div>
        {roundedBalance}{" "}
        <span className="text-large text-white" style={{ fontSize: "1rem" }}>
          USDT
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column g-16 pb-16">
        <div
          className={`${styles.total} card card--dark d-flex`}
          style={{
            position: "relative",
            backgroundColor: "black",
            margin: "0",
          }}
        >
          <img className="total_bg cover" src={bg} alt="media" />
          <div
            className={`${styles.total_main}`}
            style={{ position: "absolute", alignItems: "flex-start" }}
          >
            <span
              className="text-12 text-600 lh-1"
              style={{ color: "#b8b8b8", fontWeight: "600" }}
            >
              Total Assets
            </span>
            <div className="d-flex align-items-end g-8">
              <span
                className="text-large text-white"
                style={{
                  fontSize: "1.375rem",
                  fontWeight: "700",
                  lineHeight: "1.3",
                }}
              >
                {isConnected ? <UsdtBalances></UsdtBalances> : "0 USDT"}
              </span>
            </div>
            <span className="text-600 text-dark lh-1">
              {isConnected ? <BNBBalances></BNBBalances> : "0 BNB"}
            </span>
          </div>
          <span
            className="style_total_media__eyh6m lazy-load-image-background opacity lazy-load-image-loaded"
            style={{ color: "transparent", display: "inline-block" }}
          >
            Some Content
          </span>
        </div>
      </div>
    </>
  );
};

export default memo(WalletOverview);
