import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { contractAbi, contractAddress } from "../components/contractInfo";
import {
  contractAbi as CoinAbi,
  contractAddress as CoinAddress,
} from "../components/CoinContractInfo";
import { ALPHA_ABI, ALPHA_CONTRACT } from "../components/AlphaContractInfo";
import { OMEGA_ABI, OMEGA_CONTRACT } from "../components/OmegaContractInfo";
import { usdtabi } from "../components/content/usdtabi";
import {
  useAccount,
  useConnect,
  useEnsName,
  useSignMessage,
  useToken,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { NEWCOIN_ABI, NEWCOIN_CONTRACT } from "../components/NewCoinContract";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import { number } from "yup";
import { MintAlphaNFT } from "./hooks/MintAlphaNFT";
import { MintOmegaNFT } from "./hooks/MintOmegaNFT";

// // Initialize web3
// let web3 = new Web3("https://bsc-testnet.publicnode.com");

// // Your contract instance
// let contract = new web3.eth.Contract(alphaABI, alphaContract);
// console.log("contract", contract);

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // minHeight: "100vh",
  gap: "20px",
};
const textStyle = {
  color: "red",
};
const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: "flex",
  },
  fullbuttons: {
    width: "50%",
    borderRadius: "0px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));

async function sendMetadataToServer(data) {
  try {
    const response = await axios.post(
      "https://test.croesus-asset.management/api/metadata",
      data
    );
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
}

// Component for the form
function SeriesIIMint(props) {
  const { kyc } = props; // Destructuring the props object
  const [step, setStep] = useState(1);
  const [format, setFormat] = useState("Ebook");
  const [language, setLanguage] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingApprove, setloadingApprove] = useState(false);
  const [minted, setMinted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = React.useState("false");
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [applicantID, setapplicantID] = useState("0");
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const [usdtBalance, setusdtBalance] = useState(0);
  const [usdtApproved, setusdtApproved] = useState(0);
  const [usdtApprovedOmega, setusdtApprovedOmega] = useState(0);
  const [usdtBalanceEnough, setusdtBalanceEnough] = useState(false);
  const [usdtBalanceEnoughOmega, setusdtBalanceEnoughOmega] = useState(false);
  const [usdtisapproved, setusdtisapproved] = useState(false);
  const [usdtisapprovedOmega, setusdtisapprovedOmega] = useState(false);
  const [nftQuantity, setNftQuantity] = useState("");
  const [nftQuantityOmega, setNftQuantityOmega] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  //CRG Coin
  const [crgBalance, setcrgBalance] = useState(0);
  const [crgMaxSupply, setcrgMaxSupply] = useState(0);
  const [crgTotalSupply, setcrgTotalSupply] = useState(0);
  const [crgPrice, setcrgPrice] = useState(0);
  const [totalmaxsupply, settotalmaxsupply] = useState(0);
  const [menuItems, setmenuItems] = useState([]);
  const [alpha_supply, setalpha_supply] = useState("Loading");
  const [omega_supply, setomega_supply] = useState("Loading");
  const [maxNFTAmount, setmaxNFTAmount] = useState("");
  const [kyclink, setkyclink] = useState();
  const USDT_CONTRACT = "0x55d398326f99059ff775485246999027b3197955";

  const url =
    "https://kyc-api.amlbot.com/forms/5861287f0b54e64cda2ac51751451a242700/urls";
  const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0"; // Replace with your actual API token
  const applicantData = {
    applicant_id: applicantID, // Replace with actual data
    external_applicant_id: "123456",
    redirect_url: "https://book.croesus-asset.management/series_two",
  };

  const AlphaPrice = 1000000000000000000000;
  const OmegaPrice = 10000000000000000000000;
  //1k 1000000000000000000000
  //1 1000000000000000000

  useEffect(() => {
    setIsLoadingStatus(true);
    if (isConnected) {
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("useEffect", response.data);
          console.log(response.data.kyc);
          let supply = 3000 - response.data.count_alpha_nfts;
          setalpha_supply(supply);
          let omega_supply = 580 - response.data.count_omega_nfts;
          setomega_supply(omega_supply);
          console.log("get User Data", response.data);
          setUser(response.data.user);
          let status = response.data.user.kyc;
          console.log("KYC Status:", status);
          setStatus(status);
          isapproved();
          isapprovedOmega();
          usdt_balance();
          if (setcrgBalance !== 0) {
            fetchCoinDetails();
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setIsLoadingStatus(false);
    } else {
      setIsLoadingStatus(false);
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  useEffect(() => {
    const getAllData = async () => {
      if (nftQuantity !== "") {
        console.log("nftQuantity changed", nftQuantity);
        let usdtamount = 1000;
        console.log("usdtamount", usdtamount);
        if (usdtBalance >= usdtamount) {
          console.log("enough usdt");
          setusdtBalanceEnough(true);
          if (usdtApproved >= usdtamount) {
            console.log("enough usdt approved");
            setusdtisapproved(true);
          } else {
            console.log("not enough usdt approved");
            setusdtisapproved(false);
          }
        } else {
          console.log("not enough usdt");
          setusdtBalanceEnough(false);
        }
      }
    };
    getAllData();
  }, [nftQuantity]);

  useEffect(() => {
    const getAllData = async () => {
      if (nftQuantityOmega !== "") {
        console.log("nftQuantityOmega changed", nftQuantityOmega);
        let usdtamount = 200;
        console.log("usdtamount", usdtamount);
        if (usdtBalance >= usdtamount) {
          console.log("enough usdt");
          setusdtBalanceEnoughOmega(true);
          if (usdtApprovedOmega >= usdtamount) {
            console.log("enough usdt approved");
            setusdtisapprovedOmega(true);
          } else {
            console.log("not enough usdt approved");
            setusdtisapprovedOmega(false);
          }
        } else {
          console.log("not enough usdt");
          setusdtBalanceEnoughOmega(false);
        }
      }
    };
    getAllData();
  }, [nftQuantityOmega]);

  const handleMintButton = async () => {
    let totalmaxsupply = crgMaxSupply - crgTotalSupply;
    console.log("handleMintButton");
    console.log("nftQuantity", nftQuantity);
    console.log("totalmaxsupply", totalmaxsupply);
    try {
      const { request } = await prepareWriteContract({
        address: ALPHA_CONTRACT,
        abi: ALPHA_ABI,
        functionName: "mint",
        args: [nftQuantity, totalmaxsupply],
        account: walletAddress,
      });
      console.info("request:", request);
      const { hash } = await writeContract(request);
      console.info("hash:", hash);
      const waitMint = await waitForTransaction({
        hash: hash,
      });
      console.log("waitMint:", waitMint);
    } catch (error) {
      console.error(error);
    }
  };

  const isapprovedOmega = async () => {
    try {
      const allowance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, OMEGA_CONTRACT],
      });
      console.log("allowance Omega:", allowance);
      setusdtApprovedOmega(allowance);
    } catch (error) {
      console.error(error);
    }
  };

  const isapproved = async () => {
    try {
      const allowance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, ALPHA_CONTRACT],
      });
      console.log("allowance:", allowance);
      setusdtApproved(allowance);
    } catch (error) {
      console.error(error);
    }
  };

  const usdt_balance = async () => {
    try {
      const balance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("balance:", balance);
      const balance_InEther = Web3.utils.fromWei(String(balance), "ether");
      console.log("balance_InEther:", balance_InEther);
      setusdtBalance(Number(balance_InEther));

      // if (balance > 1000) {
      //   const fullNFTs = Math.floor(balance / 1000);
      //   console.log("fullNFTs", fullNFTs);
      //   setmaxNFTAmount(fullNFTs);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  //PREPARE APPROVE USDT
  const { config: usdtconfig } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [ALPHA_CONTRACT, AlphaPrice * nftQuantity],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdt,
    data: approvedata,
    error: errorapprove,
    isLoading: isLoadingapprove,
    isError: isErrorapprove,
  } = useContractWrite(usdtconfig);

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: approvedata?.hash,
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapproved(true);
      setRefresh((prev) => !prev);
    },
  });

  //APPROVE OMEGA USDT
  const { config: usdtconfigOmega } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [OMEGA_CONTRACT, OmegaPrice * nftQuantityOmega],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdtOmega,
    data: approvedataOmega,
    error: errorapproveOmega,
    isLoading: isLoadingapproveOmega,
    isError: isErrorapproveOmega,
  } = useContractWrite(usdtconfigOmega);

  const {
    data: approvedatareceiptOmega,
    isLoading: approveisPendingOmega,
    isSuccess: approveisSuccessOmega,
  } = useWaitForTransaction({
    hash: approvedataOmega?.hash,
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapprovedOmega(true);
      setRefresh((prev) => !prev);
    },
  });

  const fetchCoinDetails = async () => {
    try {
      const CoinData = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "checkAirdrop",
        args: [walletAddress],
      });
      console.log("CoinData:", CoinData);

      const CRGBalance = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("CRGBalance:", CRGBalance);
      const CRGBalance_InEther = Web3.utils.fromWei(
        String(CRGBalance),
        "ether"
      );
      setcrgBalance(Number(CRGBalance_InEther));

      const CRGMaxSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "maxSupply",
      });
      const CRGMaxSupply_InEther = Web3.utils.fromWei(
        String(CRGMaxSupply),
        "ether"
      );
      console.log("CRGMaxSupply:", CRGMaxSupply);
      setcrgMaxSupply(CRGMaxSupply);

      const CRGTotalSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "totalSupply",
      });
      const CRGTotalSupply_InEther = Web3.utils.fromWei(
        String(CRGTotalSupply),
        "ether"
      );
      console.log("CRGTotalSupply:", CRGTotalSupply);
      setcrgTotalSupply(CRGTotalSupply);

      // Add checks for division by zero
      if (CRGMaxSupply_InEther - CRGTotalSupply_InEther !== 0) {
        const CRG_Price =
          10000000 / (CRGMaxSupply_InEther - CRGTotalSupply_InEther);
        console.log("CRG_Price:", CRG_Price);
        setcrgPrice(CRG_Price.toFixed(2));
      } else {
        console.log("Cannot divide by zero. Check your inputs.");
      }

      const totalmaxsupply = CRGMaxSupply - CRGTotalSupply;
      settotalmaxsupply(totalmaxsupply);

      console.log("totalmaxsupply ! ", totalmaxsupply);
    } catch (error) {
      console.error(error);
    }
  };

  // VERIFICATION MODULE START
  const fetchData = async () => {
    setIsLoadingStatus(true);
    console.log("user.form_url ", user.form_url);
    if (user.form_url === null) {
      console.log("user.form_url != NULL");
      console.log("applicantData ", applicantID);
      try {
        const response = await axios.post(url, applicantData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${apiToken}`,
          },
        });
        console.log("fetchData", response.data);

        const metadata = {
          status: user.kyc,
          verificationID: response.data.verification_id,
          applicant_id: applicantID,
          form_url: response.data.form_url,
          userID: user.id,
        };
        console.log("metadata", metadata);
        const response_api = await axios.post(
          "https://test.croesus-asset.management/api/setKYC",
          metadata
        );
        setIsLoadingStatus(false);
        setkyclink(response.data.form_url);
      } catch (error) {
        console.error(error);
        setIsLoadingStatus(false);
      } finally {
        setIsLoadingStatus(false);
        // updateStatus();
        // window.location.href = kyclink;
      }
    } else {
      console.log("user.form_url !== NULL");
      setIsLoadingStatus(false);
      // getVerification();
    }
  };

  useEffect(() => {
    console.log("kyclink", kyclink);
    if (kyclink !== undefined) {
      window.location.href = kyclink;
    }
  }, [kyclink]);

  const startKYC = async () => {
    const url = "https://kyc-api.amlbot.com/applicants";
    const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0";
    console.log("user.form_url ", user.form_url);

    if (user.form_url === null) {
      let firstname = "";
      let lastname = "";
      if (user.fullname !== null) {
        const names = user.fullname.split(" ");

        if (names.length === 2) {
          firstname = names[0];
          lastname = names[1];
        } else if (names.length === 1) {
          firstname = user.fullname;
        }
      }

      const applicantData = {
        type: "PERSON",
        first_name: firstname,
        last_name: lastname,
        wallet_address: user.web3_wallet,
        email: user.email,
      };
      console.log("applicantData", applicantData);

      try {
        const response = await axios.post(url, applicantData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${apiToken}`,
          },
        });
        console.log("response ", response.data);
        setapplicantID(response.data.applicant_id);
      } catch (error) {
        console.error(`Error creating applicant: ${error}`);
      }
    } else {
      console.log("redirecting ");
      window.location.href = user.form_url;
    }
  };

  useEffect(() => {
    if (applicantID !== "0") {
      fetchData();
    }
  }, [applicantID]);

  return (
    <>
      <div
        className="content-body p-0"
        // style={{ marginBottom: "70px !important" }}
      >
        <div className="container-fluid text-center">
          <Box className={containerStyle}>
            <h1 className="text-center">A-ALPHA</h1>

            <CardMedia
              component="img"
              style={{ borderRadius: "0.625rem" }}
              image={
                "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/8fdccf9c-c305-4d19-4d40-485a0d995100/public"
              }
              className={classes.media} // Apply the custom styling class to the CardMedia component
            />
            <h3
              className="text-center mt-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {alpha_supply} / 3000
            </h3>

            <h1
              className="text-center mt-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="crypto-icon--sm crypto-icon--alt"
                style={{
                  marginRight: "6px",
                  display: "inline-flex",
                  alignItems: "end",
                }}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1l5M7-WU-LJcohPi5TuBu4DBR692OqzlvpDbQb2bqd_KiGUHWq62_0Nqyqw4LRyIRDU&amp;usqp=CAU"
                  alt="usdt"
                  style={{ width: "40px" }}
                />
                1000 USDT
              </span>
            </h1>

            {status === "completed" ? (
              <>
                <Select
                  value={nftQuantity}
                  onChange={(event) => setNftQuantity(event.target.value)}
                  displayEmpty
                  fullWidth
                  // variant="outlined"
                  className="mt-3"
                  // style={{ paddingTop: "7px", paddingBottom: "7px" }}
                >
                  <MenuItem value="" disabled>
                    Select NFT quantity
                  </MenuItem>
                  <MenuItem value="1">1 Alpha</MenuItem>
                  <MenuItem value="2">2 Alpha</MenuItem>
                  <MenuItem value="3">3 Alpha</MenuItem>
                  <MenuItem value="4">4 Alpha</MenuItem>
                  <MenuItem value="5">5 Alpha</MenuItem>
                  <MenuItem value="6">6 Alpha</MenuItem>
                  <MenuItem value="7">8 Alpha</MenuItem>
                  <MenuItem value="8">8 Alpha</MenuItem>
                  <MenuItem value="9">9 Alpha</MenuItem>
                  <MenuItem value="10">10 Alpha</MenuItem>
                  {/* Add more options as needed */}
                </Select>

                {loading ? (
                  <Box className={`${loadingStyle} text-center`}>
                    <CircularProgress />
                    <Typography variant="h6">Processing...</Typography>
                  </Box>
                ) : (
                  <>
                    {error && (
                      <Typography variant="h6" className={textStyle}>
                        {error}
                      </Typography>
                    )}
                    {minted && (
                      <Typography
                        variant="h6"
                        color="success"
                        className="text-center"
                        style={{ color: "green !important" }}
                      >
                        NFT minted!
                      </Typography>
                    )}

                    {nftQuantity !== "" ? (
                      <>
                        {usdtisapproved ? (
                          <MintAlphaNFT
                            walletAddress={walletAddress}
                            nftQuantity={nftQuantity}
                            totalmaxsupply={totalmaxsupply}
                          />
                        ) : (
                          <>
                            {usdtBalanceEnough ? (
                              <>
                                {isLoadingapprove || approveisPending ? (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                  >
                                    <CircularProgress
                                      style={{
                                        color: "white",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={approveusdt}
                                  >
                                    Approve USDT
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                                disabled
                              >
                                Not enough USDT
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {status !== "pending" ? null : (
                  <>
                    <h1
                      className="text-center mb-0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Status <br></br>
                    </h1>
                    <h1
                      className="text-center mb-0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "orange",
                      }}
                    >
                      Pending
                    </h1>
                  </>
                )}

                {isLoadingStatus ? (
                  <CircularProgress />
                ) : (
                  <Button
                    style={{
                      marginBottom: "20px",
                      marginTop: "10px",
                      width: "50%",
                    }}
                    fullWidth={true}
                    variant="contained"
                    onClick={startKYC}
                  >
                    <>Complete KYC</>
                  </Button>
                )}
              </>
            )}
          </Box>
        </div>
      </div>

      <div
        className="content-body p-0"
        // style={{ marginBottom: "70px !important" }}
      >
        <div className="container-fluid text-center">
          {/* OMEGA NFT */}
          <Box className={containerStyle + " mt-3"}>
            <h1 className="text-center">Omega</h1>

            <CardMedia
              component="img"
              style={{ borderRadius: "0.625rem" }}
              image={
                "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/b3f74969-6667-4d95-8b2d-bebacbcb4600/public"
              }
              className={classes.media} // Apply the custom styling class to the CardMedia component
            />
            <h3
              className="text-center mt-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {omega_supply} / 580
            </h3>

            <h1
              className="text-center mt-1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="crypto-icon--sm crypto-icon--alt"
                style={{
                  marginRight: "6px",
                  display: "inline-flex",
                  alignItems: "end",
                }}
              >
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1l5M7-WU-LJcohPi5TuBu4DBR692OqzlvpDbQb2bqd_KiGUHWq62_0Nqyqw4LRyIRDU&amp;usqp=CAU"
                  alt="usdt"
                  style={{ width: "40px" }}
                />
                10000 USDT
              </span>
            </h1>

            {status === "completed" ? (
              <>
                <Select
                  value={nftQuantityOmega}
                  onChange={(event) => setNftQuantityOmega(event.target.value)}
                  displayEmpty
                  fullWidth
                  // variant="outlined"
                  className="mt-3"
                  // style={{ paddingTop: "7px", paddingBottom: "7px" }}
                >
                  <MenuItem value="" disabled>
                    Select NFT quantity
                  </MenuItem>
                  <MenuItem value="1">1 Omega</MenuItem>
                  <MenuItem value="2">2 Omega</MenuItem>
                  <MenuItem value="3">3 Omega</MenuItem>
                  <MenuItem value="4">4 Omega</MenuItem>
                  <MenuItem value="5">5 Omega</MenuItem>
                  <MenuItem value="6">6 Omega</MenuItem>
                  <MenuItem value="7">8 Omega</MenuItem>
                  <MenuItem value="8">8 Omega</MenuItem>
                  <MenuItem value="9">9 Omega</MenuItem>
                  <MenuItem value="10">10 Omega</MenuItem>
                  {/* Add more options as needed */}
                </Select>

                {loading ? (
                  <Box className={`${loadingStyle} text-center`}>
                    <CircularProgress />
                    <Typography variant="h6">Processing...</Typography>
                  </Box>
                ) : (
                  <>
                    {error && (
                      <Typography variant="h6" className={textStyle}>
                        {error}
                      </Typography>
                    )}
                    {minted && (
                      <Typography
                        variant="h6"
                        color="success"
                        className="text-center"
                        style={{ color: "green !important" }}
                      >
                        NFT minted!
                      </Typography>
                    )}

                    {nftQuantityOmega !== "" ? (
                      <>
                        {usdtisapprovedOmega ? (
                          // <Button
                          //   className={classes.nft_buttons + " mt-3"}
                          //   style={{ marginBottom: "20px" }}
                          //   fullWidth={true}
                          //   variant="contained"
                          //   onClick={handleMintButton}
                          // >
                          //   BUY / Mint
                          // </Button>
                          <MintOmegaNFT
                            walletAddress={walletAddress}
                            nftQuantity={nftQuantityOmega}
                            totalmaxsupply={totalmaxsupply}
                          />
                        ) : (
                          <>
                            {usdtBalanceEnoughOmega ? (
                              <>
                                {isLoadingapproveOmega ||
                                approveisPendingOmega ? (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                  >
                                    <CircularProgress
                                      style={{
                                        color: "white",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={approveusdtOmega}
                                  >
                                    Approve USDT
                                  </Button>
                                )}
                              </>
                            ) : (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                                disabled
                              >
                                Not enough USDT
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <Button
                      className={classes.nft_buttons + " mt-3"}
                      style={{ marginBottom: "20px" }}
                      fullWidth={true}
                      variant="contained"
                      disabled
                    >
                      Select Quantity
                    </Button> */}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {status !== "pending" ? null : (
                  <>
                    <h1
                      className="text-center mb-0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Status <br></br>
                    </h1>
                    <h1
                      className="text-center mb-0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "orange",
                      }}
                    >
                      Pending
                    </h1>
                  </>
                )}

                {isLoadingStatus ? (
                  <CircularProgress />
                ) : (
                  <Button
                    style={{
                      marginBottom: "20px",
                      marginTop: "10px",
                      width: "50%",
                    }}
                    fullWidth={true}
                    variant="contained"
                    onClick={startKYC}
                  >
                    <>Complete KYC</>
                  </Button>
                )}
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default SeriesIIMint;
