import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Tabs,
  Tab,
} from "@material-ui/core";
import axios from "axios";
import {
  useAccount,
  useConnect,
  useEnsName,
  useSignMessage,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";

import Alert from "@mui/material/Alert";
import Treefold from "react-treefold";
import MatrixComponent from "./components/Matrix";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Backdrop from "@mui/material/Backdrop";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";

const useStyles = makeStyles((theme) => ({
  flex_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    maxWidth: 300,
    margin: "0 auto",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    // marginBottom: theme.spacing(2),
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  cancel_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  dialogContent: {
    textAlign: "center", // Set text alignment to center
  },
  disabled_buttons: {
    background: "#afaeaa !important",
    color: "rgba(0, 0, 0, 0.26)",
  },
  btn_container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  withdraw_button: {
    display: "block",
    color: "black",
    background: "white",
    borderRadius: "12px",
    border: "1px solid var(--border-light)",
    transition: "border-color var(--transition)",
  },
  balance: {
    border: "1px solid #d4d4d4", // Added quotation marks around the value
    display: "flex",
    transition: "border-color var(--transition)",
    borderRadius: "12px", // Moved borderRadius up to match other properties
    alignItems: "center",
    justifyContent: "center",
  },
  flexcolumns: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const matrix = [
  {
    referral: "User1",
    level_number: 1,
    sameline_number: 1,
    children: [
      {
        referral: "User2",
        level_number: 2,
        sameline_number: 1,
        children: [
          {
            referral: "User3",
            level_number: 3,
            sameline_number: 1,
            children: [],
          },
          {
            referral: "User4",
            level_number: 3,
            sameline_number: 2,
            children: [],
          },
          {
            referral: "User5",
            level_number: 3,
            sameline_number: 3,
            children: [],
          },
        ],
      },
      {
        referral: "User6",
        level_number: 2,
        sameline_number: 2,
        children: [
          {
            referral: "User7",
            level_number: 3,
            sameline_number: 1,
            children: [],
          },
          {
            referral: "User8",
            level_number: 3,
            sameline_number: 2,
            children: [],
          },
        ],
      },
      {
        referral: "User9",
        level_number: 2,
        sameline_number: 3,
        children: [],
      },
    ],
  },
];

function Pools() {
  const classes = useStyles();
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [bnbBalance, setBnbBalance] = useState(0);
  const [usdtEarned, setusdtEarned] = useState(0);
  const [bnbEarned, setbnbEarned] = useState(0);
  const [usdtWithdrawn, setusdtWithdrawn] = useState(0);
  const [bnbithdrawn, setbnbithdrawn] = useState(0);
  const [teamPartners, setteamPartners] = useState(0);
  const [directPartners, setdirectPartners] = useState(0);
  const { address, connector, isConnected } = useAccount();
  const [user, setUser] = useState(null);
  const [reflink, setreflink] = useState("loading");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDual, setOpenDual] = useState(false);
  const [openVIPPool, setOpenVIPPool] = useState(false);
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [message, setMessage] = useState(false);
  const [erroralert, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDual, setloadingDual] = useState(false);
  const [successMessage, setsuccessMessage] = useState();
  const [isBalancesLoaded, setIsBalancesLoaded] = useState(false);
  const [refTransactions, setrefTransactions] = useState([]);
  const [withdrawTransactions, setwithdrawTransactions] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loadingMessage, setloadingMessage] = useState("");

  const [nft_counts, setnft_counts] = useState(null);
  const [settings, setSettings] = useState(null);
  const [openGlobal, setOpenGlobal] = useState(false);
  const [openCZTWithdraw, setOpenCZTWithdraw] = useState(false);
  const [openCZTStats, setOpenCZTStats] = useState(false);
  const [openCZTInfo, setOpenCZTInfo] = useState(false);

  const [CZTCompound, setCompound] = useState(0);
  const [CZTPoolAll, setpool_all] = useState(0);
  const [CZTCapital, setCZTCapital] = useState(0);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };
  const pageSizeOptions = [5, 10, 25];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSizeOptions[0]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedTransactions = refTransactions.slice(startIndex, endIndex);
  const paginatedwithdrawTransactions = withdrawTransactions.slice(
    startIndex,
    endIndex
  );
  const mergedPaginatedTransactions = [
    ...paginatedTransactions,
    ...paginatedwithdrawTransactions,
  ];

  const handleClickCloseCZTInfo = () => {
    setOpenCZTInfo(false);
  };
  const handleClickOpenCZTInfo = () => {
    setOpenCZTInfo(true);
  };

  const handleClickCloseCZTStats = () => {
    setOpenCZTStats(false);
  };

  const handleClickOpenCZTStats = () => {
    setOpenCZTStats(true);
  };

  const handleCloseCZTWithdraw = () => {
    setOpenCZTWithdraw(false);
  };

  const handleClickOpenCZTWithdraw = () => {
    setOpenCZTWithdraw(true);
  };

  const handleClickOpenGlobal = () => {
    setOpenGlobal(true);
  };

  const handleCloseGlobal = () => {
    setOpenGlobal(false);
  };

  useEffect(() => {
    if (isConnected) {
      // console.log("isConnected User");
      // console.log(user);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${address}`
        )
        .then((response) => {
          setIsBalancesLoaded(false);
          console.log("user: ", response.data.user);
          setUser(response.data.user);
          setUsdtBalance(
            parseFloat(response.data.user.usdt_balance).toFixed(2)
          );
          setBnbBalance(parseFloat(response.data.user.account_bal).toFixed(2));
          setreflink(response.data.user.ref_link);

          setusdtWithdrawn(
            parseFloat(response.data.user.withdrawamount_usdt).toFixed(2)
          );
          setbnbithdrawn(
            parseFloat(response.data.user.withdrawamount_bnb).toFixed(2)
          );

          // parseFloat(response.data.user.total_earned_usdt).toFixed(2)
          // parseFloat(response.data.user.total_earned_bnb).toFixed(2)

          console.log(
            "response.data.user.account_bal",
            response.data.user.account_bal
          );
          console.log(
            "response.data.user.withdrawamount_bnb",
            response.data.user.withdrawamount_bnb
          );
          console.log(
            "response.data.user.usdtBalance",
            response.data.user.usdt_balance
          );
          console.log(
            "response.data.user.withdrawamount_usdt",
            response.data.user.withdrawamount_usdt
          );

          let usdtearned_ =
            (parseFloat(response.data.user.withdrawamount_usdt) || 0) +
            (parseFloat(response.data.user.usdt_balance) || 0);
          setusdtEarned(usdtearned_.toFixed(2));

          let bnbtearned_ =
            (parseFloat(response.data.user.withdrawamount_bnb) || 0) +
            (parseFloat(response.data.user.account_bal) || 0);
          setbnbEarned(bnbtearned_.toFixed(2));

          setteamPartners(response.data.user.matrix_count);
          setdirectPartners(response.data.user.direct_partners);

          console.log(
            "response.data.user.ref_transactions",
            response.data.user.ref_transactions
          );
          setrefTransactions(response.data.user.ref_transactions);
          setwithdrawTransactions(response.data.user.all_partner_transactions);

          //GLOBALPOOLS OLD DASHBOARD
          setnft_counts(response.data.nftCounts);
          setSettings(response.data.settings);

          setIsBalancesLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);
            if (errorMessage === "User not found") {
            }
            // You can handle/display the error message as per your requirement
          }
        });

      axios
        .get(`https://test.croesus-asset.management/api/getPoolData`)
        .then((response) => {
          console.log("getPoolData Data: ", response.data);
          setCZTCapital(response.data.pool);
          // setCZTCapital(response.data.pool);
          setCompound(response.data.pool / 2);
          // setCompound(6493.48);
          setpool_all(response.data.pool_all / 2);
        })
        .catch((error) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);
            if (errorMessage === "User not found") {
            }
          }
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected]);

  useEffect(() => {
    console.log("UseEffect loadingDual");
    console.log(loadingDual);
  }, [loadingDual]);

  useEffect(() => {
    // Example: Fetch balances from an API
    const fetchBalances = async () => {
      // Simulated API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Set the balances
    };

    fetchBalances();
  }, []);

  const handleWithdrawVipPool = () => {
    setloadingMessage("Withdrawing...");
    setopenBackdrop(true);
    setloadingDual(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/submit_vip_pool_withdraw",
        {
          user: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response);
        console.log(response.data);
        if (response.data.message === "OK") {
          setsuccessMessage("success");
          // setMessage("Successful requested!");
          // setSnackbarOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError("error");
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setError("error");
        setLoading(false);
      });

    setopenBackdrop(false);
  };

  const handleWithdrawDualPool = () => {
    setloadingMessage("Withdrawing...");
    setopenBackdrop(true);
    setloadingDual(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/submit_dual_pool_withdraw",
        {
          user: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response);
        console.log(response.data);
        if (response.data.message === "OK") {
          setsuccessMessage("success");
          // setMessage("Successful requested!");
          // setSnackbarOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError("error");
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setError("error");
        setLoading(false);
      });

    setopenBackdrop(false);
  };

  const handleClickOpenGlobalWithdraw = () => {
    setloadingMessage("Withdrawing...");
    setopenBackdrop(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/submit_withdrawal_globalpool",
        {
          user: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response);
        console.log(response.data);
        if (response.data.message === "OK") {
          setloadingMessage("Withdraw Requested!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError("error");
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setError("error");
        setLoading(false);
      });
  };

  const handleWithdrawCZTPool = () => {
    setloadingMessage("Withdrawing...");
    setopenBackdrop(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/submit_withdrawal_cztpool",
        {
          user: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response);
        console.log(response.data);
        if (response.data.message === "OK") {
          setloadingMessage("Withdraw Requested!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError("error");
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setError("error");
        setLoading(false);
      });
  };

  const handleWithdraw = () => {
    setloadingDual(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/submit_icharge_pool_withdraw",
        {
          user: user.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response);
        console.log(response.data);
        if (response.data.message === "OK") {
          setsuccessMessage("success");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setError("error");
        }
        setLoading(false);
      })

      .catch((error) => {
        console.error("Error:", error);
        setError("error");
        setLoading(false);
      });

    setOpen(false);
    setMessage("Withdraw Requested!");
    setSnackbarOpen(true);
  };

  const handleOpenVIPPool = () => {
    setOpenVIPPool(true);
  };

  const handleCloseVIPPool = () => {
    setOpenVIPPool(false);
  };

  const handleOpenDualPool = () => {
    setOpenDual(true);
  };

  const handleCloseDual = () => {
    setOpenDual(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyReflink = () => {
    navigator.clipboard
      .writeText(reflink)
      .then(() => {
        console.log("Reflink copied to clipboard!");
        setMessage("Copy successful!");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to copy reflink: ", error);
      });
  };

  const getNextMonthFirstDay = () => {
    const currentDate = new Date();
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const formattedDate = nextMonth.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => 99999999999999,
        }}
        open={openBackdrop}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <br></br>
          <span>
            <h2 style={{ color: "white" }}>{loadingMessage}</h2>
          </span>
        </div>
      </Backdrop>
      <div
        className="content-body p-0"
        style={{ minHeight: window.screen.height + 80 }}
      >
        <div className="container-fluid text-center mb-2">
          <div className={classes.container}></div>

          <div>
            <>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MuiAlert
                  onClose={() => setSnackbarOpen(false)}
                  severity="success"
                  variant="filled"
                >
                  {message}
                </MuiAlert>
              </Snackbar>
              <div className="row mt-2 mb-2 justify-content-md-center">
                <div className="col-xl-6">
                  <div className="card p-3">
                    <div className="card-header text-center p-0 flex-wrap">
                      <h1>I-CHARGE Global Pool</h1>
                    </div>

                    <div className="card-body p-0 mt-3 row">
                      <div
                        className="col-8"
                        style={{
                          paddingTop: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="p-1" component="h3" gutterBottom>
                          {!isBalancesLoaded ? (
                            <Skeleton
                              variant="text"
                              width={150}
                              height={30}
                              style={{ display: "inline-block" }}
                            />
                          ) : (
                            <>
                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                Shares : {user.icharge_shares}
                              </div>
                              {user.withdrawals_ichargeglobal == 0 ? (
                                <div
                                  className={
                                    classes.balance + " p-1 mt-1 w-100"
                                  }
                                  style={{ width: "140px" }}
                                >
                                  {settings.global_icharge} USDT / Share
                                </div>
                              ) : null}

                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                <img
                                  src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                                  alt="USDT Icon"
                                  width="35px"
                                  style={{ paddingRight: "5px" }}
                                />
                                {`   ${truncateNumber(
                                  user.icharge_global_balance
                                )}`}
                              </div>
                            </>
                          )}
                        </Typography>
                      </div>
                      {!isBalancesLoaded ? null : (
                        <div
                          className="col-4 p-2"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Button
                            className={classes.withdraw_button + " p-3"}
                            variant="contained"
                            color="primary"
                            style={{
                              gridAutoFlow: "column",
                              alignItems: "center",
                              textAlign: "center",
                              textTransform: "capitalize",
                              maxHeight: "60px",
                            }}
                            onClick={handleWithdraw}
                            // disabled={Number(user.icharge_global_balance) < 1}
                            disabled
                          >
                            <div className={classes.btn_container}>
                              <AccountBalanceWalletIcon />
                              <span
                                style={{
                                  fontSize: ".75rem",
                                  fontWeight: "lighter",
                                  lineHeight: "1.3",
                                }}
                              >
                                Withdraw
                              </span>
                            </div>
                          </Button>
                          {Number(user.icharge_global_balance) < 1 && (
                            <span>Min. 1 USDT</span>
                          )}
                        </div>
                      )}

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{ style: { minWidth: "80%" } }}
                      >
                        <DialogContent className={classes.dialogContent}>
                          <Typography variant="h4" align="center" gutterBottom>
                            Withdraw Details
                          </Typography>
                          <Typography align="center">
                            Amount: {usdtBalance} USDT
                          </Typography>
                          <Typography align="center">
                            Amount: {bnbBalance} BNB
                          </Typography>
                          <Typography align="center">Time: 24 Hours</Typography>
                          {loading && (
                            <CircularProgress align="center" className="m-3" />
                          )}{" "}
                          {erroralert && erroralert === "error" && (
                            <Alert
                              severity="error"
                              style={{ marginTop: "16px" }}
                            >
                              Error occurred while withdrawing. Please try
                              again.
                            </Alert>
                          )}
                          {successMessage && successMessage === "success" && (
                            <Alert
                              severity="success"
                              style={{ marginTop: "16px" }}
                            >
                              Withdraw requested. Refreshing...
                            </Alert>
                          )}
                        </DialogContent>
                        {!loading && !successMessage && (
                          <DialogActions style={{ justifyContent: "center" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              className={classes.nft_buttons}
                              variant="contained"
                              color="primary"
                              onClick={handleWithdraw}
                              disabled
                            >
                              Confirm!
                            </Button>
                          </DialogActions>
                        )}
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 mb-2 justify-content-md-center">
                <div className="col-xl-6">
                  <div className="card p-3">
                    <div className="card-header text-center p-0 flex-wrap">
                      <h1>I-CHARGE Dual Pool</h1>
                    </div>

                    <div className="card-body p-0 mt-3 row">
                      <div
                        className="col-8"
                        style={{
                          paddingTop: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="p-1" component="h3" gutterBottom>
                          {!isBalancesLoaded ? (
                            <Skeleton
                              variant="text"
                              width={150}
                              height={30}
                              style={{ display: "inline-block" }}
                            />
                          ) : (
                            <>
                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                Shares : {user.dual_pool}
                              </div>
                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                {settings.dual_icharge} USDT / Share
                              </div>

                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                <img
                                  src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                                  alt="USDT Icon"
                                  width="35px"
                                  style={{ paddingRight: "5px" }}
                                />
                                {`   ${truncateNumber(user.dual_pool_balance)}`}
                              </div>
                            </>
                          )}
                        </Typography>
                      </div>
                      {!isBalancesLoaded ? null : (
                        <>
                          <div
                            className="col-4 p-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              className={classes.withdraw_button + " p-3"}
                              variant="contained"
                              color="primary"
                              style={{
                                gridAutoFlow: "column",
                                alignItems: "center",
                                textAlign: "center",
                                textTransform: "capitalize",
                                maxHeight: "60px",
                              }}
                              onClick={handleOpenDualPool}
                              // disabled={Number(user.dual_pool_balance) < 1}
                              disabled
                            >
                              <div className={classes.btn_container}>
                                <AccountBalanceWalletIcon />
                                <span
                                  style={{
                                    fontSize: ".75rem",
                                    fontWeight: "lighter",
                                    lineHeight: "1.3",
                                  }}
                                >
                                  Withdraw
                                </span>
                              </div>
                            </Button>
                            {Number(user.dual_pool_balance) < 1 && (
                              <span>Min. 1 USDT</span>
                            )}
                          </div>

                          <Dialog
                            open={openDual}
                            onClose={handleCloseDual}
                            PaperProps={{ style: { minWidth: "80%" } }}
                          >
                            <DialogContent className={classes.dialogContent}>
                              <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                              >
                                Dual Pool Withdraw
                              </Typography>
                              <Typography align="center">
                                Amount: {user.dual_pool_balance} USDT
                              </Typography>
                              <Typography align="center">
                                Time: 24 Hours
                              </Typography>
                              {loadingDual && (
                                <CircularProgress
                                  align="center"
                                  className="m-3"
                                />
                              )}{" "}
                              {erroralert && erroralert === "error" && (
                                <Alert
                                  severity="error"
                                  style={{ marginTop: "16px" }}
                                >
                                  Error occurred while withdrawing. Please try
                                  again.
                                </Alert>
                              )}
                              {successMessage &&
                                successMessage === "success" && (
                                  <Alert
                                    severity="success"
                                    style={{ marginTop: "16px" }}
                                  >
                                    Withdraw requested. Refreshing...
                                  </Alert>
                                )}
                            </DialogContent>
                            {!loadingDual && !successMessage && (
                              <DialogActions
                                style={{ justifyContent: "center" }}
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={handleCloseDual}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className={classes.nft_buttons}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleWithdrawDualPool}
                                >
                                  Confirm!
                                </Button>
                              </DialogActions>
                            )}
                          </Dialog>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2 mb-2 justify-content-md-center">
                <div className="col-xl-6">
                  <div className="card p-3">
                    <div className="card-header text-center p-0 flex-wrap">
                      <h1>VIP Card Pool</h1>
                    </div>

                    <div className="card-body p-0 mt-3 row">
                      <div
                        className="col-8"
                        style={{
                          paddingTop: "0.5rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="p-1" component="h3" gutterBottom>
                          {!isBalancesLoaded ? (
                            <Skeleton
                              variant="text"
                              width={150}
                              height={30}
                              style={{ display: "inline-block" }}
                            />
                          ) : (
                            <>
                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                Shares : {user.vipcard_pool}
                              </div>
                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                {settings.vip_profits} USDT / Share
                              </div>

                              <div
                                className={classes.balance + " p-1 mt-1 w-100"}
                                style={{ width: "140px" }}
                              >
                                <img
                                  src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                                  alt="USDT Icon"
                                  width="35px"
                                  style={{ paddingRight: "5px" }}
                                />
                                {`   ${truncateNumber(user.vipcard_balance)}`}
                              </div>
                            </>
                          )}
                        </Typography>
                      </div>
                      {!isBalancesLoaded ? null : (
                        <>
                          <div
                            className="col-4 p-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Button
                              className={classes.withdraw_button + " p-3"}
                              variant="contained"
                              color="primary"
                              style={{
                                gridAutoFlow: "column",
                                alignItems: "center",
                                textAlign: "center",
                                textTransform: "capitalize",
                                maxHeight: "60px",
                              }}
                              onClick={handleOpenVIPPool}
                              disabled={Number(user.vipcard_balance) < 1}
                              // disabled
                            >
                              <div className={classes.btn_container}>
                                <AccountBalanceWalletIcon />
                                <span
                                  style={{
                                    fontSize: ".75rem",
                                    fontWeight: "lighter",
                                    lineHeight: "1.3",
                                  }}
                                >
                                  Withdraw
                                </span>
                              </div>
                            </Button>
                            {/* {Number(user.dual_pool_balance) < 1 && (
                              <span>Min. 1 USDT</span>
                            )} */}
                          </div>

                          <Dialog
                            open={openVIPPool}
                            onClose={handleCloseVIPPool}
                            PaperProps={{ style: { minWidth: "80%" } }}
                          >
                            <DialogContent className={classes.dialogContent}>
                              <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                              >
                                VIP Card Pool Withdraw
                              </Typography>
                              <Typography align="center">
                                Amount: {user.vipcard_balance} USDT
                              </Typography>
                              <Typography align="center">
                                Time: 24 Hours
                              </Typography>
                              {loadingDual && (
                                <CircularProgress
                                  align="center"
                                  className="m-3"
                                />
                              )}{" "}
                              {erroralert && erroralert === "error" && (
                                <Alert
                                  severity="error"
                                  style={{ marginTop: "16px" }}
                                >
                                  Error occurred while withdrawing. Please try
                                  again.
                                </Alert>
                              )}
                              {successMessage &&
                                successMessage === "success" && (
                                  <Alert
                                    severity="success"
                                    style={{ marginTop: "16px" }}
                                  >
                                    Withdraw requested. Refreshing...
                                  </Alert>
                                )}
                            </DialogContent>
                            {!loadingDual && !successMessage && (
                              <DialogActions
                                style={{ justifyContent: "center" }}
                              >
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={handleCloseDual}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className={classes.nft_buttons}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleWithdrawVipPool}
                                >
                                  Confirm!
                                </Button>
                              </DialogActions>
                            )}
                          </Dialog>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {nft_counts && (
                <>
                  <div className="row mt-2 mb-2 justify-content-md-center">
                    <div className="col-xl-6">
                      <div className="card p-3">
                        <div className="card-header text-center p-0 flex-wrap">
                          <h1>Global Bonus Pool</h1>
                        </div>

                        <div className="card-body p-0 mt-3 row">
                          <div className="col-8" style={{ textAlign: "left" }}>
                            {(nft_counts["Taipei"].overall >= 1 &&
                              nft_counts["Taipei"].overall >= 1 &&
                              nft_counts["Tianjin_CTF_Finance_Center"]
                                .overall >= 1) ||
                            user.id === 30 ? (
                              <>
                                <div className="p-3">
                                  <h3>{user.pool_balance} USDT</h3>

                                  {user.pool_balance !== "0" && (
                                    <Button
                                      className={classes.nft_buttons + " mb-3"}
                                      variant="contained"
                                      color="primary"
                                      onClick={handleClickOpenGlobalWithdraw}
                                      style={{ flex: 1 }}
                                    >
                                      Withdraw
                                    </Button>
                                  )}
                                  <h3 style={{ color: "#929292" }}>
                                    Total Profit : <br></br>
                                    {user.pool_balance_roi} USDT
                                  </h3>
                                </div>
                              </>
                            ) : (
                              <>
                                <h4>Required NFTs :</h4>

                                <div>
                                  <Checkbox
                                    checked={nft_counts["Taipei"].overall >= 1}
                                    style={{
                                      color:
                                        nft_counts["Taipei"].overall >= 1
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {nft_counts["Taipei"].overall} / 1 x Taipei
                                  </span>
                                </div>

                                <div>
                                  <Checkbox
                                    checked={
                                      nft_counts["China Zun Tower"].overall >= 1
                                    }
                                    style={{
                                      color:
                                        nft_counts["China Zun Tower"].overall >=
                                        1
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {nft_counts["China Zun Tower"].overall} / 1
                                    x China Zun Tower
                                  </span>
                                </div>

                                <div>
                                  <Checkbox
                                    checked={
                                      nft_counts["Tianjin_CTF_Finance_Center"]
                                        .overall >= 1
                                    }
                                    style={{
                                      color:
                                        nft_counts["Tianjin_CTF_Finance_Center"]
                                          .overall >= 1
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {
                                      nft_counts["Tianjin_CTF_Finance_Center"]
                                        .overall
                                    }{" "}
                                    / 1 x Tianjin CTF
                                  </span>
                                </div>
                              </>
                            )}

                            <div className="p-3">
                              <h5>{settings.value_per_share} USDT / Share</h5>
                            </div>
                          </div>

                          <div className="col-4 p-2">
                            <img
                              src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/43730c22-5d64-47ee-02f8-a0e6e54be900/public"
                              style={{
                                width: "100%",
                                margin: "0",
                                "@media (maxWidth: 700px)": {
                                  marginTop: "25px",
                                },
                              }}
                            />
                            <p>Member: 21</p>
                            <Button
                              className={classes.nft_buttons + " mb-3"}
                              variant="contained"
                              color="primary"
                              onClick={handleClickOpenGlobal}
                            >
                              Stats
                            </Button>
                            <Dialog
                              open={openGlobal}
                              onClose={handleCloseGlobal}
                            >
                              <DialogContent>
                                <div className="mui-dialog-content text-center">
                                  <h3 className="mui-dialog-title">
                                    Global Bonus Pool Statistics
                                  </h3>
                                  <br></br>
                                  <Typography variant="h6" component="h6">
                                    Capital: {settings.global_bonus_capital}{" "}
                                    USDT
                                  </Typography>
                                  <Typography variant="h6" component="h6">
                                    Profit: {user.pool_balance} USDT
                                  </Typography>
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCloseGlobal}
                                  >
                                    close
                                  </Button>
                                </div>
                              </DialogContent>
                            </Dialog>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2 mb-2 justify-content-md-center">
                    <div className="col-xl-6">
                      <div className="card p-3">
                        <div className="card-header text-center p-0 flex-wrap">
                          <h1>CZT Residual Income Pool</h1>
                        </div>

                        <div className="card-body p-0 mt-3 row">
                          <div className="col-8" style={{ textAlign: "left" }}>
                            <h4 className="mb-0">
                              Shares : {user.czt_pool_shares}
                            </h4>
                            <h6 className="mb-0">
                              {user.shares - user.czt_pool_shares ===
                              0 /* <h6 className="text-muted">+{user.shares - user.shares_0104} active since 01.06.23</h6> */ ? null : (
                                <h6 className="text-muted">
                                  +{user.shares - user.czt_pool_shares} active
                                  on 01.12.23
                                </h6>
                              )}
                            </h6>

                            <h4 className="mb-0">
                              Total Profit : {user.czt_pool_roi.toFixed(2)} USDT
                            </h4>
                            <h4 className="mb-0">
                              {(CZTCompound / user.czt_residual_shares).toFixed(
                                2
                              )}{" "}
                              USDT / Share
                            </h4>
                            <br></br>
                            <h4 className="mb-0">Next Increase of Shares:</h4>
                            <h4>
                              + {user.nftsToNextShare} CZT NFT's = +{" "}
                              {user.nextShares} Shares
                            </h4>
                          </div>

                          <div className="col-4 p-2">
                            <img
                              src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/fd4be244-9991-4c3c-205f-baec34ca1b00/public"
                              style={{
                                width: "100%",
                                margin: "0",
                                "@media (maxWidth: 700px)": {
                                  marginTop: "25px",
                                },
                              }}
                            />
                          </div>
                          <h6 className="mt-3">
                            Total Shares : {user.czt_residual_shares}
                          </h6>
                          {/* <h6>
                            Total Shares active on {getNextMonthFirstDay()}: 194
                          </h6> */}
                          <h6>Remaining CZT NFT's : 0</h6>

                          <div
                            className="col-12"
                            style={{ display: "flex", gap: "5px" }}
                          >
                            <Button
                              className={classes.nft_buttons + " mb-3"}
                              variant="contained"
                              color="primary"
                              onClick={handleClickOpenCZTStats}
                              style={{ flex: 1 }}
                            >
                              Stats
                            </Button>
                            <Button
                              className={classes.nft_buttons + " mb-3"}
                              variant="contained"
                              color="primary"
                              onClick={handleClickOpenCZTInfo}
                              style={{ flex: 1 }}
                            >
                              Info
                            </Button>
                            {user.cztpool_balance !== "NULL" && (
                              <Button
                                className={classes.nft_buttons + " mb-3"}
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpenCZTWithdraw}
                                style={{ flex: 1 }}
                                disabled
                              >
                                Withdraw
                              </Button>
                            )}
                          </div>

                          <Dialog
                            open={openCZTWithdraw}
                            onClose={handleCloseCZTWithdraw}
                          >
                            <DialogContent>
                              <div className="mui-dialog-content text-center">
                                <h3 className="mui-dialog-title">
                                  Withdraw Details
                                </h3>
                                <br></br>
                                <Typography variant="h6" component="h6">
                                  Amount: {user.cztpool_balance} USDT
                                </Typography>
                                <Typography variant="h6" component="h6">
                                  Time: 24 Hours
                                </Typography>
                                <Button
                                  className={" m-3"}
                                  variant="contained"
                                  color="secondary"
                                  onClick={handleCloseCZTWithdraw}
                                >
                                  close
                                </Button>
                                <Button
                                  className={classes.nft_buttons + " m-3"}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleWithdrawCZTPool}
                                >
                                  Confirm
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>

                          <Dialog
                            open={openCZTStats}
                            onClose={handleClickCloseCZTStats}
                          >
                            <DialogContent>
                              <div className="mui-dialog-content text-center">
                                <h3 className="mui-dialog-title">
                                  CZT Residual Income Pool Statistics
                                </h3>
                                <br></br>
                                <Typography variant="h7" component="h6">
                                  Capital: {CZTCapital} USDT
                                </Typography>
                                <Typography variant="h7" component="h6">
                                  Compound (50%): {CZTCompound} USDT
                                </Typography>
                                <Typography variant="h7" component="h6">
                                  Payout Shares (50%): {CZTCompound} USDT
                                </Typography>
                                <Button
                                  className={classes.nft_buttons + " mt-3"}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickCloseCZTStats}
                                >
                                  close
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>

                          <Dialog
                            open={openCZTInfo}
                            onClose={handleClickCloseCZTInfo}
                          >
                            <DialogContent>
                              <div className="mui-dialog-content text-center">
                                <h3 className="mui-dialog-title">
                                  CZT Residual Income Pool Info
                                </h3>
                                <br></br>
                                <Typography
                                  variant="h6"
                                  className={classes.flex_container}
                                  style={{
                                    color:
                                      nft_counts["China Zun Tower"].overall >= 5
                                        ? "green"
                                        : "inherit",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>5 CZT</div>
                                  <div style={{ width: "50%" }}>1 Share</div>
                                </Typography>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  className={classes.flex_container}
                                  style={{
                                    color:
                                      nft_counts["China Zun Tower"].overall >=
                                      10
                                        ? "green"
                                        : "inherit",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>10 CZT</div>
                                  <div style={{ width: "50%" }}>3 Shares</div>
                                </Typography>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  className={classes.flex_container}
                                  style={{
                                    color:
                                      nft_counts["China Zun Tower"].overall >=
                                      15
                                        ? "green"
                                        : "inherit",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>15 CZT</div>
                                  <div style={{ width: "50%" }}>5 Shares</div>
                                </Typography>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  className={classes.flex_container}
                                  style={{
                                    color:
                                      nft_counts["China Zun Tower"].overall >=
                                      20
                                        ? "green"
                                        : "inherit",
                                  }}
                                >
                                  <div style={{ width: "50%" }}>20 CZT</div>
                                  <div style={{ width: "50%" }}>10 Shares</div>
                                </Typography>
                                <Button
                                  className={classes.nft_buttons + " mt-3"}
                                  variant="contained"
                                  color="primary"
                                  onClick={handleClickCloseCZTInfo}
                                >
                                  close
                                </Button>
                              </div>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div
                className="row mt-2 justify-content-md-center"
                style={{ marginBottom: "90px" }}
              >
                <div className="col-xl-6">
                  <div className="card p-3">
                    <div className="card-header text-center p-0 flex-wrap">
                      <h1>Transactions</h1>
                      {/* <Tabs value={currentTab} onChange={handleTabChange}>
                      <Tab label="All" />
                      <Tab label="Withdrawals" />
                      <Tab label="Partner" />
                    </Tabs> */}
                    </div>
                    <CardContent className="p-0 mt-3">
                      {currentTab === 0 && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Partner</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mergedPaginatedTransactions.map(
                              (transaction, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {new Date(
                                      transaction.updated_at
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.contract_id === "7" ||
                                    transaction.contract_id === "8"
                                      ? `${transaction.amount} USDT`
                                      : `${transaction.amount} BNB`}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.contract_id === "8"
                                      ? `I-Charge`
                                      : `${transaction.partner_name}`}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      )}

                      {currentTab === 1 && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Partner</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedwithdrawTransactions.map(
                              (transaction, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {new Date(
                                      transaction.updated_at
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.contract_id === "7"
                                      ? `${transaction.amount} USDT`
                                      : `${transaction.amount} BNB`}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.partner_name}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      )}

                      {currentTab === 2 && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Partner</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedTransactions.map((transaction, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  {new Date(
                                    transaction.updated_at
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </TableCell>
                                <TableCell>
                                  {transaction.contract_id === "7"
                                    ? `${transaction.amount} USDT`
                                    : `${transaction.amount} BNB`}
                                </TableCell>
                                <TableCell>
                                  {transaction.partner_name}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                    </CardContent>
                    <TablePagination
                      rowsPerPageOptions={pageSizeOptions}
                      component="div"
                      count={refTransactions.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      labelRowsPerPage="Show:"
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pools;

function truncateNumber(value) {
  if (value === "") {
    return value;
  } else {
    let stringValue = value.toString();
    const index = stringValue.indexOf(".");
    if (index !== -1 && index + 6 < stringValue.length) {
      stringValue = stringValue.substring(0, index + 6);
    }
    return stringValue.endsWith(".") ? stringValue : Number(stringValue);
  }
}
