import React, { useState } from "react";
import axios from "axios";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import { countries } from "../countries.js";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const StepTwo = ({ cardHolderId, onSubmitSuccess }) => {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [localCardKycData, setLocalCardKycData] = useState(
    JSON.parse(localStorage.getItem("card_kyc_data")) || null
  );
  console.log("localCardKycData", localCardKycData);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [apiError, setApiError] = useState("");

  const cardHolderID = cardHolderId || localCardKycData.cardHolderId;

  const [formData, setFormData] = useState({
    cardHolderID: cardHolderID,
    idNumber: "",
    idIssuedBy: "DE",
    idExpDate: "",
    idIssueDate: "",
    idType: 0,
    idDocLang: 0,
    id_FrontSide: "",
    id_BackSide: "",
  });

  const handleCountryChange = (e) => {
    console.log(e.target.value);
    setSelectedCountry(e.target.value);
    setFormData({
      ...formData,
      idIssuedBy: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file.type === "image/heic") {
      alert("HEIC format is not supported");
      e.target.value = null;

      return;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        setFormData({ ...formData, [e.target.name]: reader.result });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const apiEndpoint =
      // "https://sandbox.fcfpay.com/api/physical-cards/holder-id-document";
      "https://dashboard.croesus-asset.management/api/physical-cards/holder-id-document";
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        //   "Bearer CMZDAudUyvVZ8KIc58FymvqwBBxJM1ZgqC6ZHeqIBRbvzh8rTDvMB00DvwVI",
        "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
    };

    try {
      console.log("formdata", formData);
      const response = await axios.post(apiEndpoint, formData, { headers });
      console.log("API Response", response.data); // Handle the response as needed
      if (
        response.data.success === true ||
        response.data.message === "Something went wrong"
      ) {
        const data = {
          user_id: localUser.id,
          kyc_step: 3,
          idNumber: formData.idNumber,
          idIssuedBy: formData.idIssuedBy,
          idExpDate: formData.idExpDate,
          idIssueDate: formData.idIssueDate,
          idType: formData.idType,
          idDocLang: formData.idDocLang,
        };
        console.log("data", data);

        fetch("https://test.croesus-asset.management/api/submit_kyc_data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((response) => response.json());

        onSubmitSuccess(true);
        setIsLoading(false);
      } else {
        setApiError(response.data.message || "An error occurred");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("There was an error!", error);
      setApiError(
        error.response?.data?.message ||
          JSON.stringify(error.response?.data) ||
          "An error occurred"
      );
      setIsLoading(false);
    }
  };

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="row">
          {/* ID Document Fields */}
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">Select ID*</label>
              <select
                name="idType"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.idType}
              >
                <option value="0">Passport</option>
                <option value="2">National Id</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black"> ID Language*</label>
              <select
                name="idDocLang"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.idDocLang}
              >
                <option value="0">English</option>
                <option value="1">Spanish</option>
                <option value="2">French</option>
                <option value="3">Arabic</option>
                <option value="4">Chinese</option>
                <option value="5">Japanese</option>
                <option value="6">Russian</option>
                <option value="7">Portuguese</option>
                <option value="8">German</option>
                <option value="9">Italian</option>
                <option value="10">Hindi</option>
                <option value="11">Other</option>
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">ID Number*</label>
              <input
                type="text"
                name="idNumber"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.idNumber}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">
                Which Country issued your ID?*
              </label>

              <select
                name="idIssuedBy"
                value={selectedCountry}
                onChange={handleCountryChange}
                className="form-control text-black"
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.isoCode}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">Date Of Expiry*</label>
              <input
                type="date"
                name="idExpDate"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.idExpDate}
                required
              />
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">Date Of Issue*</label>
              <input
                type="date"
                name="idIssueDate"
                className="form-control text-black"
                onChange={handleChange}
                value={formData.idIssueDate}
                required
              />
            </div>
          </div>
          <div className="col-12 mb-2">
            <Accordion className="accordion accordion-danger-solid text-black">
              <Accordion.Item eventKey={110}>
                <Accordion.Header className="accordion-header">
                  Important Information
                </Accordion.Header>
                <Accordion.Collapse className="accordion__body">
                  <div className="accordion-body">
                    - Do not blur out any details <br></br>- If the ID has one
                    page only, please upload the same file to ID Front and ID
                    Back Make sure that all the entered details match your ID!
                  </div>
                </Accordion.Collapse>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">ID Front Side*</label>
              <div className="form-file">
                <input
                  type="file"
                  name="id_FrontSide"
                  className="form-control text-black form-file-input"
                  onChange={handleFileChange}
                  accept="image/*"
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-2">
            <div className="form-group mb-3">
              <label className="text-label text-black">ID Back Side*</label>
              <div className="form-file">
                <input
                  type="file"
                  name="id_BackSide"
                  className="form-control text-black form-file-input"
                  onChange={handleFileChange}
                  accept="image/*"
                  required
                />
              </div>
            </div>
          </div>
          {apiError && <div className="alert alert-danger">{apiError}</div>}
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <button
              type="submit"
              className="btn btn-primary "
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100px",
              }}
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                </>
              ) : (
                <div>Submit</div>
              )}
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default StepTwo;
