import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";

const BirthdayCalendar = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1); // JavaScript months are 0-indexed

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          "https://test.croesus-asset.management/api/get_birthdays"
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching birthdays:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBirthdays();
  }, []);

  const getFormattedDate = (dateStr) => {
    const date = new Date(dateStr);
    return { day: date.getDate(), month: date.getMonth() + 1 };
  };

  const monthUsers = [...Array(31)].map((_, i) => ({
    day: i + 1,
    users: users.filter((user) => {
      const { day, month } = getFormattedDate(user.dob);
      return day === i + 1 && month === currentMonth;
    }),
  }));

  const changeMonth = (increment) => {
    setCurrentMonth((prevMonth) => {
      let newMonth = prevMonth + increment;
      if (newMonth > 12) newMonth = 1;
      if (newMonth < 1) newMonth = 12;
      return newMonth;
    });
  };

  return (
    <Card className="m-3">
      <CardContent>
        <Typography variant="h4">Birthdays in Month: {currentMonth}</Typography>
        <Button
          className="m-3"
          variant="contained"
          color="primary"
          onClick={() => changeMonth(-1)}
        >
          Previous Month
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => changeMonth(1)}
        >
          Next Month
        </Button>
        {isLoading ? (
          <Typography variant="body1">Loading Dates...</Typography>
        ) : (
          <Grid container spacing={3}>
            {monthUsers.map(({ day, users }) => (
              <Grid item xs={12} sm={6} md={4} key={day}>
                <Typography variant="h6">Day {day}</Typography>
                {users.map((user) => (
                  <Typography variant="body1" key={user.id}>
                    {user.name} - : {user.dob} <br></br>
                    {user.adress} <br></br>
                    {user.city} <br></br>
                    {user.state} <br></br>
                    {user.country} <br></br>
                    {/* Link to verificaation page  */}
                    <Button
                      variant="contained"
                      color="primary"
                      href={`https://kyc.amlbot.com/en/verifications/${user.verificationid}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      AML Link
                    </Button>
                  </Typography>
                ))}
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default BirthdayCalendar;
