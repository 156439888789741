import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { Grid, FormControl, InputLabel, Select } from "@mui/material";

import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";

import {
  MARKETPLACE_ABI,
  MARKETPLACE_CONTRACT,
  ABI_USDT,
  USDT_CONTRACT,
} from "./components/marketplacecontracts";
import {
  CRGMARKETPLACE_ABI,
  CRGMARKETPLACE_CONTRACT,
} from "./components/crgmarketplacecontracts";
import {
  ICHARGEMARKETPLACE_ABI,
  ICHARGEMARKETPLACE_CONTRACT,
} from "./components/ichargemarketplacecontracts";
import {
  ICHARGEMARKETPLACE_ABI_BATCH,
  ICHARGEMARKETPLACE_CONTRACT_BATCH,
} from "./components/ichargemarketplacecontractsbatch";
import { NEWCOIN_ABI, NEWCOIN_CONTRACT } from "./components/NewCoinContract";
import { usdtabi } from "./components/content/usdtabi";
import Web3 from "web3";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Snackbar } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
} from "@mui/material";
import MuiAlert from "@material-ui/lab/Alert";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Spring from "./components/Spring";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { CSSTransition } from "react-transition-group";
import "./animation.css";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import { MINI_CONTRACT } from "./components/MiniContractInfo";

const useStyles = makeStyles((theme) => ({
  style_container: {
    gap: "10px",
    justifyContent: "space-between",
    padding: "15px 10px",
  },
  smooth_container: {
    transition: "transform 0.3s",
  },
}));

const tabStyles = {
  "&.Mui-selected": {
    color: "#FFFFFF",
    backgroundColor: "#f8be24c7",
    height: "-webkit-fill-available",
    minHeight: "20px",
    maxHeight: "30px",
    borderRadius: "12px",
  },
  "&:not(.Mui-selected)": {
    height: "-webkit-fill-available",
    minHeight: "20px",
    maxHeight: "30px",
    borderRadius: "12px",
  },
  "&:.MuiTabs-centered": {
    borderRadius: "12px",
    border: "1px solid #bebebe",
    display: "block",
  },
};

const nftOptions = [
  {
    name: "LIVETRADING",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/8df77e0d-69b7-4707-cd1c-bebb64fd2200/public",
  },
  {
    name: "MASTERCLASS",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1f85583b-ebd7-4d88-7c37-cf9ceb5f3200/public",
  },
  {
    name: "CZT",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/b36c74bd-f273-45b7-4b24-0c9960c1a400/public",
  },
  {
    name: "Taipei",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/e516b1ea-a6c2-4948-f714-4a4922871b00/public",
  },
  {
    name: "TIANJIN",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/7fd1a2f6-9771-477c-0d51-7722a1442300/public",
  },
  {
    name: "LWT",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/bb739685-c033-47c6-7daf-2001f1180b00/public",
  },
  {
    name: "CRG",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/ca6943f6-b81d-4b82-da41-96a3630fc300/public",
  },
  {
    name: "USDT",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/438b50d9-2541-4cf6-fe7c-99b72109e800/public",
  },
  {
    name: "ICHARGE",
    imgUrl:
      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/2ce1e825-9dfb-4df3-0171-14c83aeb9700/public",
  },
];

function Marketplace() {
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [selectedTab, setSelectedTab] = useState(0);
  const [crgs, setCrgs] = useState({
    CRG: [],
  });
  const [nfts, setNfts] = useState({
    Taipei: [],
    CZT: [],
    Tianjin: [],
    LTW: [],
    CRG: [],
    ICHARGE: [],
    MASTERCLASS: [],
    LIVETRADING: [],
  });
  const [buyoffernfts, setbuyoffernfts] = useState({
    Taipei: [],
    CZT: [],
    Tianjin: [],
    LTW: [],
    CRG: [],
    ICHARGE: [],
    MASTERCLASS: [],
    LIVETRADING: [],
  });
  const [dataFetched, setDataFetched] = useState(false);
  const [stakedvalues, setstakedvalues] = useState(false);
  const tokens = [];
  const allTabLabels = [
    "LIVETRADING",
    "MASTERCLASS",
    "CZT",
    "Taipei",
    "Tianjin",
    "LTW",
    "CRG",
    "ICHARGE",
  ];
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCRG, setOpenDialogCRG] = useState(false);
  const [openDialogOffer, setOpenDialogOffer] = useState(false);
  const [OpenDialogBuyCRG, setOpenDialogBuyCRG] = useState(false);
  const [OpenDialogBuyICHARGE, setOpenDialogBuyICHARGE] = useState(false);

  const [OpenDialogBuyUSDT, setOpenDialogBuyUSDT] = useState(false);

  const [newPrice, setNewPrice] = useState(0);
  const [NewPriceOffer, setNewPriceOffer] = useState(0);
  const [BuyOfferNFT, setBuyOfferNFT] = useState(null);
  const [BuyAmount, setBuyAmount] = useState(1);
  const [buyCRGAmount, setBuyCRGAmount] = useState("");
  const [buyUSDTAmount, setBuyUSDTAmount] = useState("");

  const [User, setUser] = useState(null);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [selectedNFTId, setSelectedNFTId] = useState(null);
  const [selectedNFTContract, setSelectedNFTContract] = useState(null);
  const [selectedNFTName, setSelectedNFTName] = useState(null);
  const [error, setError] = useState(null);
  const [success_message, setsuccess_message] = useState(null);
  const [loadingChangePrice, setloadingChangePrice] = useState(false);
  const [loadingCancelOffer, setloadingCancelOffer] = useState(false);
  const [loadingBuyOffer, setloadingBuyOffer] = useState(false);
  const [loadingMakeOffer, setloadingMakeOffer] = useState(false);
  const [loadingNFTIds, setLoadingNFTIds] = useState([]);
  const [loadingDeleteOffer, setloadingDeleteOffer] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [floorprice_taipei, setfloorprice_taipei] = useState(0);
  const [floorprice_czt, setfloorprice_czt] = useState(0);
  const [floorprice_ctf, setfloorprice_ctf] = useState(0);
  const [floorprice_msc, setfloorprice_msc] = useState(0);

  const [openTelegram, setOpenTelegram] = useState(false);
  const [telegramPin, setTelegramPin] = useState(null);
  const [loadingTelegram, setloadingTelegram] = useState(false);
  const [tokenAmountBuyDialog, setTokenAmountBuyDialog] = useState(0);

  const [loadingMessage, setloadingMessage] = useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [crg_sell_amount, setcrg_sell_amount] = useState(0);
  const [openCRGSell, setopenCRGSell] = useState(false);
  const [tokenAmount, setTokenAmount] = useState("");
  const [tokenAmountUSDT, settokenAmountUSDT] = useState("");
  const [priceCRGSell, setPrice] = useState("");
  const [priceCRGBUY, setpriceCRGBUY] = useState("");
  const [usdtamount, setUsdtAmount] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [usdtFee, setUsdtFee] = useState(0);
  const [crgBalance, setcrgBalance] = useState(0);
  const [crgBalanceWei, setcrgBalanceWei] = useState(0);
  const [usdtBalanceWei, setusdtBalanceWei] = useState(0);
  const [crgPrice, setcrgPrice] = useState(0);
  const [listingFeeCRG, setlistingFeeCRG] = useState(0);
  const [maxBuyAmountUSDT, setMaxBuyAmountUSDT] = useState(0);
  const [maxBuyAmountUSDTFee, setMaxBuyAmountUSDTFee] = useState(0);
  const [usdtamountbrutto, setusdtamountbrutto] = useState(0);
  const [maxBuyAmountCRG, setMaxBuyAmountCRG] = useState(0);

  const [canexecutesellbuy, setcanexecutesellbuy] = useState(false);
  const [executeerror, setexecuteerror] = useState("");
  const [canExecuteChangePrice, setcanExecuteChangePrice] = useState(false);
  const [executeChangePriceError, setexecuteChangePriceError] = useState("");
  const [createListingEnabled, setIsCreateListingEnabled] = useState(false);
  const [dataisfetching, setdataisfetching] = useState(true);
  const [MaxClickCRGBUY, setMaxClickCRGBUY] = useState(false);
  const [usersData, setUsersData] = useState([]);

  // const handleSellCRG = () => {
  //   setopenCRGSell(true);
  // };

  const handleMaxClickUSDTBUY = () => {
    setexecuteerror("");
    console.log("listingFeeCRG ", listingFeeCRG);
    console.log("crgBalance ", crgBalance);
    console.log("priceCRGSell ", priceCRGSell);
    console.log("tokenAmountBuyDialog Max USDT", tokenAmountBuyDialog);
    // let crg_amount = tokenAmountBuyDialog / priceCRGSell;

    if (crgBalance < 0.01) {
      setusdtamountbrutto(0);
      setcrg_sell_amount(0);
      setMaxBuyAmountUSDTFee(0);
      setBuyUSDTAmount(0);
      setMaxBuyAmountUSDT(0);
    } else {
      let crg_amount =
        Web3.utils.toWei(String(tokenAmountBuyDialog), "wei") /
        Web3.utils.toWei(String(priceCRGSell), "wei");
      crg_amount = Web3.utils.toWei(String(crg_amount), "ether");

      if (crg_amount > crgBalance) {
        crg_amount = crgBalance;
      }
      console.log("crg_amount ", crg_amount);
      let fee = (tokenAmountBuyDialog * listingFeeCRG) / 100;
      let net_amount = tokenAmountBuyDialog - fee;
      setusdtamountbrutto(crg_amount);
      setcrg_sell_amount(crg_amount);
      setMaxBuyAmountUSDTFee(fee);
      setBuyUSDTAmount(crg_amount);
      setMaxBuyAmountUSDT(net_amount);
    }
  };

  const handleMaxClickIchargebatch = () => {
    setexecuteerror("");
    setMaxClickCRGBUY(true);
    console.log("usdtBalance ", usdtBalance);
    console.log("priceCRGSell ", priceCRGSell);
    console.log("handleMaxClickIchargebatch ", tokenAmountBuyDialog);
    if (usdtBalanceWei < 1000000) {
      setcrg_sell_amount(0);
      setBuyCRGAmount(0);
    } else {
      //calculate maximum buy amount icharge in usdt
      let max_icharge_amount = Math.floor(usdtBalance / priceCRGSell);
      console.log("max_icharge_amount ", max_icharge_amount);

      setcrg_sell_amount(max_icharge_amount);
      setBuyCRGAmount(max_icharge_amount);
      setcanexecutesellbuy(true);
    }
  };

  const handleMaxClickCRGBUY = () => {
    setexecuteerror("");
    setMaxClickCRGBUY(true);
    console.log("usdtBalance ", usdtBalance);
    console.log("priceCRGSell ", priceCRGSell);
    console.log("handleMaxClickCRGBUY ", tokenAmountBuyDialog);
    if (usdtBalanceWei < 1000000) {
      setcrg_sell_amount(0);
      setBuyCRGAmount(0);
    } else {
      let crg_amount = usdtBalance / priceCRGSell;
      console.log("crg_amount ", crg_amount);
      if (usdtBalance < usdtamount) {
        usdtamount = usdtBalance;
      }

      if (crg_amount > tokenAmountBuyDialog) {
        crg_amount = tokenAmountBuyDialog;
      }
      console.log("crg_amount ", crg_amount);
      setcrg_sell_amount(crg_amount);
      setBuyCRGAmount(crg_amount);
      setcanexecutesellbuy(true);
    }
  };

  const handleMaxClick = () => {
    console.log("setTokenAmount to", usdtBalance);
    settokenAmountUSDT(usdtBalance);
  };

  const handleClose = () => {
    setOpenBackdrop(false);
  };
  const handleOpen = () => {
    setOpenBackdrop(true);
  };

  const handleCloseTelegram = () => {
    setOpenTelegram(false);
  };

  const navigate = useNavigate();

  const handleOpenMenu = (event, nft) => {
    console.log("event", event);
    console.log("nft", nft);
    let menu;
    if (nft.contractAddress === "0x55d398326f99059fF775485246999027B3197955") {
      menu = "buy_" + nft.id;
    } else {
      menu = "sell_" + nft.id;
    }

    setMenuAnchor((prevState) => ({
      ...prevState,
      [menu]: event.currentTarget,
    }));
  };

  const handleCloseMenu = (nft) => {
    let menu;
    if (nft.contractAddress === "0x55d398326f99059fF775485246999027B3197955") {
      menu = "buy_" + nft.id;
    } else {
      menu = "sell_" + nft.id;
    }
    setMenuAnchor((prevState) => ({
      ...prevState,
      [menu]: null,
    }));
  };

  const handleSellClick = () => {
    navigate("/dashboard");
  };

  const handleExpand = () => {
    setShowTable(!showTable);
    setIsExpanded(!isExpanded);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSnackbarClose = () => {
    setError(null);
    setsuccess_message(null);
  };

  const displayErrorAlert = (message) => {
    setError(message);
    setTimeout(() => {
      setError(null);
    }, 2000);
  };

  const displaySuccessAlert = (message) => {
    setsuccess_message(message);
    setTimeout(() => {
      setsuccess_message(null);
    }, 2000);
  };

  const fetchListingFeeCRG = async () => {
    try {
      const listing_fee = await readContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: "getListingFee",
      });
      console.log("listing_fee:", listing_fee);

      setlistingFeeCRG(Number(listing_fee));
    } catch (error) {
      console.error(error);
    }
  };

  const tabLabels = Object.keys(nfts);

  useEffect(() => {
    console.log("buyUSDTAmount:", buyUSDTAmount);
    console.log("priceCRGSell:", priceCRGSell);
    console.log("tokenAmountBuyDialogs:", tokenAmountBuyDialog);
    let usdt_amount = buyUSDTAmount * priceCRGSell;
    // let usdt_amount =
    //   Web3.utils.toWei(String(buyUSDTAmount), "wei") *
    //   Web3.utils.toWei(String(priceCRGSell), "wei");
    console.log("usdt_amount:", usdt_amount);
    console.log("crg_amount:", buyUSDTAmount);
    console.log("crgBalance:", crgBalance);

    let crg_amount = buyUSDTAmount;
    if (crg_amount > crgBalance) {
      setcanexecutesellbuy(false);
      setexecuteerror("Not Enough Balance");
    } else {
      setcanexecutesellbuy(true);
    }
    if (usdt_amount > tokenAmountBuyDialog) {
      usdt_amount = tokenAmountBuyDialog;
      crg_amount = tokenAmountBuyDialog / priceCRGSell;
    }
    console.log("usdt_amount:", usdt_amount);
    console.log("crg_amount:", crg_amount);

    let fee = (usdt_amount * listingFeeCRG) / 100;
    let net_amount = usdt_amount - fee;

    setusdtamountbrutto(usdt_amount);
    setMaxBuyAmountUSDT(net_amount);
    setcrg_sell_amount(crg_amount);
    setMaxBuyAmountUSDTFee(fee);
    setTokenAmount(crg_amount);
    setBuyUSDTAmount(crg_amount);
  }, [buyUSDTAmount]);

  useEffect(() => {
    setexecuteerror("");
    console.log("buyCRGAmount:", buyCRGAmount);
    console.log("priceCRGSell:", priceCRGSell);
    console.log("UseEffect buyCRGAmount:", tokenAmountBuyDialog);
    let input_crg_amount = buyCRGAmount;
    if (Number(buyCRGAmount) > tokenAmountBuyDialog) {
      console.log("Input > MaxAmount");
      input_crg_amount = tokenAmountBuyDialog;
    }

    let test =
      Web3.utils.toWei(String(buyCRGAmount), "wei") *
      Web3.utils.toWei(String(priceCRGSell), "wei");
    console.log("test:", test);

    console.log("input_crg_amount:", input_crg_amount);
    console.log("priceCRGSell:", priceCRGSell);
    let usdt_amount = Number(input_crg_amount) * priceCRGSell;
    console.log("usdt_amount:", usdt_amount);
    console.log("usdtBalance:", usdtBalance);
    if (usdt_amount > usdtBalance) {
      setcanexecutesellbuy(false);
      setexecuteerror("Not Enough Balance");
      usdt_amount = usdtBalance;
    } else {
      setcanexecutesellbuy(true);
    }

    setusdtamountbrutto(usdt_amount);
    setcrg_sell_amount(input_crg_amount);
    setMaxBuyAmountUSDT(usdt_amount);
    setTokenAmount(input_crg_amount);
    setBuyCRGAmount(input_crg_amount);
  }, [buyCRGAmount]);

  useEffect(() => {
    if (priceCRGSell !== "") {
      if (tokenAmountUSDT > 0) {
        console.log("priceCRGSell:", priceCRGSell);
        console.log("tokenAmountUSDT:", tokenAmountUSDT);
        console.log("listingFeeCRG:", listingFeeCRG);
        let usdt_fee = tokenAmountUSDT * (listingFeeCRG / 100);
        console.log("usdt_fee:", usdt_fee);
        let usdt_seller = tokenAmountUSDT - usdt_fee;
        console.log("usdt_seller:", usdt_seller);
        let crg_amount = usdt_seller / priceCRGSell;
        console.log("crg_amount:", crg_amount);

        setcrg_sell_amount(crg_amount);
        // setUsdtAmount(usdt_amount);
        setUsdtFee(usdt_fee);
      }
    }
  }, [priceCRGSell, tokenAmountUSDT]);

  useEffect(() => {
    console.log("priceCRGBUY:", priceCRGBUY);
    console.log("tokenAmount:", tokenAmountUSDT);
    if (priceCRGBUY !== "") {
      if (priceCRGBUY < crgPrice) {
        setIsCreateListingEnabled(false);
        return;
      } else {
        setIsCreateListingEnabled(true);
      }
      if (tokenAmountUSDT > 0) {
        let crg_amount = tokenAmountUSDT / priceCRGBUY;
        console.log("crg_amount:", crg_amount);
        setcrg_sell_amount(crg_amount);
      }
    }
  }, [priceCRGBUY, tokenAmountUSDT]);

  const handleCreateListingCRG = async () => {
    setloadingMessage("Checking Approval...");
    handleOpen();
    try {
      const weiTokenAmount = Web3.utils.toWei(String(tokenAmountUSDT), "ether");
      const weiPrice = Web3.utils.toWei(String(priceCRGBUY), "ether");

      const approval = await readContract({
        address: "0x55d398326f99059fF775485246999027B3197955",
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, CRGMARKETPLACE_CONTRACT],
      });
      console.log("approval:", approval);
      console.log("usdtBalanceWei:", usdtBalanceWei);
      const etherAmountApproval = Web3.utils.fromWei(approval, "ether");
      console.log("etherAmountApproval:", etherAmountApproval);
      console.log("crg_sell_amount:", crg_sell_amount);

      if (approval < usdtBalanceWei) {
        setloadingMessage("Approve USDT Amount...");
        const { request: approverequest } = await prepareWriteContract({
          address: "0x55d398326f99059fF775485246999027B3197955",
          abi: usdtabi,
          functionName: "approve",
          args: [CRGMARKETPLACE_CONTRACT, usdtBalanceWei],
          account: walletAddress,
        });
        console.log("approverequest:", approverequest);
        const { hash: approvehash } = await writeContract(approverequest);
        console.log("approvehash:", approvehash);
        const approvaldata = await waitForTransaction({
          hash: approvehash,
        });
        console.log("approvaldata:", approvaldata);
        setloadingMessage("USDT Amount Approved...");
      }

      console.log("weiTokenAmount:", weiTokenAmount);
      console.log("weiPrice:", weiPrice);
      const { request } = await prepareWriteContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: "createBuyOffer",
        args: [
          "0x55d398326f99059fF775485246999027B3197955",
          weiTokenAmount,
          weiPrice,
        ],
        account: walletAddress,
      });
      setloadingMessage("Confirm Create Listing...");

      const { hash } = await writeContract(request);
      setloadingMessage("Transaction found...");

      const checkClaim = await waitForTransaction({
        hash: hash,
      });

      setsuccess_message("Listing Created Successfully");
      handleClose();
      setTimeout(() => {
        setDataFetched(false);
      }, 2000);
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        setError("User rejected the request");
      } else {
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(error);
        console.error(errorMessage);
        setError(errorMessage);
      }
      handleClose();
    }
  };

  const handleCloseCreateListingCRG = () => {
    setSelectedNFT(null);
    setTokenAmount(0);
    setopenCRGSell(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched) {
        try {
          setdataisfetching(true);
          console.log("fetchAvailableMarketItems");
          const NFT_ITEMS = await fetchAvailableMarketItems();
          console.log("NFT_ITEMS:", NFT_ITEMS);
          setNfts(NFT_ITEMS);
          const CRG_ITEMS = await fetchAvailableCRGMarketItems();
          console.log("CRG_ITEMS:", CRG_ITEMS);
          const ICHARGE_ITEMS = await fetchAvailableICHARGEMarketItems();
          console.log("ICHARGE_ITEMS:", ICHARGE_ITEMS);

          setNfts((prevNfts) => ({
            ...prevNfts,
            CRG: CRG_ITEMS,
            ICHARGE: ICHARGE_ITEMS,
          }));

          const NFT_BUYOFFERS = await fetchMarketBuyOffers();
          console.log("NFT_BUYOFFERS:", NFT_BUYOFFERS);
          setbuyoffernfts(NFT_BUYOFFERS);

          const CRGBalance = await readContract({
            address: NEWCOIN_CONTRACT,
            abi: NEWCOIN_ABI,
            functionName: "balanceOf",
            args: [walletAddress],
          });
          console.log("CRGBalance:", CRGBalance);
          setcrgBalanceWei(CRGBalance);
          const CRGBalance_InEther = Web3.utils.fromWei(
            String(CRGBalance),
            "ether"
          );
          setcrgBalance(CRGBalance_InEther);

          const CRGMaxSupply = await readContract({
            address: NEWCOIN_CONTRACT,
            abi: NEWCOIN_ABI,
            functionName: "maxSupply",
          });
          const CRGMaxSupply_InEther = Web3.utils.fromWei(
            String(CRGMaxSupply),
            "ether"
          );
          const CRGTotalSupply = await readContract({
            address: NEWCOIN_CONTRACT,
            abi: NEWCOIN_ABI,
            functionName: "totalSupply",
          });
          const CRGTotalSupply_InEther = Web3.utils.fromWei(
            String(CRGTotalSupply),
            "ether"
          );

          // Add checks for division by zero
          if (CRGMaxSupply_InEther - CRGTotalSupply_InEther !== 0) {
            const CRG_Price =
              10000000 / (CRGMaxSupply_InEther - CRGTotalSupply_InEther);
            console.log("CRG_Price:", CRG_Price);
            setcrgPrice(CRG_Price.toFixed(2));
          } else {
            console.log("Cannot divide by zero. Check your inputs.");
          }

          const usdtdata = await readContract({
            address: "0x55d398326f99059fF775485246999027B3197955",
            abi: usdtabi,
            functionName: "balanceOf",
            args: [walletAddress],
          });

          console.log("balanceOf data", usdtdata);
          setusdtBalanceWei(usdtdata);
          const USDTBalance_InEther = Web3.utils.fromWei(
            String(usdtdata),
            "ether"
          );
          console.log("USDTBalance_InEther", USDTBalance_InEther);
          // console.log("formattedBalance", formattedBalance);
          const roundedBalance = parseFloat(USDTBalance_InEther).toFixed(2);
          setUsdtBalance(USDTBalance_InEther);

          fetchListingFeeCRG();

          setdataisfetching(false);
          setDataFetched(true);
        } catch (error) {
          console.error(error);
          // Handle any errors occurred during contract interaction
        }
      }
    };

    fetchData();
  }, [dataFetched]);

  const fetchMarketBuyOffers = async () => {
    try {
      console.log("fetchMarketBuyOffers:");
      const response = await axios.get(
        `https://test.croesus-asset.management/api/fetchAvailableMarketItems`
      );

      // Convert the response data into the desired format
      const convertedData = response.data.reduce((accumulator, item) => {
        const { nft_contract, price, amount, user, id, ...rest } = item;

        if (!accumulator[nft_contract]) {
          accumulator[nft_contract] = [];
        }

        accumulator[nft_contract].push({ ...rest, price, amount, user, id });

        return accumulator;
      }, {});

      console.log("Converted Data:", convertedData);
      return convertedData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchAvailableICHARGEMarketItems = async () => {
    try {
      console.log("fetchAvailableICHARGEMarketItems");
      const ICHARGE_ITEMS = await readContract({
        address: ICHARGEMARKETPLACE_CONTRACT_BATCH,
        abi: ICHARGEMARKETPLACE_ABI_BATCH,
        functionName: "fetchAvailableMarketItems",
      });
      console.log("ICHARGE_ITEMS:", ICHARGE_ITEMS);
      console.dir(ICHARGE_ITEMS);

      let price_feeInEther;
      let amount_feeInEther;
      const convertedICHARGE_ITEMS = ICHARGE_ITEMS[1]
        .map((obj) => {
          console.log("obj:", obj);
          if (typeof obj.pricePerItem === "bigint") {
            price_feeInEther = Web3.utils.fromWei(
              String(obj.pricePerItem),
              "ether"
            );
            amount_feeInEther = Number(obj.availableQuantity);

            if (amount_feeInEther === 0) return null;

            let tokenIDs = obj.tokenIds;
            let numberTokenIDs = tokenIDs.map(Number);

            return {
              ...obj,
              price_ether: price_feeInEther,
              amountether: amount_feeInEther,
              marketID: Number(obj.batchId),
              tokenIDs: numberTokenIDs,
              contractAddress: obj.nftContractAddress,
              seller: obj.seller,
            };
          }
          return obj;
        })
        .filter(Boolean);

      // console.log("convertedICHARGE_ITEMS:", convertedICHARGE_ITEMS);

      const crgs = [];

      convertedICHARGE_ITEMS.forEach((item) => {
        let cityName = "ICHARGE";

        console.log("item:", item);

        tokens.push({
          tokenID: Number(item.batchId),
          contractAddress: item.nftContractAddress,
          seller: item.seller,
        });

        if (!crgs[cityName]) {
          crgs[cityName] = [];
        }

        crgs.push({
          id: Number(item.batchId),
          name: "ICHARGE",
          tokenIDs: item.tokenIDs,
          price: item.price_ether,
          crg_amount: item.amountether,
          seller: item.seller,
          marketID: item.marketID,
          contractAddress: MINI_CONTRACT,
        });
      });
      crgs.sort((a, b) => a.price - b.price);
      console.log("tokens", tokens);
      if (tokens.length > 0) {
        console.log("axios post");
        axios
          .post(
            "https://test.croesus-asset.management/api/get_marketplace_nft",
            {
              data: tokens,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, Accept, Authorization",
              },
            }
          )
          .then((response) => {
            console.log("Response api:", response.data);

            setUsersData(response.data.users);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }

      return crgs;
    } catch (error) {
      console.error(error);
      // Handle any errors occurred during contract interaction
    }
  };

  const fetchAvailableCRGMarketItems = async () => {
    try {
      console.log("fetchAvailableCRGMarketItems");
      const CRG_ITEMS = await readContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: "fetchAvailableMarketItems",
      });
      console.log("CRG_ITEMS:", CRG_ITEMS);

      const CRG_USDT_ITEMS = await readContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: "fetchAvailableBuyOffers",
      });
      console.log("CRG_USDT_ITEMS:", CRG_USDT_ITEMS);

      let price_feeInEther;
      let amount_feeInEther;
      const convertedCRG_ITEMS = CRG_ITEMS.map((obj) => {
        if (typeof obj.unitPrice === "bigint") {
          price_feeInEther = Web3.utils.fromWei(String(obj.unitPrice), "ether");
          amount_feeInEther = Web3.utils.fromWei(String(obj.quantity), "ether");

          return {
            ...obj,
            price: price_feeInEther,
            amountether: amount_feeInEther,
          };
        }
        return obj;
      });
      console.log("convertedCRG_ITEMS:", convertedCRG_ITEMS);

      let price_usdt_feeInEther;
      let amount_usdt_feeInEther;
      const convertedCRG_USDT_ITEMS = CRG_USDT_ITEMS.map((obj) => {
        if (typeof obj.unitPrice === "bigint") {
          price_usdt_feeInEther = Web3.utils.fromWei(
            String(obj.unitPrice),
            "ether"
          );
          amount_usdt_feeInEther = Web3.utils.fromWei(
            String(obj.quantity),
            "ether"
          );

          if (price_usdt_feeInEther < 10) {
          } else {
            return {
              ...obj,
              price: price_usdt_feeInEther,
              amountether: amount_usdt_feeInEther,
              seller: obj.buyer,
              crg_amount: amount_usdt_feeInEther / price_usdt_feeInEther,
            };
          }
        }
        return obj;
      });
      console.log("convertedCRG_USDT_ITEMS:", convertedCRG_USDT_ITEMS);

      const crgs = [];
      let crgsData = {};

      convertedCRG_ITEMS.forEach((item) => {
        if (item.quantity >= 10000000000000000) {
          let cityName = "CRG";

          // console.log("item:", item);
          tokens.push({
            tokenID: Number(item.tokenId),
            contractAddress: item.nftContractAddress,
          });

          if (!crgs[cityName]) {
            crgs[cityName] = [];
          }

          if (item.price < 10) {
          } else {
            crgs.push({
              id: Number(item.marketItemId),
              marketID: Number(item.marketItemId),
              name: "CRG",
              price: item.price,
              amount: item.amountether,
              seller: item.seller,
              contractAddress: item.tokenAddress,
            });
          }
        }
      });

      convertedCRG_USDT_ITEMS.forEach((item) => {
        if (item.quantity >= 10000000000000000) {
          let cityName = "CRG"; // Update this value according to your requirement

          // console.log("item:", item);
          tokens.push({
            tokenID: Number(item.tokenId),
            contractAddress: item.nftContractAddress,
          });

          if (!crgs[cityName]) {
            crgs[cityName] = [];
          }

          crgs.push({
            id: Number(item.buyOfferId),
            buOfferID: Number(item.buyOfferId),
            name: "CRG", // Update this value according to your requirement
            price: item.price,
            amount: item.amountether,
            seller: item.seller,
            contractAddress: item.tokenAddress,
            crg_amount: item.amountether / item.price,
          });
        }
      });
      crgs.sort((a, b) => a.price - b.price);

      return crgs;
    } catch (error) {
      console.error(error);
      // Handle any errors occurred during contract interaction
    }
  };

  const fetchAvailableMarketItems = async () => {
    try {
      const NFT_ITEMS = await readContract({
        address: MARKETPLACE_CONTRACT,
        abi: MARKETPLACE_ABI,
        functionName: "fetchAvailableMarketItems",
      });
      console.log("NFT_ITEMS:", NFT_ITEMS);

      let price_feeInEther;
      const convertedNFT_ITEMS = NFT_ITEMS.map((obj) => {
        if (typeof obj.price === "bigint") {
          price_feeInEther = Web3.utils.fromWei(String(obj.price), "ether");

          return {
            ...obj,
            price: price_feeInEther,
          };
        }
        return obj;
      });

      console.log("convertedNFT_ITEMS:", convertedNFT_ITEMS);

      // Assuming each key in convertedNFT_ITEMS is a contract address
      const nfts = {};
      let nftsData = {};

      if (Array.isArray(convertedNFT_ITEMS)) {
        // Iterate through each converted NFT item and assign the cityName
        convertedNFT_ITEMS.forEach((item) => {
          let cityName;

          // -console.log("item:", item);
          if (
            item.nftContractAddress ===
              "0x21ebfBF52d2B35b3C091d60fc5942c41bCb259bD" ||
            item.nftContractAddress ===
              "0xB8C5552D640F77866874400c1F8685C5dA4D6406"
          ) {
            cityName = "Taipei";
          } else if (
            item.nftContractAddress ===
            "0x1F152781599eAD748b481514d6F620d06207875D"
          ) {
            cityName = "Tianjin";
          } else if (
            item.nftContractAddress ===
            "0x4c0a9e2BF684448039179e90a13E3148795cA162"
          ) {
            cityName = "CZT";
          } else if (
            item.nftContractAddress ===
              "0x64625c6585B90478c023F84a5Be7a5006A786F1D" ||
            item.nftContractAddress ===
              "0xe4eC65901613750E66FeDC0B314873E513a42c85"
          ) {
            cityName = "CZT";
          } else if (
            item.nftContractAddress ===
            "0xDc40570C1840f04A4A080716f1a3153Be72c955E"
          ) {
            cityName = "MASTERCLASS";
          } else if (
            item.nftContractAddress ===
            "0xbd03601DF7fFF7590d5FB45957535972de9651Ac"
          ) {
            cityName = "LIVETRADING";
          }

          // console.log("cityName:", cityName);

          // Create or update nfts with the cityName as key
          if (!nfts[cityName]) {
            nfts[cityName] = [];
          }

          tokens.push({
            tokenID: Number(item.tokenId),
            contractAddress: item.nftContractAddress,
            seller: item.seller,
          });

          // console.log("item.tokenId:", item.tokenId);
          // console.log("cityName:", cityName);
          // console.log("item.price:", item.price);
          nfts[cityName].push({
            id: Number(item.tokenId),
            marketID: Number(item.marketItemId),
            name: cityName,
            price: item.price,
            seller: item.seller,
            contractAddress: item.nftContractAddress,
          });
        });

        // console.log("nfts:", nfts);
        // console.log("tokens:", tokens);
        if (!dataFetched) {
          // Check if data has not been fetched
          console.log("tokens", tokens);
          if (tokens.length > 0) {
            console.log("axios post");
            axios
              .post(
                "https://test.croesus-asset.management/api/get_marketplace_nft",
                {
                  data: tokens,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                    "Access-Control-Allow-Headers":
                      "Origin, Content-Type, Accept, Authorization",
                  },
                }
              )
              .then((response) => {
                console.log("Response api:", response.data);

                setUsersData(response.data.users);

                nftsData = response.data.nfts;
                setfloorprice_czt(response.data.marketplace_floor_czt);
                setfloorprice_taipei(response.data.marketplace_floor_taipei);
                setfloorprice_ctf(response.data.marketplace_floor_ctf);
                setfloorprice_msc(response.data.marketplace_floor_msc);

                Object.keys(nftsData).forEach((tokenId) => {
                  Object.keys(nfts).forEach((cityName) => {
                    nfts[cityName].forEach((nftItem) => {
                      if (Number(nftItem.id) === Number(tokenId)) {
                        nftItem.stakingVolume = nftsData[tokenId];
                      }
                    });
                  });
                });
                setstakedvalues(true);
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          }
        }
      }
      console.log("nfts:", nfts);
      return nfts;
    } catch (error) {
      console.error(error);
      // Handle any errors occurred during contract interaction
    }
  };

  function findUserByWallet(walletAddress) {
    // console.log("walletAddress", walletAddress);
    if (!walletAddress) {
      return "No wallet address provided";
    }

    let user = usersData.find(
      (user) =>
        user.web3_wallet &&
        user.web3_wallet.toLowerCase() === walletAddress.toLowerCase()
    );

    if (user) {
      return user.name;
    } else {
      return "User not found";
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setloadingTelegram(true);
    console.log("Telegram Pin", telegramPin);
    axios
      .post(
        "https://test.croesus-asset.management/api/telegram_connect",
        {
          data: {
            user: walletAddress,
            pin: telegramPin,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
        setsuccess_message("Telegram Bot connected successfully");
        setloadingTelegram(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setloadingTelegram(false);
        setError("Error please try again");
      });
  };

  const handleChangePriceCRG = async () => {
    setOpenBackdrop(true);
    setloadingMessage("Changing Price");

    // setloadingChangePrice(true);
    console.log("NFT Icontract :", selectedNFTContract);
    console.log("NFT ID :", selectedNFTId);
    console.log("Price to set :", newPrice);
    const weiPrice = Web3.utils.toWei(String(newPrice), "ether");
    console.log("Price to set in Wei:", weiPrice);
    console.log("crgPrice:", crgPrice);

    if (Number(newPrice) < Number(crgPrice)) {
      console.log("Minimum Price " + Number(crgPrice) + " USDT");
      setcanExecuteChangePrice(false);
      setexecuteChangePriceError("Minimum Price " + Number(crgPrice) + " USDT");
      setOpenBackdrop(false);
      return;
    } else {
      setcanExecuteChangePrice(true);
    }

    let changefunction;
    if (selectedNFTContract !== "0x55d398326f99059fF775485246999027B3197955") {
      changefunction = "changePrice";
    } else {
      changefunction = "changePriceBuyOffer";
    }

    try {
      const { request } = await prepareWriteContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: changefunction,
        args: [selectedNFTId, weiPrice],
        account: walletAddress,
      });
      setloadingMessage("Confirm on Wallet");
      console.log("request:", request);
      const { hash } = await writeContract(request);
      console.log("hash:", hash);
      setloadingMessage("Transaction Found...");

      const data = await waitForTransaction({
        hash: hash,
      });
      console.log("data:", data);
      if (data) {
        setOpenBackdrop(false);
        setloadingMessage("Transaction successfull");
        displaySuccessAlert("Transaction successfull");
        setOpenDialogCRG(false);
        setDataFetched(false);
      }
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        displayErrorAlert("User rejected the request");
        setOpenBackdrop(false);
      } else {
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(errorMessage);
        displayErrorAlert(errorMessage);
        setOpenBackdrop(false);
      }
    }

    setNewPrice("");
    setTimeout(() => {
      setOpenBackdrop(false);
    }, 2000);
  };

  const handleChangePrice = async () => {
    setloadingMessage("Changing Price");
    setOpenBackdrop(true);

    console.log("NFT ID :", selectedNFTId);
    console.log("NFT Contract :", selectedNFTContract);
    console.log("Price to set :", newPrice);
    const weiPrice = Web3.utils.toWei(String(newPrice), "ether");
    console.log("Price to set in Wei:", weiPrice);

    if (selectedNFTName === "Taipei") {
      if (newPrice < 100) {
        setcanExecuteChangePrice(false);
        setexecuteChangePriceError("Minimum Price 100 USDT");
        setOpenBackdrop(false);
        return;
      }
    } else if (selectedNFTName === "China Zun Tower") {
      if (newPrice < 1000) {
        setcanExecuteChangePrice(false);
        setexecuteChangePriceError("Minimum Price 1000 USDT");
        setOpenBackdrop(false);
        return;
      }
    } else if (selectedNFTName === "Tianjin_CTF_Finance_Center") {
      if (newPrice < 10000) {
        setcanExecuteChangePrice(false);
        setexecuteChangePriceError("Minimum Price 10000 USDT");
        setOpenBackdrop(false);
        return;
      }
    }

    let abi;
    let contract;
    if (selectedNFTContract === MINI_CONTRACT) {
      abi = ICHARGEMARKETPLACE_ABI;
      contract = ICHARGEMARKETPLACE_CONTRACT;
      if (newPrice < 55) {
        setcanExecuteChangePrice(false);
        setexecuteChangePriceError("Minimum Price 55 USDT");
        setOpenBackdrop(false);
        return;
      } else {
        setcanExecuteChangePrice(true);
      }
    } else {
      abi = MARKETPLACE_ABI;
      contract = MARKETPLACE_CONTRACT;
    }

    try {
      const { request } = await prepareWriteContract({
        address: contract,
        abi: abi,
        functionName: "changePrice",
        args: [selectedNFTId, weiPrice],
        account: walletAddress,
      });
      console.log("request:", request);
      setloadingMessage("Confirm in Wallet");
      const { hash } = await writeContract(request);
      console.log("hash:", hash);
      setloadingMessage("Transaction Found");

      const data = await waitForTransaction({
        hash: hash,
      });
      console.log("data:", data);
      if (data) {
        setOpenBackdrop(false);
        displaySuccessAlert("Transaction successfull");
        setOpenDialog(false);
      }
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        displayErrorAlert("User rejected the request");
        setOpenBackdrop(false);
      } else {
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(errorMessage);
        displayErrorAlert(errorMessage);
        setOpenBackdrop(false);
      }
    }

    setNewPrice("");
    setTimeout(() => {
      setOpenBackdrop(false);
      setDataFetched(false);
    }, 2000);
  };

  const handleCRG = (nft) => {
    console.log("nft", nft);
    if (nft.contractAddress === "0x55d398326f99059fF775485246999027B3197955") {
      let max_offer_usdt_amount = nft.amount * nft.price;
      let maxBuyAmCRG;
      let maxBuyAmUSDT;
      if (max_offer_usdt_amount > usdtBalance) {
        maxBuyAmCRG = usdtBalance / nft.price;
        maxBuyAmUSDT = usdtBalance;
      } else {
        maxBuyAmCRG = nft.amount;
        maxBuyAmUSDT = nft.amount * nft.price;
      }
      setMaxBuyAmountCRG(maxBuyAmCRG);
      setcrg_sell_amount(maxBuyAmCRG);
      setMaxBuyAmountUSDT(maxBuyAmUSDT);
      setTokenAmountBuyDialog(nft.amount);
      setPrice(nft.price);
      setOpenDialogBuyUSDT(true);
      setSelectedNFT(nft);
      handleCloseMenu(nft);
    } else {
      if (nft.contractAddress === MINI_CONTRACT) {
        setPrice(nft.price);
        setTokenAmountBuyDialog(nft.crg_amount);
        setOpenDialogBuyICHARGE(true);
        setSelectedNFT(nft);
        handleCloseMenu(nft);
      } else {
        let max_offer_usdt_amount = nft.amount * nft.price;
        let maxBuyAmCRG;
        let maxBuyAmUSDT;
        if (max_offer_usdt_amount > usdtBalance) {
          maxBuyAmCRG = usdtBalance / nft.price;
          maxBuyAmUSDT = usdtBalance;
        } else {
          maxBuyAmCRG = nft.amount;
          maxBuyAmUSDT = nft.amount * nft.price;
        }
        setMaxBuyAmountCRG(maxBuyAmCRG);
        setcrg_sell_amount(maxBuyAmCRG);
        setMaxBuyAmountUSDT(maxBuyAmUSDT);
        setTokenAmountBuyDialog(nft.amount);
        setPrice(nft.price);
        setOpenDialogBuyCRG(true);
        setSelectedNFT(nft);
        handleCloseMenu(nft);
      }
    }
  };

  const handleSellCRG = async (nft, usdtamount, crgamount) => {
    setloadingMessage("Checking Approval...");
    handleOpen();

    console.log("nft :", nft);
    try {
      console.log("crgamount :", crgamount);
      console.log("usdtamount :", usdtamount);
      let crgamount_wei = Web3.utils.toWei(String(crgamount), "ether");
      console.log("crgamount_wei :", crgamount_wei);
      let usdtamountwei = Web3.utils.toWei(String(usdtamount), "ether");
      console.log("usdtamountwei :", usdtamountwei);

      let real_usdt_amount = crgamount_wei * priceCRGSell;
      console.log("real_usdt_amount :", real_usdt_amount);

      let amount_buy;
      if (real_usdt_amount < usdtamountwei) {
        amount_buy = real_usdt_amount;
      } else {
        amount_buy = usdtamountwei;
      }
      console.log("amount_buy :", amount_buy);

      const approved_crg = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "allowance",
        args: [walletAddress, CRGMARKETPLACE_CONTRACT],
      });
      console.log("approved_crg :", approved_crg);
      console.log("crgamount_wei :", crgamount_wei);

      if (approved_crg < crgamount_wei) {
        setloadingMessage("Approving CRG...");
        console.log("Need to approve more CRG");
        const { request: approveRequest } = await prepareWriteContract({
          address: NEWCOIN_CONTRACT,
          abi: NEWCOIN_ABI,
          functionName: "approve",
          args: [CRGMARKETPLACE_CONTRACT, crgBalanceWei],
          account: walletAddress,
        });
        console.log("approveRequest:", approveRequest);
        const { hash: approveHash } = await writeContract(approveRequest);
        console.log("approveHash:", approveHash);

        const approvedata = await waitForTransaction({
          hash: approveHash,
        });
        console.log("approvedata:", approvedata);

        if (approvedata) {
          setloadingMessage("Creating Transaction...");
          const { request: buyRequest } = await prepareWriteContract({
            address: CRGMARKETPLACE_CONTRACT,
            abi: CRGMARKETPLACE_ABI,
            functionName: "acceptBuyOffer",
            args: [Number(nft.buOfferID), amount_buy],
            account: walletAddress,
          });
          console.log("buyRequest:", buyRequest);

          setloadingMessage("Confirm in Wallet...");
          console.log("buyRequest:", buyRequest);
          const { hash: buyHash } = await writeContract(buyRequest);
          console.log("buyHash:", buyHash);

          setloadingMessage("Transaction Found...");
          const buydata = await waitForTransaction({
            hash: buyHash,
          });
          console.log("buydata:", buydata);
          setsuccess_message("Transaction successfull");
          setTimeout(() => {
            handleClose();
            setDataFetched(false);
            setOpenDialogBuyUSDT(false);
          }, 2000);
        }
      } else {
        setloadingMessage("Creating Transaction...");
        const { request: buyRequest } = await prepareWriteContract({
          address: CRGMARKETPLACE_CONTRACT,
          abi: CRGMARKETPLACE_ABI,
          functionName: "acceptBuyOffer",
          args: [Number(nft.buOfferID), amount_buy],
          account: walletAddress,
        });

        setloadingMessage("Confirm in Wallet...");
        console.log("buyRequest:", buyRequest);
        const { hash: buyHash } = await writeContract(buyRequest);
        console.log("buyHash:", buyHash);

        setloadingMessage("Transaction Found...");
        const buydata = await waitForTransaction({
          hash: buyHash,
        });
        console.log("buydata:", buydata);
        setsuccess_message("Transaction successfull");
        setTimeout(() => {
          handleClose();
          setDataFetched(false);
          setOpenDialogBuyUSDT(false);
        }, 2000);
      }
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        setError("User rejected the request");
        handleClose();
        setOpenBackdrop(false);
      } else if (
        error.message.includes("BEP20: transfer amount exceeds balance")
      ) {
        setError("You dont have enough Balance");
        handleClose();
        setOpenBackdrop(false);
      } else {
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(errorMessage);
        setError(errorMessage);
        setOpenBackdrop(false);
      }
    }
    handleClose();
    setOpenDialogBuyUSDT(false);
  };

  const handlebatchbuyicharge = async (nft, usdtamount, ichargeamount) => {
    console.log("handleBuyCRG :");
    setloadingMessage("Checking Approval...");
    handleOpen();

    console.log("batch :", nft);
    console.log("nft.tokenIDs :", nft.tokenIDs);

    try {
      console.log("Icharge Amount :", ichargeamount);
      console.log("usdtamount :", usdtamount);
      let usdtamountwei = Web3.utils.toWei(String(usdtamount), "ether");
      console.log("usdtamountwei :", usdtamountwei);

      let TokenIDs = Array.from(
        { length: ichargeamount },
        (_, i) => nft.tokenIDs[nft.tokenIDs.length - i - 1]
      );
      console.log("TokenIDs:", TokenIDs);

      const approved_usdt = await readContract({
        address: USDT_CONTRACT,
        abi: ABI_USDT,
        functionName: "allowance",
        args: [walletAddress, ICHARGEMARKETPLACE_CONTRACT_BATCH],
      });
      console.log("approved_usdt :", Number(approved_usdt));

      if (Number(approved_usdt) < Number(usdtamountwei)) {
        setloadingMessage("Approving USDT...");
        console.log("Need to approve more USDT");
        const { request: approveRequest } = await prepareWriteContract({
          address: USDT_CONTRACT,
          abi: ABI_USDT,
          functionName: "approve",
          args: [ICHARGEMARKETPLACE_CONTRACT_BATCH, usdtamountwei],
          account: walletAddress,
        });
        console.log("approveRequest:", approveRequest);
        const { hash: approveHash } = await writeContract(approveRequest);
        console.log("approveHash:", approveHash);

        const approvedata = await waitForTransaction({
          hash: approveHash,
        });
        console.log("approvedata:", approvedata);
        if (approvedata) {
          setloadingMessage("Creating Transaction...");
          const { request: buyRequest } = await prepareWriteContract({
            address: ICHARGEMARKETPLACE_CONTRACT_BATCH,
            abi: ICHARGEMARKETPLACE_ABI_BATCH,
            functionName: "batchBuy",
            args: [nft.marketID, buyCRGAmount],
            account: walletAddress,
          });

          setloadingMessage("Confirm in Wallet...");
          console.log("buyRequest:", buyRequest);
          const { hash: buyHash } = await writeContract(buyRequest);
          console.log("buyHash:", buyHash);

          setloadingMessage("Transaction Found...");
          const buydata = await waitForTransaction({
            hash: buyHash,
          });
          console.log("buydata:", buydata);
          setsuccess_message("Processing...");

          if (buydata) {
            axios
              .post(
                "https://test.croesus-asset.management/api/transfer_nft_batch",
                {
                  contract: nft.contractAddress,
                  tokenid: TokenIDs,
                  amount: nft.price,
                  user: walletAddress,
                  seller: nft.seller,
                }
              )
              .then((response) => {
                // console.log("API Response:", response.data);
                displaySuccessAlert("Transaction successfull");
                setTimeout(() => {
                  setOpenDialogBuyICHARGE(false);
                  handleClose();
                  setOpenBackdrop(false);
                  setDataFetched(false);
                }, 2000);
              })
              .catch((error) => {
                console.error("API Error:", error);
                setTimeout(() => {
                  setOpenDialogBuyICHARGE(false);
                  handleClose();
                  setOpenBackdrop(false);
                  setDataFetched(false);
                }, 2000);
              });
          }

          setTimeout(() => {
            handleClose();
            setOpenDialogBuyICHARGE(false);
            setOpenDialogBuyCRG(false);
            setOpenBackdrop(false);
            setDataFetched(false);
          }, 2000);
        }
      } else {
        setloadingMessage("Creating Transaction...");
        const { request: buyRequest } = await prepareWriteContract({
          address: ICHARGEMARKETPLACE_CONTRACT_BATCH,
          abi: ICHARGEMARKETPLACE_ABI_BATCH,
          functionName: "batchBuy",
          args: [nft.marketID, buyCRGAmount],
          account: walletAddress,
        });

        setloadingMessage("Confirm in Wallet...");
        console.log("buyRequest:", buyRequest);
        const { hash: buyHash } = await writeContract(buyRequest);
        console.log("buyHash:", buyHash);

        setloadingMessage("Transaction Found...");
        const buydata = await waitForTransaction({
          hash: buyHash,
        });
        console.log("buydata:", buydata);
        setsuccess_message("Processing...");

        if (buydata) {
          axios
            .post(
              "https://test.croesus-asset.management/api/transfer_nft_batch",
              {
                contract: nft.contractAddress,
                tokenid: TokenIDs,
                amount: nft.price,
                user: walletAddress,
                seller: nft.seller,
              }
            )
            .then((response) => {
              // console.log("API Response:", response.data);
              displaySuccessAlert("Transaction successfull");
              setTimeout(() => {
                setOpenDialogBuyICHARGE(false);
                handleClose();
                setOpenBackdrop(false);
                setDataFetched(false);
              }, 2000);
            })
            .catch((error) => {
              console.error("API Error:", error);
              setTimeout(() => {
                setOpenDialogBuyICHARGE(false);
                handleClose();
                setOpenBackdrop(false);
                setDataFetched(false);
              }, 2000);
            });
        }

        setTimeout(() => {
          handleClose();
          setOpenDialogBuyICHARGE(false);
          setOpenDialogBuyCRG(false);
          setOpenBackdrop(false);
          setDataFetched(false);
        }, 2000);
      }
    } catch (error) {
      console.error("Error buying NFT:", error);
      setOpenDialogBuyICHARGE(false);
      setError(error.message);
      handleClose();
      setOpenDialogBuyCRG(false);
      setOpenBackdrop(false);
      // Remove the NFT id from the array of loading NFT ids in case of an error
    }
  };

  const handleBuyCRG = async (nft, usdtamount, crgamount) => {
    console.log("handleBuyCRG :");
    setloadingMessage("Checking Approval...");
    handleOpen();

    console.log("nft :", nft);
    try {
      let crgamount_wei = Web3.utils.toWei(String(crgamount), "ether");
      let usdtamountwei = Web3.utils.toWei(String(usdtamount), "ether");

      const approved_usdt = await readContract({
        address: USDT_CONTRACT,
        abi: ABI_USDT,
        functionName: "allowance",
        args: [walletAddress, CRGMARKETPLACE_CONTRACT],
      });
      console.log("approved_usdt :", approved_usdt);
      console.log("usdtBalanceWei :", usdtBalanceWei);
      console.log("crgamount_wei :", crgamount_wei);

      if (approved_usdt < usdtBalanceWei) {
        setloadingMessage("Approving USDT...");
        console.log("Need to approve more USDT");
        const { request: approveRequest } = await prepareWriteContract({
          address: USDT_CONTRACT,
          abi: ABI_USDT,
          functionName: "approve",
          args: [CRGMARKETPLACE_CONTRACT, usdtBalanceWei],
          account: walletAddress,
        });
        console.log("approveRequest:", approveRequest);
        const { hash: approveHash } = await writeContract(approveRequest);
        console.log("approveHash:", approveHash);

        const approvedata = await waitForTransaction({
          hash: approveHash,
        });
        console.log("approvedata:", approvedata);

        if (approvedata) {
          setloadingMessage("Creating Transaction...");
          const { request: buyRequest } = await prepareWriteContract({
            address: CRGMARKETPLACE_CONTRACT,
            abi: CRGMARKETPLACE_ABI,
            functionName: "pay_marketsale",
            args: [Number(nft.marketID), crgamount_wei],
            account: walletAddress,
          });

          setloadingMessage("Confirm in Wallet...");
          console.log("buyRequest:", buyRequest);
          const { hash: buyHash } = await writeContract(buyRequest);
          console.log("buyHash:", buyHash);

          setloadingMessage("Transaction Found...");
          const buydata = await waitForTransaction({
            hash: buyHash,
          });
          console.log("buydata:", buydata);
          setsuccess_message("Transaction successfull");
          setTimeout(() => {
            handleClose();
            setOpenDialogBuyCRG(false);
            setOpenBackdrop(false);
            setDataFetched(false);
          }, 2000);
        }
      } else {
        setloadingMessage("Creating Transaction...");
        const { request: buyRequest } = await prepareWriteContract({
          address: CRGMARKETPLACE_CONTRACT,
          abi: CRGMARKETPLACE_ABI,
          functionName: "pay_marketsale",
          args: [Number(nft.marketID), crgamount_wei - 1000],
          account: walletAddress,
        });
        setloadingMessage("Confirm in Wallet...");
        console.log("buyRequest:", buyRequest);
        const { hash: buyHash } = await writeContract(buyRequest);
        console.log("buyHash:", buyHash);
        setloadingMessage("Transaction Found...");

        const buydata = await waitForTransaction({
          hash: buyHash,
        });
        console.log("buydata:", buydata);

        if (buydata) {
          setLoadingNFTIds((prevLoadingNFTIds) =>
            prevLoadingNFTIds.filter((id) => id !== nft.id)
          );
          setsuccess_message("Transaction successfull");
          setTimeout(() => {
            handleClose();
            setOpenDialogBuyCRG(false);
            setOpenBackdrop(false);
            setDataFetched(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error buying NFT:", error);
      setError(error.message);
      handleClose();
      setOpenDialogBuyCRG(false);
      setOpenBackdrop(false);
      // Remove the NFT id from the array of loading NFT ids in case of an error
    }
    handleClose();
    setOpenBackdrop(false);
    setOpenDialogBuyCRG(false);
    setDataFetched(false);
  };

  const handleBuyNFT = async (nft) => {
    console.log("nft :", nft);
    setloadingMessage("Checking Approval...");
    setOpenBackdrop(true);

    let contract;
    let buy_function;
    let abi;
    let buy_args;
    if (nft.name === "ICHARGE") {
      contract = ICHARGEMARKETPLACE_CONTRACT;
      abi = ICHARGEMARKETPLACE_ABI;
      buy_function = "buy";
      buy_args = [Number(nft.marketID)];
    } else {
      contract = MARKETPLACE_CONTRACT;
      abi = MARKETPLACE_ABI;
      buy_function = "pay_marketsale";
      buy_args = [nft.contractAddress, Number(nft.marketID)];
    }

    try {
      // Add the NFT id to the array of loading NFT ids
      // setLoadingNFTIds((prevLoadingNFTIds) => [...prevLoadingNFTIds, nft.id]);

      const approved_usdt = await readContract({
        address: USDT_CONTRACT,
        abi: ABI_USDT,
        functionName: "allowance",
        args: [walletAddress, contract],
      });
      console.log("approved_usdt :", approved_usdt);

      let weiPrice = Web3.utils.toWei(String(nft.price), "ether");
      if (Number(approved_usdt) < weiPrice) {
        setloadingMessage("Approve USDT...");
        console.log("Need to approve more USDT");
        const { request: approveRequest } = await prepareWriteContract({
          address: USDT_CONTRACT,
          abi: ABI_USDT,
          functionName: "approve",
          args: [contract, weiPrice],
          account: walletAddress,
        });
        console.log("approveRequest:", approveRequest);
        setloadingMessage("Confirm Approval in Wallet...");
        const { hash: approveHash } = await writeContract(approveRequest);
        console.log("approveHash:", approveHash);
        setloadingMessage("Approve Transaction Found...");

        const approvedata = await waitForTransaction({
          hash: approveHash,
        });
        console.log("approvedata:", approvedata);
        if (approvedata) {
          const { request: buyRequest } = await prepareWriteContract({
            address: contract,
            abi: abi,
            functionName: buy_function,
            args: buy_args,
            account: walletAddress,
          });
          console.log("buyRequest:", buyRequest);
          setloadingMessage("Confirm Buy in Wallet...");
          const { hash: buyHash } = await writeContract(buyRequest);
          console.log("buyHash:", buyHash);
          setloadingMessage("Transaction Found...");

          const buydata = await waitForTransaction({
            hash: buyHash,
          });
          console.log("buydata:", buydata);

          if (buydata) {
            setLoadingNFTIds((prevLoadingNFTIds) =>
              prevLoadingNFTIds.filter((id) => id !== nft.id)
            );

            axios
              .post("https://test.croesus-asset.management/api/transfer_nft", {
                contract: nft.contractAddress,
                tokenid: nft.id,
                amount: nft.price,
                user: walletAddress,
                seller: nft.seller,
              })
              .then((response) => {
                // console.log("API Response:", response.data);
                displaySuccessAlert("Transaction successfull");
                setTimeout(() => {
                  setOpenBackdrop(false);
                  setDataFetched(false);
                }, 2000);
              })
              .catch((error) => {
                console.error("API Error:", error);
                setTimeout(() => {
                  setOpenBackdrop(false);
                  setDataFetched(false);
                }, 2000);
              });
          }
        }
      } else {
        const { request: buyRequest } = await prepareWriteContract({
          address: contract,
          abi: abi,
          functionName: buy_function,
          args: buy_args,
          account: walletAddress,
        });
        console.log("buyRequest:", buyRequest);
        setloadingMessage("Confirm Buy in Wallet...");
        const { hash: buyHash } = await writeContract(buyRequest);
        console.log("buyHash:", buyHash);
        setloadingMessage("Transaction Found...");

        const buydata = await waitForTransaction({
          hash: buyHash,
        });
        console.log("buydata:", buydata);

        if (buydata) {
          setLoadingNFTIds((prevLoadingNFTIds) =>
            prevLoadingNFTIds.filter((id) => id !== nft.id)
          );

          axios
            .post("https://test.croesus-asset.management/api/transfer_nft", {
              contract: nft.contractAddress,
              tokenid: nft.id,
              amount: nft.price,
              user: walletAddress,
              seller: nft.seller,
            })
            .then((response) => {
              // console.log("API Response:", response.data);
              displaySuccessAlert("Transaction successfull");
              setTimeout(() => {
                setOpenBackdrop(false);
                setDataFetched(false);
              }, 2000);
            })
            .catch((error) => {
              console.error("API Error:", error);
              setTimeout(() => {
                setOpenBackdrop(false);
                setDataFetched(false);
              }, 2000);
            });
        }
      }
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        setError("User rejected the request");
        handleClose();
        setOpenBackdrop(false);
      } else if (
        error.message.includes("BEP20: transfer amount exceeds balance")
      ) {
        setError("You dont have enough Balance");
        handleClose();
        setOpenBackdrop(false);
      } else {
        console.error("error", error);
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(errorMessage);
        setError(errorMessage);
        setOpenBackdrop(false);
      }
    }
  };

  const setOpen = async () => {};

  const handleCancelOfferCRG = async () => {
    setloadingMessage("Cancelling Offer");
    setOpenBackdrop(true);

    // setloadingCancelOffer(true);
    console.log("NFT ID :", selectedNFTId);
    console.log("NFT Contract :", selectedNFTContract);
    let functionName;

    if (selectedNFTContract === "0x55d398326f99059fF775485246999027B3197955") {
      functionName = "cancelBuyOffer";
    } else {
      functionName = "cancelMarketItem";
    }
    console.log("functionName :", functionName);

    try {
      const { request } = await prepareWriteContract({
        address: CRGMARKETPLACE_CONTRACT,
        abi: CRGMARKETPLACE_ABI,
        functionName: functionName,
        args: [selectedNFTId],
        account: walletAddress,
      });
      console.log("request:", request);
      setloadingMessage("Confirm transaction on Wallet");
      const { hash } = await writeContract(request);
      console.log("hash:", hash);
      setloadingMessage("Transaction in progress...");

      const data = await waitForTransaction({
        hash: hash,
      });
      console.log("data:", data);
      if (data) {
        setloadingMessage("Cancel Offer successfull");
        displaySuccessAlert("Transaction successfull");
        setOpenBackdrop(false);
        setOpenDialogCRG(false);
      }
      setOpenBackdrop(false);
    } catch (error) {
      if (error.message.includes("User rejected the request")) {
        displayErrorAlert("User rejected the request");
        setOpenBackdrop(false);
      } else {
        const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
        console.error("errorMessage");
        console.error(errorMessage);
        displayErrorAlert(errorMessage);
        setOpenBackdrop(false);
      }
    }
    setOpenBackdrop(true);
    setTimeout(() => {
      setDataFetched(false);
      setOpenDialogCRG(false);
      setOpenBackdrop(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const handleCancelOffer = async () => {
    setloadingMessage("Cancelling Offer...");
    setOpenBackdrop(true);
    console.log("selectedNFT.tokenIDs :", selectedNFT.tokenIDs);
    console.log("NFT ID :", selectedNFTId);
    // console.log("NFT Contract :", selectedNFTContract);

    if (selectedNFTContract === MINI_CONTRACT) {
      try {
        const { request } = await prepareWriteContract({
          address: ICHARGEMARKETPLACE_CONTRACT_BATCH,
          abi: ICHARGEMARKETPLACE_ABI_BATCH,
          functionName: "cancelBatch",
          args: [selectedNFTId],
          account: walletAddress,
        });
        console.log("request:", request);
        setloadingMessage("Confirm in Wallet...");
        const { hash } = await writeContract(request);
        console.log("hash:", hash);
        setloadingMessage("Transaction Found...");

        const data = await waitForTransaction({
          hash: hash,
        });
        console.log("data:", data);
        if (data) {
          setloadingMessage("Transaction successfull...");
          displaySuccessAlert("Transaction successfull");
          axios
            .post(
              "https://test.croesus-asset.management/api/cancel_icharge_batch",
              {
                id: selectedNFT.tokenIDs,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, Accept, Authorization",
                },
              }
            )
            .then((response) => {
              console.log("Response:", response.data);
              displaySuccessAlert("listing successfully canceled");
            })
            .catch((error) => {
              console.error("Error:", error);
              displayErrorAlert("Error please try again");
            });
          setOpenBackdrop(false);
          setDataFetched(false);

          setTimeout(() => {
            setDataFetched(false);
            setOpenDialog(false);
          }, 2000);
        }
      } catch (error) {
        if (error.message.includes("User rejected the request")) {
          displayErrorAlert("User rejected the request");
          setOpenBackdrop(false);
        } else {
          const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
          console.error("errorMessage");
          console.error(errorMessage);
          displayErrorAlert(errorMessage);
          setOpenBackdrop(false);
        }
      }
    } else {
      try {
        const { request } = await prepareWriteContract({
          address: MARKETPLACE_CONTRACT,
          abi: MARKETPLACE_ABI,
          functionName: "cancelMarketItem",
          args: [selectedNFTContract, selectedNFTId],
          account: walletAddress,
        });
        console.log("request:", request);
        setloadingMessage("Confirm in Wallet...");
        const { hash } = await writeContract(request);
        console.log("hash:", hash);
        setloadingMessage("Transaction Found...");

        const data = await waitForTransaction({
          hash: hash,
        });
        console.log("data:", data);
        if (data) {
          // Code for successful promise fulfillment
          setloadingMessage("Transaction successfull...");
          displaySuccessAlert("Transaction successfull");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      } catch (error) {
        if (error.message.includes("User rejected the request")) {
          displayErrorAlert("User rejected the request");
          setOpenBackdrop(false);
        } else {
          const errorMessage = error.message.split("\n")[1]; // Extract the first sentence
          console.error("errorMessage");
          console.error(errorMessage);
          displayErrorAlert(errorMessage);
          setOpenBackdrop(false);
        }
      }
      setTimeout(() => {
        setDataFetched(false);
        setOpenDialog(false);
      }, 2000);
    }
  };

  const handleMakeOffer = () => {
    setloadingMakeOffer(true);
    console.log("nft", selectedNFT);
    console.log("price", NewPriceOffer);

    axios
      .post(
        "https://test.croesus-asset.management/api/askprice",
        {
          data: {
            nft: selectedNFT,
            price: NewPriceOffer,
            user: walletAddress,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
        displaySuccessAlert("Offer made successfully");
        setloadingMakeOffer(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        displayErrorAlert("Error please try again");
        setloadingMakeOffer(false);
      });
  };

  const handleDeleteOrder = (id) => {
    console.log("id", id);
    setloadingDeleteOffer(true);

    axios
      .post(
        "https://test.croesus-asset.management/api/deleteBuyOffer",
        {
          data: {
            id: id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
        displaySuccessAlert("Offer successfully deleted");
        setloadingDeleteOffer(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
        displayErrorAlert("Error please try again");
        setloadingDeleteOffer(false);
      });
  };

  const handleMenuButton = (nftitem) => {
    console.log("nftitem", nftitem);
    console.log("Price", NewPriceOffer);
    setSelectedNFT(nftitem);
    setOpenDialogOffer(true);
    // Close the menu
    handleCloseMenu(nftitem);
  };

  const handleEditButton = (nft, nftName, nftId, Contract) => {
    console.log("nftId", nftId);
    console.log("Contract", Contract);
    console.log("Name", nftName);
    // Set the selectedNFTId state to the clicked NFT ID
    setSelectedNFT(nft);
    setSelectedNFTId(nftId);
    setSelectedNFTContract(Contract);
    setSelectedNFTName(nftName);
    // Open the dialog
    setOpenDialog(true);
  };

  const handlCRG = (nftId, Contract) => {
    console.log(nftId);
    console.log(Contract);
    setOpenDialogCRG(true);
    setSelectedNFTId(nftId);
    setSelectedNFTContract(Contract);
  };

  const handleBuyOffer = () => {
    setloadingBuyOffer(true);
    console.log("BuyOfferNFT", BuyOfferNFT);
    console.log("newPrice", newPrice);
    console.log("BuyAmount", BuyAmount);

    axios
      .post(
        "https://test.croesus-asset.management/api/buy_offer",
        {
          data: {
            nft_contract: BuyOfferNFT,
            price: newPrice,
            user: walletAddress,
            amount: BuyAmount,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, Accept, Authorization",
          },
        }
      )
      .then((response) => {
        console.log("Response:", response.data);
        displaySuccessAlert("Offer created successfully");
        setloadingBuyOffer(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2000); // 2000 mi
      })
      .catch((error) => {
        console.error("Error:", error);
        displayErrorAlert(error);
        setloadingBuyOffer(false);
        setTimeout(() => {
          window.location.reload(false);
        }, 2000); // 2000 mi
      });
  };

  const getImgUrlByNftName = (name, nft) => {
    // console.log("getImgUrlByNftName NFT", nft);

    if (nft) {
      if (
        nft.contractAddress === "0x55d398326f99059fF775485246999027B3197955"
      ) {
        name = "USDT";
        const selectedNft = nftOptions.find((nft) => nft.name === name);
        return selectedNft ? selectedNft.imgUrl : "";
      } else {
        const selectedNft = nftOptions.find((nft) => nft.name === name);
        return selectedNft ? selectedNft.imgUrl : "";
      }
    } else {
      const selectedNft = nftOptions.find((nft) => nft.name === name);
      return selectedNft ? selectedNft.imgUrl : "";
    }
  };

  useEffect(() => {
    if (isConnected) {
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("API RETURN: ", response);
          console.log("user: ", response.data.user);
          console.log("nfts: ", response.data.nftCounts);
          setUser(response.data.user);
        })
        .catch((error) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);
            if (errorMessage === "User not found") {
            }
            // You can handle/display the error message as per your requirement
          }
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected]);

  return (
    <div className="p-2">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
        open={openBackdrop}
      >
        {" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <br></br>
          <span>
            <h2 style={{ color: "white" }}>{loadingMessage}</h2>
          </span>
        </div>
      </Backdrop>
      <Snackbar
        open={error !== null}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="error"
        >
          {error}
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={success_message !== null}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity="success"
        >
          {success_message}
        </MuiAlert>
      </Snackbar>{" "}
      <div className="row mt-2 mb-2 justify-content-md-center">
        <div className="col-xl-6">
          {User && User.tele_id === null ? (
            <>
              <button
                className="btn btn-warning btn-lg btn-block mb-3"
                style={{
                  margin: "0px auto",
                  width: "fit-content",
                  padding: "0px 11px",
                  borderColor: "#24a1db",
                  backgroundColor: "#24a1db",
                }}
                onClick={() => setOpenTelegram(true)}
              >
                Connect with Telegram Bot now!{" "}
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png"
                  style={{ width: "47px", marginLeft: "8px" }}
                />
              </button>

              <Dialog
                open={openTelegram}
                onClose={handleCloseTelegram}
                aria-labelledby="telegram_modal"
                aria-hidden="true"
              >
                <DialogTitle id="telegram_modal">
                  Connect to Marketplace Telegram Bot
                </DialogTitle>
                <form
                  name="add-blog-post-form"
                  id="add-blog-post-form"
                  onSubmit={handleSubmit}
                >
                  <DialogContent dividers>
                    <h5>1. Start the Bot</h5>
                    <p>
                      Click on the button below to initialize the connection to
                      our Marketplace Telegram Bot.
                    </p>
                    <div style={{ textAlign: "center" }}>
                      <a
                        className="navbar-brand"
                        target="_blank"
                        href="https://t.me/CroesusInfo_bot"
                        style={{ margin: "0px auto" }}
                      >
                        <img
                          className="brand-logo"
                          alt="modern admin logo"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png"
                          style={{
                            width: "55px",
                          }}
                        />
                      </a>
                    </div>
                    <hr />
                    <h5>2. Enter the PIN Code</h5>
                    <TextField
                      variant="outlined"
                      fullWidth
                      placeholder="Telegram PIN"
                      name="pin"
                      onChange={(e) => setTelegramPin(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseTelegram}
                      variant="contained"
                      style={{ backgroundColor: "red" }}
                    >
                      Close
                    </Button>
                    <Button type="submit" variant="contained">
                      {loadingTelegram ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        <>Submit</>
                      )}
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          ) : (
            <>
              <Tabs
                sx={{
                  "& .MuiTabs-flexContainer": {
                    width: "fit-content",
                    borderRadius: "12px",
                    border: "1px solid rgb(208, 208, 208)",
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "white",
                  },
                  "& .MuiButtonBase-root": {
                    padding: "5px",
                    fontSize: "1rem",
                  },
                }}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={selectedTab}
                onChange={handleChangeTab}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0000",
                    maxHeight: "30px",
                  },
                }}
              >
                {allTabLabels.map((label, index) => (
                  <Tab key={label} label={label} sx={tabStyles} />
                ))}
              </Tabs>

              {selectedTab !== 5 ? (
                <div
                  className={
                    classes.smooth_container + " style_container card bordered"
                  }
                  style={{ border: "1px solid #d0d0d0", height: "fit-content" }}
                >
                  <div className={classes.style_container}>
                    {selectedTab !== 4 ? (
                      <h1 className="text-center">Create Buy Offer</h1>
                    ) : (
                      <>
                        <h1 className="text-center">Create Buy Listing</h1>
                        <p className="text-center">
                          Available Balance: {truncateNumber(usdtBalance)} USDT
                        </p>
                      </>
                    )}

                    <div className="text-center">
                      {isExpanded ? (
                        <ExpandLessIcon onClick={handleExpand} />
                      ) : (
                        <ExpandMoreIcon onClick={handleExpand} />
                      )}
                    </div>

                    <CSSTransition
                      in={showTable}
                      timeout={500}
                      classNames="fade"
                      unmountOnExit
                    >
                      {selectedTab !== 4 ? (
                        <Grid
                          container
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                          className={`mt-2 ${showTable ? "fadeIn" : ""}`}
                        >
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel id="nft_amount_label">
                                Amount
                              </InputLabel>
                              <Select
                                labelId="nft_amount_label"
                                id="nft_amount_select"
                                label="Amount"
                                onChange={(e) => setBuyAmount(e.target.value)}
                              >
                                {Array.from({ length: 10 }, (_, index) => (
                                  <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={5}>
                            <FormControl fullWidth>
                              <InputLabel id="nftSelectLabel">
                                Select NFT
                              </InputLabel>
                              <Select
                                labelId="nftSelectLabel"
                                id="nftSelect"
                                label="Select NFT"
                                onChange={(e) => setBuyOfferNFT(e.target.value)}
                                // value={nftOptions[0].name}
                              >
                                {nftOptions.map(
                                  (option) =>
                                    option.name !== "USDT" && (
                                      <MenuItem
                                        key={option.name}
                                        value={option.name}
                                      >
                                        <img
                                          src={option.imgUrl}
                                          alt={option.name}
                                          style={{
                                            maxHeight: "20px",
                                            marginRight: "1rem",
                                          }}
                                        />
                                        {option.name}
                                      </MenuItem>
                                    )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label="Price (in USDT)"
                              id="priceInput"
                              type="number"
                              onChange={(e) => setNewPrice(e.target.value)}
                            />
                          </Grid>
                          {loadingBuyOffer && (
                            <Grid item xs={12}>
                              <div className="text-center">
                                <CircularProgress
                                  size={24}
                                  color="inherit"
                                  className="mt-3"
                                />
                              </div>
                            </Grid>
                          )}
                          <Grid item xs={12} className="text-center">
                            <Typography variant="h5" component="h2">
                              {BuyOfferNFT === "Taipei" ? (
                                <div>Floor Price: {floorprice_taipei} USDT</div>
                              ) : BuyOfferNFT === "CZT" ? (
                                <div>Floor Price: {floorprice_czt} USDT</div>
                              ) : BuyOfferNFT === "TIANJIN" ? (
                                <div>Floor Price: {floorprice_ctf} USDT</div>
                              ) : BuyOfferNFT === "MASTERCLASS" ? (
                                <div>Floor Price: {floorprice_msc} USDT</div>
                              ) : (
                                <div>Floor Price: n/a</div>
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              style={{ backgroundColor: "#f8be24" }}
                              onClick={() => handleBuyOffer()}
                            >
                              Create Offer
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        //CREATE BUY LISTING CRG
                        <>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                            className="pt-2"
                          >
                            <TextField
                              className="mb-3"
                              label="Token Amount"
                              type="number"
                              value={truncateNumber(tokenAmountUSDT)}
                              onChange={(e) =>
                                settokenAmountUSDT(e.target.value)
                              }
                              fullWidth
                              style={{ flexGrow: 1 }}
                              InputProps={{ endAdornment: "USDT" }}
                            />
                            <Button
                              className="m-2"
                              variant="outlined"
                              onClick={handleMaxClick}
                              style={{ flexShrink: 0 }}
                            >
                              Max
                            </Button>
                          </div>
                          <TextField
                            className="mb-3"
                            label="Price / Token"
                            type="number"
                            value={priceCRGBUY}
                            onChange={(e) => setpriceCRGBUY(e.target.value)}
                            fullWidth
                            InputProps={{ endAdornment: "USDT" }}
                          />
                          {!createListingEnabled && (
                            <span className="text-danger text-center">
                              Minimum Price {crgPrice} USDT
                            </span>
                          )}
                          <div className="d-flex flex-column g-8 mb-3">
                            <div className={classes.style_container}>
                              {selectedTab !== 4 && (
                                <div className="d-flex justify-content-between border-bottom pb-8">
                                  <span className="text-dark">Fee:</span>
                                  <span className="text-header">
                                    {listingFeeCRG}% ({usdtFee.toFixed(2)} USDT)
                                  </span>
                                </div>
                              )}
                              <div className="d-flex justify-content-between">
                                <span className="text-dark">You Receive:</span>
                                <span className="text-header">
                                  {truncateNumber(crg_sell_amount)} CRG
                                </span>
                              </div>
                            </div>
                            <div className="text-dark">
                              Reminder: The listing will be processed
                              automatically, as soon as a seller purchases this
                              offer. The CRG will be distributed automatically
                              to your wallet through the smart contract.
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className={classes.nft_buttons + " mb-3"}
                              variant="contained"
                              color="primary"
                              onClick={handleCreateListingCRG}
                            >
                              Create Listing
                            </Button>
                            {/* <Button
                        className={classes.nft_buttons + " mb-3"}
                        variant="contained"
                        color="primary"
                        onClick={handleCloseCreateListingCRG}
                      >
                        Close
                      </Button> */}
                          </div>
                        </>
                      )}
                    </CSSTransition>
                  </div>
                </div>
              ) : null}

              <div className="d-flex flex-column g-8 text-center">
                {allTabLabels.map((tabLabel, index) => (
                  <>
                    <div key={"s" + tabLabel + index}>
                      {selectedTab === index &&
                        dataFetched &&
                        (Array.isArray(buyoffernfts[tabLabel]) &&
                        buyoffernfts[tabLabel].length > 0
                          ? buyoffernfts[tabLabel].map((nft, i) => (
                              <Spring type="list" index={i} key={i}>
                                <div
                                  className={
                                    classes.smooth_container +
                                    " style_container card bordered"
                                  }
                                  style={{
                                    border: "1px solid #d0d0d0",
                                  }}
                                >
                                  <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                                    <div className={classes.style_container}>
                                      <div className="makeStyles-nfticon-28">
                                        <img
                                          src={getImgUrlByNftName(
                                            tabLabel,
                                            nft
                                          )}
                                          alt=""
                                          style={{
                                            maxHeight:
                                              tabLabel === "CRG"
                                                ? "25px"
                                                : "40px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className={classes.style_container}>
                                      BUY OFFER
                                    </div>
                                    <div className={classes.style_container}>
                                      {nft.amount} x
                                    </div>
                                    <div className={classes.style_container}>
                                      {nft.price} USDT
                                    </div>
                                    <div className={classes.style_container}>
                                      {walletAddress.toLowerCase() !==
                                      nft.user.toLowerCase() ? (
                                        <Button
                                          variant="contained"
                                          style={{ backgroundColor: "#f8be24" }}
                                          onClick={handleSellClick}
                                        >
                                          SELL
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          style={{
                                            backgroundColor:
                                              "rgb(25, 118, 210)",
                                          }}
                                          onClick={() =>
                                            handleDeleteOrder(nft.id)
                                          }
                                        >
                                          {loadingDeleteOffer ? (
                                            <CircularProgress
                                              size={24}
                                              color="inherit"
                                            />
                                          ) : (
                                            <>DELETE</>
                                          )}
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="style_group__7Tj9f d-flex align-items-center"></div>
                                </div>
                              </Spring>
                            ))
                          : null)}
                    </div>
                  </>
                ))}
              </div>

              <div
                className="d-flex flex-column g-8 text-center"
                style={{ marginBottom: "100px" }}
              >
                {dataFetched ? (
                  <div
                    className="p-3"
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {selectedTab === 6 ? (
                      <>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Coin
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Amount
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Price / Token
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Action
                        </p>
                      </>
                    ) : selectedTab === 1 ? (
                      <>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          NFT
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          ID
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Price
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Action
                        </p>
                      </>
                    ) : selectedTab === 7 ? (
                      <>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Coin
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Amount
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Price / NFT
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Action
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          NFT
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          ID
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Staking Volume
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Price
                        </p>
                        <p className="d-inline-flex align-items-center g-4 text-10 text-600">
                          Action
                        </p>
                      </>
                    )}
                  </div>
                ) : null}

                {dataisfetching ? (
                  <div className="d-inline-flex align-items-center g-4 text-10 text-600 justify-content-center">
                    <CircularProgress size={24} color="inherit" />
                  </div>
                ) : null}

                {allTabLabels.map((tabLabel, index) => (
                  <>
                    {tabLabels.includes(tabLabel) ? (
                      <div key={"t" + tabLabel + index}>
                        {selectedTab === index &&
                          dataFetched &&
                          (Array.isArray(nfts[tabLabel]) &&
                          nfts[tabLabel].length > 0
                            ? nfts[tabLabel].map((nft, i) => (
                                <Spring type="list" index={i} key={i}>
                                  <div
                                    className={
                                      classes.smooth_container +
                                      " style_container card bordered"
                                    }
                                    style={{
                                      border: "1px solid #d0d0d0",
                                    }}
                                  >
                                    <div className="d-flex align-items-center flex-grow-1 justify-content-between">
                                      <div className={classes.style_container}>
                                        <div className="makeStyles-nfticon-28">
                                          <img
                                            src={getImgUrlByNftName(
                                              tabLabel,
                                              nft
                                            )}
                                            alt="pic"
                                            style={{
                                              maxHeight:
                                                selectedTab !== 4 &&
                                                selectedTab !== 5
                                                  ? "25px"
                                                  : "40px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className={classes.style_container}>
                                        {/* {console.log(nft)} */}
                                        <>
                                          {selectedTab === 4 &&
                                          selectedTab === 5 ? (
                                            <>
                                              {walletAddress ===
                                              "0xeCc5960a72840733731CE9a0f15F0B230aaF8E29" ? (
                                                <>
                                                  #{nft.id} <br></br>
                                                  {selectedTab === 7 &&
                                                    findUserByWallet(
                                                      nft.seller
                                                    )}
                                                </>
                                              ) : (
                                                <>#{nft.id}</>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {nft.contractAddress ===
                                              NEWCOIN_CONTRACT ? (
                                                <>
                                                  {truncateNumber(nft.amount)}{" "}
                                                  CRG
                                                </>
                                              ) : (
                                                <>
                                                  {truncateNumber(
                                                    nft.crg_amount
                                                  )}{" "}
                                                  I-CHARGE
                                                  {walletAddress ===
                                                  "0xeCc5960a72840733731CE9a0f15F0B230aaF8E29" ? (
                                                    <>
                                                      <br></br>
                                                      {selectedTab === 7 &&
                                                        findUserByWallet(
                                                          nft.seller
                                                        )}
                                                    </>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      </div>

                                      {selectedTab !== 6 &&
                                      selectedTab !== 7 &&
                                      selectedTab !== 1 ? (
                                        <div
                                          className={classes.style_container}
                                        >
                                          {selectedTab === 7 ? (
                                            <>50 USDT</>
                                          ) : (
                                            <>{nft.stakingVolume} USDT</>
                                          )}
                                        </div>
                                      ) : null}
                                      <div className={classes.style_container}>
                                        {nft.price} USDT
                                      </div>
                                      <div className={classes.style_container}>
                                        {nft.seller !== walletAddress ? (
                                          <div id={`nft-${nft.id}`}>
                                            {loadingNFTIds.includes(nft.id) ? (
                                              <CircularProgress
                                                size={24}
                                                color="inherit"
                                                className="mt-3"
                                              />
                                            ) : (
                                              <div>
                                                {selectedTab === 8 ? (
                                                  <Button
                                                    id={`button-${nft.id}`}
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor:
                                                        "#f8be24",
                                                    }}
                                                    onClick={(e) => {
                                                      handleBuyNFT(nft);
                                                    }}
                                                  >
                                                    BUY
                                                  </Button>
                                                ) : selectedTab === 6 ||
                                                  selectedTab === 7 ? (
                                                  <Button
                                                    id={`button-${nft.id}`}
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor:
                                                        "#f8be24",
                                                    }}
                                                    onClick={(e) => {
                                                      handleCRG(nft);
                                                    }}
                                                  >
                                                    {nft.contractAddress ===
                                                      NEWCOIN_CONTRACT ||
                                                    nft.contractAddress ===
                                                      MINI_CONTRACT ? (
                                                      <>BUY</>
                                                    ) : (
                                                      <>SELL</>
                                                    )}
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    id={`button-${nft.id}`}
                                                    variant="contained"
                                                    style={{
                                                      backgroundColor:
                                                        "#f8be24",
                                                    }}
                                                    onClick={(e) => {
                                                      console.log(nft);
                                                      handleOpenMenu(e, nft);
                                                    }}
                                                  >
                                                    <MoreHorizIcon />
                                                  </Button>
                                                )}

                                                <Menu
                                                  anchorEl={
                                                    menuAnchor[
                                                      nft.contractAddress ===
                                                      "0x55d398326f99059fF775485246999027B3197955"
                                                        ? "buy_" + nft.id
                                                        : "sell_" + nft.id
                                                    ]
                                                  }
                                                  keepMounted
                                                  open={Boolean(
                                                    menuAnchor[
                                                      nft.contractAddress ===
                                                      "0x55d398326f99059fF775485246999027B3197955"
                                                        ? "buy_" + nft.id
                                                        : "sell_" + nft.id
                                                    ]
                                                  )}
                                                  onClose={() =>
                                                    handleCloseMenu(nft)
                                                  }
                                                >
                                                  <MenuItem
                                                    id={`nft-buy-${nft.id}`}
                                                    // style={{
                                                    //   borderBottom:
                                                    //     "1px solid #d0d0d0",
                                                    //   textTransform: "uppercase",
                                                    // }}
                                                    onClick={() => {
                                                      selectedTab === 6
                                                        ? handleCRG(nft)
                                                        : handleBuyNFT(nft);
                                                    }}
                                                  >
                                                    {nft.contractAddress ===
                                                      NEWCOIN_CONTRACT ||
                                                    nft.contractAddress ===
                                                      MINI_CONTRACT ||
                                                    nft.name === "Taipei" ||
                                                    nft.name === "CZT" ||
                                                    nft.name ===
                                                      "MASTERCLASS" ? (
                                                      <>BUY</>
                                                    ) : (
                                                      <>SELL</>
                                                    )}
                                                  </MenuItem>
                                                  {nft.contractAddress ===
                                                    NEWCOIN_CONTRACT ||
                                                  nft.contractAddress ===
                                                    MINI_CONTRACT ? null : (
                                                    <MenuItem
                                                      id={`make-offer-${nft.id}`}
                                                      style={{
                                                        textTransform:
                                                          "uppercase",
                                                      }}
                                                      onClick={() =>
                                                        handleMenuButton(nft)
                                                      }
                                                    >
                                                      Make Offer
                                                    </MenuItem>
                                                  )}
                                                </Menu>
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          <Button
                                            variant="contained"
                                            style={{
                                              backgroundColor:
                                                "rgb(25, 118, 210)",
                                            }}
                                            onClick={() => {
                                              selectedTab === 6
                                                ? handlCRG(
                                                    nft.id,
                                                    nft.contractAddress
                                                  )
                                                : handleEditButton(
                                                    nft,
                                                    nft.name,
                                                    nft.marketID,
                                                    nft.contractAddress
                                                  );
                                            }}
                                          >
                                            Edit
                                          </Button>
                                        )}
                                      </div>
                                    </div>
                                    <div className="style_group__7Tj9f d-flex align-items-center"></div>
                                  </div>
                                </Spring>
                              ))
                            : null)}
                      </div>
                    ) : selectedTab === index ? (
                      <h4 style={{ marginBottom: "75px" }}>No NFTs listed</h4>
                    ) : null}
                  </>
                ))}

                <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                  <DialogTitle className="text-center">
                    Edit NFT Price
                  </DialogTitle>
                  <DialogContent>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "1rem",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          label="New Price"
                          value={newPrice}
                          onChange={(e) => setNewPrice(e.target.value)}
                          InputProps={{ endAdornment: "USDT" }}
                        />
                        <br></br>
                        {loadingChangePrice && (
                          <CircularProgress
                            size={24}
                            color="inherit"
                            className="mt-3"
                          />
                        )}
                        <Button
                          variant="contained"
                          onClick={handleChangePrice}
                          style={{
                            backgroundColor: "#f8be24",
                            marginTop: "1rem",
                          }}
                        >
                          Change Price
                        </Button>
                      </div>
                      <Divider
                        style={{ marginBottom: "1rem", width: "100%" }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column", // Added this line
                          alignItems: "center", // Align items at center along horizontal axis
                          width: "100%",
                        }}
                      >
                        {loadingCancelOffer && (
                          <CircularProgress
                            size={24}
                            color="inherit"
                            className="mt-3"
                          />
                        )}
                        <Button
                          variant="contained"
                          onClick={handleCancelOffer}
                          style={{ backgroundColor: "red", marginTop: "1rem" }}
                        >
                          Cancel Sell
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={openDialogCRG}
                  onClose={() => setOpenDialogCRG(false)}
                >
                  <DialogTitle className="text-center">
                    Edit Listing
                  </DialogTitle>
                  <DialogContent>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "1rem",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          label="New Price"
                          value={newPrice}
                          className="mt-2"
                          onChange={(e) => {
                            const value = e.target.value.replace(",", ".");
                            setNewPrice(value);
                          }}
                          InputProps={{ endAdornment: "USDT" }}
                        />
                        <br></br>
                        {!canExecuteChangePrice && (
                          <span className="text-danger text-center">
                            {executeChangePriceError}
                          </span>
                        )}
                        <Button
                          variant="contained"
                          onClick={handleChangePriceCRG}
                          style={{
                            backgroundColor: "#f8be24",
                            marginTop: "1rem",
                          }}
                        >
                          Change Price
                        </Button>
                      </div>
                      <Divider
                        style={{ marginBottom: "1rem", width: "100%" }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column", // Added this line
                          alignItems: "center", // Align items at center along horizontal axis
                          width: "100%",
                        }}
                      >
                        {loadingCancelOffer && (
                          <CircularProgress
                            size={24}
                            color="inherit"
                            className="mt-3"
                          />
                        )}
                        <Button
                          variant="contained"
                          onClick={handleCancelOfferCRG}
                          style={{ backgroundColor: "red" }}
                        >
                          Cancel Listing
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>

              {/* <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>NFT</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Staking Volume</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(nfts[tabLabels[selectedTab]]) ? (
              nfts[tabLabels[selectedTab]].map((nft) => (
                <TableRow key={nft.id}>
                  <TableCell>
                    <div className="makeStyles-nfticon-28">
                      <img
                        src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/e516b1ea-a6c2-4948-f714-4a4922871b00/public"
                        alt="Taipei"
                        style={{ maxHeight: "25px" }}
                      ></img>
                    </div>
                  </TableCell>
                  <TableCell>#{nft.id}</TableCell>
                  <TableCell>{nft.stakingVolume} USDT</TableCell>
                  <TableCell>{nft.price} USDT</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#f8be24" }}
                    >
                      Buy
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No NFTs available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer> */}

              <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle className="text-center">
                  Edit NFT Price
                </DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        InputLabelProps={{
                          sx: {
                            marginTop: "8px",
                          },
                        }}
                        label="New Price"
                        value={newPrice}
                        onChange={(e) => setNewPrice(e.target.value)}
                        InputProps={{ endAdornment: "USDT" }}
                      />
                      <br></br>
                      {!canExecuteChangePrice && (
                        <span className="text-danger text-center">
                          {executeChangePriceError}
                        </span>
                      )}
                      <Button
                        variant="contained"
                        onClick={handleChangePrice}
                        style={{
                          backgroundColor: "#f8be24",
                          marginTop: "1rem",
                        }}
                      >
                        Change Price
                      </Button>
                    </div>
                    <Divider style={{ marginBottom: "1rem", width: "100%" }} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column", // Added this line
                        alignItems: "center", // Align items at center along horizontal axis
                        width: "100%",
                      }}
                    >
                      {loadingCancelOffer && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          className="mt-3"
                        />
                      )}
                      <Button
                        variant="contained"
                        onClick={handleCancelOffer}
                        style={{ backgroundColor: "red", marginTop: "1rem" }}
                      >
                        Cancel Sell
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={openDialogOffer}
                onClose={() => setOpenDialogOffer(false)}
                MenuProps={{ disablePortal: true }}
              >
                <DialogTitle className="text-center">Make Offer</DialogTitle>
                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label="New Price"
                        InputLabelProps={{
                          sx: {
                            marginTop: "8px",
                          },
                        }}
                        // value={NewPriceOffer}
                        onChange={(e) => setNewPriceOffer(e.target.value)}
                        InputProps={{ endAdornment: "USDT" }}
                      />
                      <br></br>
                      {loadingMakeOffer && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          className="mt-3"
                        />
                      )}
                      <Button
                        variant="contained"
                        onClick={handleMakeOffer}
                        style={{
                          backgroundColor: "#f8be24",
                          marginTop: "1rem",
                        }}
                      >
                        Make Offer
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={OpenDialogBuyCRG}
                onClose={() => setOpenDialogBuyCRG(false)}
                MenuProps={{ disablePortal: true }}
              >
                <DialogTitle className="text-center h3">
                  {truncateNumber(tokenAmountBuyDialog)} CRG
                </DialogTitle>

                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        Available Balance: {truncateNumber(usdtBalance)} USDT
                      </p>

                      <div
                        style={{ display: "flex", alignItems: "baseline" }}
                        className="pt-2"
                      >
                        <TextField
                          label="Amount"
                          value={truncateNumber(buyCRGAmount)}
                          onChange={(e) => {
                            const value = e.target.value.replace(",", ".");
                            setBuyCRGAmount(value);
                          }}
                          InputProps={{ endAdornment: "CRG" }}
                        />
                        <Button
                          className="m-2"
                          variant="outlined"
                          onClick={handleMaxClickCRGBUY}
                          style={{ flexShrink: 0 }}
                        >
                          Max
                        </Button>
                      </div>
                      <br></br>
                      {loadingMakeOffer && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          className="mt-3"
                        />
                      )}
                      {executeerror !== "" && (
                        <span className="text-danger text-center">
                          {executeerror}
                        </span>
                      )}

                      <div className={classes.style_container + " w-100"}>
                        <div className="d-flex justify-content-between border-bottom pb-8">
                          <span className="text-dark">You Pay:</span>
                          <span className="text-header">
                            {truncateNumber(maxBuyAmountUSDT)} USDT
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="text-dark">You Receive:</span>
                          <span className="text-header">
                            {truncateNumber(crg_sell_amount)} CRG
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleBuyCRG(
                            selectedNFT,
                            maxBuyAmountUSDT,
                            crg_sell_amount
                          )
                        }
                        disabled={!canexecutesellbuy}
                        style={{
                          backgroundColor: "#f8be24",
                          marginTop: "1rem",
                        }}
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={OpenDialogBuyICHARGE}
                onClose={() => setOpenDialogBuyICHARGE(false)}
                MenuProps={{ disablePortal: true }}
              >
                <DialogTitle className="text-center h3">
                  {truncateNumber(tokenAmountBuyDialog)} I-Charge
                </DialogTitle>

                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>
                        Available Balance: {truncateNumber(usdtBalance)} USDT
                      </p>

                      <div
                        style={{ display: "flex", alignItems: "baseline" }}
                        className="pt-2"
                      >
                        <TextField
                          label="Amount"
                          value={truncateNumber(buyCRGAmount)}
                          onChange={(e) => {
                            const value = e.target.value.replace(",", ".");
                            setBuyCRGAmount(value);
                          }}
                          // InputProps={{ endAdornment: "I-Charge" }}
                        />
                        <Button
                          className="m-2"
                          variant="outlined"
                          onClick={handleMaxClickIchargebatch}
                          style={{ flexShrink: 0 }}
                        >
                          Max
                        </Button>
                      </div>
                      <br></br>
                      {loadingMakeOffer && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          className="mt-3"
                        />
                      )}
                      {executeerror !== "" && (
                        <span className="text-danger text-center">
                          {executeerror}
                        </span>
                      )}

                      <div className={classes.style_container + " w-100"}>
                        <div className="d-flex justify-content-between border-bottom pb-8">
                          <span className="text-dark">You Pay:</span>
                          <span className="text-header">
                            {truncateNumber(maxBuyAmountUSDT)} USDT
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="text-dark">You Receive:</span>
                          <span className="text-header">
                            {truncateNumber(crg_sell_amount)} I-Charge
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handlebatchbuyicharge(
                            selectedNFT,
                            maxBuyAmountUSDT,
                            crg_sell_amount
                          )
                        }
                        disabled={!canexecutesellbuy}
                        style={{
                          backgroundColor: "#f8be24",
                          marginTop: "1rem",
                        }}
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>

              <Dialog
                open={OpenDialogBuyUSDT}
                onClose={() => setOpenDialogBuyUSDT(false)}
                MenuProps={{ disablePortal: true }}
              >
                <DialogTitle className="text-center h3">
                  {tokenAmountBuyDialog} USDT
                </DialogTitle>

                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p>Available Balance: {truncateNumber(crgBalance)} CRG</p>

                      <div
                        style={{ display: "flex", alignItems: "baseline" }}
                        className="pt-2"
                      >
                        <TextField
                          label="Amount"
                          value={truncateNumber(buyUSDTAmount)}
                          onChange={(e) => {
                            const value = e.target.value.replace(",", ".");
                            setBuyUSDTAmount(value);
                          }}
                          InputProps={{ endAdornment: "CRG" }}
                        />
                        <Button
                          className="m-2"
                          variant="outlined"
                          onClick={handleMaxClickUSDTBUY}
                          style={{ flexShrink: 0 }}
                        >
                          Max
                        </Button>
                      </div>
                      <br></br>
                      {loadingMakeOffer && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          className="mt-3"
                        />
                      )}
                      {executeerror !== "" && (
                        <span className="text-danger text-center">
                          {executeerror}
                        </span>
                      )}
                      <div className={classes.style_container + " w-100"}>
                        <div className="d-flex justify-content-between border-bottom pb-8">
                          <span className="text-dark">You Sell:</span>
                          <span className="text-header">
                            {truncateNumber(crg_sell_amount)} CRG
                          </span>
                        </div>

                        <div className="d-flex justify-content-between border-bottom pb-8">
                          <span className="text-dark">Fee:</span>
                          <span className="text-header">
                            {listingFeeCRG}% (
                            {truncateNumber(maxBuyAmountUSDTFee)} USDT)
                          </span>
                        </div>

                        <div className="d-flex justify-content-between">
                          <span className="text-dark">You Receive:</span>
                          <span className="text-header">
                            {truncateNumber(maxBuyAmountUSDT)} USDT
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleSellCRG(
                            selectedNFT,
                            usdtamountbrutto,
                            crg_sell_amount
                          )
                        }
                        disabled={!canexecutesellbuy}
                        style={{
                          backgroundColor: "#f8be24",
                          marginTop: "1rem",
                        }}
                      >
                        SELL
                      </Button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Marketplace;

function truncateNumber(value) {
  if (value === "") {
    return value;
  } else {
    let stringValue = value.toString();
    const index = stringValue.indexOf(".");
    if (index !== -1 && index + 6 < stringValue.length) {
      stringValue = stringValue.substring(0, index + 6);
    }
    return stringValue.endsWith(".") ? stringValue : Number(stringValue);
  }
}
