import React, { useState, useEffect } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
} from "wagmi";
import { STANDARD_ABI, STANDARD_CONTRACT } from "../../components/standardnft";
import { VIP_ABI, VIP_CONTRACT } from "../../components/vipnft";
import { usdtabi } from "../../components/content/usdtabi";
import {
  CRGMARKETPLACE_ABI,
  CRGMARKETPLACE_CONTRACT,
} from "../../components/crgmarketplacecontracts";
import {
  NEWCOIN_ABI,
  NEWCOIN_CONTRACT,
} from "../../components/NewCoinContract";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { utils } from "ethers";
import { watchContractEvent } from "@wagmi/core";
import axios from "axios";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import Web3 from "web3";
import {
  MARKETPLACE_ABI,
  MARKETPLACE_CONTRACT,
  USDT_CONTRACT,
} from "../marketplacecontracts";

const useStyles = makeStyles((theme) => ({
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));

const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

export function MintCardVIPNFT({
  walletAddress,
  card,
  totalmaxsupply,
  crgprice,
  crgbalance,
  usdtbalance,
  GBP,
  cztpoolshares,
  globalpoolshares,
  requirementsforglobal,
}) {
  console.log("totalmaxsupply", totalmaxsupply);
  console.log("crgprice", crgprice);
  console.log("requirementsforglobal", requirementsforglobal);
  console.log("globalpoolshares", globalpoolshares);
  console.log("GBP", GBP);
  const navigate = useNavigate();
  const [loadingMint, setloadingMint] = useState(false);
  const [loadingMintCZT, setloadingMintCZT] = useState(false);
  const [loadingMintGlobal, setloadingMintGlobal] = useState(false);

  const [mintMessage, setmintMessage] = useState("");
  const [usdtisapproved, setusdtisapproved] = useState(false);
  const [usdtApproved, setusdtApproved] = useState(0);
  const [crgapproved, setcrgapproved] = useState(0);

  // Button states
  const [isCrgApproved, setIsCrgApproved] = useState(false);
  const [isUsdtApproved, setIsUsdtApproved] = useState(false);

  const [specialMint, setSpecialMint] = useState("");

  const { enqueueSnackbar } = useSnackbar();
  console.log("MindCardNFT");
  const classes = useStyles();
  console.log("walletAddress", walletAddress);
  console.log("card", card);

  let crg_amount = 1;
  let buywithcztpool = false;
  let buywithglobalpool = false;
  let buywithcrg = false;
  let mintamount = 0;
  let discount = 0;
  let usdttopay = 2000;

  if (GBP !== null) {
    console.log("buywithcrg");
    buywithcrg = true;
  }

  if (crgprice !== 0) {
    if (cztpoolshares >= 5) {
      buywithcztpool = true;
      discount =
        cztpoolshares >= 20
          ? 500
          : cztpoolshares >= 15
          ? 375
          : cztpoolshares >= 10
          ? 250
          : cztpoolshares >= 5
          ? 125
          : 0;

      if (discount > 0) {
        crg_amount = discount / crgprice;
        usdttopay = 2000 - discount;
        console.log("CZT Discount in CRG", crg_amount);
        console.log("CZT Discount in USDT", discount);
        console.log("USDT to pay", usdttopay);
      }
    }

    if (globalpoolshares > 0 && crgprice !== 0) {
      buywithglobalpool = true;
      crg_amount = 1000 / crgprice;
      console.log("crgbalance", crgbalance);
      console.log("crg_amount", crg_amount);
      usdttopay = 1000;
      if (crgbalance >= crg_amount) {
        console.log("globalpoolshares", globalpoolshares);
        console.log("CRG Balance enough");
        buywithcrg = true;
      }
    }
    //Test Values
    // usdttopay = 1;
    // crg_amount = 1;

    // wrap in async function
    async function readContracts() {
      if (!walletAddress || !NEWCOIN_CONTRACT || !CRGMARKETPLACE_CONTRACT) {
        console.error("Required parameters are undefined");
        return;
      }

      try {
        const approved_crg = await readContract({
          address: NEWCOIN_CONTRACT,
          abi: NEWCOIN_ABI,
          functionName: "allowance",
          args: [walletAddress, CRGMARKETPLACE_CONTRACT],
        });
        console.log("approved_crg:", approved_crg);
        setcrgapproved(approved_crg);
        setIsCrgApproved(
          Web3.utils.fromWei(approved_crg, "ether") >= crg_amount
        );

        const allowance = await readContract({
          address: "0x55d398326f99059fF775485246999027B3197955",
          abi: usdtabi,
          functionName: "allowance",
          args: [walletAddress, VIP_CONTRACT],
        });
        console.log("allowance CRG:", allowance);
        setusdtApproved(allowance);
        setIsUsdtApproved(Web3.utils.fromWei(allowance, "ether") >= usdttopay);

        if (approved_crg >= Web3.utils.toWei(String(crg_amount), "ether")) {
          console.log("enough crg approved");
          buywithcrg = true;
        } else {
          console.log("not enough crg approved");
          // buywithcrg = false;
        }
      } catch (error) {
        console.error("Error in readContracts:", error);
      }
    }

    readContracts();
  }

  const isapproved = async () => {
    try {
      const allowance = await readContract({
        address: "0x55d398326f99059fF775485246999027B3197955",
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, VIP_CONTRACT],
      });
      console.log("allowance USDT:", allowance);
      setusdtApproved(allowance);
      setIsUsdtApproved(Web3.utils.fromWei(allowance, "ether") >= usdttopay);
      // Return an object with 'allowance' value and 'isSuccess' flag set to true.
      return {
        isSuccess: true,
        allowance: allowance,
      };
    } catch (error) {
      console.error(error);

      // In case of any errors, return an object with 'isSuccess' flag set to false.
      return {
        isSuccess: false,
        allowance: null,
      };
    }
  };

  const isapprovedCRG = async () => {
    try {
      const approved_crg = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "allowance",
        args: [walletAddress, CRGMARKETPLACE_CONTRACT],
      });
      console.log("approved_crg:", approved_crg);
      console.log("needed:", crg_amount);
      // setusdtApproved(approved_crg);

      // Return an object with 'allowance' value and 'isSuccess' flag set to true.
      return {
        isSuccess: true,
        allowance: 0,
        // allowance: approved_crg,
      };
    } catch (error) {
      console.error(error);

      // In case of any errors, return an object with 'isSuccess' flag set to false.
      return {
        isSuccess: false,
        allowance: null,
      };
    }
  };

  let NFT_CONTRACT;
  let MINT_AMOUNT;
  if (card === 1) {
    NFT_CONTRACT = STANDARD_CONTRACT;
    // MINT_AMOUNT = 100;
    MINT_AMOUNT = 0.01;
  } else if (card === 2) {
    NFT_CONTRACT = STANDARD_CONTRACT;
    MINT_AMOUNT = 400;
  } else if (card === 3) {
    NFT_CONTRACT = VIP_CONTRACT;
    MINT_AMOUNT = 1000;
  }

  const handleMintButton = async () => {
    usdttopay = 2000;
    console.log("handleMintButton");
    setloadingMint(true);

    // Must return a promise for await to work.
    const result = await isapproved();

    // Now act based on the result of 'isapproved'.
    if (result.isSuccess) {
      // You now have allowance value directly from the result object
      const allowance = result.allowance;

      if (allowance < Web3.utils.toWei(String(usdttopay), "ether")) {
        approveusdt();
      } else {
        handlewritefunction();
      }
    } else {
      console.error("Approval failed.");
    }
  };

  const handleMintButtonCRG = async () => {
    console.log("handleMintButtonCRG");
    setSpecialMint("global");
    // setloadingMint(true);
    setloadingMintGlobal(true);
    usdttopay = 1000;

    // CRG APPROVED
    const crg_approved = await isapprovedCRG();
    // USDT APPROVED
    const result = await isapproved();
    console.log("result", result);

    if (!isCrgApproved) {
      approvecrg();
    } else {
      console.log("result", result);
      if (result.allowance < Web3.utils.toWei(String(usdttopay), "ether")) {
        approveusdt();
      } else {
        // !isUsdtApproved ? approveusdt() : handlewritefunctionCRGGlobal();

        if (writecrgMarketplace) {
          writecrgMarketplace();
        } else {
          console.error("writecrgMarketplace function is not available.");
        }
      }
    }

    // // Now act based on the result of 'isapproved'.
    // if (result.isSuccess && crg_approved.isSuccess) {
    //   // You now have allowance value directly from the result object
    //   const allowance = result.allowance;

    //   if (allowance <= Web3.utils.toWei(String(crg_amount), "ether")) {
    //     approvecrg();
    //   } else {
    //     handlewritefunctionCRG();
    //   }
    // } else {
    //   if (!isUsdtApproved) {
    //     approveusdt();
    //   } else {
    //     handlewritefunctionCRG();
    //   }

    //   console.error("Approval failed.");
    // }
  };

  const handleMintButtonCRGCZT = async () => {
    console.log("handleMintButtonCRGCZT");
    setloadingMintCZT(true);

    console.log("crgapproved", Web3.utils.fromWei(crgapproved, "ether"));
    console.log("crg_amount", crg_amount);
    console.log("usdtApproved", usdtApproved);

    if (Number(Web3.utils.fromWei(crgapproved, "ether")) < Number(crg_amount)) {
      console.log("approving CRG");
      approvecrg();
    }

    // CRG APPROVED
    const crg_approved = await isapprovedCRG();
    // USDT APPROVED
    const result = await isapproved();

    if (result.isSuccess && crg_approved.isSuccess) {
      handlewritefunctionCRG();
    } else {
      console.error("Approval failed.");
    }
  };

  useEffect(() => {
    if (usdtApproved >= Web3.utils.toWei(String(MINT_AMOUNT), "ether")) {
      console.log("enough usdt approved");
      setusdtisapproved(true);
    } else {
      console.log("not enough usdt approved");
      setusdtisapproved(false);
    }
  }, [usdtApproved]);

  //NORMAL BUY
  const { config } = usePrepareContractWrite({
    address: NFT_CONTRACT,
    abi: VIP_ABI,
    functionName: "mint",
    args: [totalmaxsupply, Web3.utils.toWei(2000, "ether")],
    account: walletAddress,
    enabled: isUsdtApproved,
  });
  const { data, write } = useContractWrite(config || {});

  //CZT BUY
  const { config: cztbuyconfig } = usePrepareContractWrite({
    address: NFT_CONTRACT,
    abi: VIP_ABI,
    functionName: "mint",
    args: [totalmaxsupply, mintamount],
    account: walletAddress,
  });
  const { data: cztbuydata, write: cztbuywrite } = useContractWrite(
    cztbuyconfig || {}
  );

  const { config: configCRGMarketplace } = usePrepareContractWrite({
    address: CRGMARKETPLACE_CONTRACT,
    abi: CRGMARKETPLACE_ABI,
    functionName: "createMarketItem",
    args: [NEWCOIN_CONTRACT, Web3.utils.toWei(String(crg_amount), "ether"), 1],
    account: walletAddress,
    enabled: isCrgApproved,
  });
  const { data: datacrgmarketplace, write: writecrgMarketplace } =
    useContractWrite(configCRGMarketplace || {});

  const {
    data: createMarketItemreceiptcrg,
    isLoading: createMarketItemisPendingcrg,
    isSuccess: createMarketItemisSuccesscrg,
  } = useWaitForTransaction({
    hash: datacrgmarketplace?.hash,
    onError(error) {
      setloadingMint(false);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      enqueueSnackbar("CRG send successful!", {
        variant: "success",
      });
      console.log("CRG send successful", data);
      if (specialMint === "global") {
        console.log("Write Global function");
        handlewritefunctionCRGGlobal();
      } else if (specialMint === "czt") {
        console.log("Write CZT function");
        handlewritefunctionCRG();
      }
      // setusdtisapproved(true);
      // setIsUsdtApproved(true);
      // setloadingMintCZT(false);
      // setloadingMintGlobal(false);
      // writecrgGlobal();
    },
  });

  const { config: configCRG } = usePrepareContractWrite({
    address: VIP_CONTRACT,
    abi: VIP_ABI,
    functionName: "specialMint",
    args: [
      totalmaxsupply,
      Web3.utils.toWei(String(crg_amount), "ether"),
      Web3.utils.toWei(String(usdttopay), "ether"),
    ],
    account: walletAddress,
    enabled: isUsdtApproved,
  });
  const { data: datacrg, write: writecrgGlobal } = useContractWrite(
    configCRG || {}
  );

  const { config: configCRGCZT } = usePrepareContractWrite({
    address: VIP_CONTRACT,
    abi: VIP_ABI,
    functionName: "variableMint",
    args: [
      totalmaxsupply,
      Web3.utils.toWei(String(crg_amount), "ether"),
      Web3.utils.toWei(String(usdttopay), "ether"),
      Web3.utils.toWei(String(discount), "ether"),
    ],
    account: walletAddress,
    enabled: isCrgApproved && isUsdtApproved,
  });
  const { data: datacrgCZT, write: writecrgCZT } = useContractWrite(
    configCRGCZT || {}
  );

  useEffect(() => {
    console.log("Configuration for CRG CZT:", configCRGCZT);
    console.log("writecrgCZT function:", writecrgCZT);
  }, [isCrgApproved, isUsdtApproved, writecrgCZT]);

  const handlewritefunction = () => {
    write();

    const unwatch = watchContractEvent(
      {
        address: NFT_CONTRACT,
        abi: VIP_ABI,
        eventName: "Minted",
      },
      (log) => {
        console.log("EventLog", log);
        const tokenIds = []; // Array to store unique tokenIds
        const users = []; // Array to store unique user values
        const txhashs = []; // Array to store unique user values

        for (let i = 0; i < log.length; i++) {
          const log_ = log[i];
          console.log("log", log_);
          const { args } = log_;
          const { transactionHash } = log_;
          console.log("transactionHash", transactionHash);
          console.log("args", args);
          const { tokenId, user } = args;
          console.log("tokenId", tokenId);
          console.log("user", user);

          if (!tokenIds.includes(tokenId)) {
            tokenIds.push(Number(tokenId));
          }

          if (!users.includes(user)) {
            users.push(user);
          }

          if (!txhashs.includes(transactionHash)) {
            txhashs.push(transactionHash);
          }
        }

        console.log("TokenIds", tokenIds);
        console.log("Users", users);
        unwatch();

        // Send tokenIds and users to your API using Axios POST
        axios
          .post("https://test.croesus-asset.management/api/submit_card_nft", {
            tokenIds,
            users,
            txhashs,
            card,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            enqueueSnackbar("Mint Successful!", {
              variant: "success",
            });
            setloadingMint(false);
            setloadingMintCZT(false);
            setloadingMintGlobal(false);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setloadingMint(false);
            setloadingMintCZT(false);
            setloadingMintGlobal(false);
          });
      }
    );
  };

  const handlewritefunctionCRGGlobal = () => {
    if (writecrgGlobal) {
      writecrgGlobal();
    } else {
      console.error("writecrgGlobal function is not available.");
    }

    const unwatch = watchContractEvent(
      {
        address: NFT_CONTRACT,
        abi: VIP_ABI,
        eventName: "Minted",
      },
      (log) => {
        console.log("EventLog", log);
        const tokenIds = []; // Array to store unique tokenIds
        const users = []; // Array to store unique user values
        const txhashs = []; // Array to store unique user values

        for (let i = 0; i < log.length; i++) {
          const log_ = log[i];
          console.log("log", log_);
          const { args } = log_;
          const { transactionHash } = log_;
          console.log("transactionHash", transactionHash);
          console.log("args", args);
          const { tokenId, user } = args;
          console.log("tokenId", tokenId);
          console.log("user", user);

          if (!tokenIds.includes(tokenId)) {
            tokenIds.push(Number(tokenId));
          }

          if (!users.includes(user)) {
            users.push(user);
          }

          if (!txhashs.includes(transactionHash)) {
            txhashs.push(transactionHash);
          }
        }

        console.log("TokenIds", tokenIds);
        console.log("Users", users);
        unwatch();

        // Send tokenIds and users to your API using Axios POST
        axios
          .post("https://test.croesus-asset.management/api/submit_card_nft", {
            tokenIds,
            users,
            txhashs,
            card,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            enqueueSnackbar("Mint Successful!", {
              variant: "success",
            });
            setloadingMint(false);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setloadingMint(false);
          });
      }
    );
  };

  const handlewritefunctionCRG = () => {
    if (writecrgCZT) {
      writecrgCZT();
    } else {
      console.error("writecrgCZT function is not available.");
    }
    const unwatch = watchContractEvent(
      {
        address: NFT_CONTRACT,
        abi: VIP_ABI,
        eventName: "Minted",
      },
      (log) => {
        console.log("EventLog", log);
        const tokenIds = []; // Array to store unique tokenIds
        const users = []; // Array to store unique user values
        const txhashs = []; // Array to store unique user values

        for (let i = 0; i < log.length; i++) {
          const log_ = log[i];
          console.log("log", log_);
          const { args } = log_;
          const { transactionHash } = log_;
          console.log("transactionHash", transactionHash);
          console.log("args", args);
          const { tokenId, user } = args;
          console.log("tokenId", tokenId);
          console.log("user", user);

          if (!tokenIds.includes(tokenId)) {
            tokenIds.push(Number(tokenId));
          }

          if (!users.includes(user)) {
            users.push(user);
          }

          if (!txhashs.includes(transactionHash)) {
            txhashs.push(transactionHash);
          }
        }

        console.log("TokenIds", tokenIds);
        console.log("Users", users);
        unwatch();

        // Send tokenIds and users to your API using Axios POST
        axios
          .post("https://test.croesus-asset.management/api/submit_card_nft", {
            tokenIds,
            users,
            txhashs,
            card,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            enqueueSnackbar("Mint Successful!", {
              variant: "success",
            });
            setloadingMint(false);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setloadingMint(false);
          });
      }
    );
  };

  //WEB3 COMPONENTS

  //PREPARE APPROVE USDT
  const { config: usdtconfig } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [NFT_CONTRACT, Web3.utils.toWei(2000, "ether")],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      setloadingMint(false);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdt,
    data: approvedata,
    error: errorapprove,
    isLoading: isLoadingapprove,
    isError: isErrorapprove,
  } = useContractWrite(usdtconfig);

  // useEffect(() => {
  //   setloadingMint(false);
  //   setloadingMintCZT(false);
  //   setloadingMintGlobal(false);
  // }, [isErrorapprove]);

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: approvedata?.hash,
    onError(error) {
      setloadingMint(false);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);

      setloadingMint(false);
      setusdtisapproved(true);
      setIsUsdtApproved(true);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
    },
  });

  //PREPARE APPROVE CRG
  const { config: crgconfig } = usePrepareContractWrite({
    address: NEWCOIN_CONTRACT,
    abi: NEWCOIN_ABI,
    functionName: "approve",
    args: [
      CRGMARKETPLACE_CONTRACT,
      Web3.utils.toWei(String(crg_amount), "ether"),
    ],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      setloadingMint(false);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  const {
    write: approvecrg,
    data: approvedatacrg,
    error: errorapprovecrg,
    isLoading: isLoadingapprovecrg,
    isError: isErrorapprovecrg,
  } = useContractWrite(crgconfig);

  useEffect(() => {
    setloadingMint(false);
    setloadingMintCZT(false);
    setloadingMintGlobal(false);
  }, [isErrorapprovecrg]);

  const {
    data: approvedatareceiptcrg,
    isLoading: approveisPendingcrg,
    isSuccess: approveisSuccesscrg,
  } = useWaitForTransaction({
    hash: approvedatacrg?.hash,
    onError(error) {
      setloadingMint(false);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapproved(true);
      handlewritefunctionCRG();
      setcrgapproved(true);
      setloadingMintCZT(false);
      setloadingMintGlobal(false);
    },
  });

  const handleButtonClick = () => {
    setloadingMintCZT(true);
    setSpecialMint("czt");
    if (!isCrgApproved) {
      approvecrg();
    } else {
      if (writecrgMarketplace) {
        writecrgMarketplace();
      } else {
        console.error("writecrgMarketplace function is not available.");
      }
    }

    if (!isUsdtApproved) {
      approveusdt();
    } else {
      handlewritefunctionCRG();
    }
  };

  return (
    <div>
      <>
        {/* {GBP != null && (
          <>
            <br></br>
            <Typography>
              Congratulations! You are elegible for special condition mint
              “Global Bonus Pool Shareholder”. You can pay the mint partially
              with $CRG, which will be burnt after your succesful purchase.
            </Typography>
            <br></br>
            <br></br>
            <h6>1000 USDT and 1000 USDT in $CRG</h6>
            <Button
              className={classes.nft_buttons + " mt-1"}
              style={{ marginBottom: "20px", maxHeight: "33px" }}
              fullWidth={true}
              variant="contained"
              onClick={() => handleMintButtonCRG()}
            >
              {loadingMintGlobal ? (
                <CircularProgress size={25} />
              ) : !isCrgApproved ? (
                "Approve CRG"
              ) : !isUsdtApproved ? (
                "Approve USDT"
              ) : (
                "BUY / Mint CRG GP"
              )}
            </Button>
          </>
        )} */}
        {requirementsforglobal && buywithcrg && (
          <>
            <Typography>
              Congratulations! You are elegible for special condition mint
              “Global Bonus Pool Shareholder”. You can pay the mint partially
              with $CRG, which will be burnt after your succesful purchase.
            </Typography>
            <h6>1000 USDT and 1000 USDT in $CRG</h6>
            <Button
              className={classes.nft_buttons + " mt-1"}
              style={{ marginBottom: "20px", maxHeight: "33px" }}
              fullWidth={true}
              variant="contained"
              onClick={() => handleMintButtonCRG()}
              disabled={
                GBP === "1"
                  ? false
                  : !buywithglobalpool ||
                    globalpoolshares === 0 ||
                    !requirementsforglobal
              }
            >
              {loadingMintGlobal ? (
                <CircularProgress size={25} />
              ) : !isCrgApproved ? (
                "Approve CRG"
              ) : !isUsdtApproved ? (
                "Approve USDT"
              ) : (
                "BUY / Mint CRG GP"
              )}
            </Button>
          </>
        )}
        {/* {buywithcztpool && ( */}

        {buywithcztpool && !buywithcrg && (
          <>
            <Typography>
              Congratulations! You are elegible for special condition mint “CZT
              Pool Shareholder”. You can pay the mint partially with $CRG, which
              will be burnt after your succesful purchase.
            </Typography>
            <h6>
              {2000 - discount} USDT and {discount} USDT in $CRG
            </h6>
            <Button
              className={classes.nft_buttons + " mt-1"}
              style={{ marginBottom: "20px", maxHeight: "33px" }}
              fullWidth={true}
              variant="contained"
              onClick={handleButtonClick}
              disabled={
                loadingMintCZT || !buywithcztpool || !requirementsforglobal
              } // Also consider 'buywithcztpool'
            >
              {loadingMintCZT ? (
                <CircularProgress size={25} />
              ) : usdtbalance < 1000 ? (
                "Not Enough USDT"
              ) : !isCrgApproved ? (
                "Approve CRG"
              ) : !isUsdtApproved ? (
                "Approve USDT"
              ) : (
                "BUY / Mint CRG CZT"
              )}
            </Button>
          </>
        )}

        <h6>2000 USDT</h6>
        <Button
          className={classes.nft_buttons + " mt-3"}
          style={{ marginBottom: "20px", maxHeight: "33px" }}
          fullWidth={true}
          variant="contained"
          onClick={() => handleMintButton()}
        >
          {loadingMint ? (
            <CircularProgress size={25} />
          ) : usdtbalance < 2000 ? (
            "Not Enough USDT"
          ) : !isUsdtApproved ? (
            "Approve USDT"
          ) : (
            "BUY / Mint"
          )}
        </Button>
        {/* )} */}
      </>
      {/* )} */}
    </div>
  );
}

export default MintCardVIPNFT;
