import React, { Fragment, useState, useEffect } from "react";
//import Multistep from "react-multistep";
import { Stepper, Step } from "react-form-stepper";

import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import axios from "axios";
import { Row, Col, Card, Accordion } from "react-bootstrap";

const KYCWizard = ({ NFTCard, card_kyc_data }) => {
  const [underMaintenance, setUnderMaintenance] = useState(false);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  console.log("localUser", localUser);
  console.log("NFTCard KYCWizard", NFTCard);
  console.log("card_kyc_data", card_kyc_data);
  localStorage.setItem("card_kyc_data", JSON.stringify(card_kyc_data));

  const [goSteps, setGoSteps] = useState(0);
  const [isStepOneComplete, setIsStepOneComplete] = useState(false);
  const [isStepTwoComplete, setIsStepTwoComplete] = useState(false);
  const [isStepThreeComplete, setIsStepThreeComplete] = useState(false);
  const [isfullverified, setisfullverified] = useState(false);

  const [cardHolderId, setCardHolderId] = useState("");

  const handleStepThreeSuccess = (success) => {
    setIsStepThreeComplete(success);
  };

  const handleStepTwoSuccess = (success) => {
    setIsStepTwoComplete(success);
  };

  const handleStepOneSuccess = (success) => {
    setIsStepOneComplete(success);
  };

  const handleCardHolderIdReceived = (id) => {
    console.log("handlecardHolderIdReceived", id);
    setCardHolderId(id);
  };

  //useEffect localUser
  useEffect(() => {
    console.log("Localuser Effect Wizard");
    if (card_kyc_data) {
      if (card_kyc_data?.isKycAccepted == "true") {
        console.log("setisfullverified(true)");
        setisfullverified(true);
        setGoSteps(3);
      } else {
        if (card_kyc_data?.kyc_step == "2") {
          console.log("setGoSteps(1)");
          setIsStepOneComplete(true);
          setGoSteps(1);
        } else if (card_kyc_data?.kyc_step == "3") {
          console.log("setGoSteps(2)");
          setGoSteps(2);
        } else if (card_kyc_data?.kyc_step == "4") {
          console.log("setGoSteps(3)");
          setGoSteps(3);
        }
      }
    }
  }, [card_kyc_data]);

  useEffect(() => {
    if (localUser && cardHolderId && goSteps === 0) {
      console.log("setIsStepOneComplete ");
      setIsStepOneComplete(true);
      setGoSteps(1);
      window.scrollTo(0, 0);
    }

    const loadData = async () => {
      console.log("check kyc cardholderID: " + localUser.cardHolderId);
      try {
        // const response = await axios.post(
        //   "https://dashboard.croesus-asset.management/api/physical-cards/kyc/status",
        //   {
        //     card_holder_id: localUser.cardHolderId,
        //   },
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Accept: "application/json",
        //       Authorization:
        //         "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        //     },
        //   }
        // );
        // handle your response here
        // console.log("loadData", response.data);
        // const expectedData = { success: true, kyc_status: true, message: true };
        // if (JSON.stringify(response.data) === JSON.stringify(expectedData)) {
        //   setisfullverified(true);
        //   setCardHolderId(localUser.cardHolderId);
        //   setGoSteps(3);
        // } else {
        //   console.error("Received unexpected data", response.data);
        // }
      } catch (err) {
        // handle error here
        console.error(err);
      }
    };

    if (localUser.cardHolderId) {
      loadData();
    }
  }, [cardHolderId]);

  useEffect(() => {
    if (isStepTwoComplete || isStepThreeComplete) {
      console.log("UseEffect Steps Complete");
      setGoSteps((prevStep) => prevStep + 1);
      window.scrollTo(0, 0);
    }
  }, [isStepTwoComplete, isStepThreeComplete]);

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="">
            <div className="card-body">
              {underMaintenance ? (
                <div
                  class="alert alert-danger text-center		" /* Change alert type as per need */
                  role="alert"
                >
                  This section is currently under maintenance.
                </div>
              ) : (
                <div className="form-wizard">
                  {card_kyc_data?.isKycAccepted !== "true" && (
                    <Stepper className="nav-wizard" activeStep={goSteps}>
                      <Step className="nav-link" />
                      <Step className="nav-link" />
                      <Step className="nav-link" />
                      <Step className="nav-link" />
                    </Stepper>
                  )}
                  {goSteps === 0 && (
                    <>
                      <h3 className="text-center text-black">
                        Personal Information
                      </h3>

                      <Accordion className="accordion accordion-danger-solid text-black">
                        <Accordion.Item eventKey={110}>
                          <Accordion.Header className="accordion-header">
                            Important Information
                          </Accordion.Header>
                          <Accordion.Collapse className="accordion__body">
                            <div className="accordion-body">
                              To avoid delays during the KYC process, please
                              double check your entries to avoid typing errors.
                            </div>
                          </Accordion.Collapse>
                        </Accordion.Item>
                      </Accordion>

                      {card_kyc_data?.issuerKycReviewNotes &&
                        card_kyc_data?.kyc_step === "1" && (
                          <div
                            class="solid alert alert-danger alert-dismissible show"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            <h5 className="text-white m-0">
                              {card_kyc_data?.issuerKycReviewNotes}
                            </h5>
                          </div>
                        )}
                      <br></br>
                      <StepOne
                        onSubmitSuccess={handleStepOneSuccess}
                        onCardHolderIdReceived={handleCardHolderIdReceived}
                      />
                    </>
                  )}
                  {goSteps === 1 && (
                    <>
                      <h3 className="text-center text-black mb-2">
                        ID Verification{" "}
                      </h3>

                      <Accordion className="accordion accordion-danger-solid text-black">
                        <Accordion.Item eventKey={110}>
                          <Accordion.Header className="accordion-header">
                            Important Information
                          </Accordion.Header>
                          <Accordion.Collapse className="accordion__body">
                            <div className="accordion-body">
                              To avoid delays during the KYC process, please
                              double check your entries to avoid typing errors.
                            </div>
                          </Accordion.Collapse>
                        </Accordion.Item>
                      </Accordion>

                      {card_kyc_data?.issuerKycReviewNotes &&
                        card_kyc_data?.kyc_step === "2" && (
                          <div
                            class="solid alert alert-danger alert-dismissible show"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            <h5 className="text-white m-0">
                              {card_kyc_data?.issuerKycReviewNotes}
                            </h5>
                          </div>
                        )}
                      <br></br>
                      <StepTwo
                        onSubmitSuccess={handleStepTwoSuccess}
                        cardHolderId={cardHolderId}
                      />
                      <div className="text-end toolbar toolbar-bottom p-2"></div>
                    </>
                  )}
                  {goSteps === 2 && (
                    <>
                      <h3 className="text-center text-black">
                        Address Verification{" "}
                      </h3>
                      <Accordion className="accordion accordion-danger-solid text-black">
                        <Accordion.Item eventKey={110}>
                          <Accordion.Header className="accordion-header">
                            Important Information
                          </Accordion.Header>
                          <Accordion.Collapse className="accordion__body">
                            <div className="accordion-body">
                              To avoid delays during the KYC process, please
                              double check your entries to avoid typing errors.
                            </div>
                          </Accordion.Collapse>
                        </Accordion.Item>
                      </Accordion>

                      {card_kyc_data?.issuerKycReviewNotes &&
                        card_kyc_data?.kyc_step === "3" && (
                          <div
                            class="solid alert alert-danger alert-dismissible show"
                            role="alert"
                            style={{ color: "white" }}
                          >
                            <h5 className="text-white m-0">
                              {card_kyc_data?.issuerKycReviewNotes}
                            </h5>
                          </div>
                        )}
                      <br></br>
                      <StepThree
                        onSubmitSuccess={handleStepThreeSuccess}
                        cardHolderId={cardHolderId}
                      />
                      <div className="text-end toolbar toolbar-bottom p-2">
                        {/* <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button> */}
                        {/* <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                        // disabled={!isStepThreeComplete}
                      >
                        Next
                      </button> */}
                      </div>
                    </>
                  )}
                  {goSteps === 3 && (
                    <>
                      <StepFour isfullverified={isfullverified} />{" "}
                      {!isfullverified && (
                        <>
                          <div
                            className="col-12 mb-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              class="fade solid alert alert-warning alert-dismissible show"
                              role="alert"
                              style={{ color: "white" }}
                            >
                              <h5 className="text-white m-0">
                                This process can take up to 72 hours.(weekends
                                excluded)
                              </h5>
                            </div>
                          </div>
                          <div
                            className="text-end toolbar toolbar-bottom p-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(2)}
                      >
                        Prev
                      </button> */}
                            {/* <button className="btn btn-primary sw-btn-next ms-1">
                        Submit
                      </button> */}

                            {/* <button className="btn btn-primary  ms-1">
                            Check KYC
                          </button> */}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KYCWizard;
