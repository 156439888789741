import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Button } from "@material-ui/core";
import ApexLineChart from "./SwipeableChart";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import { Select, MenuItem } from "@material-ui/core";
import { MintMasterclassNFT } from "../pages/components/hooks/MintMasterclassNFT";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Web3 from "web3";

import {
  MASTERCLASS_ABI,
  MASTERCLASS_CONTRACT,
} from "../pages/components/materclasscontract";
import {
  MASTERCLASSV2_ABI,
  MASTERCLASSV2_CONTRACT,
} from "../pages/components/materclasscontractv2";
import {
  NEWCOIN_ABI,
  NEWCOIN_CONTRACT,
} from "../pages/components/NewCoinContract";

import { usdtabi } from "../pages/components/content/usdtabi";

// import masterclassjpg from "../../images/masterclass.jpg";
// import "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1f85583b-ebd7-4d88-7c37-cf9ceb5f3200/public" from "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/1f85583b-ebd7-4d88-7c37-cf9ceb5f3200/public";

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: "flex",
  },
  fullbuttons: {
    width: "50%",
    borderRadius: "0px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));
function Masterclass() {
  const classes = useStyles();
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(null);
  const { address: walletAddress, connector, isConnected } = useAccount();

  const [requirement1Fulfilled, setrequirement1Fulfilled] = useState(false);
  const [requirement2Fulfilled, setrequirement2Fulfilled] = useState(false);
  const [requirement3Fulfilled, setrequirement3Fulfilled] = useState(false);
  const [requirement4Fulfilled, setrequirement4Fulfilled] = useState(false);

  const [requirementMCsubscription, setrequirementMCsubscription] =
    useState(false);
  const [
    requirementMCsubscriptiondiscount,
    setrequirementMCsubscriptiondiscount,
  ] = useState(false);

  const [requirementv2Fulfilled, setrequirementv2Fulfilled] = useState(false);
  const [masterclassAmount, setmasterclassAmount] = useState(0);
  const [masterclassAmountV2, setmasterclassAmountV2] = useState(0);

  const [masterclass, setmasterclass] = useState(0);

  const [usdtBalance, setusdtBalance] = useState(0);
  const [usdtBalanceWei, setusdtBalanceWei] = useState(0);
  const [usdtApproved, setusdtApproved] = useState(0);
  const [usdtApprovedOmega, setusdtApprovedOmega] = useState(0);
  const [usdtBalanceEnough, setusdtBalanceEnough] = useState(false);
  const [usdtBalanceEnoughOmega, setusdtBalanceEnoughOmega] = useState(false);
  const [usdtisapprovedMasterclass, setusdtisapprovedMasterclass] =
    useState(false);
  const [usdtisapprovedLivetrading, setusdtisapprovedLivetrading] =
    useState(false);
  const [nftQuantity, setNftQuantity] = useState(1);
  const [nftQuantityOmega, setNftQuantityOmega] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [crgBalance, setcrgBalance] = useState(0);
  const [crgMaxSupply, setcrgMaxSupply] = useState(0);
  const [crgTotalSupply, setcrgTotalSupply] = useState(0);
  const [crgPrice, setcrgPrice] = useState(0);
  const [totalmaxsupply, settotalmaxsupply] = useState(0);
  const [supply, setsupply] = useState(0);
  const [supplyV2, setsupplyV2] = useState(0);

  const [usdtamountTransferMasterclass, setusdtamountTransferMasterclass] =
    useState(0);
  const [usdtamountTransferLiveTrading, setusdtamountTransferLiveTrading] =
    useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const USDT_CONTRACT = "0x55d398326f99059ff775485246999027b3197955";
  const PF_Wallet = "0x3B5338fafE8f826fB25522A0532265BA6BBa5448";

  let Price = 0;

  //   const Price = 100000000000000000;

  const handlesendmasterclass = () => {
    setLoading(true);
    console.log("handlesendmasterclass");
    console.log("userid", user.id);
    axios
      .get(
        `https://test.croesus-asset.management/api/registermasterclass/${user.id}`
      )
      .then((response) => {
        console.log("registermasterclass", response);
        setLoading(false);
        setmasterclass(1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const getAllData = async () => {
      if (nftQuantity !== "") {
        console.log("nftQuantity changed", nftQuantity);
        // let usdtamount = 0.1;
        let usdtamountMasterclass = 800;
        let usdtamountLiveTrading = 1;
        if (requirementMCsubscription) {
          // usdtamount = 800;
          Price = 800;
          setusdtamountTransferMasterclass(
            Web3.utils.toWei(String(usdtamountMasterclass), "ether")
          );
        }
        if (requirementMCsubscriptiondiscount) {
          // usdtamount = 1;
          Price = 240;
          setusdtamountTransferLiveTrading(
            Web3.utils.toWei(String(usdtamountLiveTrading), "ether")
          );
        }
        console.log("usdtamountMasterclass", String(usdtamountMasterclass));
        console.log("usdtamountLiveTrading", String(usdtamountLiveTrading));
        let usdtamountweiMasterclass = Web3.utils.toWei(
          String(usdtamountMasterclass),
          "ether"
        );
        let usdtamountweiLiveTrading = Web3.utils.toWei(
          String(usdtamountMasterclass),
          "ether"
        );
        console.log("usdtamountweiMasterclass", usdtamountMasterclass);
        console.log("usdtamountweiLiveTrading", usdtamountweiLiveTrading);
        console.log("usdtBalance", usdtBalance);
        console.log("usdtApproved", usdtApproved);
        let approvedusdt = Web3.utils.toWei(String(usdtApproved), "ether");
        console.log("approvedusdt", approvedusdt);
        // if (usdtBalance >= usdtamount) {
        //   console.log("enough usdt");
        //   setusdtBalanceEnough(true);
        // setusdtBalanceEnough(true);
        if (usdtApproved >= usdtamountweiMasterclass) {
          console.log("enough usdt approved");
          setusdtisapprovedMasterclass(true);
        } else {
          console.log("not enough usdt approved");
          setusdtisapprovedMasterclass(false);
        }

        if (usdtApproved >= usdtamountweiLiveTrading) {
          console.log("enough usdt approved");
          setusdtisapprovedLivetrading(true);
        } else {
          console.log("not enough usdt approved");
          setusdtisapprovedLivetrading(false);
        }
        // } else {
        //   console.log("not enough usdt");
        //   setusdtBalanceEnough(false);
        // }
      }
    };
    getAllData();
  }, [nftQuantity, requirementMCsubscription, usdtApproved]);

  const isapproved = async () => {
    try {
      const allowance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, PF_Wallet],
      });
      console.log("allowance:", allowance);
      setusdtApproved(allowance);
    } catch (error) {
      console.error(error);
    }
  };

  const usdt_balance = async () => {
    try {
      const balance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("balance:", balance);
      setusdtBalanceWei(balance);
      const balance_InEther = Web3.utils.fromWei(String(balance), "ether");
      console.log("balance_InEther:", balance_InEther);
      setusdtBalance(balance_InEther);

      if (balance_InEther >= 300) {
        setusdtBalanceEnough(true);
        //   const fullNFTs = Math.floor(balance / 1000);
        //   console.log("fullNFTs", fullNFTs);
        //   setmaxNFTAmount(fullNFTs);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isConnected) {
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("useEffect", response.data);
          setUser(response.data.user);
          setmasterclass(response.data.user.masterclass);

          isapproved();
          usdt_balance();
          if (setcrgBalance !== 0) {
            fetchCoinDetails();
          }
          let supply = 60 - response.data.count_masterclass_nfts;
          // let supplyV2 = 60 - response.data.count_masterclassV2_nfts;
          let supplyV2 = 30 - response.data.count_masterclasslive_nfts;
          console.log("supplyV2", supplyV2);
          setsupply(supply);
          setsupplyV2(supplyV2);

          let crg_balance = response?.data?.user?.crg_balance;
          // console.log("crg_balance", crg_balance);
          let book_count =
            response?.data?.nftCounts?.["The Croesus Maxim"]?.overall;
          // console.log("book_count", book_count);

          let alpha_count = response?.data?.nftCounts?.["A-Alpha"]?.overall;
          // console.log("alpha_count", alpha_count);
          let omega_count = response?.data?.nftCounts?.["Omega"]?.overall;
          // console.log("omega_count", omega_count);
          let icharge_count = response?.data?.nftCounts?.["I-Charge"]?.overall;
          // console.log("icharge_count", icharge_count);

          console.log(
            "MASTERCLASS",
            response?.data?.nftCounts?.["MASTERCLASS"]?.overall
          );
          console.log(
            "MASTERCLASSV2",
            response?.data?.nftCounts?.["MASTERCLASSV2"]?.overall
          );

          setmasterclassAmountV2(
            response?.data?.nftCounts?.["MASTERCLASSV2"]?.overall
          );

          setmasterclassAmount(
            response?.data?.nftCounts?.["MASTERCLASS"]?.overall -
              response?.data?.nftCounts?.["MASTERCLASSV2"]?.overall
          );
          if (response?.data?.nftCounts?.["MASTERCLASSV2"]?.overall > 0) {
            setrequirementv2Fulfilled(true);
            setrequirementMCsubscription(true);
          }
          if (
            response?.data?.nftCounts?.["Masterclass Live Trading"]?.overall > 0
          ) {
            setrequirementMCsubscriptiondiscount(true);
          }

          if (icharge_count >= 10) {
            setrequirement1Fulfilled(true);
          }
          if (alpha_count >= 1 || omega_count >= 1) {
            setrequirement3Fulfilled(true);
          }
          if (crg_balance > 20) {
            setrequirement2Fulfilled(true);
          }
          if (book_count > 0) {
            setrequirement4Fulfilled(true);
          }
          if (
            (icharge_count >= 10 && alpha_count >= 1) ||
            (omega_count >= 1 && crg_balance > 20)
          ) {
            setmasterclass(1);
          }

          setisLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  //WEB3 COMPONENTS
  //PREPARE APPROVE USDT MASTERCLASS
  const { config: usdtconfig } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [PF_Wallet, usdtamountTransferMasterclass],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdt,
    data: approvedata,
    error: errorapprove,
    isLoading: isLoadingapprove,
    isError: isErrorapprove,
  } = useContractWrite(usdtconfig);

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: approvedata?.hash,
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapprovedMasterclass(true);
      setRefresh((prev) => !prev);
    },
  });

  //PREPARE APPROVE USDT LIVETRADING
  const { config: usdtconfiglivetrading } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [PF_Wallet, usdtamountTransferLiveTrading],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      // console.log("usdtconfiglivetrading usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfiglivetrading Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfiglivetrading", usdtconfiglivetrading);
  const {
    write: approveusdtlivetrading,
    data: approvedatalivetrading,
    error: errorapprovelivetrading,
    isLoading: isLoadingapprovelivetrading,
    isError: isErrorapprovelivetrading,
  } = useContractWrite(usdtconfiglivetrading);

  const {
    data: approvedatareceiptlivetrading,
    isLoading: approveisPendinglivetrading,
    isSuccess: approveisSuccesslivetrading,
  } = useWaitForTransaction({
    hash: approvedatalivetrading?.hash,
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapprovedLivetrading(true);
      setRefresh((prev) => !prev);
    },
  });

  //TRANSFER MASTERCLASS
  const { config } = usePrepareContractWrite({
    address: USDT_CONTRACT,
    abi: usdtabi,
    functionName: "transfer",
    args: [PF_Wallet, usdtamountTransferMasterclass],
    // enabled: !!withdrawalToProcess,
  });
  const {
    data: senddata,
    write: transfer,
    isLoading: sendpending,
    error: prepareTransferError,
  } = useContractWrite(config);

  const {
    data: topupdataceipt,
    isLoading: withdrawisPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: senddata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      handleTransactionSuccess(data);
    },
    onError(error) {
      console.log("onError", error);
      alert("Error sending payout");
    },
  });

  //TRANSFER LIVETRADING
  const { config: configlivetrading } = usePrepareContractWrite({
    address: USDT_CONTRACT,
    abi: usdtabi,
    functionName: "transfer",
    args: [PF_Wallet, usdtamountTransferLiveTrading],
    // enabled: !!withdrawalToProcess,
  });
  const {
    data: senddatalivetrading,
    write: transferlivetrading,
    isLoading: sendpendinglivetrading,
    error: prepareTransferErrorlivetrading,
  } = useContractWrite(configlivetrading);

  const {
    data: topupdataceiptlivetrading,
    isLoading: withdrawisPendinglivetrading,
    isSuccess: topupdataSuccesslivetrading,
  } = useWaitForTransaction({
    hash: senddatalivetrading?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      handleTransactionSuccessLiveTrading(data);
    },
    onError(error) {
      console.log("onError", error);
      alert("Error sending payout");
    },
  });

  const handleTransactionSuccessLiveTrading = async (data) => {
    console.log("handleTransactionSuccessLiveTrading:", data);
    // console.log("withdrawalId:", withdrawalToProcess.id);
    console.log("txHash:", data.transactionHash);
    try {
      const response = await axios.post(
        "https://test.croesus-asset.management/api/submit_masterclass_subscription_livetrading",
        {
          user: user.id,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.message === "success") {
        user.livetrading_sub = 1;
      }
      // window.location.reload(true);
    } catch (error) {
      console.error("Error saving transaction hash:", error);
      // alert("Error saving transaction hash");
    } finally {
      // setWithdrawalToProcess(null);
    }
  };

  const handleTransactionSuccess = async (data) => {
    console.log("handleTransactionSuccess:", data);
    // console.log("withdrawalId:", withdrawalToProcess.id);
    console.log("txHash:", data.transactionHash);
    try {
      const response = await axios.post(
        "https://test.croesus-asset.management/api/submit_masterclass_subscription",
        {
          user: user.id,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data.message === "success") {
        user.masterclass_sub = 1;
      }
      // window.location.reload(true);
    } catch (error) {
      console.error("Error saving transaction hash:", error);
      // alert("Error saving transaction hash");
    } finally {
      // setWithdrawalToProcess(null);
    }
  };

  const fetchCoinDetails = async () => {
    try {
      const CoinData = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "checkAirdrop",
        args: [walletAddress],
      });
      // console.log("CoinData:", CoinData);

      const CRGBalance = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      // console.log("CRGBalance:", CRGBalance);
      const CRGBalance_InEther = Web3.utils.fromWei(
        String(CRGBalance),
        "ether"
      );
      setcrgBalance(Number(CRGBalance_InEther));

      const CRGMaxSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "maxSupply",
      });
      const CRGMaxSupply_InEther = Web3.utils.fromWei(
        String(CRGMaxSupply),
        "ether"
      );
      // console.log("CRGMaxSupply:", CRGMaxSupply);
      setcrgMaxSupply(CRGMaxSupply);

      const CRGTotalSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "totalSupply",
      });
      const CRGTotalSupply_InEther = Web3.utils.fromWei(
        String(CRGTotalSupply),
        "ether"
      );
      // console.log("CRGTotalSupply:", CRGTotalSupply);
      setcrgTotalSupply(CRGTotalSupply);

      // Add checks for division by zero
      if (CRGMaxSupply_InEther - CRGTotalSupply_InEther !== 0) {
        const CRG_Price =
          10000000 / (CRGMaxSupply_InEther - CRGTotalSupply_InEther);
        console.log("CRG_Price:", CRG_Price);
        setcrgPrice(CRG_Price.toFixed(2));
      } else {
        console.log("Cannot divide by zero. Check your inputs.");
      }

      const totalmaxsupply = CRGMaxSupply - CRGTotalSupply;
      settotalmaxsupply(totalmaxsupply);

      // console.log("totalmaxsupply ! ", totalmaxsupply);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="pt-3"
      //   content-body
      style={{ minHeight: window.screen.height + 80, paddingTop: 0 }}
    >
      {isLoading && (
        <div
          className="text-center"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 99999999999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <Typography variant="h4" style={{ color: "white" }}>
            Loading...
          </Typography>
        </div>
      )}

      {isLoadingapprove && isLoadingapprovelivetrading ? (
        <div
          className="text-center"
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 99999999999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <Typography variant="h4" style={{ color: "white" }}>
            Approve in wallet...
          </Typography>
        </div>
      ) : null}

      <div className="container-fluid text-center mb-2">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Masterclass Subscription - 3 Months</h3>
              </div>

              <div className="card-body mt-3 row d-flex justify-content-center">
                {user.masterclass_sub == 1 ? (
                  <>
                    <h3 className="text-success">Subscription is active.</h3>
                    <h4>1st September - 30th November 2024</h4>
                  </>
                ) : (
                  <div>
                    <h3 className="text-danger">Subscription is inactive.</h3>
                    {requirementMCsubscription && (
                      <>
                        <h5>1st September - 30th November 2024</h5>
                        <h4>800 USDT</h4>
                      </>
                    )}
                    {/* {requirementMCsubscriptiondiscount && (
                      <h5 className="text-info">Discount Price: 240 USDT</h5>
                    )} */}

                    {/* 
                    {requirementMCsubscription && (
                      <>
                        {usdtisapprovedMasterclass ? (
                          <>
                            {withdrawisPending ||
                            sendpending ||
                            sendpendinglivetrading ||
                            withdrawisPendinglivetrading ? (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                              >
                                <CircularProgress
                                  style={{
                                    color: "white",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className={classes.nft_buttons + " mt-3"}
                                  style={{
                                    marginBottom: "20px",
                                  }}
                                  fullWidth={true}
                                  variant="contained"
                                  onClick={transfer}
                                >
                                  BUY
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {usdtBalanceEnough ? (
                              <>
                                {isLoadingapprove ||
                                approveisPending ||
                                isLoadingapprovelivetrading ||
                                approveisPendinglivetrading ? (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                  >
                                    <CircularProgress
                                      style={{
                                        color: "white",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <>
                                    {requirementv2Fulfilled && (
                                      <Button
                                        className={
                                          classes.nft_buttons + " mt-3"
                                        }
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                        fullWidth={true}
                                        variant="contained"
                                        onClick={approveusdt}
                                      >
                                        Approve USDT
                                      </Button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                                disabled
                              >
                                Not enough USDT
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )} */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-center mb-2">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Live Trading Subscription - 3 Months</h3>
              </div>

              <div className="card-body mt-3 row d-flex justify-content-center">
                {user.livetrading_sub == 1 ? (
                  <>
                    <h3 className="text-success">Subscription is active.</h3>
                    <h4>1st September - 30th November 2024</h4>
                  </>
                ) : (
                  <div>
                    <h3 className="text-danger">Subscription is inactive.</h3>
                    {/* {requirementMCsubscription && (
                      <h5>Regular Price: 800 USDT</h5>
                    )} */}
                    {requirementMCsubscriptiondiscount && (
                      <>
                        <h5>1st September - 30th November 2024</h5>
                        <h4>240 USDT</h4>
                      </>
                    )}
                    {/* 
                    {requirementMCsubscriptiondiscount && (
                      <>
                        {usdtisapprovedLivetrading ? (
                          <>
                            {withdrawisPending ||
                            sendpending ||
                            sendpendinglivetrading ||
                            withdrawisPendinglivetrading ? (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                              >
                                <CircularProgress
                                  style={{
                                    color: "white",
                                    width: "30px",
                                    height: "30px",
                                  }}
                                />
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className={classes.nft_buttons + " mt-3"}
                                  style={{
                                    marginBottom: "20px",
                                  }}
                                  fullWidth={true}
                                  variant="contained"
                                  onClick={transferlivetrading}
                                >
                                  BUY
                                </Button>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {usdtBalanceEnough ? (
                              <>
                                {isLoadingapprove ||
                                approveisPending ||
                                isLoadingapprovelivetrading ||
                                approveisPendinglivetrading ? (
                                  <Button
                                    className={classes.nft_buttons + " mt-3"}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                  >
                                    <CircularProgress
                                      style={{
                                        color: "white",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    />
                                  </Button>
                                ) : (
                                  <>
                                    {requirementv2Fulfilled && (
                                      <Button
                                        className={
                                          classes.nft_buttons + " mt-3"
                                        }
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                        fullWidth={true}
                                        variant="contained"
                                        onClick={approveusdtlivetrading}
                                      >
                                        Approve USDT
                                      </Button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <Button
                                className={classes.nft_buttons + " mt-3"}
                                style={{ marginBottom: "20px" }}
                                fullWidth={true}
                                variant="contained"
                                disabled
                              >
                                Not enough USDT
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    )}
                     */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid text-center mb-2">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Genesis Live Trading NFT</h3>
              </div>

              <div className="card-body mt-3 row d-flex justify-content-center">
                <div>
                  <img
                    src={
                      "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/8df77e0d-69b7-4707-cd1c-bebb64fd2200/public"
                    }
                    alt="Masterclass"
                    style={{ width: "100%" }}
                  />
                </div>
                <>
                  <h3
                    className="text-center mt-3"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    {supplyV2} / 30
                  </h3>

                  <h1
                    className="text-center mt-1"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className="crypto-icon--sm crypto-icon--alt"
                      style={{
                        marginRight: "6px",
                        display: "inline-flex",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1l5M7-WU-LJcohPi5TuBu4DBR692OqzlvpDbQb2bqd_KiGUHWq62_0Nqyqw4LRyIRDU&amp;usqp=CAU"
                        alt="usdt"
                        style={{ width: "40px" }}
                      />
                      300 USDT
                    </span>
                  </h1>
                  <FormGroup style={{ alignItems: "flex-start" }}>
                    <h6 className="p-3 pb-0">At Least :</h6>
                    <div>
                      <Checkbox
                        checked={requirementv2Fulfilled}
                        style={{
                          color:
                            requirementv2Fulfilled == true
                              ? "#01b35c"
                              : "#000000",
                        }}
                      />
                      <span>1x Masterclass V2 </span>
                    </div>
                  </FormGroup>
                  <div className="col-8 ">
                    <Select
                      value={nftQuantity}
                      onChange={(event) => setNftQuantity(event.target.value)}
                      displayEmpty
                      fullWidth
                      className="mt-3"
                      style={{ zIndex: 999999999999999999999999999999 }}
                    >
                      <MenuItem value="" disabled>
                        Select NFT quantity
                      </MenuItem>
                      {Array.from({ length: masterclassAmountV2 }, (_, i) => (
                        <MenuItem
                          style={{ zIndex: 999999999999999999999999999999 }}
                          value={i + 1}
                          key={i + 1}
                        >
                          {`${i + 1} Genesis Live Trading NFT`}
                        </MenuItem>
                      ))}
                    </Select>

                    {loading ? (
                      <Box className={`text-center`}>
                        <CircularProgress />
                        <Typography variant="h6">Processing...</Typography>
                      </Box>
                    ) : (
                      <>
                        {error && (
                          <Typography variant="h6" style={{ color: "red" }}>
                            {error}
                          </Typography>
                        )}
                        <>
                          {nftQuantity != "" && supplyV2 > 0 ? (
                            <>
                              {usdtisapprovedMasterclass ? (
                                <MintMasterclassNFT
                                  walletAddress={walletAddress}
                                  nftQuantity={nftQuantity}
                                  totalmaxsupply={totalmaxsupply}
                                />
                              ) : (
                                <>
                                  {usdtBalanceEnough ? (
                                    <>
                                      {isLoadingapprove ||
                                      approveisPending ||
                                      isLoadingapprovelivetrading ||
                                      approveisPendinglivetrading ? (
                                        <Button
                                          className={
                                            classes.nft_buttons + " mt-3"
                                          }
                                          style={{ marginBottom: "20px" }}
                                          fullWidth={true}
                                          variant="contained"
                                        >
                                          <CircularProgress
                                            style={{
                                              color: "white",
                                              width: "30px",
                                              height: "30px",
                                            }}
                                          />
                                        </Button>
                                      ) : (
                                        <>
                                          {requirementv2Fulfilled && (
                                            <Button
                                              className={
                                                classes.nft_buttons + " mt-3"
                                              }
                                              style={{
                                                marginBottom: "20px",
                                              }}
                                              fullWidth={true}
                                              variant="contained"
                                              onClick={approveusdt}
                                            >
                                              Approve USDT
                                            </Button>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <Button
                                      className={classes.nft_buttons + " mt-3"}
                                      style={{ marginBottom: "20px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      disabled
                                    >
                                      Not enough USDT
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Masterclass;
