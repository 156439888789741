import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";
import axios from "axios";

const ControlPanel = () => {
  const [data, setData] = useState({
    totalTradingVolume: null,
    riskReserveRatio: null,
    tradingVolumes: {},
    pnl: [],
    equityChange: null,
    dailyProfit: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your-api-url' with the actual API endpoint
        const response = await axios.get(
          "https://test.croesus-asset.management/api/get_statistic_admin"
        );
        console.log(response.data);
        // setData({
        //   totalTradingVolume: response.data.total_trading_volume,
        //   riskReserveRatio: response.data.risk_ratio,
        //   forexVolume: response.data.forex_volume,
        //   futuresVolume: response.data.futures_volume,
        //   defiVolume: response.data.defi_volume,
        //   sportVolume: response.data.sport_volume,
        //   reserveVolume: response.data.reserve_volume,
        //   pnlForex: response.data.pnl_forex,
        //   pnlFutures: response.data.pnl_futures,
        //   pnlDefi: response.data.pnl_defi,
        //   pnlSpot: response.data.pnl_spot,
        //   pnlReserve: response.data.pnl_reserve,
        //   pnlForex_yesterday: response.data.pnl_forex_yesterday,
        //   pnlFutures_yesterday: response.data.pnl_futures_yesterday,
        //   pnlDefi_yesterday: response.data.pnl_defi_yesterday,
        //   pnlSpot_yesterday: response.data.pnl_spot_yesterday,
        //   pnlReserve_yesterday: response.data.pnl_reserve_yesterday,
        // });
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography variant="h3" align="center" gutterBottom>
        Control Panel
      </Typography>

      {/* Distribution Funds and Risk/Reserve Ratio */}
      <Typography variant="h4" align="center" gutterBottom>
        Distribution Funds
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {/* Total Trading Volume */}
        <Card>
          <CardContent>
            <Typography variant="h6">Total Trading Volume</Typography>{" "}
            {data.totalTradingVolume ?? "Loading..."} USDT
          </CardContent>
        </Card>

        {/* Risk/ Reserve Ratio */}
        <Card>
          <CardContent>
            <Typography variant="h6">Risk/ Reserve Ratio</Typography>
            {data.riskReserveRatio ?? "Loading..."} %
          </CardContent>
        </Card>
      </Box>

      {/* Trading Volumes */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Forex</Typography>
            <Typography variant="h6">
              {data.forexVolume ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Futures</Typography>
            <Typography variant="h6">
              {data.futuresVolume ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">DeFi</Typography>
            <Typography variant="h6">
              {data.defiVolume ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Spot</Typography>
            <Typography variant="h6">
              {data.sportVolume ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Reserve</Typography>
            <Typography variant="h6">
              {data.reserveVolume ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Profitability */}
      <Typography variant="h4" align="center" gutterBottom>
        Profitability
      </Typography>

      {/* PnL Cards */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Forex PnL</Typography>
            <Typography variant="h6">
              {data.pnlForex ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Futures PnL</Typography>
            <Typography variant="h6">
              {data.pnlFutures ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">DeFi PnL</Typography>
            <Typography variant="h6">
              {data.pnlDefi ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Spot PnL</Typography>
            <Typography variant="h6">
              {data.pnlSpot ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Reserve PnL</Typography>
            <Typography variant="h6">
              {data.pnlReserve ?? "Loading..."} USDT
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Typography variant="h6" align="center" gutterBottom>
        Equity Change Previous Day
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Forex PnL</Typography>
            <Typography
              variant="h6"
              style={{ color: data.pnlForex_yesterday > 0 ? "green" : "red" }}
            >
              {(data.pnlForex_yesterday > 0 ? "+" : "") +
                (data.pnlForex_yesterday ?? "Loading...") +
                "%"}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Futures PnL</Typography>
            <Typography
              variant="h6"
              style={{ color: data.pnlFutures_yesterday > 0 ? "green" : "red" }}
            >
              {(data.pnlFutures_yesterday > 0 ? "+" : "") +
                (data.pnlFutures_yesterday ?? "Loading...") +
                "%"}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">DeFi PnL</Typography>
            <Typography
              variant="h6"
              style={{ color: data.pnlDefi_yesterday > 0 ? "green" : "red" }}
            >
              {(data.pnlDefi_yesterday > 0 ? "+" : "") +
                (data.pnlDefi_yesterday ?? "Loading...") +
                "%"}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Spot PnL</Typography>
            <Typography
              variant="h6"
              style={{ color: data.pnlSpot_yesterday > 0 ? "green" : "red" }}
            >
              {(data.pnlSpot_yesterday > 0 ? "+" : "") +
                (data.pnlSpot_yesterday ?? "Loading...") +
                "%"}
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ width: "200px" }}>
          <CardContent>
            <Typography variant="subtitle1">Reserve PnL</Typography>
            <Typography
              variant="h6"
              style={{ color: data.pnlReserve_yesterday > 0 ? "green" : "red" }}
            >
              {(data.pnlReserve_yesterday > 0 ? "+" : "") +
                (data.pnlReserve_yesterday ?? "Loading...") +
                "%"}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Equity Change and Daily Profit */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        {/* Equity Change previous day */}
        <Card>
          <CardContent>
            <Typography variant="subtitle1">
              Equity Change previous day
            </Typography>
            {/* Content here */}
          </CardContent>
        </Card>

        {/* Daily Profit Distributed */}
        <Card>
          <CardContent>
            <Typography variant="subtitle1">
              Daily Profit Distributed
            </Typography>
            {/* Content here */}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ControlPanel;
