import React, { Fragment, useState, useContext, useEffect } from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { Row, Card, Col, Button, Nav, Alert, Accordion } from "react-bootstrap";
import { Stepper, Step } from "react-form-stepper";
import { usdt_abi } from "./contracts/USDT_ABI.js";

import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import Topupbutton from "./Topupbutton.js";

const CardWidget = (props) => {
  //   console.log("props", props);
  const [localUser, setLocalUser] = useState(props.user || null);
  //   console.log("props.user", props.user);
  const [card_kyc_data, setcard_kyc_data] = useState(
    props.card_kyc_data || null
  );
  const [userCard, setuserCard] = useState(props.userCard || null);

  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  const [goSteps, setGoSteps] = useState(0);
  const [refreshloading, setrefreshloading] = useState(false);
  const [cardBalance, setcardBalance] = useState(0);
  const [issuer_Last4, setissuer_Last4] = useState("0000");
  const [LoadAmount, setLoadAmount] = useState(0);
  const [minAmount, setminAmount] = useState(0);
  const [real_amount, setreal_amount] = useState(0);
  const [topuperror, settopuperror] = useState("");
  const [limitleft, setlimitleft] = useState(0);
  const [net_topup, setnet_topup] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [dailytopups, setdailytopups] = useState(0);
  const [isdailylimit, setdailylimit] = useState(false);
  const [status, setStatus] = useState("");
  const [success_message, setsuccess_message] = useState(false);
  const [old_balance, set_old_balance] = useState(false);
  const [address_in, setaddressin] = useState("");

  const [show, setShow] = useState(false);
  const [success_message_pin, setsuccess_message_pin] = useState(false);
  const [maintenance, setmaintenance] = useState("");
  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [toFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const CROESUS_WALLET = "0xaB880Da672Fc00C5CFe6d9561D1185d67DaEEf9c";
  const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";

  const loadingfees = {
    1: [0.0495, 5],
    2: [0.0395, 4],
    3: [0.0295, 3],
    10: [0, 0],
  };

  const handlerefreshbalance = async () => {
    setrefreshloading(true);
    const balance = await fetchcardbalance();
    if (balance !== cardBalance) {
      console.log("Balance changed");
      if (localUser?.cards?.bb_active == "0") {
        console.log("Card is inactive");
        const data = {
          userId: localUser.id,
        };

        fetch("https://test.croesus-asset.management/api/update_card_status", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .finally(() => {
            console.log("bb active successful DB");
            // setcard_status("1");
          });
      } else {
        console.log("Card active");
      }
      setrefreshloading(false);
      window.location.reload();
    }
    setcardBalance(balance);
    setrefreshloading(false);
  };

  useEffect(() => {
    if (localUser) {
      if (localUser.settings.maintenance === 0) {
        setmaintenance(localUser.settings.maintenance);
      } else {
        setmaintenance("");
      }
    }
  }, [localUser]);

  //SET STEP
  useEffect(() => {
    if (userCard !== null) {
      let min_amount;
      let fix_fee;
      let real_amount;
      if (userCard?.bb_active == "0") {
        if (userCard?.card_type === 1) {
          fix_fee = 20;
        } else if (userCard?.card_type === 2) {
          fix_fee = 10;
        } else if (userCard?.card_type === 3) {
          fix_fee = 0;
        }
        setminAmount(100);
        min_amount = 100;
        real_amount = LoadAmount - fix_fee;
      } else {
        setminAmount(50);
        min_amount = 50;
        fix_fee = 0;
        real_amount = LoadAmount;
      }

      setrefreshloading(true);
      console.log("fetching Balance");
      console.log(userCard);
      console.log("userCard.length", userCard.length);
      if (userCard) {
        const fetchBalance = async () => {
          if (userCard?.cardKitNumber !== 12345) {
            console.log("fetched Balance");
            const balance = await fetchcardbalance();
            setcardBalance(balance);
            setissuer_Last4(userCard?.issuer_Last4);
            if (userCard.initial_pin === "1") {
              setGoSteps(2);
            } else {
              setGoSteps(1);
            }
            setrefreshloading(false);
          }
        };

        fetchBalance();
      } else {
        setrefreshloading(false);
      }
    }
  }, [userCard]);

  const fetchcardbalance = async () => {
    // if (userCard?.bb_active === 1) {
    console.log("userCard", userCard);
    try {
      const response = await axios.post(
        "https://test.croesus-asset.management/api/getBalanceByUser",
        {
          cardKitNumber: localUser.cards.cardKitNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("balance update", response);

      if (response.data.success === true) {
        return response.data.message;
      } else {
        if (
          response.data.message === "Failed to retrieve balance" ||
          response.data.message === "Something went wrong"
        ) {
          if (localUser) {
            return localUser.cards.balance;
          }
        }
        return localUser.cards.balance;
      }

      if (!response.status === 200) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.log(error); // log the error to the console for debugging

      if (localUser) {
        return localUser.cards.balance;
      }
      // Handle the error based on the error message
      if (error.response && error.response.status === 500) {
        // Server error, handle it as per the requirement
      } else if (error.request) {
        // The request was made but no response was received
        console.log("The request was made but no response was received");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
    }
  };

  const loadinglimit = {
    1: [1500],
    2: [3000],
    3: [150000],
    10: [0, 0],
  };

  const handleonPinChanged = () => {
    console.log("handleonPinChanged");
    setShow(true);
    setsuccess_message_pin(true);
    console.log("setGoSteps 1");
    setGoSteps(2);
  };

  const handleStatusChange = (status) => {
    // console.log("handleStatusChange", status);
    setStatus(status);
  };

  const handlesettopuperror = (message) => {
    // console.log("handleettopuperror", message);
    settopuperror(message);
  };

  const handleTopUpSuccess = async () => {
    // console.log("handleTopUpSuccess");
    setsuccess_message(true);
    set_old_balance(cardBalance);
    const interval = setInterval(() => {
      handlerefreshbalance();
    }, 15000);

    return () => clearInterval(interval);
  };

  //topup process

  useEffect(() => {
    // console.log(
    //   "localUser.transactionstopuprealtime",
    //   localUser.transactionstopuprealtime.length
    // );
    if (localUser.transactionstopuprealtime.length > 0) {
      const totalBrutAmount = localUser.transactionstopuprealtime.reduce(
        (total, transaction) => total + parseFloat(transaction.brut_amount),
        0
      );
      // console.log("Total Brut Amount:", totalBrutAmount);
      setdailytopups(totalBrutAmount);
      let dailylimit;
      if (localUser?.cards?.card_type) {
        // console.log("dailylimit", dailylimit);
        dailylimit =
          Number(loadinglimit[localUser?.cards?.card_type][0]) -
          totalBrutAmount;
        // console.log("dailylimit left", dailylimit);
      }
      setlimitleft(dailylimit);
      console.log("dailylimit", dailylimit);
      // console.log("LoadAmount", LoadAmount);
      if (LoadAmount !== 0) {
        if (dailylimit >= LoadAmount) {
          console.log("setdailylimit true");
          setdailylimit(true);
        }
      } else {
        if (dailylimit >= totalBrutAmount) {
          console.log("setdailylimit true");
          setdailylimit(true);
        }
      }
    } else {
      if (localUser?.cards?.card_type) {
        setlimitleft(Number(loadinglimit[localUser?.cards?.card_type][0]));
        if (LoadAmount !== 0) {
          if (
            Number(loadinglimit[localUser?.cards?.card_type][0]) >= LoadAmount
          ) {
            // console.log("setdailylimit true");
            setdailylimit(true);
          } else {
            setdailylimit(false);
          }
        } else {
          setdailylimit(true);
        }
      }
    }
  }, [localUser, LoadAmount]);

  useEffect(() => {
    settopuperror("");
    console.log("useEffect Loadmount");
    console.log("Address", address);
    let min_amount;
    let fix_fee;
    let real_amount;
    if (localUser?.cards?.bb_active == "0") {
      if (localUser?.cards?.card_type === 1) {
        fix_fee = 20;
      } else if (localUser?.cards?.card_type === 2) {
        fix_fee = 10;
      } else if (localUser?.cards?.card_type === 3) {
        fix_fee = 0;
      }
      setminAmount(100);
      min_amount = 100;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }
    if (LoadAmount >= min_amount) {
      console.log("Number(LoadAmount)", Number(LoadAmount));
      console.log("Number(limitleft)", Number(limitleft));
      if (Number(LoadAmount) > Number(limitleft)) {
        console.log("Limit exceeded");
        return;
      }

      let InputAmount = LoadAmount - fix_fee;
      console.log("InputAmount", InputAmount);

      console.log(
        "bridgetloadingfee %",
        loadingfees[localUser?.cards?.card_type][0]
      );
      console.log(
        "bridgetloadingfee $",
        loadingfees[localUser?.cards?.card_type][1]
      );
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      console.log("bridgetloadingfee", bridgetloadingfee);

      // let net_card_amount = InputAmount - bridgetloadingfee;
      let cryptapifee = LoadAmount * 0.01;
      console.log("cryptapifee", cryptapifee);
      let net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;

      console.log("net_card_amount", net_card_amount);
      //   let fcf_fee = net_card_amount * 0.0275 + 3;
      //   console.log("fcf_fee", fcf_fee);
      //   let net_card_loading_amount = net_card_amount - fcf_fee;
      //   console.log("net_card_loading_amount", net_card_loading_amount);

      //   //TOPUP SPLITTING
      //   let bb_amount = InputAmount - net_card_amount;
      //   console.log("bb_amount", bb_amount);
      //   let bb_marketing = bb_amount * 0.8;
      //   console.log("bb_marketing", bb_marketing);
      //   let bb_cr = bb_amount * 0.1;
      //   console.log("bb_cr", bb_cr);
      //   let bb_team = bb_amount * 0.1;
      //   console.log("bb_team", bb_team);
      //   let control = bb_marketing + bb_cr + bb_team;
      //   console.log("control", control);

      setnet_topup(net_card_amount.toFixed(2));
      setToFcf(net_card_amount.toFixed(2));
    } else {
    }
  }, [LoadAmount]);

  async function fetchDepositData(cardNumber, amount, tokenSymbol) {
    // console.log("cardNumber", cardNumber);
    // console.log("amount", amount.toString());
    // console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://dashboard.croesus-asset.management/api/physical-cards/load-deposit",
      {
        card_number: cardNumber,
        amount: amount.toString(),
        token_symbol: tokenSymbol,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization:
            "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
        },
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  const {
    data: txhashapprove,
    write: writeValue,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: BSC_USDT_CONTRACT,
    abi: usdt_abi,
    functionName: "transfer",
    args: [address_in, LoadAmount],
    onSuccess(data) {
      console.log("Success transfer");
      // window.scrollTo(0, document.body.scrollHeight);
      // setbscAllowance(approve_amount);
      // handleSetValue();
    },
    onError(error) {
      console.log("Error", error);
      setIsLoading(false);
    },
  });

  const createWeb3API = async () => {
    console.log("Loadamount", LoadAmount);
    setIsLoading(true);
    // setisLoadingTransaction(true);
    let fcf_network;
    let crypt_network;
    if (chain.id === 56) {
      fcf_network = "BSC.USDT";
      crypt_network = "bep20/usdt";
      // setisLoadingTransactionBSC(true);
    } else if (chain.id === 137) {
      fcf_network = "MATIC.USDT";
      crypt_network = "polygon/usdt";
      // setisLoadingTransactionMATIC(true);
    }
    let min_amount;
    let fix_fee;
    let real_amount;
    if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === "1"
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 30;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.bb_active == "0" &&
      localUser?.cards?.monthly_fee === null
    ) {
      setminAmount(100);
      min_amount = 100;
      fix_fee = 20;
      real_amount = LoadAmount - fix_fee;
      setreal_amount(real_amount);
    } else if (
      localUser?.cards?.monthly_fee === "1" &&
      localUser?.cards?.bb_active !== "0"
    ) {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 10;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    } else {
      setminAmount(50);
      min_amount = 50;
      fix_fee = 0;
      real_amount = LoadAmount;
      setreal_amount(real_amount);
    }

    try {
      let amount = LoadAmount;
      console.log("amount", amount);

      //   1. Schritt Definition Netto Ladebetrag Karte (das was der Kunde dann tatsächlich auf die Karte bekommt)
      // 	—> Sendebetrag - BridgeBit Loading Fee (Prozentual + Absolut) - 1% cryptapi = Net Card Loading
      let InputAmount = LoadAmount - fix_fee;

      let cryptapifee = amount * 0.01;
      // console.log("cryptapifee createapiaddress", cryptapifee);
      let bridgetloadingfee =
        InputAmount * loadingfees[localUser?.cards?.card_type][0] +
        loadingfees[localUser?.cards?.card_type][1];
      // console.log("bridgetloadingfee", bridgetloadingfee);
      let net_card_amount;

      net_card_amount = InputAmount - bridgetloadingfee - cryptapifee - 0.5;

      console.log("net_card_amount", net_card_amount);
      setnet_topup(net_card_amount.toFixed(2));

      // 	2. Definition Absoluter Betrag FCF, um prozentuale Aufteilung zu ermitteln
      // 	—> Net Card Loading + FCF Top Up Fee (Prozentual (2.75%  + Absolut (3 usd)

      const apiResponse = await fetchDepositData(
        localUser.cards.cardKitNumber,
        net_card_amount.toFixed(2),
        fcf_network
      );

      console.log("apiResponse", apiResponse);
      setfcftopayaddress(apiResponse.data.address);

      let fcf_amount = apiResponse.data.data.order_amount;
      setToFcf(fcf_amount);

      // 	3. Definition Absoluter Betrag BridgeBit um prozentuale Aufteilung zu ermitteln
      // 	—> Sendebetrag - 2. Absoluter Betrag FCF

      let bridgebit_amount = amount - fcf_amount;
      console.log("bridgebit_amount", bridgebit_amount);
      // let topupsplit = bridgebit_amount * 0.8;
      // settopupref(topupsplit);
      // console.log("topupsplit", topupsplit);

      // 	4. Prozentuale Aufteilung
      // 	1. Absoluter FCF Betrag / (Sendebetrag - Blockchain Fee)

      var to_fcf_percentage = fcf_amount / (amount * 0.99 - 0.5);
      console.log("to_fcf_percentage", to_fcf_percentage);
      setToFcfPercentage(to_fcf_percentage);

      // 	2. Absoluter BB Betrag / (Sendebetrag - Blockchain Fee)

      var to_cam_percentage = bridgebit_amount / (amount * 0.99 - 0.5);
      // console.log("to_cam_percentage", to_cam_percentage);
      // setbb_marketingAmount(bridgebit_amount * 0.8);
      // setbb_crAmount(bridgebit_amount * 0.1);
      // setbb_teamAmount(bridgebit_amount * 0.1);

      let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
      let perc_to_fcf = temp.toFixed(4);
      // console.log("test", perc_to_fcf);
      //   var perc_to_fcf = (Math.ceil(to_fcf_percentage * 100) / 100).toFixed(4);
      var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
      setToCamPercentage(perc_to_cam);

      console.log(perc_to_fcf);
      console.log(perc_to_cam);

      const query = new URLSearchParams({
        callback: `http://api.bridge-bit.com/api/cryptapi-callback?topup=1&user_id=${
          localUser.id
        }&time=${Math.floor(Date.now() / 1000)}`, // Replace with actual callback URL
        multi_token: 1,
        address:
          perc_to_cam +
          "@" +
          CROESUS_WALLET +
          "|" +
          perc_to_fcf +
          "@" +
          apiResponse.data.address, // Replace with the address to forward payments to
        // ... other parameters
      }).toString();

      const response = await fetch(
        `https://api.cryptapi.io/${crypt_network}/create/?${query}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Address created:", data);

      if (data.status === "success") {
        setaddressin(data.address_in);
        try {
          await writeValue();
          // setfcftopayaddress(apiResponse.data.address);
        } catch (err) {
          console.error(`Error while writing value: ${err}`);
        }
        // setaddressin(data.address_in);
        // setCreatedAddress(data.address_in);
        // fetchQrCode(data.address_in, crypt_network);
        // setisLoadingTransaction(true);
      } else {
        // Handle API response error
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating new address:", error);
      setIsLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-xl-12 col-xxl-12">
        <div className="card mb-0">
          {goSteps === 0 ? (
            <div className="text-center">
              <Spinner />
              <h4 className="mt-2">Loading...</h4>
            </div>
          ) : (
            <>
              {userCard.bb_active == "0" && userCard.cardKitNumber !== null && (
                <>
                  <div className="card-header">
                    <h6 className="card-title">
                      First Steps With Your Croesus Card
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-wizard ">
                      <Stepper
                        className="nav-wizard"
                        activeStep={goSteps}
                        label={false}
                      >
                        <Step
                          className="nav-link"
                          onClick={() => setGoSteps(1)}
                        />
                        <Step
                          className="nav-link"
                          onClick={() => setGoSteps(2)}
                        />
                        {/* <Step className="nav-link" onClick={() => setGoSteps(2)} /> */}
                      </Stepper>
                      {goSteps === 1 && (
                        <>
                          <h4 className="card-title text-center">
                            SET PERSONAL PIN
                          </h4>
                          <StepOne
                            localUser={localUser}
                            onPinChanged={handleonPinChanged}
                          />

                          {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-primary sw-btn-next"
                        onClick={() => setGoSteps(2)}
                      >
                        Next
                      </button>
                    </div> */}
                        </>
                      )}
                      {goSteps === 2 && (
                        <>
                          <div className="text-center mb-3">
                            <h4 className="card-title text-center">
                              INITIAL TOP UP
                            </h4>
                            {userCard?.card_type === 1 ? (
                              <label className="text-black font-w600 ">
                                To activate your card you have to top up at
                                least $100 or more. The activation fee of 20$
                                will be automatically substracted from your
                                initial top up.
                              </label>
                            ) : (
                              <>
                                {userCard?.card_type === 2 ? (
                                  <label className="text-black font-w600 ">
                                    To activate your card you have to top up at
                                    least $100 or more. The activation fee of
                                    10$ will be automatically substracted from
                                    your initial top up.
                                  </label>
                                ) : (
                                  <label className="text-black font-w600 ">
                                    To activate your card you have to top up at
                                    least $100 or more.
                                  </label>
                                )}
                              </>
                            )}
                          </div>
                          <StepTwo localUser={localUser} />
                          {/* <div className="text-end toolbar toolbar-bottom p-2">
                      <button
                        className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => setGoSteps(1)}
                      >
                        Prev
                      </button>
                      <button
                        className="btn btn-primary sw-btn-next ms-1"
                        onClick={() => setGoSteps(3)}
                      >
                        Next
                      </button>
                    </div> */}
                        </>
                      )}
                      {goSteps === 3 && (
                        <>
                          {/* <StepThree /> */}
                          <div className="text-end toolbar toolbar-bottom p-2">
                            <button
                              className="btn btn-secondary sw-btn-prev me-1"
                              onClick={() => setGoSteps(2)}
                            >
                              Prev
                            </button>
                            <button
                              className="btn btn-primary sw-btn-next ms-1"
                              onClick={() => setGoSteps(3)}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-xl-8 col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="p-0">
                      <div
                        className="card-bx "
                        style={{
                          minWidth: "270px",
                          position: "relative",
                          display: "flex",
                          zIndex: 1,
                          width: "100%",
                          marginBottom:
                            userCard.initial_pin == "0" ? "80px" : "20px",
                          justifyContent: "center",
                          opacity: userCard.initial_pin == "0" ? 0.3 : 1,
                        }}
                      >
                        <img
                          src={
                            userCard?.card_type === 3
                              ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/7df8b2b4-eef1-4ecf-9e31-913b3323f800/public"
                              : userCard?.card_type === 2
                              ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/26be82ad-2f22-4a58-5eed-54755f4a8f00/public"
                              : userCard?.card_type === 1
                              ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/75e8e207-5b64-4973-8b11-3cba045dca00/public"
                              : "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/75e8e207-5b64-4973-8b11-3cba045dca00/public"
                          }
                          alt=""
                          className="mw-100 "
                          style={{
                            height: "auto",
                            minHeight: "230px",
                            position: "absolute",
                            width: "100%",
                            zIndex: 0,
                            borderRadius: "0.625rem",
                            objectFit: "fill",
                          }}
                        />
                        <div
                          className="card-info text-white w-100"
                          style={{
                            position: "relative",
                            padding: "15px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <p className="fs-22 text-black mb-1"></p>
                            {userCard.bb_active == "1" ? (
                              <p className="mb-1 text-success font-w600">
                                Active
                              </p>
                            ) : (
                              <p className="mb-1 text-danger font-w600">
                                Inactive
                              </p>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center mb-sm-1 mb-1"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2
                              className="fs-36 text-white mb-sm-2 mb-3"
                              style={{ textShadow: "black 1px 1px 4px" }}
                            >
                              {refreshloading ? (
                                <div className="loadingDots">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div>
                              ) : (
                                `$${cardBalance}`
                              )}
                            </h2>
                            <button
                              className="btn btn-light ml-2 p-2"
                              style={{
                                background: "rgba(0, 0, 0, 0.54)",
                                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                border: "1px solid #D5DFE7",
                                color: "white",
                              }}
                              onClick={handlerefreshbalance}
                            >
                              <i
                                className={`fas fa-sync-alt ${
                                  refreshloading ? "fa-spin" : ""
                                }`}
                                style={{ fontSize: "25px" }}
                              ></i>
                            </button>
                          </div>

                          {LoadAmount < minAmount ? (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{
                                justifyContent: "space-between",
                                textShadow: "black 1px 1px 4px",
                              }}
                            >
                              <span
                                className="fs-14 text-danger mb-0"
                                style={{
                                  textShadow: "black 1px 1px 4px;",
                                  fontWeight: "500",
                                }}
                              >
                                Min. Amount {minAmount}
                              </span>

                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center mb-sm-2 mb-2"
                              style={{
                                justifyContent: "end",
                                textShadow: "black 1px 1px 4px",
                              }}
                            >
                              <h4 className="fs-20 text-white mb-0">
                                **** **** **** {issuer_Last4}
                              </h4>
                            </div>
                          )}
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control h-auto text-white"
                                placeholder="Enter Amount"
                                onChange={(e) => setLoadAmount(e.target.value)}
                                style={{
                                  background: "#0000008a",
                                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                                  color: "white",
                                }}
                                // disabled={
                                //   isLoadingTransactionBSC ||
                                //   isLoadingTransactionMATIC
                                // }
                              />
                              {maintenance === "" && (
                                <Topupbutton
                                  LoadAmount={real_amount}
                                  LoadAmountNet={net_topup}
                                  LoadAmountFCF={toFcf}
                                  onTopUpSuccess={handleTopUpSuccess}
                                  onStatusChange={handleStatusChange}
                                  settopuperror={handlesettopuperror}
                                  dailylimitexceeded={isdailylimit}
                                  userCard={userCard}
                                />
                              )}
                              {/* <button
                                className="btn btn-light"
                                onClick={createWeb3API}
                                disabled={isLoading}
                                // style={{ height: "40px" }}
                              >
                                {isLoading ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                  ></Spinner>
                                ) : (
                                  "TopUp test"
                                )}
                              </button> */}
                            </div>{" "}
                          </div>
                          <>
                            {/* {Number(LoadAmount) > Number(limitleft) && (
                          <h4 className="fs-14 text-danger mb-0">
                            Daily limit exceeded.
                          </h4>
                        )} */}
                            {maintenance !== "" && (
                              <h3 className="fs-14 text-danger mb-0">
                                Card loads currently unavailable due to bank
                                maintenance. Will resume shortly.
                              </h3>
                            )}

                            {/* {topuperror !== "" && (
                          <h4 className="fs-14 text-danger mb-0">
                            You don´t have enough USDT on your wallet.
                          </h4>
                        )} */}
                            {/* {maintenance !== "" && (
                          <>
                            {localUser?.settings?.BSC === 0 &&
                            localUser?.settings?.MATIC === 0 ? (
                              <>
                                <h4 className="fs-14 text-danger mb-0">
                                  {maintenance}
                                </h4>
                              </>
                            ) : (
                              <>
                                {localUser?.settings?.BSC === 0 && (
                                  <>
                                    <h4 className="fs-14 text-danger mb-0">
                                      USDT BEP20 Card loads currently
                                      unavailable due to bank maintenance. Will
                                      resume shortly.
                                    </h4>
                                  </>
                                )}
                                {localUser?.settings?.MATIC === 0 && (
                                  <>
                                    <h4 className="fs-14 text-danger mb-0">
                                      USDT MATIC Card loads currently
                                      unavailable due to bank maintenance. Will
                                      resume shortly.
                                    </h4>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )} */}
                          </>
                        </div>
                      </div>{" "}
                      {(userCard?.card_type === 1 ||
                        userCard?.card_type === 2) && (
                        <Accordion
                          className="accordion accordion-primary-solid mt-4"
                          defaultActiveKey="0"
                          style={{ maxWidth: "350px", margin: "0px auto" }}
                        >
                          <Accordion.Item eventKey={1}>
                            <Accordion.Header className="accordion-header fs-20">
                              Daily Top Up Limit
                            </Accordion.Header>
                            <Accordion.Collapse
                              eventKey={1}
                              className="accordion__body fs-16 text-black"
                            >
                              <div className="accordion-body text-center">
                                {/* {dailytopups} USDT /{" "} */}
                                {loadinglimit[userCard?.card_type][0]} USDT
                              </div>
                            </Accordion.Collapse>
                          </Accordion.Item>
                        </Accordion>
                      )}
                    </div>
                  </div>
                </div>
                {net_topup != 0 && (
                  <div
                    className="mt-3 mt-md-5 col-xl-8 col-lg-12 col-sm-12"
                    style={{
                      backgroundImage: `url(${
                        userCard?.card_type === 3
                          ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/db4ab009-63fc-491d-4842-248047dc9300/public"
                          : userCard?.card_type === 2
                          ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/198110d7-3cd6-4170-d370-48675dcd8f00/public"
                          : userCard?.card_type === 1
                          ? "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/03a31bea-23f2-422a-4d49-0ea1aca43400/public"
                          : "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/03a31bea-23f2-422a-4d49-0ea1aca43400/public"
                      })`,
                      backgroundSize: "cover",
                      minHeight: "270px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Col
                      xl="12"
                      sm="6"
                      lg="6"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="m-0">
                        <Card.Body
                          className="d-flex text-center text-light"
                          style={{
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <>
                            <>
                              <>
                                <p className="fs-22 mb-2 text-light">
                                  {status}
                                </p>
                                {/* <p className="fs-18 mb-1 text-black">
                                  Fee:{" "}
                                  {(
                                    loadingfees[
                                      localUser?.cards?.card_type
                                    ][0] * 100
                                  ).toFixed(2)}
                                  % + $
                                  {loadingfees[localUser?.cards?.card_type][1]}
                                </p> */}
                              </>
                              <p className="fs-22 mb-0 text-light">
                                NET TOP UP: ${net_topup}
                              </p>
                            </>
                            {net_topup != 0 && (
                              <>
                                <div className="d-flex flex-column">
                                  {success_message ? (
                                    <div
                                      className="alert alert-warning"
                                      role="alert"
                                    >
                                      Transaction Confirmed. The card balance
                                      loading can take up to 5 minutes. Please
                                      be patient and do not reload this page.
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        </Card.Body>
                      </div>
                    </Col>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardWidget;
