import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import axios from "axios";
import { CircularProgress, Grid, Box } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const MANAGE_CARDS = () => {
  const [reload, setreload] = useState("");
  const [userplans, setuserplans] = useState([]);
  const [cards, setcards] = useState([]);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserPlan, setSelectedUserPlan] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [cardDetails, setCardDetails] = useState("");
  const [cardKitNumbers, setCardKitNumbers] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogShipping, setOpenDialogShipping] = useState(false);

  const [newCHID, setNewCHID] = useState("");
  const [openAlert, setAlert] = useState("");
  const [deliveryLink, setdeliveryLink] = useState("");
  const [loadingpush, setloadingpush] = useState(false);
  const [successfulsend, setsuccessfulsend] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://test.croesus-asset.management/api/getcards`
      );
      console.log("API getcards:", response);

      setcards(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleChangeCHID = async () => {
    try {
      console.log("New CHID:", newCHID);
      console.log("Selected Row:", selectedRow);

      const response = await axios.post(
        "https://test.croesus-asset.management/api/change-chid",
        {
          card_holder_id: selectedRow.cardholdersId,
          new_chid: newCHID,
          user_id: selectedRow.user_id,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      // Check the response
      if (response.data.message === "saved") {
        // Show success alert after successful change
        setAlert(true);
        // Close the dialog after successful change
        // handleCloseDialog();
      } else {
        // You can add here a failure alert or any other action you want to take in case of failure
      }
    } catch (error) {
      console.error("Error changing CHID:", error);
    }
  };

  const handleOpenDialogShipping = (row) => {
    setSelectedRow(row);
    setOpenDialogShipping(true);
  };

  const handleCloseDialogShipping = () => {
    setOpenDialogShipping(false);
  };

  const handleOpenDialog = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const fetchCardDetails = async (cardHolderId) => {
    setIsLoadingDetails(true);
    try {
      const response = await axios.post(
        "https://test.croesus-asset.management/api/cards/get-details",
        { card_holder_id: cardHolderId },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Card Details Received:", response.data);
      setCardDetails(JSON.stringify(response.data, null, 2));
      const cardKitNumber = response.data.data.data.cards[0].cardKitNumber;
      setcards((prev) =>
        prev.map((card) =>
          card.cardholdersId === cardHolderId
            ? { ...card, cardKitNumber }
            : card
        )
      );
    } catch (error) {
      console.error("Error fetching card details:", error);
      setCardDetails("Failed to fetch details");
      setCardKitNumbers((prev) => ({
        ...prev,
        [cardHolderId]: "Failed to fetch",
      }));
    }
    setIsLoadingDetails(false);
  };

  const handlebotpush = () => {
    setloadingpush(true);
    console.log("handlebotpush");
    console.log("userid", selectedRow.user_id);
    let userId = selectedRow.user_id; // replace with actual user id
    let link = deliveryLink; // replace with actual link

    // axios
    //   .post(`https://test.croesus-asset.management/api/send_card_delivery`, {
    //     userid: userId,
    //     link: link,
    //   })
    //   .then((response) => {
    //     setloadingpush(false);
    //     setsuccessfulsend(true);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const renderTableRows = (cards) => {
    // const filteredUserplans = userplans.filter((userplan) =>
    //   userplan.username.includes(searchTerm)
    // );

    if (!Array.isArray(cards)) {
      // Fallback Component or Null when userplans is not an array
      return null;
    }

    return cards.map((card) => (
      <React.Fragment key={card.id}>
        <TableRow>
          <TableCell>{card?.id}</TableCell>
          <TableCell>{card?.name}</TableCell>
          <TableCell>{card?.cardholdersId}</TableCell>
          <TableCell>{card?.cardKitNumber}</TableCell>
          <TableCell>
            {card?.cardKitNumber === null && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => fetchCardDetails(card.cardholdersId)}
              >
                {isLoadingDetails ? (
                  <CircularProgress size={24} />
                ) : (
                  "Get Card Details"
                )}
              </Button>
            )}
            <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialog(card)}
            >
              Change CHID
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              color="primary"
              onClick={() => handleOpenDialogShipping(card)}
            >
              Shipping Details
            </Button>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));
  };

  if (isLoading) {
    return (
      <div>
        {" "}
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <h4>Loading...</h4>
          </Grid>
        </Box>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </div>
    ); // or some loading spinner
  }

  return (
    <>
      <Dialog
        open={openDialogShipping}
        onClose={handleCloseDialogShipping}
        style={{ minWidth: "250px" }}
      >
        <DialogTitle>{"Shipping Details"}</DialogTitle>
        <DialogContent style={{ minWidth: "450px" }}>
          {/* {openAlert && (
            <Alert onClose={() => setAlert(false)} severity="success">
              CHID has been successfully changed!
            </Alert>
          )} */}
          {selectedRow?.shipping_info?.name} <br></br>
          {selectedRow?.shipping_info?.address}
          <br />
          {selectedRow?.shipping_info?.zip} {selectedRow?.shipping_info?.city}
          <br />
          {selectedRow?.shipping_info?.state}
          <br></br>
          {selectedRow?.shipping_info?.country}
          <br></br>
          <br></br>
          <input
            type="text"
            className="form-control h-auto text-black"
            placeholder=""
            onChange={(e) => setdeliveryLink(e.target.value)}
          />
          <br></br>
          {successfulsend && (
            <Alert severity="success">Push successful send</Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlebotpush} color="primary">
            {loadingpush ? <CircularProgress size={24} /> : "Confirm"}
          </Button>
          <Button onClick={handleCloseDialogShipping} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Change Card Holder ID"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Card Holder ID"
            type="text"
            fullWidth
            value={newCHID}
            onChange={(event) => setNewCHID(event.target.value)}
          />
          {openAlert && (
            <Alert onClose={() => setAlert(false)} severity="success">
              CHID has been successfully changed!
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangeCHID} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div className="row">
        <div className="col-12 m-3">
          {/* <TextField
          className="mt-2 mb-2"
          label="Search by username"
          variant="outlined"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        /> */}

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>CardID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>CHID</TableCell>
                  <TableCell>KitNumber</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows(cards)}</TableBody>
            </Table>
          </TableContainer>
          <TextField
            component={Paper}
            fullWidth
            label="Console"
            readOnly
            multiline
            InputProps={{
              readOnly: true,
              inputProps: { style: { minHeight: "100px" } },
            }}
            margin="normal"
            variant="outlined"
            value={cardDetails}
            rows={4}
          />
        </div>
      </div>
    </>
  );
};

export default MANAGE_CARDS;
