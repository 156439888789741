import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Tabs,
  Tab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  flex_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  card: {
    maxWidth: 300,
    margin: "0 auto",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    // marginBottom: theme.spacing(2),
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  cancel_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  dialogContent: {
    textAlign: "center", // Set text alignment to center
  },
  disabled_buttons: {
    background: "#afaeaa !important",
    color: "rgba(0, 0, 0, 0.26)",
  },
  btn_container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  withdraw_button: {
    display: "block",
    color: "black",
    background: "white",
    borderRadius: "12px",
    border: "1px solid var(--border-light)",
    transition: "border-color var(--transition)",
  },
  balance: {
    border: "1px solid #d4d4d4", // Added quotation marks around the value
    display: "flex",
    transition: "border-color var(--transition)",
    borderRadius: "12px", // Moved borderRadius up to match other properties
    alignItems: "center",
    justifyContent: "center",
  },
  flexcolumns: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Company() {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div
      className="content-body p-0"
      style={{ minHeight: window.screen.height + 180 }}
    >
      <div
        className="container-fluid text-center "
        style={{ marginBottom: "100px" }}
      >
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-12">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h1>Company Introduction</h1>
              </div>

              <div className="card-body mt-3 row" style={{ display: "block" }}>
                <img
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/2ca859cf-6d7e-479f-34d0-3954ee5b0b00/public"
                  alt="Background"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    maxWidth: "230px",
                  }}
                />
                <Button
                  className={classes.nft_buttons}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href="https://app.croesus-asset.management/public/downloads/Croesus%20Asset%20Management%20Limited.pdf"
                >
                  DOWNLOAD
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-12">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h1>Our Social Media</h1>
              </div>

              <div className="card-body mt-3 row" style={{ display: "block" }}>
                <img
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/6e7490b5-891d-4799-19ab-dc1f685c1200/public"
                  alt="Background"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    maxWidth: "230px",
                  }}
                />
                <Button
                  className={classes.nft_buttons}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href="https://linktr.ee/croesusam"
                >
                  LINKTREE
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-12">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h1>Trading Insight</h1>
              </div>

              <div className="card-body mt-3 row" style={{ display: "block" }}>
                <img
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/93d76482-cb80-49a6-eaa9-f21c7ae4ec00/public"
                  alt="Background"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    maxWidth: "230px",
                  }}
                />
                <Button
                  className={classes.nft_buttons}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  href="https://tradermake.money/trader/9617GAxEEz3727/"
                >
                  LINK
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-12">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h1>Performance</h1>
              </div>

              <div className="card-body mt-3 row" style={{ display: "block" }}>
                <img
                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/d0885b02-2f53-44e7-2d66-cce4cbd9c100/public"
                  alt="Background"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    maxWidth: "230px",
                  }}
                />
                <Button
                  className={classes.nft_buttons}
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog} // Open the dialog when the button is clicked
                >
                  OPEN
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Performance</DialogTitle>
          <DialogContent>
            <Table>
              {/* <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Report</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead> */}
              <TableBody>
                {/* Add your table rows here */}
                <TableRow>
                  <TableCell>YTD Performance</TableCell>
                  <TableCell>
                    <Button
                      className={classes.nft_buttons}
                      variant="contained"
                      color="primary"
                      target="_blank"
                      href="https://app.croesus-asset.management/public/downloads/Croesus%20Asset%20Management_YTD%20Performance_.pdf"
                    >
                      OPEN
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default Company;
