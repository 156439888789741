import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexLineChart = ({ filteredDailyProfits }) => {
  // Setting up the options for ApexCharts
  const strokeColors = ["#000000", "#fabc28"]; // Replace with your desired hex colors

  const options = {
    chart: {
      id: "line-chart",
      toolbar: {
        show: true, // Set to true if you want to show the toolbar
      },
      zoom: {
        enabled: true, // Set to true to enable zooming
      },
    },
    xaxis: {
      categories: filteredDailyProfits.labels, // Your x-axis categories (labels)
    },
    stroke: {
      curve: "smooth", // Determines the type of curve for the lines
    },
    colors: strokeColors, // Add this line to set custom colors
  };

  // Your series data for the chart
  const series = filteredDailyProfits.datasets.map((dataset, index) => {
    // You can use a mapping or a conditional logic to assign names based on index or any other property
    if (index === 0) {
      return { ...dataset, name: "Staked" };
    } else if (index === 1) {
      return { ...dataset, name: "Equity" };
    }
    return dataset; // If there are more series and you don't want to change their names
  });

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350} // Adjust height as needed
      />
    </div>
  );
};

export default ApexLineChart;
