import React, { useState, useEffect } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";

const StakingPage = () => {
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const DataArray = [];
  const [nfts_staked, setnfts_staked] = useState(DataArray);
  const [nfts_owned, setnfts_owned] = useState(DataArray);

  useEffect(() => {
    if (isConnected) {
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("get User Data", response.data);
          setUser(response.data.user);
          setnfts_staked(response.data.nfts_staked);
          setnfts_owned(response.data.nfts_owned);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
      setLoading(false);
    }
  }, [isConnected, walletAddress]);

  return (
    <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12 mt-3">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">NFTs Owned</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive recentOrderTable">
            <table className="table verticle-middle table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Token ID</th>
                  <th scope="col">Transaction Hash </th>
                  <th scope="col">Minted At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Function</th>
                </tr>
              </thead>
              <tbody>
                {nfts_owned.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.tokenid}</td>
                    <td>
                      <a href={`https://bscscan.com/tx/${item.txhash}`}>
                        {item.txhash.slice(0, 10)}...
                      </a>
                    </td>
                    <td>{item.minted_at}</td>
                    <td>
                      <span>{item.status}</span>
                    </td>
                    <td>
                      <Dropdown className="dropdown custom-dropdown mb-0">
                        <Dropdown.Toggle
                          className="btn sharp btn-primary tp-btn i-false"
                          data-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="12" cy="5" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="19" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Dropdown.Item className="dropdown-item">
                            Details
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item text-danger">
                            Cancel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">NFTs Staked</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive recentOrderTable">
            <table className="table verticle-middle table-responsive-md">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Token ID</th>
                  <th scope="col">Transaction Hash </th>
                  <th scope="col">Minted At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Function</th>
                </tr>
              </thead>
              <tbody>
                {nfts_staked.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.tokenid}</td>
                    <td>
                      <a href={`https://bscscan.com/tx/${item.txhash}`}>
                        {item.txhash.slice(0, 10)}...
                      </a>
                    </td>
                    <td>{item.minted_at}</td>
                    <td>
                      <span>{item.status}</span>
                    </td>
                    <td>
                      <Dropdown className="dropdown custom-dropdown mb-0">
                        <Dropdown.Toggle
                          className="btn sharp btn-primary tp-btn i-false"
                          data-toggle="dropdown"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="12" cy="5" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="19" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Dropdown.Item className="dropdown-item">
                            Details
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown-item text-danger">
                            Cancel
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingPage;
