import React, { useState, useEffect, useMemo } from "react";
import Treefold from "react-treefold";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  primaryText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  secondaryText: {
    fontSize: 14,
    // add additional styles as needed
  },
}));

const SkeletonLoader = () => {
  // Implement a skeleton loader UI
  return (
    <div>
      {/* Skeleton loading UI goes here */}
      Loading...
    </div>
  );
};

const MatrixComponent = ({ user }) => {
  const classes = useStyles();
  const [matrix, setMatrix] = useState([]);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [loading, setLoading] = useState(true);
  const [structureturnover, setstructureturnover] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Memoize the API URL to avoid unnecessary re-fetches
    const apiUrl = `https://test.croesus-asset.management/api/getMatrixTurnover/${user.id}`;

    if (user.id) {
      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          console.log("data", data);
          setMatrix([data]); // Wrap data in an array
          setstructureturnover(data.downline_nfts);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error.message);
          setLoading(false);
          if (error.message === "Network response was not ok") {
            setError("No Matrix found");
          }
        });
    }
  }, [user]);

  const renderNode = ({
    node,
    level,
    isFolder,
    isExpanded,
    getToggleProps,
    hasChildNodes,
    renderChildNodes,
  }) => {
    const arrowIcon = hasChildNodes ? (isExpanded ? "▼" : "►") : ""; // Show icon if there are child nodes
    console.log(`renderNode:`, node);

    return (
      <>
        <br></br>
        <div style={{}}>
          {/*       <div style={{ marginLeft: level * 20 }}>
           */}
          <ListItem
            button
            onClick={getToggleProps().onClick}
            style={{
              backgroundColor:
                node.nfts === 0
                  ? "#ff9797"
                  : isExpanded
                  ? "#e0f7fa"
                  : "#f0f0f0",
              border: "1px solid #ccc",
              borderRadius: "5px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ListItemText
              primaryTypographyProps={{ className: classes.primaryText }}
              secondaryTypographyProps={{ className: classes.secondaryText }}
              primary={`${node.name}`}
              secondary={
                <>
                  <div>{`Investment: ${node.investment} USDT`}</div>
                  <div>{`Turnover: ${node.downlineTurnover} USDT`}</div>
                </>
              }
            />
            <div>
              {" "}
              {node.nfts === 0 ? "0 NFTs  " : null}
              {arrowIcon}
            </div>
          </ListItem>
          {isExpanded && (
            <List component="div" disablePadding>
              {renderChildNodes()}
            </List>
          )}
        </div>
      </>
    );
  };

  const skeletonLoader = useMemo(() => <SkeletonLoader />, []); // Memoize the skeleton loader

  return (
    <div>
      {loading && skeletonLoader}
      {!loading && !error && (
        <>
          {/* <Typography variant="body1" color="textPrimary">
            Structure Turnover : {structureturnover} USDT
          </Typography>
          <br></br> */}
          <Treefold
            nodes={matrix}
            render={renderNode}
            isNodeExpanded={(node) => expandedNodes[node.name]}
            onToggleExpand={(node) => {
              setExpandedNodes({
                ...expandedNodes,
                [node.name]: !expandedNodes[node.name],
              });
            }}
            key={matrix.map((node) => node.name).join(",")}
          />
        </>
      )}
      {error && <div key="error">Error: {error}</div>}
    </div>
  );
};

export default MatrixComponent;
