import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { contractAbi, contractAddress } from "./components/contractInfo";

import {
  contractAbi as CoinAbi,
  contractAddress as CoinAddress,
} from "./components/CoinContractInfo";
import { usdtabi } from "./components/content/usdtabi";

import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@material-ui/core";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import logo from "../../images/logo-full_horizontal.png";
import Skeleton from "@mui/material/Skeleton";
import EpubReader from "./EpubReader";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import Alert from "@mui/material/Alert";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

// Initialize web3
let web3 = new Web3(
  "https://responsive-chaotic-borough.bsc.discover.quiknode.pro/70cff735d747d5eb9a000ba83941159c878f49b0/"
);

// Your contract instance
let contract = new web3.eth.Contract(contractAbi, contractAddress);
console.log("contract", contract);

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  gap: "20px",
};
const textStyle = {
  color: "red",
};
const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

function useUserNfts(walletAddress, refresh) {
  console.log("useUserNfts", walletAddress);

  const [userNfts, setUserNfts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserNFTs = async () => {
    setIsLoading(true);
    const userTokenIds = await contract.methods
      .tokensOfOwner(walletAddress)
      .call();
    // console.log("userTokenIds", userTokenIds);

    const userNfts = [];
    for (let i = 0; i < userTokenIds.length; i++) {
      const tokenId = userTokenIds[i];
      // console.log("tokenId", tokenId);
      const tokenAttributes = await contract.methods
        .getAttributes(tokenId)
        .call();
      if (tokenAttributes.language == "DE") {
        tokenAttributes.imageUrl =
          "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/15e1867b-5984-4f12-2d3d-33fb23aaaa00/public";
      } else {
        tokenAttributes.imageUrl =
          "https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/3289d069-4662-4701-40f3-ce743b832400/public";
      }
      console.log("tokenAttributes ", tokenAttributes);

      userNfts.push({
        id: tokenId,
        language: tokenAttributes.language,
        format: tokenAttributes.format,
        imageUrl: tokenAttributes.imageUrl,
      });

      // console.log("tokenAttributes", tokenAttributes);
    }

    setUserNfts(userNfts);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserNFTs();
  }, [walletAddress, refresh]);

  return { userNfts, isLoading };
}

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: "flex",
  },
  fullbuttons: {
    width: "50%",
    borderRadius: "0px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
  logoAbbr: {
    paddingRight: "25px",
    // Add any other necessary styling for your logo
  },
  web3Button: {
    padding: "10px",
    // Add any other necessary styling for your Web3Button
  },
  media: {
    maxWidth: "22vh",
    margin: "0 auto", // Aligns the image in the center horizontally
  },
  nft: {
    margin: "0 auto", // Aligns the image in the center horizontally
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    width: "70% !important",
    color: "white",
  },
  ebook_buttons: {
    background: "#716c58 !important",
    width: "70% !important",
    color: "white",
  },
  alert: {
    width: "70% !important",
    display: "inline-flex !important",
    marginBottom: "10px",
  },
}));

function NftCard({ nft, user }) {
  console.log("nft card", nft);
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("existing");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setcity] = useState("");
  const [country, setCountry] = useState("");
  const { address: walletAddress, connector, isConnected } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [sendprint, setsendprint] = useState(false);

  const openEpubReaderPage = () => {
    const secureToken =
      "YPtF1z5gEefI1jNZKxRIzEQ05V=oM9by4zoP2mccDGz4S721yMVWierjb0THVC3";
    localStorage.setItem("epubReaderToken", secureToken);
    localStorage.setItem("language", nft.language);
    const url = `/epub-reader`;
    navigate(url);
  };

  const fetchDatas = async () => {
    const metadata = {
      user: user.id,
      tokenid: nft.id,
      name: name,
      address: address,
      postalCode: postalCode,
      city: city,
      country: country,
    };
    console.log("fetchDatas metadata ", metadata);

    try {
      const response = await axios.post(
        "https://test.croesus-asset.management/api/printversion",
        metadata
      );
      console.log("fetchDatas response  ", response);
    } finally {
      console.log("Finally ");
      window.location.reload();
    }
  };

  const handlePrintVersion = () => {
    setOpen(true);
    setName(user.fullname);
    setAddress(user.adress);
    setPostalCode(user.areacode);
    setcity(user.city);
    setCountry(user.country);
  };
  const handleClose = () => setOpen(false);
  const handleChange = (event) => setValue(event.target.value);

  const { config: setAttributesConfig } = usePrepareContractWrite({
    address: contractAddress,
    abi: contractAbi,
    functionName: "setAttributes",
    args: [nft.id, nft.language, "Print"],
    account: walletAddress,
  });
  // console.log("setAttributesConfig", setAttributesConfig);
  const {
    write: setAttributeswrite,
    data: Attributedata,
    error: errorsetAttribute,
    isLoading: isLoadingsetAttribute,
    isError: isErrorAttribute,
  } = useContractWrite(setAttributesConfig);

  const {
    data: Attributedatareceipt,
    isLoading: AttributeisPending,
    isSuccess: AttributeisSuccess,
  } = useWaitForTransaction({
    hash: Attributedata?.hash,
    onSuccess: async (data) => {
      // Add 'async' here
      setsendprint(true);

      enqueueSnackbar("Success!", {
        variant: "success",
      });
      setOpen(false);
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    },
  });

  useEffect(() => {
    console.log("sendprint ", sendprint);
    if (sendprint === true) {
      console.log("fetchDatas ");
      fetchDatas();
    }
  }, [sendprint]);

  useEffect(() => {
    console.log("AttributeisSuccess ", AttributeisSuccess);

    if (AttributeisSuccess === true) {
      const sendData = async () => {
        // Add 'async' here
        const metadata = {
          user: user.id,
          tokenid: Number(nft.id),
          name: name,
          address: address,
          postalCode: postalCode,
          city: city,
          country: country,
        };
        console.log("fetchDatas metadata ", metadata);

        axios
          .post(
            "https://test.croesus-asset.management/api/printversion",
            {
              user: user.id,
              tokenid: Number(nft.id),
              name: name,
              language: nft.language,
              address: address,
              postalCode: postalCode,
              city: city,
              country: country,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, Accept, Authorization",
              },
            }
          )
          .then((response) => {
            console.log("S Response:", response.data);
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      sendData(); // Call the async function
    }
  }, [AttributeisSuccess]);

  const setAttributes = () => {
    setAttributeswrite();
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card style={{ padding: "10px", marginBottom: "10px" }}>
        <CardActionArea>
          <CardContent>
            <Typography variant="h5" component="div">
              NFT #{Number(nft.id)}
            </Typography>
            <Typography variant="h6" component="div">
              Language: {nft.language}
            </Typography>
            <Typography variant="h6" component="div">
              Format: {nft.format}
            </Typography>
          </CardContent>
        </CardActionArea>
        <div>
          <Button
            className={`${classes.fullbuttons} ${classes.nft_buttons} mb-2`}
            variant="contained"
            color="primary"
            onClick={openEpubReaderPage}
          >
            Open E-Book
          </Button>
          {nft.format === "Print" || nft.format === "print" ? null : (
            <Button
              className={`${classes.fullbuttons} ${classes.ebook_buttons} mb-2`}
              variant="contained"
              color="secondary"
              onClick={handlePrintVersion}
            >
              Order Print Version
            </Button>
          )}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Confirm Shipping Information</DialogTitle>
            <DialogContent>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="shipping"
                  name="shipping1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label={`Confirm Shipping Information`}
                  />
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label="Deliver to a different address"
                  />
                </RadioGroup>
                <TextField
                  margin="dense"
                  label="Name"
                  type="text"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  disabled={value !== "new"}
                />
                <TextField
                  margin="dense"
                  label="Street/ No"
                  type="text"
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  disabled={value !== "new"}
                />
                <TextField
                  margin="dense"
                  label="City"
                  type="text"
                  fullWidth
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                  disabled={value !== "new"}
                />
                <TextField
                  margin="dense"
                  label="Postal Code"
                  type="text"
                  fullWidth
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                  disabled={value !== "new"}
                />
                <TextField
                  margin="dense"
                  label="Country"
                  type="text"
                  fullWidth
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  disabled={value !== "new"}
                />
              </FormControl>
              <Typography variant="h6">Expected Delivery: 2 weeks</Typography>
            </DialogContent>
            {isLoadingsetAttribute ? (
              <Box className={`${loadingStyle} text-center`}>
                <CircularProgress />
                <Typography variant="h6">Confirm in Wallet...</Typography>
              </Box>
            ) : (
              <></>
            )}
            {AttributeisPending ? (
              <Box className={`${loadingStyle} text-center`}>
                <CircularProgress />
                <Typography variant="h6">Transaction Pending...</Typography>
              </Box>
            ) : (
              <></>
            )}
            <DialogActions>
              <Button
                className={`${classes.fullbuttons} ${classes.ebook_buttons} mb-2`}
                onClick={handleClose}
                style={{ color: "white" }}
              >
                Cancel
              </Button>
              <Button
                className={`${classes.fullbuttons} ${classes.nft_buttons} mb-2`}
                onClick={setAttributes}
                autoFocus
                style={{ color: "white" }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Card>
    </Grid>
  );
}

async function sendMetadataToServer(data) {
  try {
    const response = await axios.post(
      "https://test.croesus-asset.management/api/metadata",
      data
    );
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
}

// Component for the form
export function MintWizard(props) {
  const navigate = useNavigate();
  const { kyc } = props; // Destructuring the props object
  const [step, setStep] = useState(1);
  const [format, setFormat] = useState("Ebook");
  const [language, setLanguage] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [minted, setMinted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { userNfts, isLoading } = useUserNfts(walletAddress, refresh);
  const classes = useStyles();
  const { chain } = useNetwork();
  console.log("chain".chain);
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const [kyclink, setkyclink] = useState();
  const [user, setUser] = useState(null);
  const [applicantID, setapplicantID] = useState("0");
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [status, setStatus] = React.useState("false");
  const [usdtisapproved, setusdtisapproved] = useState(false);
  const [reloadnfts, setreloadnfts] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [book_supply, setubooksupply] = useState(0);

  const url =
    "https://kyc-api.amlbot.com/forms/5861287f0b54e64cda2ac51751451a242700/urls";
  const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0"; // Replace with your actual API token
  const applicantData = {
    applicant_id: applicantID, // Replace with actual data
    external_applicant_id: "123456",
    redirect_url: "https://book.croesus-asset.management/booknft",
  };

  // VERIFICATION MODULE START
  const fetchData = async () => {
    setIsLoadingStatus(true);
    console.log("user.form_url ", user.form_url);
    if (user.form_url === null) {
      console.log("user.form_url != NULL");
      console.log("applicantData ", applicantID);
      try {
        const response = await axios.post(url, applicantData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${apiToken}`,
          },
        });
        console.log("fetchData", response.data);

        const metadata = {
          status: user.kyc,
          verificationID: response.data.verification_id,
          applicant_id: applicantID,
          form_url: response.data.form_url,
          userID: user.id,
        };
        console.log("metadata", metadata);
        const response_api = await axios.post(
          "https://test.croesus-asset.management/api/setKYC",
          metadata
        );
        setIsLoadingStatus(false);
        setkyclink(response.data.form_url);
      } catch (error) {
        console.error(error);
        setIsLoadingStatus(false);
      } finally {
        setIsLoadingStatus(false);
        // updateStatus();
        // window.location.href = kyclink;
      }
    } else {
      console.log("user.form_url !== NULL");
      setIsLoadingStatus(false);
      // getVerification();
    }
  };

  useEffect(() => {
    console.log("kyclink", kyclink);
    if (kyclink !== undefined) {
      window.location.href = kyclink;
    }
  }, [kyclink]);

  const startKYC = async () => {
    const url = "https://kyc-api.amlbot.com/applicants";
    const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0";
    console.log("user.form_url ", user.form_url);

    if (user.form_url === null) {
      let firstname = "";
      let lastname = "";
      if (user.fullname !== null) {
        const names = user.fullname.split(" ");

        if (names.length === 2) {
          firstname = names[0];
          lastname = names[1];
        } else if (names.length === 1) {
          firstname = user.fullname;
        }
      }

      const applicantData = {
        type: "PERSON",
        first_name: firstname,
        last_name: lastname,
        wallet_address: user.web3_wallet,
        email: user.email,
      };
      console.log("applicantData", applicantData);

      try {
        const response = await axios.post(url, applicantData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${apiToken}`,
          },
        });
        console.log("response ", response.data);
        setapplicantID(response.data.applicant_id);
      } catch (error) {
        console.error(`Error creating applicant: ${error}`);
      }
    } else {
      console.log("redirecting ");
      window.location.href = user.form_url;
    }
  };

  useEffect(() => {
    if (applicantID !== "0") {
      fetchData();
    }
  }, [applicantID]);

  const getFormUrl = async () => {
    try {
    } catch (error) {
    } finally {
    }
  };

  const getVerification = async () => {
    setIsLoadingStatus(true);
    const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0";

    try {
      const response = await axios.get(
        `https://kyc-api.amlbot.com/verifications/${user.verificationid}`,
        {
          headers: {
            Authorization: apiToken,
          },
        }
      );

      console.log("getVerification", response.data);
      // setUser(response.data);
      // console.log(response.data.kyc);
      console.log(response.data.status);
      setStatus(response.data.status);

      if (response.data.status === "unused") {
        // window.location.href = kyclink;
      } else {
        const metadata = {
          status: response.data.status,
          verificationID: user.verificationid,
          userID: user.id,
        };
        console.log("metadata", metadata);
        const response_api = await axios.post(
          "https://test.croesus-asset.management/api/setKYC",
          metadata
        );
        setIsLoadingStatus(false);
      }
    } catch (error) {
      console.error(`Error getting verification: ${error}`);
      setIsLoadingStatus(false);
    }
  };

  // VERIFICATION MODULE ENDS

  useEffect(() => {
    if (isConnected && chain.id !== 56) {
      switchNetwork(56);
    }
  }, [chain]);

  useEffect(() => {
    if (isConnected) {
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("get User Data", response.data);
          setUser(response.data.user);
          let supply = 999 - response.data.count_books;
          setubooksupply(supply);
          let status = response.data.user.kyc;
          if (status === "false" && response.data.user.verificationid != null) {
            console.log("Try to get verification");
            getVerification();
          }
          console.log(response.data.user.kyc);
          setStatus(response.data.user.kyc);
          setLoading(false);
          setIsLoadingStatus(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingStatus(false);
        });
    } else {
      console.log("disconnected");
      setLoading(false);
      setIsLoadingStatus(false);
    }
  }, [isConnected, walletAddress, refresh]);

  //PREPARE APPROVE USDT
  const { config: usdtconfig } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [contractAddress, 269000000000000000000],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdt,
    data: approvedata,
    error: errorapprove,
    isLoading: isLoadingapprove,
    isError: isErrorapprove,
  } = useContractWrite(usdtconfig);

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: approvedata?.hash,
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapproved(true);
      setRefresh((prev) => !prev);
    },
  });

  //PREPARE MINT TRANSACTION
  // console.log("language:", language);
  // console.log("format:", format);
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: contractAbi,
    functionName: "mint",
    args: [language, format],
    account: walletAddress,
    onError(error) {
      console.log("Error: usePrepareContractWrite");
      let errorstring = JSON.stringify(error);
      if (errorstring.includes("BEP20: transfer amount exceeds allowance")) {
        console.log("Error: Transfer amount exceeds allowance");
        setusdtisapproved(false);
      } else {
        console.log("Other error occurred");
      }
    },
    onSuccess(data) {
      console.log("Success usePrepareContractWrite", data);
      setusdtisapproved(true);
    },
  });
  // console.log("config", config);
  const {
    write,
    data,
    error: errorMint,
    isLoading: isLoadingMint,
    isError,
  } = useContractWrite(config);

  // const {
  //   write,
  //   data,
  //   error: errorMint,
  //   isLoading: isLoadingMint,
  //   isError,
  // } = useContractWrite({
  //   address: contractAddress,
  //   abi: contractAbi,
  //   functionName: "mint",
  //   args: [language, format],
  // });

  const {
    data: receipt,
    isLoading: isPending,
    isSuccess,
  } = useWaitForTransaction({
    hash: data?.hash,
  });

  useEffect(() => {
    if (isSuccess) {
      console.log("isSuccess receipt:", receipt);
      console.log("isSuccess data:", data);
      enqueueSnackbar("Mint Successful!", {
        variant: "success",
      });
      mintNFT();
    }
  }, [isSuccess]);

  useEffect(() => {
    console.log("errorMint");
    console.log(errorMint);
    console.log(isError);
    setError("Error : Please try again");
  }, [errorMint]);

  const mintNFT = async () => {
    console.log("mintNFT async:");
    setLoading(true);
    setError(null);
    try {
      console.log("receipt:", receipt);

      const hexString = receipt.logs[4].topics[1];
      const tokenId = parseInt(hexString, 16);
      console.log("receipt TokenId:", tokenId);

      const metadata = {
        tokenId,
        language,
        format,
      };
      console.log("metadata:", metadata);

      const apidata = {
        tokenId: tokenId,
        transactionHash: receipt.transactionHash,
        to: receipt.to,
        from: receipt.from,
      };

      try {
        console.log("Try API create_nft: ");
        const response = await axios.post(
          "https://test.croesus-asset.management/api/create_nft",
          apidata
        );
        console.log("Response create_nft:", response.data);
        console.log("data:", data);
      } catch (apierror) {
        console.error("apierror:", apierror);
      }

      sendMetadataToServer(metadata);
      setIsLoadingStatus(false);
      setStep(1);
      setMinted(true);
      setRefresh((prev) => !prev);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div
        className="content-body p-0"
        // style={{ minHeight: window.screen.height + 50 }}
      >
        {/* <div className="container-fluid text-center mb-2">				
                <div className={classes.container}>
                    <Link to="#">
                    <img
                        className={`${classes.logoAbbr} dark-logo`}
                        width="200"
                        src={logo}
                        alt=""
                    />
                    <img
                        className={`${classes.logoAbbr} light-logo text-center m-auto`}
                        width="200"
                        src={logo}
                        alt=""
                    />
                    </Link>
                    <Web3Button className={classes.web3Button} />          
                </div>
            </div> */}
        <div className="container-fluid text-center">
          <div className="row mt-2 justify-content-md-center">
            <div className="col-xl-12">
              {status !== "false" ? (
                <div className="col-xl-12">
                  {isLoading ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  ) : (
                    <>
                      {userNfts.map((nft) => (
                        <NftCard nft={nft} user={user} key={nft.id} />
                      ))}
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <div className="col-xl-12">
              <div className="card ">
                <div className="card-header  text-center pb-0 border-0 flex-wrap mb-3"></div>
                <div className="card-body text-center pt-0 pb-2">
                  <Box className={containerStyle}>
                    <h1 className="text-center">The Croesus Maxim</h1>
                    <CardMedia
                      component="img"
                      image="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/3289d069-4662-4701-40f3-ce743b832400/public"
                      className={classes.nft}
                      style={{
                        height: `calc(100vh - ${window.screen.height *
                          0.47}px)`,
                        width: "auto",
                      }}
                    />
                    <h3
                      className="text-center mt-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {book_supply} / 999
                    </h3>

                    <h1
                      className="text-center mt-1"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        className="crypto-icon--sm crypto-icon--alt"
                        style={{
                          marginRight: "6px",
                          display: "inline-flex",
                          alignItems: "end",
                        }}
                      >
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1l5M7-WU-LJcohPi5TuBu4DBR692OqzlvpDbQb2bqd_KiGUHWq62_0Nqyqw4LRyIRDU&amp;usqp=CAU"
                          alt="usdt"
                          style={{ width: "40px" }}
                        />
                        269 USDT
                      </span>
                    </h1>

                    {/* <Typography variant="h6" style={{ fontFamily: 'Courier' }}>Mint Book NFT</Typography>       */}
                    {/* <Typography variant="h6" style={{ fontFamily: 'Courier' }}>0x6b33eff936B183D77a0905FeE887d1Dc0041dB5a</Typography>       */}
                    {loading ? (
                      <Box className={`${loadingStyle} text-center`}>
                        <CircularProgress />
                        <Typography variant="h6">Loading...</Typography>
                      </Box>
                    ) : (
                      <>
                        {isError && (
                          <Typography
                            style={{ color: "red" }}
                            variant="h6"
                            className={textStyle}
                          >
                            {error}
                          </Typography>
                        )}
                        {minted && (
                          <Typography
                            variant="h6"
                            color="success"
                            className="text-center"
                            style={{ color: "green !important" }}
                          >
                            NFT minted!
                          </Typography>
                        )}

                        {status === "completed" ? (
                          <>
                            {step === 1 && (
                              <>
                                {usdtisapproved ? (
                                  <div>
                                    <Typography
                                      className="text-center mb-2"
                                      variant="subtitle1"
                                    >
                                      Choose Language
                                    </Typography>
                                    <Button
                                      className={classes.nft_buttons}
                                      style={{ marginBottom: "10px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      onClick={() => {
                                        setLanguage("EN");
                                        setStep(3);
                                      }}
                                    >
                                      English
                                    </Button>
                                    <Button
                                      className={classes.nft_buttons}
                                      style={{ marginBottom: "10px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      onClick={() => {
                                        setLanguage("DE");
                                        setStep(3);
                                      }}
                                    >
                                      German
                                    </Button>
                                  </div>
                                ) : (
                                  <div>
                                    {(isLoadingapprove || approveisPending) && (
                                      <div>
                                        <CircularProgress />
                                      </div>
                                    )}

                                    {approveisSuccess ? (
                                      <Alert
                                        className={classes.alert}
                                        severity="success"
                                        color="success"
                                      >
                                        USDT Amount approved!
                                      </Alert>
                                    ) : null // Replace with the desired logic for when approveisSuccess is falsy
                                    }
                                    <Button
                                      className={classes.nft_buttons}
                                      style={{ marginBottom: "20px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      onClick={approveusdt}
                                    >
                                      {approveisPending ? (
                                        <div>Approval Pending</div>
                                      ) : (
                                        <div>Approve USDT</div>
                                      )}
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}

                            {step === 2 && (
                              <div>
                                <Typography
                                  className="text-center"
                                  variant="subtitle1"
                                >
                                  Enter Shipping Info
                                </Typography>
                                <TextField
                                  placeholder="Full Name"
                                  onChange={(e) => setFullName(e.target.value)}
                                />
                                <TextField
                                  placeholder="Address"
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                                {usdtisapproved ? (
                                  <Button
                                    className={classes.nft_buttons}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={write}
                                  >
                                    BUY / Mint
                                  </Button>
                                ) : (
                                  <Button
                                    className={classes.nft_buttons}
                                    style={{ marginBottom: "20px" }}
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={approveusdt}
                                  >
                                    Approve USDT
                                  </Button>
                                )}
                              </div>
                            )}

                            {step === 3 && (
                              <div>
                                {(isLoadingMint || isPending) && (
                                  <div>
                                    <CircularProgress />
                                  </div>
                                )}

                                {minted ? (
                                  <Alert
                                    className={classes.alert}
                                    severity="success"
                                    color="success"
                                  >
                                    Mint Successfull!
                                  </Alert>
                                ) : null // Replace with the desired logic for when approveisSuccess is falsy
                                }

                                <Button
                                  className={classes.nft_buttons}
                                  style={{ marginBottom: "20px" }}
                                  fullWidth={true}
                                  variant="contained"
                                  onClick={write}
                                >
                                  {isLoadingMint ? (
                                    <div>Check Wallet</div>
                                  ) : (
                                    <>
                                      {isPending ? (
                                        <div>Transaction Pending</div>
                                      ) : (
                                        <div>BUY / MINT</div>
                                      )}
                                    </>
                                  )}
                                </Button>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {status !== "pending" ? null : (
                              <>
                                <h1
                                  className="text-center mb-0"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Status <br></br>
                                </h1>
                                <h1
                                  className="text-center mb-0"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "orange",
                                  }}
                                >
                                  Pending
                                </h1>
                              </>
                            )}

                            {isLoadingStatus ? (
                              <CircularProgress />
                            ) : (
                              <Button
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "10px",
                                  width: "50%",
                                }}
                                fullWidth={true}
                                variant="contained"
                                onClick={startKYC}
                              >
                                <>Complete KYC</>
                              </Button>
                            )}
                          </>
                        )}
                        {/* {isLoadingMint && <div>Check wallet...</div>}
                    {isPending && <div>Transaction pending...</div>} */}
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MintWizard;
