import React, { Fragment, useState, useContext, useEffect } from "react";
// WEB3
import {
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useAccount,
  useNetwork,
} from "wagmi";
import Web3 from "web3";

const StepTwo = (localUser) => {
  const [network, setNetwork] = useState("");
  console.log("StepTwo", localUser);
  const { address, isConnecting, isDisconnected } = useAccount();
  const { chain } = useNetwork();
  console.log(chain);

  return (
    <section>
      <div className="row justify-content-md-center p-3">
        <div className="col-lg-12">
          <div
            className="row d-flex"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <div
              className="col-lg-6 d-flex"
              style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <label className="text-black font-w600 ">
                Cryptapi TEST Select Blockchain
              </label>
              <div
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row", // Change from 'column' to 'row'
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  value="bep20"
                  //   onClick={(e) => handlesetNetwork("bep20")}
                  className="btn btn-primary mt-3 m-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  //   disabled={network && network !== "bep20"}
                >
                  <img
                    src="https://api.cryptapi.io/media/token_logos/bnb_logo_otj5BsU.png"
                    alt="network"
                    style={{ marginRight: "10px", width: "25px" }}
                  />
                  BEP20
                </button>

                <button
                  value="trc20"
                  //   onClick={(e) => handlesetNetwork("trc20")}
                  className="btn btn-primary mt-3 m-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  //   disabled={network && network !== "trc20"}
                >
                  <img
                    src="https://cdn.bitkeep.vip/u_b_fdfe0be0-c215-11ed-bb06-6b42bb500220.png"
                    alt="network"
                    style={{ marginRight: "10px", width: "25px" }}
                  />
                  TRC20
                </button>

                <button
                  value="matic"
                  //   onClick={(e) => handlesetNetwork("matic")}
                  className="btn btn-primary mt-3 m-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  //   disabled={network && network !== "matic"}
                >
                  <img
                    src="https://api.cryptapi.io/media/token_logos/polygon_logo_ZZ69slV.png"
                    alt="network"
                    style={{ marginRight: "10px", width: "25px" }}
                  />
                  MATIC
                </button>

                <button
                  value="btc"
                  //   onClick={(e) => handlesetNetwork("btc")}
                  className="btn btn-primary mt-3 m-1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  //   disabled={network && network !== "btc"}
                >
                  <img
                    src="https://api.cryptapi.io/media/token_logos/btc.png"
                    alt="network"
                    style={{ marginRight: "10px", width: "25px" }}
                  />
                  BTC
                </button>
              </div> */}
            </div>
          </div>
          <div
            className="row d-flex mt-3"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            {/* {apialert && <Alert variant="danger">{apialert}</Alert>} */}
          </div>

          {/* {createdAddress && (
                <div
                  className="row d-flex mt-3"
                  style={{ flexDirection: "column", alignItems: "center" }}
                >
                  <div
                    className="col-lg-12 d-flex"
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label className="text-black font-w600 ">Address</label>
                    <div className="input-group mb-3 mt-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                        value={createdAddress}
                        readOnly
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(createdAddress)
                            .then(() => alert("Address copied to clipboard!"))
                            .catch((error) =>
                              console.error("Could not copy text: ", error)
                            );
                        }}
                      >
                        COPY
                      </button>
                    </div>
                    <label className="text-black font-w600 ">Amount</label>
                    <div
                      className="input-group mb-3 mt-3"
                      style={{ maxWidth: "200px" }}
                    >
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        value={LoadAmount}
                        readOnly
                      />

                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => {
                          navigator.clipboard
                            .writeText(LoadAmount)
                            .then(() => alert("Amount copied to clipboard!"))
                            .catch((error) =>
                              console.error("Could not copy text: ", error)
                            );
                        }}
                      >
                        COPY
                      </button>
                    </div>
                    {qrCode && (
                      <div
                        className="d-flex mb-3"
                        style={{ justifyContent: "center" }}
                      >
                        <img
                          src={`data:image/png;base64,${qrCode}`}
                          alt="QR Code"
                          style={{ maxWidth: "220px" }}
                        />
                      </div>
                    )}

                    {isLoadingTransaction ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        {txn_hash ? (
                          <>
                            <label className="text-black font-w600 mt-3">
                              Transaction Hash
                            </label>
                            <label className="text-black font-w600 mt-3">
                              {txn_hash.substring(0, 15)}...
                            </label>
                            <label className="text-black font-w600 mt-3">
                              Confirmations : {confirmations}
                            </label>
                          </>
                        ) : (
                          <>
                            <label className="text-black font-w600 mt-3">
                              Waiting for Payment
                            </label>
                          </>
                        )}
                      </>
                    ) : (
                      <div class="alert alert-success" role="alert">
                        Transaction Confirmed
                      </div>
                    )}
                  </div>
                </div>
              )} */}
          <div className="row">
            <div className="col-lg-6"></div>{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepTwo;
