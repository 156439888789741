import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Tabs,
  Tab,
} from "@material-ui/core";
import axios from "axios";
import {
  useAccount,
  useConnect,
  useEnsName,
  useSignMessage,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { Skeleton } from "@mui/material";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";

import Alert from "@mui/material/Alert";
import Treefold from "react-treefold";
import MatrixComponent from "./components/Matrix";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 300,
    margin: "0 auto",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    // marginBottom: theme.spacing(2),
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  dialogContent: {
    textAlign: "center", // Set text alignment to center
  },
  disabled_buttons: {
    background: "#afaeaa !important",
    color: "rgba(0, 0, 0, 0.26)",
  },
  btn_container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  withdraw_button: {
    display: "block",
    color: "black",
    background: "white",
    borderRadius: "12px",
    border: "1px solid var(--border-light)",
    transition: "border-color var(--transition)",
  },
  balance: {
    border: "1px solid #d4d4d4", // Added quotation marks around the value
    display: "flex",
    transition: "border-color var(--transition)",
    borderRadius: "12px", // Moved borderRadius up to match other properties
    alignItems: "center",
    justifyContent: "center",
  },
  flexcolumns: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const matrix = [
  {
    referral: "User1",
    level_number: 1,
    sameline_number: 1,
    children: [
      {
        referral: "User2",
        level_number: 2,
        sameline_number: 1,
        children: [
          {
            referral: "User3",
            level_number: 3,
            sameline_number: 1,
            children: [],
          },
          {
            referral: "User4",
            level_number: 3,
            sameline_number: 2,
            children: [],
          },
          {
            referral: "User5",
            level_number: 3,
            sameline_number: 3,
            children: [],
          },
        ],
      },
      {
        referral: "User6",
        level_number: 2,
        sameline_number: 2,
        children: [
          {
            referral: "User7",
            level_number: 3,
            sameline_number: 1,
            children: [],
          },
          {
            referral: "User8",
            level_number: 3,
            sameline_number: 2,
            children: [],
          },
        ],
      },
      {
        referral: "User9",
        level_number: 2,
        sameline_number: 3,
        children: [],
      },
    ],
  },
];

function PartnerDashboard() {
  const classes = useStyles();
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [bnbBalance, setBnbBalance] = useState(0);
  const [usdtEarned, setusdtEarned] = useState(0);
  const [bnbEarned, setbnbEarned] = useState(0);
  const [usdtWithdrawn, setusdtWithdrawn] = useState(0);
  const [bnbithdrawn, setbnbithdrawn] = useState(0);
  const [teamPartners, setteamPartners] = useState(0);
  const [directPartners, setdirectPartners] = useState(0);
  const { address, connector, isConnected } = useAccount();
  const [user, setUser] = useState(null);
  const [nft_counts, setnft_counts] = useState(null);
  const [reflink, setreflink] = useState("loading");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [erroralert, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setsuccessMessage] = useState();
  const [isBalancesLoaded, setIsBalancesLoaded] = useState(false);
  const [refTransactions, setrefTransactions] = useState([]);
  const [withdrawTransactions, setwithdrawTransactions] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const pageSizeOptions = [5, 10, 25];
  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  // States for refTransactions
  const [pageRef, setPageRef] = useState(0);
  const [rowsPerPageRef, setRowsPerPageRef] = useState(pageSizeOptions[0]);

  // Handlers for refTransactions
  const handleChangePageRef = (event, newPage) => {
    setPageRef(newPage);
  };

  const handleChangeRowsPerPageRef = (event) => {
    setRowsPerPageRef(parseInt(event.target.value, 10));
    setPageRef(0);
  };

  // States for withdrawTransactions
  const [pageWithdraw, setPageWithdraw] = useState(0);
  const [rowsPerPageWithdraw, setRowsPerPageWithdraw] = useState(
    pageSizeOptions[0]
  );

  // Handlers for withdrawTransactions
  const handleChangePageWithdraw = (event, newPage) => {
    setPageWithdraw(newPage);
  };

  const handleChangeRowsPerPageWithdraw = (event) => {
    setRowsPerPageWithdraw(parseInt(event.target.value, 10));
    setPageWithdraw(0);
  };

  const startIndexRef = pageRef * rowsPerPageRef;
  const endIndexRef = startIndexRef + rowsPerPageRef;
  const paginatedTransactions = refTransactions.slice(
    startIndexRef,
    endIndexRef
  );

  const startIndexWithdraw = pageWithdraw * rowsPerPageWithdraw;
  const endIndexWithdraw = startIndexWithdraw + rowsPerPageWithdraw;
  const paginatedwithdrawTransactions = withdrawTransactions.slice(
    startIndexWithdraw,
    endIndexWithdraw
  );

  useEffect(() => {
    if (isConnected) {
      // console.log("isConnected User");
      // console.log(user);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${address}`
        )
        .then((response) => {
          setIsBalancesLoaded(false);
          console.log("user: ", response.data.user);
          setUser(response.data.user);
          console.log("setnft_counts: ", response.data.user.nftCounts);
          setnft_counts(response.data.nftCounts);
          setUsdtBalance(
            parseFloat(response.data.user.usdt_balance).toFixed(2)
          );
          setBnbBalance(parseFloat(response.data.user.account_bal).toFixed(2));
          setreflink(
            "https://discover.croesus-asset.management/register?ref=" +
              response.data.user.name
          );
          //setreflink(response.data.user.ref_link);

          setusdtWithdrawn(
            parseFloat(response.data.user.withdrawamount_usdt).toFixed(2)
          );
          setbnbithdrawn(
            parseFloat(response.data.user.withdrawamount_bnb).toFixed(2)
          );

          // parseFloat(response.data.user.total_earned_usdt).toFixed(2)
          // parseFloat(response.data.user.total_earned_bnb).toFixed(2)

          console.log(
            "response.data.user.account_bal",
            response.data.user.account_bal
          );
          console.log(
            "response.data.user.withdrawamount_bnb",
            response.data.user.withdrawamount_bnb
          );
          console.log(
            "response.data.user.usdtBalance",
            response.data.user.usdt_balance
          );
          console.log(
            "response.data.user.withdrawamount_usdt",
            response.data.user.withdrawamount_usdt
          );

          let usdtearned_ =
            (parseFloat(response.data.user.withdrawamount_usdt) || 0) +
            (parseFloat(response.data.user.usdt_balance) || 0);
          setusdtEarned(usdtearned_.toFixed(2));

          let bnbtearned_ =
            (parseFloat(response.data.user.withdrawamount_bnb) || 0) +
            (parseFloat(response.data.user.account_bal) || 0);
          setbnbEarned(bnbtearned_.toFixed(2));

          setteamPartners(response.data.user.matrix_count);
          setdirectPartners(response.data.user.direct_partners);
          console.log("setREF", response.data.user.ref_transactions);

          setrefTransactions(response.data.user.ref_transactions);
          console.log(
            "setwithdrawTransactions",
            response.data.user.all_partner_transactions
          );
          setwithdrawTransactions(response.data.user.all_partner_transactions);

          setIsBalancesLoaded(true);
        })
        .catch((error) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);
            if (errorMessage === "User not found") {
            }
            // You can handle/display the error message as per your requirement
          }
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected]);

  useEffect(() => {
    // Example: Fetch balances from an API
    const fetchBalances = async () => {
      // Simulated API call delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
      // Set the balances
    };

    fetchBalances();
  }, []);

  const handleWithdraw = () => {
    setLoading(true); // Show circular progress indicator

    if (bnbBalance !== "0.00" || usdtBalance !== "0.00") {
      axios
        .post(
          "https://test.croesus-asset.management/api/submit_withdrawal_ref",
          {
            usdtBalance: usdtBalance,
            bnbBalance: bnbBalance,
            user: user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, Accept, Authorization",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response);
          console.log(response.data);
          if (response.data.message === "OK") {
            setsuccessMessage("success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            setError("error");
          }
          setLoading(false);
        })

        .catch((error) => {
          console.error("Error:", error);
          setError("error");
          setLoading(false);
        });

      setOpen(false);
      setMessage("Withdraw Requested!");
      setSnackbarOpen(true);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyReflink = () => {
    navigator.clipboard
      .writeText(reflink)
      .then(() => {
        console.log("Reflink copied to clipboard!");
        setMessage("Copy successful!");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to copy reflink: ", error);
      });
  };

  return (
    <div
      className="content-body p-0"
      style={{ minHeight: window.screen.height + 80 }}
    >
      <div className="container-fluid text-center mb-2">
        <div className={classes.container}></div>

        <div>
          <>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MuiAlert
                onClose={() => setSnackbarOpen(false)}
                severity="success"
                variant="filled"
              >
                {message}
              </MuiAlert>
            </Snackbar>
            <div className="row mt-2 mb-2 justify-content-md-center">
              <div className="col-xl-6" style={{ float: "left" }}>
                <div className="card p-3 feature-box pull-up m-0">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1>Partner Link</h1>
                  </div>
                  <div className="card-body">
                    <h3 className="mb-1 text-nowrap">Your Upline</h3>
                    {user && (
                      <>
                        <h6 className="mb-1 text-nowrap">
                          {user.partner_mail}
                        </h6>
                        {user.matrix === "0" ||
                        user.matrix === "false" ? null : (
                          <>
                            <h5
                              className="card-title text-primary mb-1"
                              style={{}}
                            >
                              <input
                                id="ref_link"
                                type="text"
                                className="form-control"
                                placeholder="Username"
                                value={
                                  "https://discover.croesus-asset.management/register?ref=" +
                                  user.name
                                }
                                required // No value is needed
                                readOnly // No value is needed
                                style={{ fontSize: `0.7rem` }}
                              />
                            </h5>
                            <Button
                              className={classes.nft_buttons}
                              variant="contained"
                              color="primary"
                              onClick={handleCopyReflink}
                            >
                              COPY
                            </Button>{" "}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2 mb-2 justify-content-md-center"></div>
            <div className="row mt-2 mb-2 justify-content-md-center">
              <div className="col-xl-6">
                <div className="card p-3">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1>Partner Balance</h1>
                  </div>
                  <div className="card-body p-0 mt-3 row">
                    <div
                      className="col-6"
                      style={{ paddingTop: "0.5rem", alignSelf: "center" }}
                    >
                      <Typography variant="h5" component="h2" gutterBottom>
                        {!isBalancesLoaded ? (
                          <Skeleton
                            variant="text"
                            width={150}
                            height={30}
                            style={{ display: "inline-block" }}
                          />
                        ) : (
                          <div
                            className={`${classes.balance} p-1`}
                            style={{ fontWeight: "bold" }}
                          >
                            <img
                              src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                              alt="USDT Icon"
                              width="35px"
                              style={{ paddingRight: "5px" }}
                            />
                            {`   ${usdtBalance}`}
                          </div>
                        )}
                      </Typography>

                      {/* <Typography variant="h5" component="h2" gutterBottom>
                        {!isBalancesLoaded ? (
                          <Skeleton
                            variant="text"
                            width={150}
                            height={30}
                            style={{ display: "inline-block" }}
                          />
                        ) : (
                          <div
                            className={`${classes.balance} p-1`}
                            style={{ fontWeight: "bold" }}
                          >
                            <img
                              src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Binance-Coin-BNB-icon.png"
                              alt="BNB Icon"
                              width="35px"
                              style={{ paddingRight: "5px" }}
                            />
                            {`   ${bnbBalance}`}
                          </div>
                        )}
                      </Typography> */}
                    </div>
                    <div
                      className="col-6 p-2"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        className={classes.withdraw_button + " p-3"}
                        variant="contained"
                        color="primary"
                        style={{
                          gridAutoFlow: "column",
                          alignItems: "center",
                          textAlign: "center",
                          textTransform: "capitalize",
                        }}
                        onClick={handleWithdraw}
                        disabled
                        // disabled={
                        //   bnbBalance === "0.00" && usdtBalance === "0.00"
                        // }
                      >
                        <div className={classes.btn_container}>
                          <AccountBalanceWalletIcon />
                          <span
                            style={{
                              fontSize: ".75rem",
                              fontWeight: "lighter",
                              lineHeight: "1.3",
                            }}
                          >
                            Withdraw
                          </span>
                        </div>
                      </Button>
                    </div>

                    <Dialog
                      open={open}
                      onClose={handleClose}
                      PaperProps={{ style: { minWidth: "80%" } }}
                    >
                      <DialogContent className={classes.dialogContent}>
                        <Typography variant="h4" align="center" gutterBottom>
                          Withdraw Details
                        </Typography>
                        <Typography align="center">
                          Amount: {usdtBalance} USDT
                        </Typography>
                        <Typography align="center">
                          Amount: {bnbBalance} BNB
                        </Typography>
                        <Typography align="center">Time: 24 Hours</Typography>
                        {loading && (
                          <CircularProgress align="center" className="m-3" />
                        )}{" "}
                        {erroralert && erroralert === "error" && (
                          <Alert severity="error" style={{ marginTop: "16px" }}>
                            Error occurred while withdrawing. Please try again.
                          </Alert>
                        )}
                        {successMessage && successMessage === "success" && (
                          <Alert
                            severity="success"
                            style={{ marginTop: "16px" }}
                          >
                            Withdraw requested. Refreshing...
                          </Alert>
                        )}
                      </DialogContent>
                      {!loading && !successMessage && (
                        <DialogActions style={{ justifyContent: "center" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={classes.nft_buttons}
                            variant="contained"
                            color="primary"
                            onClick={handleWithdraw}
                          >
                            Confirm!
                          </Button>
                        </DialogActions>
                      )}
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-2 mb-2 justify-content-md-center">
              <div className="col-xl-6">
                <div className="card p-3">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1>Total Partner Income</h1>
                  </div>
                  <div className="card-body p-0 mt-3"></div>
                  <div className="col-xl-6">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">
                          <div className="card-body">
                            <div
                              className="row"
                              style={{
                                border: "1px solid #d4d4d4",
                                borderRadius: "12px",
                              }}
                            >
                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div className="balance_data m-0">
                                  <img
                                    src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Binance-Coin-BNB-icon.png"
                                    alt="BNB Icon"
                                    width="35px"
                                    style={{
                                      paddingRight: "5px",
                                      width: "50px",
                                    }}
                                  />
                                  <div
                                    className="balance_info"
                                    style={{ textAlign: "left" }}
                                  >
                                    <h4>{bnbEarned}</h4>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-4 p-0"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <span className="text-success">Earned</span>
                                <div className="balance_data m-0">
                                  <div
                                    className="balance-icon income m-0"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.16667 25.6667H19.8333C20.9384 25.6667 21.9982 25.2277 22.7796 24.4463C23.561 23.6649 24 22.6051 24 21.5V16.5C24 15.3949 23.561 14.3351 22.7796 13.5537C21.9982 12.7723 20.9384 12.3333 19.8333 12.3333H8.16667C7.0616 12.3333 6.00179 12.7723 5.22039 13.5537C4.43899 14.3351 4 15.3949 4 16.5V21.5C4 22.6051 4.43899 23.6649 5.22039 24.4463C6.00179 25.2277 7.0616 25.6667 8.16667 25.6667ZM5.66667 16.5C5.66667 15.837 5.93006 15.2011 6.3989 14.7322C6.86774 14.2634 7.50363 14 8.16667 14H19.8333C20.4964 14 21.1323 14.2634 21.6011 14.7322C22.0699 15.2011 22.3333 15.837 22.3333 16.5V21.5C22.3333 22.163 22.0699 22.7989 21.6011 23.2678C21.1323 23.7366 20.4964 24 19.8333 24H8.16667C7.50363 24 6.86774 23.7366 6.3989 23.2678C5.93006 22.7989 5.66667 22.163 5.66667 21.5V16.5Z"
                                        fill="#000000"
                                      />
                                      <path
                                        d="M14.0002 22.3333C14.6595 22.3333 15.3039 22.1378 15.8521 21.7716C16.4002 21.4053 16.8275 20.8847 17.0798 20.2756C17.3321 19.6665 17.3981 18.9963 17.2695 18.3497C17.1409 17.7031 16.8234 17.1091 16.3572 16.643C15.891 16.1768 15.2971 15.8593 14.6505 15.7307C14.0039 15.6021 13.3337 15.6681 12.7246 15.9204C12.1155 16.1727 11.5949 16.5999 11.2286 17.1481C10.8623 17.6963 10.6669 18.3407 10.6669 19C10.6669 19.884 11.018 20.7319 11.6432 21.357C12.2683 21.9821 13.1161 22.3333 14.0002 22.3333ZM14.0002 17.3333C14.3298 17.3333 14.6521 17.4311 14.9261 17.6142C15.2002 17.7973 15.4138 18.0576 15.54 18.3622C15.6661 18.6667 15.6991 19.0018 15.6348 19.3251C15.5705 19.6484 15.4118 19.9454 15.1787 20.1785C14.9456 20.4116 14.6486 20.5703 14.3253 20.6346C14.002 20.6989 13.6669 20.6659 13.3624 20.5398C13.0578 20.4136 12.7975 20.2 12.6144 19.9259C12.4313 19.6519 12.3335 19.3296 12.3335 19C12.3335 18.558 12.5091 18.134 12.8217 17.8215C13.1342 17.5089 13.5582 17.3333 14.0002 17.3333ZM14.0002 2.33333C13.7792 2.33333 13.5672 2.42113 13.4109 2.57741C13.2546 2.73369 13.1669 2.94565 13.1669 3.16666V7.825L11.0502 5.70833C10.8908 5.57181 10.6857 5.50047 10.476 5.50857C10.2662 5.51667 10.0673 5.60361 9.91888 5.75203C9.77047 5.90044 9.68353 6.09939 9.67543 6.30912C9.66733 6.51885 9.73866 6.72391 9.87519 6.88333L13.4085 10.425C13.4853 10.5 13.5759 10.5594 13.6752 10.6C13.778 10.6435 13.8885 10.666 14.0002 10.666C14.1118 10.666 14.2224 10.6435 14.3252 10.6C14.4245 10.5594 14.5151 10.5 14.5919 10.425L18.1669 6.88333C18.3034 6.72391 18.3747 6.51885 18.3666 6.30912C18.3585 6.09939 18.2716 5.90044 18.1232 5.75203C17.9747 5.60361 17.7758 5.51667 17.5661 5.50857C17.3563 5.50047 17.1513 5.57181 16.9919 5.70833L14.8335 7.825V3.16666C14.8335 2.94565 14.7457 2.73369 14.5894 2.57741C14.4332 2.42113 14.2212 2.33333 14.0002 2.33333V2.33333Z"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div className="balance_data m-0">
                                  <div
                                    className="balance_info"
                                    style={{ textAlign: "left" }}
                                  >
                                    <h4>{usdtEarned}</h4>
                                  </div>
                                  <img
                                    src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                                    alt="BNB Icon"
                                    width="35px"
                                    style={{
                                      paddingLeft: "5px",
                                      width: "50px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              className="row mt-3"
                              style={{
                                border: "1px solid #d4d4d4",
                                borderRadius: "12px",
                              }}
                            >
                              <div
                                className="col-4"
                                style={{ display: "flex" }}
                              >
                                <div className="balance_data m-0">
                                  <img
                                    src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Binance-Coin-BNB-icon.png"
                                    alt="BNB Icon"
                                    width="35px"
                                    style={{
                                      paddingRight: "5px",
                                      width: "50px",
                                    }}
                                  />
                                  <div
                                    className="balance_info"
                                    style={{ textAlign: "left" }}
                                  >
                                    <h4>{bnbithdrawn}</h4>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-4 p-0"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <span className="text-success">Withdrawn</span>
                                <div className="balance_data m-0">
                                  <div
                                    className="balance-icon income m-0"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8.16667 25.6667H19.8333C20.9384 25.6667 21.9982 25.2277 22.7796 24.4463C23.561 23.6649 24 22.6051 24 21.5V16.5C24 15.3949 23.561 14.3351 22.7796 13.5537C21.9982 12.7723 20.9384 12.3333 19.8333 12.3333H8.16667C7.0616 12.3333 6.00179 12.7723 5.22039 13.5537C4.43899 14.3351 4 15.3949 4 16.5V21.5C4 22.6051 4.43899 23.6649 5.22039 24.4463C6.00179 25.2277 7.0616 25.6667 8.16667 25.6667ZM5.66667 16.5C5.66667 15.837 5.93006 15.2011 6.3989 14.7322C6.86774 14.2634 7.50363 14 8.16667 14H19.8333C20.4964 14 21.1323 14.2634 21.6011 14.7322C22.0699 15.2011 22.3333 15.837 22.3333 16.5V21.5C22.3333 22.163 22.0699 22.7989 21.6011 23.2678C21.1323 23.7366 20.4964 24 19.8333 24H8.16667C7.50363 24 6.86774 23.7366 6.3989 23.2678C5.93006 22.7989 5.66667 22.163 5.66667 21.5V16.5Z"
                                        fill="#000000"
                                      />
                                      <path
                                        d="M14.0002 22.3333C14.6595 22.3333 15.3039 22.1378 15.8521 21.7716C16.4002 21.4053 16.8275 20.8847 17.0798 20.2756C17.3321 19.6665 17.3981 18.9963 17.2695 18.3497C17.1409 17.7031 16.8234 17.1091 16.3572 16.643C15.891 16.1768 15.2971 15.8593 14.6505 15.7307C14.0039 15.6021 13.3337 15.6681 12.7246 15.9204C12.1155 16.1727 11.5949 16.5999 11.2286 17.1481C10.8623 17.6963 10.6669 18.3407 10.6669 19C10.6669 19.884 11.018 20.7319 11.6432 21.357C12.2683 21.9821 13.1161 22.3333 14.0002 22.3333ZM14.0002 17.3333C14.3298 17.3333 14.6521 17.4311 14.9261 17.6142C15.2002 17.7973 15.4138 18.0576 15.54 18.3622C15.6661 18.6667 15.6991 19.0018 15.6348 19.3251C15.5705 19.6484 15.4118 19.9454 15.1787 20.1785C14.9456 20.4116 14.6486 20.5703 14.3253 20.6346C14.002 20.6989 13.6669 20.6659 13.3624 20.5398C13.0578 20.4136 12.7975 20.2 12.6144 19.9259C12.4313 19.6519 12.3335 19.3296 12.3335 19C12.3335 18.558 12.5091 18.134 12.8217 17.8215C13.1342 17.5089 13.5582 17.3333 14.0002 17.3333ZM14.0002 2.33333C13.7792 2.33333 13.5672 2.42113 13.4109 2.57741C13.2546 2.73369 13.1669 2.94565 13.1669 3.16666V7.825L11.0502 5.70833C10.8908 5.57181 10.6857 5.50047 10.476 5.50857C10.2662 5.51667 10.0673 5.60361 9.91888 5.75203C9.77047 5.90044 9.68353 6.09939 9.67543 6.30912C9.66733 6.51885 9.73866 6.72391 9.87519 6.88333L13.4085 10.425C13.4853 10.5 13.5759 10.5594 13.6752 10.6C13.778 10.6435 13.8885 10.666 14.0002 10.666C14.1118 10.666 14.2224 10.6435 14.3252 10.6C14.4245 10.5594 14.5151 10.5 14.5919 10.425L18.1669 6.88333C18.3034 6.72391 18.3747 6.51885 18.3666 6.30912C18.3585 6.09939 18.2716 5.90044 18.1232 5.75203C17.9747 5.60361 17.7758 5.51667 17.5661 5.50857C17.3563 5.50047 17.1513 5.57181 16.9919 5.70833L14.8335 7.825V3.16666C14.8335 2.94565 14.7457 2.73369 14.5894 2.57741C14.4332 2.42113 14.2212 2.33333 14.0002 2.33333V2.33333Z"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div className="balance_data m-0">
                                  <div
                                    className="balance_info"
                                    style={{ textAlign: "left" }}
                                  >
                                    <h4>{usdtWithdrawn}</h4>
                                  </div>
                                  <img
                                    src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/256/Tether-USDT-icon.png"
                                    alt="BNB Icon"
                                    width="35px"
                                    style={{
                                      paddingLeft: "5px",
                                      width: "50px",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="" style={{ display: "flex" }}>
              {/* //row col-xl-6 col-12 mt-2 mb-2 justify-content-md-center */}
              <div
                className="col-xl-6 col-6"
                style={{ paddingRight: "0.5rem" }}
              >
                <div className="card p-3">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1 className="text-center m-auto">Team</h1>
                  </div>
                  <div className="card-body p-0 mt-3">
                    {/* {!isBalancesLoaded ? (
                      <Skeleton
                        variant="text"
                        width={150}
                        height={30}
                        style={{ margin: "0px auto" }}
                      />
                    ) : (
                      <Typography variant="h5" component="h2" gutterBottom>
                        {teamPartners} Direct Partner(s)
                      </Typography>
                    )} */}
                    {!isBalancesLoaded ? (
                      <Skeleton
                        variant="text"
                        width={150}
                        height={30}
                        style={{ margin: "0px auto" }}
                      />
                    ) : (
                      <Typography variant="h5" component="h2" gutterBottom>
                        {teamPartners} Partner(s)
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-6" style={{ paddingLeft: "0.5rem" }}>
                <div className="card p-3">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1 className="text-center m-auto">Direct</h1>
                  </div>
                  <div className="card-body p-0 mt-3">
                    {!isBalancesLoaded ? (
                      <Skeleton
                        variant="text"
                        width={150}
                        height={30}
                        style={{ margin: "0px auto" }}
                      />
                    ) : (
                      <Typography variant="h5" component="h2" gutterBottom>
                        {directPartners} Partner(s)
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {user && (
              <div className="row mt-2 mb-2 justify-content-md-center">
                <div className="col-xl-6">
                  <div className="card p-3">
                    <div className="card-header text-center p-0 flex-wrap">
                      <h1>Matrix Referrral System</h1>
                    </div>
                    <div className="card-body p-0 mt-3">
                      {/* <h4>Partner Level</h4> */}
                      {user.matrix === "false" ? (
                        <>
                          <Typography variant="body1" color="textPrimary">
                            Qualified Matrix Levels: 0
                          </Typography>
                        </>
                      ) : (
                        <>
                          {user.matrix === "10" ? (
                            <>
                              <Typography variant="body1" color="textPrimary">
                                Qualified Matrix Levels: 10
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ color: "green", fontweight: "bold" }}
                              >
                                Congratulations, you are qualified for all
                                referral levels.
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography variant="body1" color="textPrimary">
                                Qualified Matrix Levels: {user.matrix}
                              </Typography>
                            </>
                          )}
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormGroup style={{ alignItems: "flex-start" }}>
                          {user.matrix !== "10" ? (
                            <h6
                              className="p-3 pb-0"
                              style={{ textAlign: "left" }}
                            >
                              Reach Next Level with Series I NFTs <br></br>At
                              Least :
                            </h6>
                          ) : null}

                          {user.matrix === "false" ? (
                            <div>
                              <Checkbox />
                              <span>
                                {nft_counts.Taipei.overall} / 1 x Taipei
                              </span>
                            </div>
                          ) : (
                            <>
                              {user.matrix === "1" ? (
                                <div>
                                  <Checkbox
                                    checked={nft_counts.Taipei.overall >= 5}
                                  />
                                  <span>
                                    {nft_counts.Taipei.overall} / 5 x Taipei
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {user.matrix === "2" ? (
                                    <>
                                      <div>
                                        <Checkbox
                                          checked={
                                            nft_counts.Taipei.overall >= 1
                                          }
                                          style={{
                                            color:
                                              nft_counts.Taipei.overall >= 1
                                                ? "#01b35c"
                                                : "#000000",
                                          }}
                                        />
                                        <span>
                                          {nft_counts.Taipei.overall} / 1 x
                                          Taipei
                                        </span>
                                      </div>
                                      <div>
                                        <Checkbox
                                          checked={
                                            nft_counts["China Zun Tower"]
                                              .overall >= 1
                                          }
                                          style={{
                                            color:
                                              nft_counts["China Zun Tower"]
                                                .overall >= 1
                                                ? "#01b35c"
                                                : "#000000",
                                          }}
                                        />
                                        <span>
                                          {
                                            nft_counts["China Zun Tower"]
                                              .overall
                                          }{" "}
                                          / 1 x China Zun Tower
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {user.matrix === "3" ? (
                                        <>
                                          <div>
                                            <Checkbox
                                              checked={
                                                nft_counts.Taipei.overall >= 5
                                              }
                                              style={{
                                                color:
                                                  nft_counts.Taipei.overall >= 5
                                                    ? "#01b35c"
                                                    : "#000000",
                                              }}
                                            />
                                            <span>
                                              {nft_counts.Taipei.overall} / 5 x
                                              Taipei
                                            </span>
                                          </div>
                                          <div>
                                            <Checkbox
                                              checked={
                                                nft_counts["China Zun Tower"]
                                                  .overall >= 2
                                              }
                                              style={{
                                                color:
                                                  nft_counts["China Zun Tower"]
                                                    .overall >= 2
                                                    ? "#01b35c"
                                                    : "#000000",
                                              }}
                                            />
                                            <span>
                                              {
                                                nft_counts["China Zun Tower"]
                                                  .overall
                                              }{" "}
                                              / 2 x China Zun Tower
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {user.matrix === "4" ? (
                                            <>
                                              <div>
                                                <Checkbox
                                                  checked={
                                                    nft_counts.Taipei.overall >=
                                                    5
                                                  }
                                                  style={{
                                                    color:
                                                      nft_counts.Taipei
                                                        .overall >= 5
                                                        ? "#01b35c"
                                                        : "#000000",
                                                  }}
                                                />
                                                <span>
                                                  {nft_counts.Taipei.overall} /
                                                  5 x Taipei
                                                </span>
                                              </div>
                                              <div>
                                                <Checkbox
                                                  checked={
                                                    nft_counts[
                                                      "China Zun Tower"
                                                    ].overall >= 3
                                                  }
                                                  style={{
                                                    color:
                                                      nft_counts[
                                                        "China Zun Tower"
                                                      ].overall >= 3
                                                        ? "#01b35c"
                                                        : "#000000",
                                                  }}
                                                />
                                                <span>
                                                  {
                                                    nft_counts[
                                                      "China Zun Tower"
                                                    ].overall
                                                  }{" "}
                                                  / 3 x China Zun Tower
                                                </span>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {user.matrix === "5" ? (
                                                <>
                                                  <div>
                                                    <Checkbox
                                                      checked={
                                                        nft_counts.Taipei
                                                          .overall >= 5
                                                      }
                                                      style={{
                                                        color:
                                                          nft_counts.Taipei
                                                            .overall >= 5
                                                            ? "#01b35c"
                                                            : "#000000",
                                                      }}
                                                    />
                                                    <span>
                                                      {
                                                        nft_counts.Taipei
                                                          .overall
                                                      }{" "}
                                                      / 5 x Taipei
                                                    </span>
                                                  </div>
                                                  <div>
                                                    <Checkbox
                                                      checked={
                                                        nft_counts[
                                                          "China Zun Tower"
                                                        ].overall >= 4
                                                      }
                                                      style={{
                                                        color:
                                                          nft_counts[
                                                            "China Zun Tower"
                                                          ].overall >= 4
                                                            ? "#01b35c"
                                                            : "#000000",
                                                      }}
                                                    />
                                                    <span>
                                                      {
                                                        nft_counts[
                                                          "China Zun Tower"
                                                        ].overall
                                                      }{" "}
                                                      / 4 x China Zun Tower
                                                    </span>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  {user.matrix === "6" ? (
                                                    <>
                                                      <div>
                                                        <Checkbox
                                                          checked={
                                                            nft_counts.Taipei
                                                              .overall >= 5
                                                          }
                                                          style={{
                                                            color:
                                                              nft_counts.Taipei
                                                                .overall >= 5
                                                                ? "#01b35c"
                                                                : "#000000",
                                                          }}
                                                        />
                                                        <span>
                                                          {
                                                            nft_counts.Taipei
                                                              .overall
                                                          }{" "}
                                                          / 5 x Taipei
                                                        </span>
                                                      </div>
                                                      <div>
                                                        <Checkbox
                                                          checked={
                                                            nft_counts[
                                                              "China Zun Tower"
                                                            ].overall >= 5
                                                          }
                                                          style={{
                                                            color:
                                                              nft_counts[
                                                                "China Zun Tower"
                                                              ].overall >= 5
                                                                ? "#01b35c"
                                                                : "#000000",
                                                          }}
                                                        />
                                                        <span>
                                                          {
                                                            nft_counts[
                                                              "China Zun Tower"
                                                            ].overall
                                                          }{" "}
                                                          / 5 x China Zun Tower
                                                        </span>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    <>
                                                      {user.matrix === "7" ? (
                                                        <>
                                                          <div>
                                                            <Checkbox
                                                              checked={
                                                                nft_counts
                                                                  .Taipei
                                                                  .total >= 10
                                                              }
                                                              style={{
                                                                color:
                                                                  nft_counts
                                                                    .Taipei
                                                                    .total >= 10
                                                                    ? "#01b35c"
                                                                    : "#000000",
                                                              }}
                                                            />
                                                            <span>
                                                              {
                                                                nft_counts
                                                                  .Taipei
                                                                  .overall
                                                              }{" "}
                                                              / 10 x Taipei
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <Checkbox
                                                              checked={
                                                                nft_counts[
                                                                  "China Zun Tower"
                                                                ].overall >= 7
                                                              }
                                                              style={{
                                                                color:
                                                                  nft_counts[
                                                                    "China Zun Tower"
                                                                  ].overall >= 7
                                                                    ? "#01b35c"
                                                                    : "#000000",
                                                              }}
                                                            />
                                                            <span>
                                                              {
                                                                nft_counts[
                                                                  "China Zun Tower"
                                                                ].overall
                                                              }{" "}
                                                              / 7 x China Zun
                                                              Tower
                                                            </span>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {user.matrix ===
                                                          "8" ? (
                                                            <>
                                                              <div>
                                                                <Checkbox
                                                                  checked={
                                                                    nft_counts
                                                                      .Taipei
                                                                      .total >=
                                                                    10
                                                                  }
                                                                  style={{
                                                                    color:
                                                                      nft_counts
                                                                        .Taipei
                                                                        .total >=
                                                                      10
                                                                        ? "#01b35c"
                                                                        : "#000000",
                                                                  }}
                                                                />
                                                                <span>
                                                                  {
                                                                    nft_counts
                                                                      .Taipei
                                                                      .total
                                                                  }{" "}
                                                                  / 10 x Taipei
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <Checkbox
                                                                  checked={
                                                                    nft_counts[
                                                                      "China Zun Tower"
                                                                    ].overall >=
                                                                    8
                                                                  }
                                                                  style={{
                                                                    color:
                                                                      nft_counts[
                                                                        "China Zun Tower"
                                                                      ]
                                                                        .overall >=
                                                                      8
                                                                        ? "#01b35c"
                                                                        : "#000000",
                                                                  }}
                                                                />
                                                                <span>
                                                                  {
                                                                    nft_counts[
                                                                      "China Zun Tower"
                                                                    ].overall
                                                                  }{" "}
                                                                  / 8 x China
                                                                  Zun Tower
                                                                </span>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <>
                                                              {user.matrix ===
                                                              "9" ? (
                                                                <>
                                                                  <div>
                                                                    <Checkbox
                                                                      checked={
                                                                        nft_counts
                                                                          .Taipei
                                                                          .total >=
                                                                        1
                                                                      }
                                                                      style={{
                                                                        color:
                                                                          nft_counts
                                                                            .Taipei
                                                                            .total >=
                                                                          1
                                                                            ? "#01b35c"
                                                                            : "#000000",
                                                                      }}
                                                                    />
                                                                    <span>
                                                                      {
                                                                        nft_counts
                                                                          .Taipei
                                                                          .total
                                                                      }{" "}
                                                                      / 1 x
                                                                      Taipei
                                                                    </span>
                                                                  </div>
                                                                  <div>
                                                                    <Checkbox
                                                                      checked={
                                                                        nft_counts[
                                                                          "China Zun Tower"
                                                                        ]
                                                                          .total >=
                                                                        1
                                                                      }
                                                                      style={{
                                                                        color:
                                                                          nft_counts[
                                                                            "China Zun Tower"
                                                                          ]
                                                                            .total >=
                                                                          1
                                                                            ? "#01b35c"
                                                                            : "#000000",
                                                                      }}
                                                                    />
                                                                    <span>
                                                                      {
                                                                        nft_counts[
                                                                          "China Zun Tower"
                                                                        ]
                                                                          .overall
                                                                      }{" "}
                                                                      / 1 x
                                                                      China Zun
                                                                      Tower
                                                                    </span>
                                                                  </div>
                                                                  <div>
                                                                    <Checkbox
                                                                      checked={
                                                                        nft_counts[
                                                                          "Tianjin_CTF_Finance_Center"
                                                                        ]
                                                                          .total >=
                                                                        1
                                                                      }
                                                                      style={{
                                                                        color:
                                                                          nft_counts[
                                                                            "Tianjin_CTF_Finance_Center"
                                                                          ]
                                                                            .total >=
                                                                          1
                                                                            ? "#01b35c"
                                                                            : "#000000",
                                                                      }}
                                                                    />
                                                                    <span>
                                                                      {
                                                                        nft_counts[
                                                                          "Tianjin_CTF_Finance_Center"
                                                                        ]
                                                                          .overall
                                                                      }{" "}
                                                                      / 1 x
                                                                      Tianjin
                                                                      CTF
                                                                      Finance
                                                                      Center
                                                                    </span>
                                                                  </div>
                                                                </>
                                                              ) : null}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </FormGroup>

                        <FormGroup style={{ alignItems: "flex-start" }}>
                          <>
                            {user.matrix !== "10" ? (
                              <h6
                                className="p-3 pb-0"
                                style={{ textAlign: "left" }}
                              >
                                Reach Next Level with Series II NFTs <br></br>At
                                Least :
                              </h6>
                            ) : null}

                            {user.matrix === "false" ? (
                              <>
                                <div>
                                  <Checkbox
                                    checked={nft_counts["A-Alpha"].overall >= 1}
                                    style={{
                                      color:
                                        nft_counts["A-Alpha"].overall >= 1
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {nft_counts["A-Alpha"].overall} / 1 x
                                    A-Alpha
                                  </span>
                                </div>
                                <div>
                                  <Checkbox
                                    checked={
                                      nft_counts["I-Charge"].overall >= 10 ||
                                      nft_counts["I-Charge"].staked >= 10
                                    }
                                    style={{
                                      color:
                                        nft_counts["I-Charge"].overall >= 10 ||
                                        nft_counts["I-Charge"].staked >= 10
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {nft_counts["I-Charge"].overall +
                                      nft_counts["I-Charge"].staked}{" "}
                                    / 10 x I-Charge
                                  </span>
                                </div>
                                <div>
                                  <Checkbox
                                    checked={user.crg_balance >= 25}
                                    style={{
                                      color:
                                        user.crg_balance >= 25
                                          ? "#01b35c"
                                          : "#000000",
                                    }}
                                  />
                                  <span>
                                    {Number(user.crg_balance).toFixed(2)} / 25 x
                                    CRG
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {user.matrix === "1" ? (
                                  <>
                                    <div>
                                      <Checkbox
                                        checked={
                                          nft_counts["A-Alpha"].overall >= 2
                                        }
                                        style={{
                                          color:
                                            nft_counts["A-Alpha"].overall >= 2
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {nft_counts["A-Alpha"].overall} / 2 x
                                        A-Alpha
                                      </span>
                                    </div>
                                    <div>
                                      <Checkbox
                                        checked={
                                          nft_counts["I-Charge"].overall >=
                                            20 ||
                                          nft_counts["I-Charge"].staked >= 20
                                        }
                                        style={{
                                          color:
                                            nft_counts["I-Charge"].overall >=
                                              20 ||
                                            nft_counts["I-Charge"].staked >= 20
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {nft_counts["I-Charge"].total +
                                          nft_counts["I-Charge"].staked}{" "}
                                        / 20 x I-Charge
                                      </span>
                                    </div>
                                    <div>
                                      <Checkbox
                                        checked={user.crg_balance >= 50}
                                        style={{
                                          color:
                                            user.crg_balance >= 50
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {Number(user.crg_balance).toFixed(2)} /
                                        50 x CRG
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {user.matrix === "2" ? (
                                      <>
                                        <div>
                                          <Checkbox
                                            checked={
                                              nft_counts["A-Alpha"].total >= 3
                                            }
                                            style={{
                                              color:
                                                nft_counts["A-Alpha"].total >= 3
                                                  ? "#01b35c"
                                                  : "#000000",
                                            }}
                                          />
                                          <span>
                                            {nft_counts["A-Alpha"].total} / 3 x
                                            A-Alpha
                                          </span>
                                        </div>
                                        <div>
                                          <Checkbox
                                            checked={
                                              nft_counts["I-Charge"].total >=
                                                30 ||
                                              nft_counts["I-Charge"].staked >=
                                                30
                                            }
                                            style={{
                                              color:
                                                nft_counts["I-Charge"].total >=
                                                  30 ||
                                                nft_counts["I-Charge"].staked >=
                                                  30
                                                  ? "#01b35c"
                                                  : "#000000",
                                            }}
                                          />
                                          <span>
                                            {nft_counts["I-Charge"].total +
                                              nft_counts["I-Charge"]
                                                .staked}{" "}
                                            / 30 x I-Charge
                                          </span>
                                        </div>
                                        <div>
                                          <Checkbox
                                            checked={user.crg_balance >= 75}
                                            style={{
                                              color:
                                                user.crg_balance >= 75
                                                  ? "#01b35c"
                                                  : "#000000",
                                            }}
                                          />
                                          <span>
                                            {Number(user.crg_balance).toFixed(
                                              2
                                            )}{" "}
                                            / 75 x CRG
                                          </span>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {user.matrix === "3" ? (
                                          <>
                                            <div>
                                              <Checkbox
                                                checked={
                                                  nft_counts["A-Alpha"].total >=
                                                  4
                                                }
                                                style={{
                                                  color:
                                                    nft_counts["A-Alpha"]
                                                      .total >= 4
                                                      ? "#01b35c"
                                                      : "#000000",
                                                }}
                                              />
                                              <span>
                                                {nft_counts["A-Alpha"].total} /
                                                4 x A-Alpha
                                              </span>
                                            </div>
                                            <div>
                                              <Checkbox
                                                checked={
                                                  nft_counts["I-Charge"]
                                                    .total >= 40 ||
                                                  nft_counts["I-Charge"]
                                                    .staked >= 40
                                                }
                                                style={{
                                                  color:
                                                    nft_counts["I-Charge"]
                                                      .total >= 40
                                                      ? "#01b35c"
                                                      : "#000000",
                                                }}
                                              />
                                              <span>
                                                {nft_counts["I-Charge"].total +
                                                  nft_counts["I-Charge"]
                                                    .staked}{" "}
                                                / 40 x I-Charge
                                              </span>
                                            </div>
                                            <div>
                                              <Checkbox
                                                checked={
                                                  user.crg_balance >= 100
                                                }
                                                style={{
                                                  color:
                                                    user.crg_balance >= 100
                                                      ? "#01b35c"
                                                      : "#000000",
                                                }}
                                              />
                                              <span>
                                                {Number(
                                                  user.crg_balance
                                                ).toFixed(2)}{" "}
                                                / 100 x CRG
                                              </span>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {user.matrix === "4" ? (
                                              <>
                                                <div>
                                                  <Checkbox
                                                    checked={
                                                      nft_counts["A-Alpha"]
                                                        .overall >= 5
                                                    }
                                                    style={{
                                                      color:
                                                        nft_counts["A-Alpha"]
                                                          .overall >= 5
                                                          ? "#01b35c"
                                                          : "#000000",
                                                    }}
                                                  />
                                                  <span>
                                                    {
                                                      nft_counts["A-Alpha"]
                                                        .total
                                                    }{" "}
                                                    / 5 x A-Alpha
                                                  </span>
                                                </div>
                                                <div>
                                                  <Checkbox
                                                    checked={
                                                      nft_counts["I-Charge"]
                                                        .overall >= 50
                                                    }
                                                    style={{
                                                      color:
                                                        nft_counts["I-Charge"]
                                                          .overall >= 50
                                                          ? "#01b35c"
                                                          : "#000000",
                                                    }}
                                                  />
                                                  <span>
                                                    {
                                                      nft_counts["I-Charge"]
                                                        .overall
                                                    }{" "}
                                                    / 50 x I-Charge
                                                  </span>
                                                </div>
                                                <div>
                                                  <Checkbox
                                                    checked={
                                                      user.crg_balance >= 125
                                                    }
                                                    style={{
                                                      color:
                                                        user.crg_balance >= 125
                                                          ? "#01b35c"
                                                          : "#000000",
                                                    }}
                                                  />
                                                  <span>
                                                    {Number(
                                                      user.crg_balance
                                                    ).toFixed(2)}{" "}
                                                    / 125 x CRG
                                                  </span>
                                                </div>
                                                <div>
                                                  <Checkbox
                                                    checked={
                                                      nft_counts[
                                                        "The Croesus Maxim"
                                                      ].total >= 1
                                                    }
                                                    style={{
                                                      color:
                                                        nft_counts[
                                                          "The Croesus Maxim"
                                                        ].total >= 1
                                                          ? "#01b35c"
                                                          : "#000000",
                                                    }}
                                                  />
                                                  <span>
                                                    {
                                                      nft_counts[
                                                        "The Croesus Maxim"
                                                      ].total
                                                    }{" "}
                                                    / 1 x The Croesus Maxim
                                                  </span>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                {user.matrix === "5" ? (
                                                  <>
                                                    <div>
                                                      <Checkbox
                                                        checked={
                                                          nft_counts["A-Alpha"]
                                                            .overall >= 6
                                                        }
                                                        style={{
                                                          color:
                                                            nft_counts[
                                                              "A-Alpha"
                                                            ].overall >= 6
                                                              ? "#01b35c"
                                                              : "#000000",
                                                        }}
                                                      />
                                                      <span>
                                                        {
                                                          nft_counts["A-Alpha"]
                                                            .overall
                                                        }{" "}
                                                        / 6 x A-Alpha
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <Checkbox
                                                        checked={
                                                          nft_counts["I-Charge"]
                                                            .overall >= 60
                                                        }
                                                        style={{
                                                          color:
                                                            nft_counts[
                                                              "I-Charge"
                                                            ].overall >= 60
                                                              ? "#01b35c"
                                                              : "#000000",
                                                        }}
                                                      />
                                                      <span>
                                                        {
                                                          nft_counts["I-Charge"]
                                                            .overall
                                                        }{" "}
                                                        / 60 x I-Charge
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <Checkbox
                                                        checked={
                                                          user.crg_balance >=
                                                          150
                                                        }
                                                        style={{
                                                          color:
                                                            user.crg_balance >=
                                                            150
                                                              ? "#01b35c"
                                                              : "#000000",
                                                        }}
                                                      />
                                                      <span>
                                                        {Number(
                                                          user.crg_balance
                                                        ).toFixed(2)}{" "}
                                                        / 150 x CRG
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <Checkbox
                                                        checked={
                                                          nft_counts[
                                                            "The Croesus Maxim"
                                                          ].overall >= 1
                                                        }
                                                        style={{
                                                          color:
                                                            nft_counts[
                                                              "The Croesus Maxim"
                                                            ].overall >= 1
                                                              ? "#01b35c"
                                                              : "#000000",
                                                        }}
                                                      />
                                                      <span>
                                                        {
                                                          nft_counts[
                                                            "The Croesus Maxim"
                                                          ].overall
                                                        }{" "}
                                                        / 1 x The Croesus Maxim
                                                      </span>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>
                                                    {user.matrix === "6" ? (
                                                      <>
                                                        <div>
                                                          <Checkbox
                                                            checked={
                                                              nft_counts[
                                                                "A-Alpha"
                                                              ].overall >= 7
                                                            }
                                                            style={{
                                                              color:
                                                                nft_counts[
                                                                  "A-Alpha"
                                                                ].overall >= 7
                                                                  ? "#01b35c"
                                                                  : "#000000",
                                                            }}
                                                          />
                                                          <span>
                                                            {
                                                              nft_counts[
                                                                "A-Alpha"
                                                              ].overall
                                                            }{" "}
                                                            / 7 x A-Alpha
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <Checkbox
                                                            checked={
                                                              nft_counts[
                                                                "I-Charge"
                                                              ].overall >= 70
                                                            }
                                                            style={{
                                                              color:
                                                                nft_counts[
                                                                  "I-Charge"
                                                                ].overall >= 70
                                                                  ? "#01b35c"
                                                                  : "#000000",
                                                            }}
                                                          />
                                                          <span>
                                                            {
                                                              nft_counts[
                                                                "I-Charge"
                                                              ].overall
                                                            }{" "}
                                                            / 70 x I-Charge
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <Checkbox
                                                            checked={
                                                              user.crg_balance >=
                                                              175
                                                            }
                                                            style={{
                                                              color:
                                                                user.crg_balance >=
                                                                175
                                                                  ? "#01b35c"
                                                                  : "#000000",
                                                            }}
                                                          />
                                                          <span>
                                                            {Number(
                                                              user.crg_balance
                                                            ).toFixed(2)}{" "}
                                                            / 175 x CRG
                                                          </span>
                                                        </div>
                                                        <div>
                                                          <Checkbox
                                                            checked={
                                                              nft_counts[
                                                                "The Croesus Maxim"
                                                              ].overall >= 1
                                                            }
                                                            style={{
                                                              color:
                                                                nft_counts[
                                                                  "The Croesus Maxim"
                                                                ].overall >= 1
                                                                  ? "#01b35c"
                                                                  : "#000000",
                                                            }}
                                                          />
                                                          <span>
                                                            {
                                                              nft_counts[
                                                                "The Croesus Maxim"
                                                              ].overall
                                                            }{" "}
                                                            / 1 x The Croesus
                                                            Maxim
                                                          </span>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        {user.matrix === "7" ? (
                                                          <>
                                                            <div>
                                                              <Checkbox
                                                                checked={
                                                                  nft_counts[
                                                                    "A-Alpha"
                                                                  ].overall >= 8
                                                                }
                                                                style={{
                                                                  color:
                                                                    nft_counts[
                                                                      "A-Alpha"
                                                                    ].overall >=
                                                                    8
                                                                      ? "#01b35c"
                                                                      : "#000000",
                                                                }}
                                                              />
                                                              <span>
                                                                {
                                                                  nft_counts[
                                                                    "A-Alpha"
                                                                  ].overall
                                                                }{" "}
                                                                / 8 x A-Alpha
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <Checkbox
                                                                checked={
                                                                  nft_counts[
                                                                    "I-Charge"
                                                                  ].overall >=
                                                                  80
                                                                }
                                                                style={{
                                                                  color:
                                                                    nft_counts[
                                                                      "I-Charge"
                                                                    ].overall >=
                                                                    80
                                                                      ? "#01b35c"
                                                                      : "#000000",
                                                                }}
                                                              />
                                                              <span>
                                                                {
                                                                  nft_counts[
                                                                    "I-Charge"
                                                                  ].overall
                                                                }{" "}
                                                                / 80 x I-Charge
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <Checkbox
                                                                checked={
                                                                  user.crg_balance >=
                                                                  200
                                                                }
                                                                style={{
                                                                  color:
                                                                    user.crg_balance >=
                                                                    200
                                                                      ? "#01b35c"
                                                                      : "#000000",
                                                                }}
                                                              />
                                                              <span>
                                                                {Number(
                                                                  user.crg_balance
                                                                ).toFixed(
                                                                  2
                                                                )}{" "}
                                                                / 200 x CRG
                                                              </span>
                                                            </div>
                                                            <div>
                                                              <Checkbox
                                                                checked={
                                                                  nft_counts[
                                                                    "The Croesus Maxim"
                                                                  ].overall >= 1
                                                                }
                                                                style={{
                                                                  color:
                                                                    nft_counts[
                                                                      "The Croesus Maxim"
                                                                    ].overall >=
                                                                    1
                                                                      ? "#01b35c"
                                                                      : "#000000",
                                                                }}
                                                              />
                                                              <span>
                                                                {
                                                                  nft_counts[
                                                                    "The Croesus Maxim"
                                                                  ].overall
                                                                }{" "}
                                                                / 1 x The
                                                                Croesus Maxim
                                                              </span>
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {user.matrix ===
                                                            "8" ? (
                                                              <>
                                                                <div>
                                                                  <Checkbox
                                                                    checked={
                                                                      nft_counts[
                                                                        "A-Alpha"
                                                                      ]
                                                                        .overall >=
                                                                      9
                                                                    }
                                                                  />
                                                                  <span>
                                                                    {
                                                                      nft_counts[
                                                                        "A-Alpha"
                                                                      ].overall
                                                                    }{" "}
                                                                    / 9 x
                                                                    A-Alpha
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <Checkbox
                                                                    checked={
                                                                      nft_counts[
                                                                        "I-Charge"
                                                                      ]
                                                                        .overall >=
                                                                      90
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        nft_counts[
                                                                          "I-Charge"
                                                                        ]
                                                                          .total >=
                                                                        90
                                                                          ? "#01b35c"
                                                                          : "#000000",
                                                                    }}
                                                                  />
                                                                  <span>
                                                                    {
                                                                      nft_counts[
                                                                        "I-Charge"
                                                                      ].overall
                                                                    }{" "}
                                                                    / 90 x
                                                                    I-Charge
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <Checkbox
                                                                    checked={
                                                                      user.crg_balance >=
                                                                      225
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        user.crg_balance >=
                                                                        225
                                                                          ? "#01b35c"
                                                                          : "#000000",
                                                                    }}
                                                                  />
                                                                  <span>
                                                                    {Number(
                                                                      user.crg_balance
                                                                    ).toFixed(
                                                                      2
                                                                    )}{" "}
                                                                    / 225 x CRG
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <Checkbox
                                                                    checked={
                                                                      nft_counts[
                                                                        "The Croesus Maxim"
                                                                      ]
                                                                        .overall >=
                                                                      1
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        nft_counts[
                                                                          "The Croesus Maxim"
                                                                        ]
                                                                          .total >=
                                                                        1
                                                                          ? "#01b35c"
                                                                          : "#000000",
                                                                    }}
                                                                  />
                                                                  <span>
                                                                    {
                                                                      nft_counts[
                                                                        "The Croesus Maxim"
                                                                      ].overall
                                                                    }{" "}
                                                                    / 1 x The
                                                                    Croesus
                                                                    Maxim
                                                                  </span>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {user.matrix ===
                                                                "9" ? (
                                                                  <>
                                                                    <div>
                                                                      <Checkbox
                                                                        checked={
                                                                          nft_counts[
                                                                            "A-Alpha"
                                                                          ]
                                                                            .overall >=
                                                                          1
                                                                        }
                                                                        style={{
                                                                          color:
                                                                            nft_counts[
                                                                              "A-Alpha"
                                                                            ]
                                                                              .overall >=
                                                                            1
                                                                              ? "#01b35c"
                                                                              : "#000000",
                                                                        }}
                                                                      />
                                                                      <span>
                                                                        {
                                                                          nft_counts[
                                                                            "A-Alpha"
                                                                          ]
                                                                            .overall
                                                                        }{" "}
                                                                        / 1 x
                                                                        A-Alpha
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <Checkbox
                                                                        checked={
                                                                          nft_counts[
                                                                            "I-Charge"
                                                                          ]
                                                                            .overall >=
                                                                          100
                                                                        }
                                                                        style={{
                                                                          color:
                                                                            nft_counts[
                                                                              "I-Charge"
                                                                            ]
                                                                              .overall >=
                                                                            100
                                                                              ? "#01b35c"
                                                                              : "#000000",
                                                                        }}
                                                                      />
                                                                      <span>
                                                                        {
                                                                          nft_counts[
                                                                            "I-Charge"
                                                                          ]
                                                                            .overall
                                                                        }{" "}
                                                                        / 100 x
                                                                        I-Charge
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <Checkbox
                                                                        checked={
                                                                          user.crg_balance >=
                                                                          250
                                                                        }
                                                                        style={{
                                                                          color:
                                                                            user.crg_balance >=
                                                                            250
                                                                              ? "#01b35c"
                                                                              : "#000000",
                                                                        }}
                                                                      />
                                                                      <span>
                                                                        {Number(
                                                                          user.crg_balance
                                                                        ).toFixed(
                                                                          2
                                                                        )}{" "}
                                                                        / 250 x
                                                                        CRG
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <Checkbox
                                                                        checked={
                                                                          nft_counts[
                                                                            "The Croesus Maxim"
                                                                          ]
                                                                            .overall >=
                                                                          1
                                                                        }
                                                                        style={{
                                                                          color:
                                                                            nft_counts[
                                                                              "The Croesus Maxim"
                                                                            ]
                                                                              .overall >=
                                                                            1
                                                                              ? "#01b35c"
                                                                              : "#000000",
                                                                        }}
                                                                      />
                                                                      <span>
                                                                        {
                                                                          nft_counts[
                                                                            "The Croesus Maxim"
                                                                          ]
                                                                            .overall
                                                                        }{" "}
                                                                        / 1 x
                                                                        The
                                                                        Croesus
                                                                        Maxim
                                                                      </span>
                                                                    </div>
                                                                    <div>
                                                                      <Checkbox />
                                                                      <span>
                                                                        0 / 1 x
                                                                        Coming
                                                                        Soon
                                                                      </span>
                                                                    </div>
                                                                  </>
                                                                ) : null}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            {Number(user.matrix) < 10 ? (
                              <>
                                {" "}
                                {Number(user.matrix) < 3 ? (
                                  <>
                                    <h6
                                      className="p-3 pb-0"
                                      style={{ textAlign: "left" }}
                                    >
                                      Shortcut To Level 3:
                                    </h6>

                                    <>
                                      <div>
                                        <Checkbox
                                          checked={
                                            nft_counts.Taipei.overall >= 1
                                          }
                                          style={{
                                            color:
                                              nft_counts.Taipei.overall >= 1
                                                ? "#01b35c"
                                                : "#000000",
                                          }}
                                        />
                                        <span>
                                          {nft_counts.Taipei.overall} / 1 x
                                          Taipei
                                        </span>
                                      </div>
                                      <div>
                                        <Checkbox
                                          checked={
                                            nft_counts["China Zun Tower"]
                                              .overall >= 1
                                          }
                                          style={{
                                            color:
                                              nft_counts["China Zun Tower"]
                                                .overall >= 1
                                                ? "#01b35c"
                                                : "#000000",
                                          }}
                                        />
                                        <span>
                                          {
                                            nft_counts["China Zun Tower"]
                                              .overall
                                          }{" "}
                                          / 1 x China Zun Tower
                                        </span>
                                      </div>
                                    </>
                                  </>
                                ) : null}
                                <>
                                  <h6
                                    className="p-3 pb-0"
                                    style={{ textAlign: "left" }}
                                  >
                                    Shortcut To Level 10 (including 1 Share
                                    Global Bonus Pool):
                                  </h6>
                                  <>
                                    <div>
                                      <Checkbox
                                        checked={nft_counts.Taipei.overall >= 1}
                                        style={{
                                          color:
                                            nft_counts.Taipei.overall >= 1
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {nft_counts.Taipei.overall} / 1 x Taipei
                                      </span>
                                    </div>
                                    <div>
                                      <Checkbox
                                        checked={
                                          nft_counts["China Zun Tower"]
                                            .overall >= 1
                                        }
                                        style={{
                                          color:
                                            nft_counts["China Zun Tower"]
                                              .overall >= 1
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {nft_counts["China Zun Tower"].overall}{" "}
                                        / 1 x China Zun Tower
                                      </span>
                                    </div>
                                    <div>
                                      <Checkbox
                                        checked={
                                          nft_counts[
                                            "Tianjin_CTF_Finance_Center"
                                          ].overall >= 1
                                        }
                                        style={{
                                          color:
                                            nft_counts[
                                              "Tianjin_CTF_Finance_Center"
                                            ].overall >= 1
                                              ? "#01b35c"
                                              : "#000000",
                                        }}
                                      />
                                      <span>
                                        {
                                          nft_counts[
                                            "Tianjin_CTF_Finance_Center"
                                          ].overall
                                        }{" "}
                                        / 1 x Tianjin CTF Finance Center
                                      </span>
                                    </div>
                                  </>
                                </>
                              </>
                            ) : null}
                          </>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="card-body p-0 mt-3">
                      <MatrixComponent user={user} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className="row mt-2 justify-content-md-center"
              style={{ marginBottom: "90px" }}
            >
              <div className="col-xl-6">
                <div className="card p-3">
                  <div className="card-header text-center p-0 flex-wrap">
                    <h1>Transactions</h1>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                      <Tab label="Withdrawals" />
                      <Tab label="Partner" />
                    </Tabs>
                  </div>
                  <CardContent
                    className="p-0 mt-3"
                    style={{ overflowX: "auto" }}
                  >
                    {currentTab === 0 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>TXN</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedwithdrawTransactions.map(
                              (transaction, index) => (
                                <TableRow key={index}>
                                  <TableCell>
                                    {new Date(
                                      transaction.updated_at
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "2-digit",
                                      year: "numeric",
                                    })}
                                  </TableCell>
                                  <TableCell style={{ whiteSpace: "nowrap" }}>
                                    {`${
                                      Math.floor(transaction.amount * 100) / 100
                                    } ${transaction.currency}`}
                                  </TableCell>

                                  <TableCell
                                    style={{
                                      color:
                                        transaction.status === "Processed"
                                          ? "green"
                                          : transaction.status === "pending"
                                          ? "orange"
                                          : "red",
                                    }}
                                  >
                                    {transaction.status}
                                  </TableCell>
                                  <TableCell>
                                    {transaction.txn_id ? (
                                      <a
                                        href={`https://www.bscscan.com/tx/${transaction.txn_id}`}
                                        target="_blank"
                                        style={{ color: "blue" }}
                                        rel="noopener noreferrer" // for security reasons
                                      >
                                        {transaction.txn_id.length > 5
                                          ? `${transaction.txn_id.slice(
                                              0,
                                              5
                                            )}...`
                                          : transaction.txn_id}
                                      </a>
                                    ) : (
                                      "N/A"
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={pageSizeOptions}
                          component="div"
                          count={withdrawTransactions.length}
                          rowsPerPage={rowsPerPageWithdraw}
                          page={pageWithdraw}
                          onPageChange={handleChangePageWithdraw}
                          onRowsPerPageChange={handleChangeRowsPerPageWithdraw}
                          labelRowsPerPage="Show:"
                        />
                      </>
                    )}

                    {currentTab === 1 && (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Partner</TableCell>
                              <TableCell>NFT</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {paginatedTransactions.map((transaction, index) => (
                              <TableRow key={index}>
                                <TableCell style={{ whiteSpace: "nowrap" }}>
                                  {new Date(
                                    transaction.updated_at
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  })}
                                </TableCell>
                                <TableCell style={{ whiteSpace: "nowrap" }}>
                                  {transaction.contract_id === "5" ||
                                  transaction.contract_id === "2" ||
                                  transaction.contract_id === "7" ||
                                  transaction.contract_id === "9" ||
                                  transaction.contract_id === "8" ||
                                  transaction.contract_id === "11"
                                    ? `${transaction.amount} USDT`
                                    : `${transaction.amount} BNB`}
                                </TableCell>
                                <TableCell style={{ whiteSpace: "nowrap" }}>
                                  {user.name === transaction.partner_name
                                    ? "IC-GBP"
                                    : `${transaction.partner_name} `}
                                </TableCell>
                                <TableCell style={{ whiteSpace: "nowrap" }}>
                                  {user.name === transaction.partner_name
                                    ? ``
                                    : transaction.contract_id === "8"
                                    ? `${getNftName(transaction.contract_id)}`
                                    : `${getNftName(transaction.contract_id)}`}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={pageSizeOptions}
                          component="div"
                          count={refTransactions.length}
                          rowsPerPage={rowsPerPageRef}
                          page={pageRef}
                          onPageChange={handleChangePageRef}
                          onRowsPerPageChange={handleChangeRowsPerPageRef}
                          labelRowsPerPage="Show:"
                        />
                      </>
                    )}
                  </CardContent>
                  {/* <TablePagination
                    rowsPerPageOptions={pageSizeOptions}
                    component="div"
                    count={refTransactions.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Show:"
                  /> */}
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default PartnerDashboard;

const getNftName = (contractId) => {
  // Consider mappingObj as an object where key is contract_id and value is nft.name.
  // You may have to write logic to create this mapping as per your available data.
  const mappingObj = {
    1: "CZT",
    6: "CZT",
    2: "Taipei",
    5: "Taipei",
    8: "I-Charge",
    9: "A-Alpha",
    11: "Omega",
  };
  return mappingObj[contractId];
};
