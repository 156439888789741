import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { CircularProgress, Grid, Box } from "@material-ui/core";

const Users = () => {
  const [reload, setreload] = useState("");
  const [userplans, setuserplans] = useState([]);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserPlan, setSelectedUserPlan] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://test.croesus-asset.management/api/getusers`
      );
      console.log("API getUsers:", response);

      setuserplans(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const renderTableRows = (userplans) => {
    // const filteredUserplans = userplans.filter((userplan) =>
    //   userplan.username.includes(searchTerm)
    // );

    if (!Array.isArray(userplans)) {
      // Fallback Component or Null when userplans is not an array
      return null;
    }

    return userplans.map((userplan) => (
      <React.Fragment key={userplan.id}>
        <TableRow>
          <TableCell>{userplan?.id}</TableCell>
          <TableCell>{userplan?.name}</TableCell>
          <TableCell>{userplan?.card_kyc_data?.cardholdersId}</TableCell>
          <TableCell>{userplan?.card_kyc_data?.cardKitNumber}</TableCell>
        </TableRow>
      </React.Fragment>
    ));
  };

  if (isLoading) {
    return (
      <div>
        {" "}
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <h4>Loading...</h4>
          </Grid>
        </Box>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </div>
    ); // or some loading spinner
  }

  return (
    <div>
      <TextField
        className="mt-2 mb-2"
        label="Search by username"
        variant="outlined"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>CHID</TableCell>
              <TableCell>CKitNumber</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(userplans)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Users;
