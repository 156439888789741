import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import { contractAbi as CoinAbi, contractAddress as CoinAddress } from './components/CoinContractInfo';
import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { useAccount, useConnect, useEnsName, useSignMessage } from 'wagmi';
import logo from "../../images/logo-full.png";
import { Link, useNavigate } from 'react-router-dom';

// Set your contract details
const coinContractAbi = CoinAbi;
const coinContractAddress = CoinAddress;

// Initialize web3
let web3 = new Web3(window.ethereum);

// Your contract instance
let coinContract = new web3.eth.Contract(coinContractAbi, coinContractAddress);
console.log("coinContract", coinContract);

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    // minHeight: '100vh',
    gap: '20px',
};

const loadingStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
};

function CroesusCoinComponent() {
    const [totalSupply, setTotalSupply] = useState(0);
    const [userBalance, setUserBalance] = useState(0); 
    const [refreshCount, setRefreshCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [lastBurnedTokens, setLastBurnedTokens] = useState([]);
    const [airdropAddress, setAirdropAddress] = useState('');
    const [airdropAmount, setAirdropAmount] = useState('');
    const [isAirdropping, setIsAirdropping] = useState(false);
    const [ownerAddress, setOwnerAddress] = useState('');
	const { address, connector, isConnected } = useAccount()

    const handleAddToMetamask = async () => {
        const ethereum = window.ethereum;
    
        if (ethereum && ethereum.isMetaMask) {
          try {
            await ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                  type: 'ERC20',
                  options: {
                    address: '0x1075470b6D4c77727cf725a2A64bB64A3e2D65F1', // Replace with your Croesus Coin token contract address
                    symbol: 'CRG', // Replace with your token symbol
                    decimals: 18, // Replace with your token decimals
                    image: 'https://thumbnail.imgbin.com/15/12/17/imgbin-security-token-token-coin-initial-coin-offering-sharks-in-the-water-overcoming-life-s-challenges-others-WY4F4sXui6T04qPyFe6cLFfKK_t.jpg', // Replace with the URL to your token image
                  },
                },
              });
          } catch (error) {
            console.error('Error adding Croesus Coin to MetaMask:', error);
          }
        }
      };

    const fetchLastBurnedTokens = async () => {
        setIsLoading(true);
        try {
          const response = await coinContract.methods.getLastBurnedTokens().call();
          console.log('last burned tokens:', response);
          const lastBurnedTokensWithAmount = response.map((token) => {
            const amount = Number(token.amount) / Math.pow(10, 18);
            return { ...token, amount };
          });
          setLastBurnedTokens(lastBurnedTokensWithAmount);
        } catch (error) {
          console.error('Error fetching last burned tokens:', error);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        async function fetchTotalSupply() {
            setIsLoading(true);
            const supply = await coinContract.methods.totalSupply().call();
            const supplyInEther = Number(supply) / Math.pow(10, 18);

            setTotalSupply(supplyInEther);
            setIsLoading(false);
        }

        async function fetchUserBalance() {
            setIsLoading(true);
            const accounts = await web3.eth.getAccounts();
            const userAccount = accounts[0];
            const balance = await coinContract.methods.balanceOf(userAccount).call();
            const balanceInEther = Number(balance) / Math.pow(10, 18);

            setUserBalance(balanceInEther);
            setIsLoading(false);
        }

        async function fetchOwnerAddress() {
            try {
              const owner = await coinContract.methods.owner().call();
              setOwnerAddress(owner);
            } catch (error) {
              console.error('Error fetching owner address:', error);
            }
        }

        fetchTotalSupply();
        fetchLastBurnedTokens();
        fetchUserBalance();
        fetchOwnerAddress();
    }, [refreshCount]); // Refresh data when refreshCount changes

    const handleRefresh = () => {
        setIsLoading(true);
        setTimeout(() => {
            setRefreshCount(prevCount => prevCount + 1);
        }, 1500);
    }

    const handleAirdrop = async () => {
        setIsAirdropping(true);
        try {
            const amountInWei = web3.utils.toWei(airdropAmount.toString()); // Convert to wei

            await coinContract.methods.airdropToAddress(airdropAddress, amountInWei).send({ from: airdropAddress }); // Replace with the owner address
            // Clear input fields after successful airdrop
            setAirdropAddress('');
            setAirdropAmount('');
            // Refresh data after airdrop
            setRefreshCount(prevCount => prevCount + 1);
        } catch (error) {
            console.error('Error during airdrop:', error);
        }
        setIsAirdropping(false);
    };

    return (
      <div className="content-body p-0" style={{ minHeight: window.screen.height + 50 }}>
           
        <div className="container-fluid text-center mb-2">				
            <Link to={"#"}>
            <img className="logo-abbr dark-logo" width="200" src={logo} alt="" />
            <img className="logo-abbr light-logo text-center m-auto" width="200" src={logo} alt="" />
            </Link>
        </div>
        <div className="container-fluid text-center">			

          <Box style={{ ...containerStyle, wordBreak: 'break-all', fontFamily: 'Courier' }}>
          <h1 className='text-center'>Croesus Coin Info</h1>
          <Typography variant="h6">Coin Contract Address:</Typography>        
          <Typography variant="h6" style={{ fontFamily: 'Courier' }}>{coinContractAddress}</Typography>      
          <Button onClick={handleAddToMetamask} variant="contained">
              Add to Wallet
          </Button>
          <Typography variant="h6">Total Supply: {totalSupply} CRG</Typography>
          <Typography variant="h6">Your Balance: {userBalance} CRG</Typography> 
          {lastBurnedTokens.length > 0 && (
            <Box>
              <Typography variant="h6">Last Burned Tokens:</Typography>
              {lastBurnedTokens.map((token, index) => (
                <Typography key={index} variant="body1">
                  Token ID: {token.tokenId} - Amount: {token.amount} - Burned At: {new Date(token.timestamp * 1000).toLocaleString()}
                </Typography>
              ))}
            </Box>
          )}
          {isLoading ? (
              <Box style={loadingStyle}>
              <CircularProgress />
              <Typography variant="h6">Refreshing...</Typography>
              </Box>
          ) : (
              <Button onClick={handleRefresh} variant="contained">Refresh</Button>
          )}

              <TextField
                  fullWidth={true}
                  sx={{ width: '100%', input: { color: 'white' } }}
                  label="Recipient Address"
                  variant="outlined"
                  value={airdropAddress}
                  onChange={e => setAirdropAddress(e.target.value)}
              />
              <TextField
                  fullWidth={true}
                  sx={{ width: '100%', input: { color: 'white' }  }}
                  label="Airdrop Amount"
                  variant="outlined"
                  value={airdropAmount}
                  onChange={e => setAirdropAmount(e.target.value)}
              />
          {ownerAddress !== address && (
              <Typography variant="body1" color="error">
                  Only the owner wallet can perform the airdrop.
              </Typography>
          )}
          {isAirdropping ? (
              <Box style={loadingStyle}>
              <CircularProgress />
              <Typography variant="h6">Airdropping...</Typography>
              </Box>
          ) : (
              <Button
                  onClick={handleAirdrop}
                  variant="contained"
                  disabled={isAirdropping || isLoading || ownerAddress !== address}
              >
                  {isAirdropping ? 'Airdropping...' : 'Airdrop'}
              </Button>
          )}

          </Box>
        </div>
      </div>
    );
}

export default CroesusCoinComponent;
