import { useEffect, useState } from "react";
import axios from "axios";

const useApiCall = (isConnected, address) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isConnected) {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://test.croesus-asset.management/api/user-data?address=${address}`
          );
          console.log("API response:", response);

          // Handle successful response here
          // For example, update state or perform any required actions
          setIsAuthenticated(true);
        } catch (error) {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);

            if (errorMessage === "User not found") {
              // Handle the specific error condition here
              // For example, display an error message to the user
            }

            // You can handle/display the error message as per your requirement
          }
        }
      };

      fetchData();
    } else {
      console.log("disconnected");
    }
  }, [isConnected, address]);

  return [isAuthenticated, setIsAuthenticated];
};

export default useApiCall;
