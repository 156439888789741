import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  useMediaQuery,
  useTheme,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  LinearProgress,
  Select,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import { format } from "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

import axios from "axios";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // minHeight: "100vh",
  gap: "20px",
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  realCard: {
    background: "linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)", // Example gradient
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    borderRadius: "15px",
    padding: "10px",
    maxWidth: 345,
    // margin: theme.spacing(2),
  },
  cardNumber: {
    letterSpacing: "4px",
  },
  cardHolder: {
    marginTop: theme.spacing(2),
  },
  cardExpiry: {
    marginTop: theme.spacing(1),
    textAlign: "right",
  },
  table: {
    minWidth: 650,
  },
  kycContainer: {
    marginTop: theme.spacing(4),
  },
  formInput: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  card: {
    backgroundColor: "#00AEEF", // Adjust the color to match the card's blue
    borderRadius: "15px",
    color: "white",
    margin: "auto",
    maxWidth: "100%",
    overflow: "hidden",
    padding: theme.spacing(2),
    position: "relative",
    // Adjust the card size based on the screen width
    [theme.breakpoints.up("sm")]: {
      width: "480px",
    },
  },
  balanceTitle: {
    fontSize: "1.2rem",
    opacity: 0.7,
    textAlign: "center",
  },
  balance: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    margin: "10px 0",
    textAlign: "center",
  },
  cardDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    "& div": {
      marginBottom: theme.spacing(1),
    },
    // Adjust text alignment and spacing for larger screens
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      "& div": {
        marginBottom: 0,
      },
    },
  },
  detailText: {
    fontSize: "1rem",
    opacity: 0.7,
  },
  paper: {
    padding: theme.spacing(3),
  },
  formInput: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  fileInputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function getSteps() {
  return ["Address Information", "Identification Document", "Review & Submit"];
}

function KYCWizard() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [idFront, setIdFront] = useState(null);
  const [idBack, setIdBack] = useState(null);
  const [addressFront, setAddressFront] = useState(null);
  const [addressBack, setAddressBack] = useState(null);

  // Initialize the state for KYC data
  const [kycData, setKycData] = useState({
    cardHolderID: "",
    address_TypeId: "",
    address_IssuedBy: "",
    address_IssuedDate: null,
    address_LangId: "",
    address_FrontSide: null,
    address_BackSide: null,
    idNumber: "",
    idIssuedBy: "",
    idExpDate: null,
    idIssueDate: null,
    idType: "",
    idDocLang: "",
    id_FrontSide: "",
    id_BackSide: "",
  });

  // Handle file change
  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const reader = new FileReader();

    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        switch (name) {
          case "id_FrontSide":
            setIdFront(reader.result);
            break;
          case "id_BackSide":
            setIdBack(reader.result);
            break;
          case "address_FrontSide":
            setAddressFront(reader.result);
            break;
          case "address_BackSide":
            setAddressBack(reader.result);
            break;
          default:
            break;
        }
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmitKYC();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setKycData({ ...kycData, [name]: value });
  };

  const handleSubmitKYC = () => {
    const addressData = {
      cardHolderID: kycData.cardHolderID,
      address_TypeId: kycData.address_TypeId,
      address_IssuedBy: kycData.address_IssuedBy,
      address_IssuedDate: kycData.address_IssuedDate,
      address_LangId: kycData.address_LangId,
      address_FrontSide: kycData.address_FrontSide,
      address_BackSide: kycData.address_BackSide,
    };

    const idDocumentData = {
      cardHolderID: kycData.cardHolderID,
      idNumber: kycData.idNumber,
      idIssuedBy: kycData.idIssuedBy,
      idExpDate: kycData.idExpDate,
      idIssueDate: kycData.idIssueDate,
      idType: kycData.idType,
      idDocLang: kycData.idDocLang,
      id_FrontSide: kycData.id_FrontSide,
      id_BackSide: kycData.id_BackSide,
    };
    idDocumentData.id_FrontSide = idFront;
    idDocumentData.id_BackSide = idBack;

    // Define the headers for your requests
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer 5ScRiAOgI5BlgdjSwWNUbNagsj8fFCjady",
      },
    };

    // Axios POST request for address
    axios
      .post(
        "https://merchant.fcfpay.com/api/physical-cards/holder-address",
        addressData,
        config
      )
      .then((response) => {
        console.log("Address Data Submitted", response.data);
        // Here you might want to proceed to submit the ID document data
        // You might also want to handle the step progression differently based on response
      })
      .catch((error) => {
        console.error("Error submitting Address Data", error);
      });

    // Axios POST request for ID document
    axios
      .post(
        "https://merchant.fcfpay.com/api/physical-cards/holder-id-document",
        idDocumentData,
        config
      )
      .then((response) => {
        console.log("ID Document Data Submitted", response.data);
      })
      .catch((error) => {
        console.error("Error submitting ID Document Data", error);
      });
  };

  // Inside handleSubmitKYC, assign the base64 encoded image to the payload

  return (
    <Paper className={classes.paper}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 && (
        // Form for Address Information
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <>
            <TextField
              label="Card Holder ID"
              name="cardHolderID"
              onChange={handleChange}
              value={kycData.cardHolderID}
              className={classes.formInput}
            />
            <FormControl className={classes.formInput}>
              <InputLabel id="address_TypeId-label">Address Type</InputLabel>
              <Select
                labelId="address_TypeId-label"
                id="address_TypeId"
                name="address_TypeId"
                value={kycData.address_TypeId}
                onChange={handleChange}
              >
                <MenuItem value={0}>Credit Card Statement</MenuItem>
                <MenuItem value={1}>Utility Bill</MenuItem>
                <MenuItem value={2}>Bank Statement</MenuItem>
                <MenuItem value={3}>Bank Letter</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Address Issued By"
              name="address_IssuedBy"
              onChange={handleChange}
              value={kycData.address_IssuedBy}
              className={classes.formInput}
            />
            <KeyboardDatePicker
              margin="normal"
              id="address_IssuedDate"
              name="address_IssuedDate"
              label="Address Issued Date"
              format="yyyy-MM-dd"
              value={kycData.address_IssuedDate || null}
              onChange={(date) =>
                setKycData({ ...kycData, address_IssuedDate: date })
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className={classes.formInput}
              invalidDateMessage="Invalid Date Format" // Custom error message for invalid dates
            />

            <FormControl className={classes.formInput}>
              <InputLabel id="address_LangId-label">
                Document Language
              </InputLabel>
              <Select
                labelId="address_LangId-label"
                id="address_LangId"
                name="address_LangId"
                value={kycData.address_LangId}
                onChange={handleChange}
              >
                <MenuItem value={0}>English</MenuItem>
                <MenuItem value={1}>Spanish</MenuItem>
                <MenuItem value={2}>French</MenuItem>
                <MenuItem value={3}>Arabic</MenuItem>
                <MenuItem value={4}>Chinese</MenuItem>
                <MenuItem value={5}>Japanese</MenuItem>
                <MenuItem value={6}>Russian</MenuItem>
                <MenuItem value={7}>Portuguese</MenuItem>
                <MenuItem value={8}>German</MenuItem>
                <MenuItem value={9}>Italian</MenuItem>
                <MenuItem value={10}>Hindi</MenuItem>
                <MenuItem value={11}>Other</MenuItem>
              </Select>
            </FormControl>
            {/* File input for Address Front Side */}
            <input
              accept="image/*"
              className={classes.input + " mt-3"}
              id="address_FrontSide-file"
              name="address_FrontSide"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="address_FrontSide-file">
              <Button variant="contained" color="primary" component="span">
                Upload Address Front Side
              </Button>
            </label>
            {addressFront && (
              <LinearProgress variant="determinate" value={100} />
            )}

            {/* File input for Address Back Side */}
            <input
              accept="image/*"
              className={classes.input}
              id="address_BackSide-file"
              name="address_BackSide"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="address_BackSide-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={"mt-3"}
              >
                Upload Address Back Side
              </Button>
            </label>
            {addressBack && (
              <LinearProgress variant="determinate" value={100} />
            )}
          </>
        </MuiPickersUtilsProvider>
      )}

      {activeStep === 1 && (
        // Form for ID Document
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
          <>
            <TextField
              label="ID Number"
              name="idNumber"
              onChange={handleChange}
              value={kycData.idNumber}
              className={classes.formInput}
            />
            <TextField
              label="ID Issued By"
              name="idIssuedBy"
              onChange={handleChange}
              value={kycData.idIssuedBy}
              className={classes.formInput}
            />
            <TextField
              label="ID Expiry Date"
              name="idExpDate"
              onChange={(event) => {
                const value = format(
                  new Date(event.target.value),
                  "yyyy-MM-dd"
                );
                handleChange({ target: { name: event.target.name, value } });
              }}
              value={kycData.idExpDate}
              className={classes.formInput}
            />
            <FormControl className={classes.formInput}>
              <InputLabel id="idType-label">ID Type</InputLabel>
              <Select
                labelId="idType-label"
                id="idType"
                name="idType"
                value={kycData.idType}
                onChange={handleChange}
              >
                {/* Map your menu items here */}
                <MenuItem value={0}>Passport</MenuItem>
                <MenuItem value={1}>Driver License</MenuItem>
                <MenuItem value={2}>National Id</MenuItem>
                {/* ... other menu items */}
              </Select>
            </FormControl>
            <KeyboardDatePicker
              margin="normal"
              id="idIssueDate"
              name="idIssueDate"
              label="ID Issue Date"
              format="yyyy-MM-dd"
              value={kycData.idIssueDate || null} // Ensure this is a valid Date object or null
              onChange={(date) =>
                setKycData({ ...kycData, idIssueDate: date || null })
              } // Handle null dates
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className={classes.formInput}
              invalidDateMessage="Invalid Date Format" // Custom error message for invalid dates
            />

            {/* ... other DatePickers and Selects */}
            <div className={classes.fileInputContainer}>
              <input
                accept="image/*"
                className={classes.input}
                id="id_FrontSide-file"
                name="id_FrontSide"
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }} // Hide the default input
              />
              <label htmlFor="id_FrontSide-file">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  Upload Front Side
                </Button>
              </label>
              {idFront && (
                <Typography variant="caption" display="block">
                  {idFront.name} {/* Show file name */}
                </Typography>
              )}
            </div>
            {idFront && <LinearProgress variant="determinate" value={100} />}

            {/* File input for ID Back Side */}
            <input
              accept="image/*"
              className={classes.input}
              id="id_BackSide-file"
              name="id_BackSide"
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="id_BackSide-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={"mt-3"}
              >
                Upload ID Back Side
              </Button>
            </label>
            {idBack && <LinearProgress variant="determinate" value={100} />}
          </>
        </MuiPickersUtilsProvider>
      )}
      <div className={classes.buttonContainer}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          {activeStep === steps.length - 1 ? "Submit" : "Next"}
        </Button>
      </div>
    </Paper>
  );
}

function Crypto_Card() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [balance, setBalance] = React.useState("1,234.56"); // Placeholder for balance
  const transactions = []; // Placeholder for transactions
  const cardNumber = "**** **** **** 1234"; // Example card number
  const cardHolderName = "Franklin Jr."; // Example cardholder name
  const expiryDate = "08/21"; // Example expiry date

  // Function to handle top-up (dummy for now)
  const handleTopUp = () => {
    console.log("Top Up Clicked");
  };

  return (
    <div
      className="content-body p-0"
      // style={{ marginBottom: "70px !important" }}
    >
      <div className="container-fluid text-center">
        <h1 className="text-center">Crypto Card</h1>
        <div className="row justify-content-md-center p-3">
          <div className="col-lg-6 col-md-8 col-12">
            {/* KYC Wizard */}
            <KYCWizard />

            <Card className={classes.card + " mt-3"}>
              <CardContent>
                <Typography className={classes.balanceTitle}>
                  Main Balance
                </Typography>
                <Typography className={classes.balance}>$1,234.56</Typography>
                <div className={classes.cardDetails}>
                  {!isMobile && (
                    <div>
                      <Typography className={classes.detailText}>
                        **** **** **** 1234
                      </Typography>
                    </div>
                  )}
                  <div>
                    <Typography className={classes.detailText}>
                      VALID THRU
                    </Typography>
                    <Typography className={classes.detailText}>
                      08/21
                    </Typography>
                  </div>
                  <div>
                    <Typography className={classes.detailText}>
                      CARD HOLDER
                    </Typography>
                    <Typography className={classes.detailText}>
                      Franklin Jr.
                    </Typography>
                  </div>
                  {isMobile && (
                    <div>
                      <Typography className={classes.detailText}>
                        **** **** **** 1234
                      </Typography>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>

            {/* Transactions Table */}
            <div className="col-xl-6 mt-4">
              <div className="card p-3">
                <div className="card-header text-center p-0 flex-wrap">
                  <h1>Transactions</h1>
                </div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>TXN</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions.map((row) => (
                      <TableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          {row.date}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.amount}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.type}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crypto_Card;
