import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Button } from "@material-ui/core";
import ApexLineChart from "./SwipeableChart";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 300,
    margin: "0 auto",
    marginTop: theme.spacing(2),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    // marginBottom: theme.spacing(2),
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
  dialogContent: {
    textAlign: "center", // Set text alignment to center
  },
  disabled_buttons: {
    background: "#afaeaa !important",
    color: "rgba(0, 0, 0, 0.26)",
  },
  btn_container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  withdraw_button: {
    display: "block",
    color: "black",
    background: "white",
    borderRadius: "12px",
    border: "1px solid var(--border-light)",
    transition: "border-color var(--transition)",
  },
  balance: {
    border: "1px solid #d4d4d4", // Added quotation marks around the value
    display: "flex",
    transition: "border-color var(--transition)",
    borderRadius: "12px", // Moved borderRadius up to match other properties
    alignItems: "center",
    justifyContent: "center",
  },
  flexcolumns: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Statistic() {
  const classes = useStyles();
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [isLoading, setIsLoading] = useState(true);
  const [Error, setError] = useState();
  const [user, setUser] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [originalDailyProfits, setOriginalDailyProfits] = useState({
    labels: [],
    datasets: [],
  });
  const [filteredDailyProfits, setFilteredDailyProfits] = useState({
    labels: [],
    datasets: [],
  });

  const filterDataByDateRange = (data, daysBack) => {
    console.log("filterDataByDateRange", data);
    console.log("filterDataByDateRange", daysBack);

    if (!daysBack) return data;

    const endDate = dayjs().endOf("day").valueOf(); // Set end date to the end of today
    const startDate = dayjs()
      .subtract(daysBack, "day")
      .startOf("day")
      .valueOf(); // Set start date to 'X' days back from today

    // Get indexes of labels within the date range
    const indexes = data.labels.flatMap((label, index) => {
      const time = dayjs(label).valueOf();
      return time >= startDate && time <= endDate ? index : [];
    });

    const filteredLabels = data.labels.filter((label) => {
      const time = dayjs(label).valueOf();
      return time >= startDate && time <= endDate;
    });

    const filteredDatasets = data.datasets.map((dataset, index) => {
      const filteredData = dataset.data.filter((_, index) => {
        const label = data.labels[index];
        const time = dayjs(label).valueOf();
        return time >= startDate && time <= endDate;
      });

      return {
        ...dataset,
        name: `Custom Name ${index + 1}`, // Add your custom name here
        data: filteredData,
      };
    });

    // Make sure the order is chronological before returning
    return {
      ...data,
      labels: filteredLabels, // Correct the order
      datasets: filteredDatasets.map((dataset) => ({
        ...dataset,
        data: dataset.data, // Correct the order
      })),
    };
  };

  const handleDateRangeSelection = (days) => {
    handleDateChange(days); // Pass the days directly
  };

  const handleDateChange = (days) => {
    // Filter originalDailyProfits and update filteredDailyProfits
    setDateRange([dayjs().subtract(days, "day"), dayjs()]); // Update date range state to reflect the selected range
    const filteredData = filterDataByDateRange(originalDailyProfits, days);
    setFilteredDailyProfits(filteredData);
  };

  const handleResetDateRange = () => {
    setDateRange([null, null]);
    // Reset filteredDailyProfits to the original data
    setFilteredDailyProfits(originalDailyProfits);
  };

  useEffect(() => {
    if (user) {
      axios
        .post(
          "https://test.croesus-asset.management/api/getProfitData",
          {
            user: user.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, Accept, Authorization",
            },
          }
        )
        .then((response) => {
          console.log("Response ProfitData:", response);
          console.log(response.data);

          const labels = response.data.labels; // Assuming these come in reversed order
          const datasets = response.data.datasets.map((dataset) => ({
            ...dataset,
            data: Array.isArray(dataset.data) ? dataset.data : [],
            // ... any other dataset properties
          }));

          const updatedChartData = {
            labels: labels.reverse(), // Reverse the array to correct the order
            datasets: datasets.map((dataset) => ({
              ...dataset,
              data: dataset.data.reverse(), // Reverse the dataset data array as well
            })),
          };

          setOriginalDailyProfits(updatedChartData);
          setFilteredDailyProfits(updatedChartData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [user]);

  useEffect(() => {
    if (isConnected) {
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            console.log("Error message:", errorMessage);
            if (errorMessage === "User not found") {
            }
            // You can handle/display the error message as per your requirement
          }
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected]);

  return (
    <div
      className="content-body p-0"
      style={{ minHeight: window.screen.height + 80 }}
    >
      <div className="container-fluid text-center mb-2">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-12">
            <div className="card p-3">
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Statistics</h3>
              </div>

              <div className="card-body mt-3 row" style={{ display: "block" }}>
                {isLoading ? (
                  <Typography variant="body1">Loading...</Typography>
                ) : (
                  <>
                    {/* <div>
                      <Line height={250} data={filteredDailyProfits} />
                    </div> */}
                    <ApexLineChart
                      filteredDailyProfits={filteredDailyProfits}
                    />
                    <Button
                      className={classes.nft_buttons + " mt-2"}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDateRangeSelection(7)}
                    >
                      7 Days
                    </Button>

                    <Button
                      className={classes.nft_buttons + " mt-2"}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDateRangeSelection(14)}
                    >
                      14 Days
                    </Button>
                    <Button
                      className={classes.nft_buttons + " mt-2"}
                      variant="contained"
                      color="primary"
                      onClick={() => handleDateRangeSelection(30)}
                    >
                      30 Days
                    </Button>
                    <Button
                      className={classes.nft_buttons + " mt-2"}
                      variant="contained"
                      color="primary"
                      onClick={handleResetDateRange}
                    >
                      YTD
                    </Button>
                  </>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistic;
