import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
  useContract,
} from "wagmi";
import {
  Card,
  CardContent,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { FARMING_ABI, FARMING_CONTRACT } from "./components/farmingcontract";

const FarmingAdmin = () => {
  const { address, connector, isConnected } = useAccount();
  const [nftsInFarming, setNftsInFarming] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwnerConnected, setIsOwnerConnected] = useState(false);
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState(true); // true for ascending, false for descending
  const [sortedNfts, setSortedNfts] = useState([]);

  const { write: unstakeNFT } = useContractWrite({
    addressOrName: FARMING_CONTRACT,
    contractInterface: FARMING_ABI,
    functionName: "unstake",
  });

  const handleUnstake = async (nft) => {
    console.log("handleUnstake", nft);
    try {
      // Execute the unstake function with the required arguments
      const tx = await unstakeNFT({
        args: [nft.contract, Number(nft.tokenid), 11190000000000000000],
      });
      console.log("tx", tx);

      // Refresh your state or UI as needed
    } catch (err) {
      console.error("Error unstaking NFT:", err);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const toggleSortDirection = () => {
    setSortDirection(!sortDirection);
  };

  useEffect(() => {
    let sorted = [...nftsInFarming].sort((a, b) => (a.id > b.id ? 1 : -1));
    if (!sortDirection) sorted.reverse();
    setSortedNfts(sorted);
  }, [nftsInFarming, sortDirection]);

  useEffect(() => {
    // Function to fetch NFTs in farming from your API
    console.log("checkOwnerStatus Wallet : ", address);
    if (address === "0x5b5b0385BFe866bA7167Bdd356EBAB07ee8BDDC9") {
      setIsOwnerConnected(true);
    }

    const fetchNFTs = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          "https://test.croesus-asset.management/api/get_farming_nfts"
        );
        const fetchedNfts = response.data;
        console.log("Fetched NFTs: ", fetchedNfts);
        let sorted = [...fetchedNfts].sort((a, b) => (a.id > b.id ? 1 : -1));
        if (!sortDirection) sorted.reverse();
        setNftsInFarming(sorted);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNFTs();
  }, [isConnected]);

  useEffect(() => {
    let sorted = [...nftsInFarming].sort((a, b) => (a.id > b.id ? 1 : -1));
    if (!sortDirection) sorted.reverse();
    setSortedNfts(sorted);
  }, [nftsInFarming, sortDirection]);

  return (
    <Card className="m-3">
      <CardContent>
        <h1>Farming</h1>
        {isOwnerConnected ? (
          <>
            <h4>Owner Wallet Connected !</h4>
            <TextField
              className="m-3 "
              onChange={handleSearchChange}
              value={search}
              label="Search NFT USER "
              style={{ width: "200px" }}
            />

            {isLoading ? (
              <p>Loading NFTs...</p>
            ) : (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>NFT</TableCell>
                      <TableCell align="right">Name</TableCell>
                      <TableCell align="right">Token Id</TableCell>
                      <TableCell align="right">User</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedNfts
                      .filter((nft) =>
                        nft.user?.toLowerCase().includes(search.toLowerCase())
                      )
                      .map((nft) => (
                        <TableRow key={nft.id}>
                          <TableCell component="th" scope="row">
                            {nft.id}
                          </TableCell>
                          <TableCell align="right">{nft.name}</TableCell>
                          <TableCell align="right">{nft.tokenid}</TableCell>
                          <TableCell align="right">{nft.user}</TableCell>
                          <TableCell align="right">
                            <Button onClick={() => handleUnstake(nft)}>
                              Unstake
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <h4>
            Connect Owner Wallet 0x5b5b0385BFe866bA7167Bdd356EBAB07ee8BDDC9
          </h4>
        )}
      </CardContent>
    </Card>
  );
};

export default FarmingAdmin;
