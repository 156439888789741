import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Button } from "@material-ui/core";
import ApexLineChart from "./SwipeableChart";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import { Select, MenuItem } from "@material-ui/core";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Web3 from "web3";
import KYCForm from "./KYCFormCard";
import KYCWizard from "./KYCWizard";
import { MintCardNFTs } from "../pages/components/hooks/MintCardNFTs";
import { MintCardVIPNFT } from "../pages/components/hooks/MintCardVIPNFTs";
import CardWidget from "../pages/components/hooks/CardWidget";
import {
  NEWCOIN_ABI,
  NEWCOIN_CONTRACT,
} from "../pages/components/NewCoinContract";
import Spinner from "react-bootstrap/Spinner";
import vip_card from "../pages/components/images/vipcard_rm.jpg";
import premium_card from "../pages/components/images/premium_card_rm.jpg";
import standard_card from "../pages/components/images/standard_card_rm.jpg";
import {
  MARKETPLACE_ABI,
  MARKETPLACE_CONTRACT,
  USDT_CONTRACT,
} from "../marketplacecontracts";
import Alert from "react-bootstrap/Alert";

function Cards() {
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [localCardKycData, setLocalCardKycData] = useState(
    JSON.parse(localStorage.getItem("card_kyc_data")) || null
  );
  const [user, setUser] = useState([]);
  const [card_kyc_data, setcard_kyc_data] = useState([]);
  const [userCard, setCard] = useState([]);
  const [NFTCard, setNFTCard] = useState([]);
  const [NFTCardData, setNFTCardData] = useState([]);
  const [NFTCards, setNFTCards] = useState([]);
  const [cztpoolshares, setcztpoolshares] = useState(0);
  const [globalpoolshares, setglobalpoolshares] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState(null);
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [showForm, setShowForm] = useState(false);

  const [requirement1Fulfilled, setrequirement1Fulfilled] = useState(false);
  const [requirement2Fulfilled, setrequirement2Fulfilled] = useState(false);
  const [requirement3Fulfilled, setrequirement3Fulfilled] = useState(false);
  const [requirement4Fulfilled, setrequirement4Fulfilled] = useState(false);
  const [requirement5Fulfilled, setrequirement5Fulfilled] = useState(false);
  const [requirement6Fulfilled, setrequirement6Fulfilled] = useState(false);

  const [requirementsforglobal, setrequirementsforglobal] = useState(false);
  const [requirementsforpremium, setrequirementsforpremium] = useState(false);
  const [requirementsforCZT, setrequirementsforCZT] = useState(false);

  const [crgBalance, setcrgBalance] = useState(0);
  const [usdtbalance, setusdtbalance] = useState(0);

  const [crgMaxSupply, setcrgMaxSupply] = useState(0);
  const [crgTotalSupply, setcrgTotalSupply] = useState(0);
  const [crgPrice, setcrgPrice] = useState(0);
  const [totalmaxsupply, settotalmaxsupply] = useState(0);
  const [count_vipcard_nfts, setcount_vipcard_nfts] = useState(0);

  const [shippingsaved, setshippingsaved] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoadingShipping, setIsLoadingShipping] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [formData, setFormData] = useState({
    userId: localUser?.id,
    address: localUser?.shipping
      ? localUser?.shipping.address
      : localUser?.address,
    city: localUser?.shipping ? localUser?.shipping.city : localUser?.city,
    state: localUser?.shipping ? localUser?.shipping.state : localUser?.state,
    zip: localUser?.shipping ? localUser?.shipping.zip : localUser?.zip,
    country: localUser?.shipping
      ? localUser?.shipping.country
      : localUser?.country,
  });

  let globalbonuspoolcount = 0;

  useEffect(() => {
    console.log("useEffect Localuser", localUser);
    if (localUser) {
      // console.log("set user", JSON.stringify(user));
      localStorage.setItem("user", JSON.stringify(localUser));
      localStorage.setItem("userCard", JSON.stringify(userCard));
      localStorage.setItem("card_kyc_data", JSON.stringify(card_kyc_data));
      if (localUser?.shipping !== null) {
        setshippingsaved(true);
      }
      if (localUser?.set_gbp == 1) {
        setrequirementsforglobal(true);
      }
      setShowForm(true);
    }
  }, [localUser, card_kyc_data, userCard]);

  //GET USER DATA
  useEffect(() => {
    if (isConnected) {
      setisLoading(true); // Start loading
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("useEffect", response.data);
          setUser(response.data.user);
          setcard_kyc_data(response.data.card_kyc_data);
          setCard(response.data.card);
          console.log("Card NFT", response.data.card_nft);
          setNFTCard(response.data.card_nft);
          setLocalUser(response.data.user);

          if (response.data.nftCounts["I-Charge"].overall >= 30) {
            setrequirement2Fulfilled(true);
          }
          if (response.data.nftCounts["I-Charge"].overall >= 10) {
            setrequirement4Fulfilled(true);
          }
          if (parseInt(response.data.user.crg_balance) >= 75) {
            setrequirement5Fulfilled(true);
          }
          if (response.data.nftCounts["The Croesus Maxim"].overall >= 1) {
            setrequirement6Fulfilled(true);
          }
          if (response.data.nftCounts["A-Alpha"].overall >= 3) {
            setrequirement3Fulfilled(true);
          }

          if (
            response.data.nftCounts["I-Charge"].overall >= 30 &&
            response.data.nftCounts["A-Alpha"].overall >= 3 &&
            parseInt(response.data.user.crg_balance) >= 75 &&
            response.data.nftCounts["The Croesus Maxim"].overall >= 1
          ) {
            setrequirementsforglobal(true);
          }

          if (user?.set_gbp == 1) {
            setrequirementsforglobal(true);
          }

          if (
            response.data.nftCounts["I-Charge"].overall >= 10 &&
            parseInt(response.data.user.crg_balance) >= 10
          ) {
            setrequirementsforpremium(true);
          }

          if (parseInt(response.data.user.crg_balance) >= 10) {
            setrequirement1Fulfilled(true);
          }
          fetchCoinDetails();

          setcztpoolshares(response.data.czt_shares);

          console.log("Set globalpoolshares", response.data.globalbonuspool);
          setglobalpoolshares(response.data.globalbonuspool);

          setcount_vipcard_nfts(response.data.count_vipcard_nfts);

          setisLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  const nftCards = [
    {
      id: 3,
      name: "VIP Card",
      image: vip_card,
      price: "2000",
      quantity: 100,
      mintCondition: "Condition 3",
    },
    {
      id: 2,
      name: "Premium Card",
      image: premium_card,
      price: "400",
      quantity: 500,
      mintCondition: "Condition 2",
    },
    {
      id: 1,
      name: "Standard Card",
      image: standard_card,
      price: "100",
      quantity: "",
      mintCondition: "Condition 1",
    },
  ];

  useEffect(() => {
    if (NFTCard !== null) {
      console.log("useEffect NFTCard");

      let matchingNFTCards = []; // Stores all matching cards

      NFTCard.forEach((cardItem) => {
        let name;

        if (cardItem.contract_id === "13") {
          name = "Standard Card";
        } else if (cardItem.contract_id === "14") {
          name = "Premium Card";
        } else if (cardItem.contract_id === "15") {
          name = "VIP Card";
        }

        const card = nftCards.find((nftCard) => nftCard.name === name);
        console.log("card:", card);

        if (card) {
          // Add cardItem content to the found card before pushing it into matchingNFTCards
          const updatedCard = { ...card, ...cardItem };
          matchingNFTCards.push(updatedCard);
        }
      });

      setNFTCards(matchingNFTCards);
    }
  }, [NFTCard]);

  const fetchCoinDetails = async () => {
    try {
      const CoinData = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "checkAirdrop",
        args: [walletAddress],
      });
      console.log("CoinData:", CoinData);

      const CRGBalance = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("CRGBalance:", CRGBalance);
      const CRGBalance_InEther = Web3.utils.fromWei(
        String(CRGBalance),
        "ether"
      );
      setcrgBalance(Number(CRGBalance_InEther));

      const USDTBalance = await readContract({
        address: USDT_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("USDTBalance:", USDTBalance);
      const USDTBalance_InEther = Web3.utils.fromWei(
        String(USDTBalance),
        "ether"
      );
      setusdtbalance(Number(USDTBalance_InEther));

      const CRGMaxSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "maxSupply",
      });
      const CRGMaxSupply_InEther = Web3.utils.fromWei(
        String(CRGMaxSupply),
        "ether"
      );
      console.log("CRGMaxSupply:", CRGMaxSupply);
      setcrgMaxSupply(CRGMaxSupply);

      const CRGTotalSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "totalSupply",
      });
      const CRGTotalSupply_InEther = Web3.utils.fromWei(
        String(CRGTotalSupply),
        "ether"
      );
      console.log("CRGTotalSupply:", CRGTotalSupply);
      setcrgTotalSupply(CRGTotalSupply);

      // Add checks for division by zero
      if (CRGMaxSupply_InEther - CRGTotalSupply_InEther !== 0) {
        const CRG_Price =
          10000000 / (CRGMaxSupply_InEther - CRGTotalSupply_InEther);
        console.log("CRG_Price:", CRG_Price);
        setcrgPrice(CRG_Price.toFixed(2));
      } else {
        console.log("Cannot divide by zero. Check your inputs.");
      }

      const totalmaxsupply = CRGMaxSupply - CRGTotalSupply;
      settotalmaxsupply(totalmaxsupply);

      console.log("totalmaxsupply ! ", totalmaxsupply);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const nameRegex = /^[a-zA-Z\s-]+$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex = /^[0-9]{9,15}$/;
  const callingCodeRegex = /^[0-9]{3}$/;

  const onlynumbers = /^[0-9]{3,15}$/;

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
      case "country":
        return nameRegex.test(value) && value.length <= 22;

      case "email":
        return emailRegex.test(value) && value.length >= 3;
      case "phone":
      case "cellPhoneNumber":
        return phoneRegex.test(value);
      case "callingCode":
        return callingCodeRegex.test(value) && value.length === 3;

      case "city":
        return nameRegex.test(value) && value.length >= 3;

      case "zip":
        return onlynumbers.test(value) && value.length >= 3;

      case "state":
        return value.length >= 2 && value.length <= 25;
      // Add other field validations here...
      default:
        return true;
    }
  };

  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    setIsLoadingShipping(true);

    try {
      const cardHolderId = localUser?.cardHolderId;

      const shippingData = {
        userId: localUser?.id,
        card_holder_id: cardHolderId,
        ...formData,
      };
      console.log("shippingData", shippingData);

      const response = await fetch(
        "https://test.croesus-asset.management/api/update_shipping",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(shippingData),
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("Shipping information updated successfully", data.message);
        setshippingsaved(true);
        setIsLoadingShipping(false);
      } else {
        console.error("Failed to update shipping information", data.error);
        setIsLoadingShipping(false);
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    const mapObject = {
      ä: "ae",
      ö: "oe",
      ü: "ue",
      ß: "ss",
    };

    if (
      name === "firstName" ||
      name === "lastName" ||
      name === "state" ||
      name === "address" ||
      name === "city"
    ) {
      // Apply transformation
      value = value.replace(/ä|ö|ü|ß/gi, (matched) => mapObject[matched]);
    }

    const mapPhone = {
      "+": "00",
    };
    if (name === "phone") {
      value = value.replace(/\+/gi, (matched) => mapPhone[matched]);
    }
    const isValid = validateField(name, value);

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: isValid ? "" : "Invalid input" });
  };
  const handleCountryChange = (e) => {
    console.log("handleCountryChange", e.target.value);
    const newCountry = e.target.value;
    setSelectedCountry(newCountry);
    setFormData({ ...formData, country: newCountry, state: "" });
  };

  return (
    <div
      className="pt-3"
      style={{ minHeight: window.screen.height + 80, paddingTop: 0 }}
    >
      <div className="container-fluid text-center mb-4">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            {NFTCard.length > 0 && (
              <div className="card p-3" style={{ height: "auto" }}>
                {card_kyc_data?.isKycAccepted !== "true" && (
                  <>
                    <div className="card-header text-center p-0 flex-wrap">
                      <h3>
                        You need to complete kyc to order your physical card{" "}
                      </h3>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      className="mt-3"
                      onClick={() => setShowForm(true)}
                    >
                      Complete KYC
                    </Button>
                  </>
                )}

                {showForm && (
                  <div className="card-body">
                    <KYCWizard
                      NFTCard={NFTCard}
                      card_kyc_data={card_kyc_data}
                    />
                  </div>
                )}

                {card_kyc_data?.isKycAccepted === "true" && (
                  <div className="card">
                    <div className="card-body">
                      <h3 className="text-black text-center mb-3">
                        Shipping Address Information
                      </h3>
                      {user?.isKycAccepted === "false" ? (
                        <Alert variant="danger">
                          To enter your shipping address you have to wait until
                          your kyc has been approved
                        </Alert>
                      ) : (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-6 mb-2">
                              <div className="form-group mb-3">
                                <label className="text-label  text-black">
                                  Address Line*
                                </label>
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control text-black"
                                  onChange={handleChange}
                                  value={formData.address}
                                  disabled={shippingsaved}
                                  style={shippingsaved ? { opacity: 0.6 } : {}}
                                  required
                                />
                                {errors.address && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.address}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="form-group mb-3">
                                <label className="text-label  text-black">
                                  City*
                                </label>
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control text-black"
                                  onChange={handleChange}
                                  value={formData.city}
                                  disabled={shippingsaved}
                                  style={shippingsaved ? { opacity: 0.6 } : {}}
                                  required
                                />
                                {errors.city && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.city}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 mb-2">
                              <div className="form-group mb-3">
                                <label className="text-label  text-black">
                                  Country*
                                </label>
                                <input
                                  className="form-control text-black"
                                  type="text"
                                  name="country"
                                  value={selectedCountry}
                                  onChange={handleCountryChange}
                                  disabled={shippingsaved}
                                  style={shippingsaved ? { opacity: 0.6 } : {}}
                                />
                                {errors.country && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.country}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="form-group mb-3">
                                <label className="text-label  text-black">
                                  State*
                                </label>
                                <input
                                  name="state"
                                  className="form-control text-black"
                                  type="text"
                                  value={formData.state}
                                  onChange={handleChange}
                                  disabled={shippingsaved}
                                  style={shippingsaved ? { opacity: 0.6 } : {}}
                                  required
                                />
                                {errors.state && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.state}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="form-group mb-3">
                                <label className="text-label  text-black">
                                  Postal Code*
                                </label>
                                <input
                                  type="text"
                                  name="zip"
                                  className="form-control text-black"
                                  onChange={handleChange}
                                  value={formData.zip}
                                  disabled={shippingsaved}
                                  style={shippingsaved ? { opacity: 0.6 } : {}}
                                  required
                                />
                                {errors.zip && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ display: "block" }}
                                  >
                                    {errors.zip} (only numbers allowed)
                                  </div>
                                )}
                              </div>
                            </div>

                            {shippingsaved && (
                              <div className="p-3">
                                <Alert variant="success text-center">
                                  Shipping information saved successfully.
                                </Alert>
                              </div>
                            )}
                            {!shippingsaved && (
                              <div
                                className="col-lg-12 "
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary "
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    minWidth: "100px",
                                  }}
                                >
                                  {isLoadingShipping ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="me-2"
                                      />
                                    </>
                                  ) : (
                                    <div>Submit</div>
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {NFTCards.length > 0 && (
              <div className="card p-3" style={{ height: "auto" }}>
                <div className="card-header text-center p-0 flex-wrap">
                  {!NFTCards && <h3>Cards</h3>}
                </div>
                <div className="card-body">
                  <>
                    <Typography variant="h5">My Card NFTs</Typography>

                    {NFTCards.map((card, index) => (
                      // key is given a unique value - here it is the index of map function
                      <div key={index}>
                        <br />
                        <Typography variant="h6">{card.name}</Typography>
                        <Typography variant="h6">#{card.tokenid}</Typography>
                        <img
                          src={card.image}
                          alt={card.name}
                          style={{ width: "80%" }}
                        />
                      </div>
                    ))}
                  </>
                </div>
              </div>
            )}

            <div className="card p-3" style={{ height: "auto" }}>
              <div className="card-header text-center p-0 flex-wrap">
                {!NFTCards && <h3>Cards</h3>}
              </div>
              <div className="card-body">
                <>
                  {/* {card_kyc_data?.isKycAccepted === "true" ? (
                  user?.card?.card_type !== "0" ? ( */}

                  {card_kyc_data?.isKycAccepted !== "true" ? (
                    user?.card !== null && user?.card !== undefined ? (
                      <>
                        <h2>Card Widget {user?.card}</h2>
                        <CardWidget
                          user={user}
                          userCard={userCard}
                          card_kyc_data={card_kyc_data}
                        />
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}

                  {nftCards.map((card) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={card.id}
                      className="mt-2"
                    >
                      {/* <Card> */}
                      <CardContent>
                        <Typography variant="h5">{card.name}</Typography>
                        <img
                          src={card.image}
                          alt={card.name}
                          style={{ width: "100%" }}
                        />
                        {/* <div
                              className="container mb-2"
                              style={{
                                height: "170px",
                                background: "black",
                                borderRadius: "10px",
                              }}
                            ></div> */}
                        {card.id === 3 ? (
                          <Typography variant="h6">
                            {card.quantity - count_vipcard_nfts} /{" "}
                            {card.quantity}
                          </Typography>
                        ) : (
                          <Typography variant="h6">
                            {card.price} USDT
                          </Typography>
                        )}
                        {/* <Typography variant="h6">
                            Minting Condition: {card.mintCondition}
                          </Typography> */}
                        {card.id === 1 ? (
                          <>
                            {/* <Button
                                  variant="contained"
                                  color="primary"
                                  className="mt-3"
                                >
                                  Mint
                                </Button> */}
                            <MintCardNFTs
                              walletAddress={walletAddress}
                              card={card.id}
                              totalmaxsupply={totalmaxsupply}
                            />
                          </>
                        ) : (
                          <>
                            {card.id === 2 ? (
                              <>
                                <div
                                  className="d-flex "
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <h6 className="p-3 pb-0">At Least :</h6>
                                  <div>
                                    <Checkbox
                                      checked={requirement1Fulfilled}
                                      style={{
                                        color:
                                          requirement1Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>10x CRG</span>
                                  </div>
                                  <div>
                                    <Checkbox
                                      checked={setrequirement4Fulfilled}
                                      style={{
                                        color:
                                          requirement4Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>10x I-Charge NFT</span>
                                  </div>
                                </div>
                                <MintCardNFTs
                                  walletAddress={walletAddress}
                                  card={card.id}
                                  totalmaxsupply={totalmaxsupply}
                                  requirementsforpremium={
                                    requirementsforpremium
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <div
                                  className="d-flex "
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <h6 className="p-3 pb-0">At least :</h6>
                                  <div>
                                    <Checkbox
                                      checked={requirement3Fulfilled}
                                      style={{
                                        color:
                                          requirement3Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>3x Alpha NFT</span>
                                  </div>
                                  <div>
                                    <Checkbox
                                      checked={requirement2Fulfilled}
                                      style={{
                                        color:
                                          requirement2Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>30x I-Charge</span>
                                  </div>
                                  <div>
                                    <Checkbox
                                      checked={requirement5Fulfilled}
                                      style={{
                                        color:
                                          requirement5Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>75x CRG</span>
                                  </div>
                                  <div>
                                    <Checkbox
                                      checked={requirement6Fulfilled}
                                      style={{
                                        color:
                                          requirement6Fulfilled == true
                                            ? "#01b35c"
                                            : "#000000",
                                      }}
                                    />
                                    <span>1x Croesus Maxim Book</span>
                                  </div>
                                </div>
                                {requirementsforglobal && (
                                  <h6 className="p-3 pb-0">
                                    Special Mint Conditions :
                                  </h6>
                                )}

                                {totalmaxsupply !== 0 && (
                                  <MintCardVIPNFT
                                    walletAddress={walletAddress}
                                    card={card.id}
                                    totalmaxsupply={totalmaxsupply}
                                    crgprice={crgPrice}
                                    crgbalance={crgBalance}
                                    GBP={user.set_gbp}
                                    usdtbalance={usdtbalance}
                                    cztpoolshares={cztpoolshares}
                                    globalpoolshares={globalpoolshares}
                                    requirementsforglobal={
                                      requirementsforglobal
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  ))}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
