import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./styles.module.css";
import { ClassicSpinner } from "react-spinners-kit";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Tooltip,
} from "@material-ui/core";
import { useAccount, useConnect, useEnsName, useSignMessage } from "wagmi";
import logo from "../../images/logo-full_horizontal.png";
import LinkIcon from "@material-ui/icons/Link";
import { Button, TextField, Box } from "@mui/material";

import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

const GetKYCForm = async () => {
  const [isLoading, setIsLoading] = useState(false);

  const url =
    "https://kyc-api.amlbot.com/forms/5861287f0b54e64cda2ac51751451a242700/urls";
  const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0"; // Replace with your actual API token
  const applicantData = {
    applicant_id: "4b89e9d301a1a6444a18ae726ef1d51863f0", // Replace with actual data
    external_applicant_id: "123456",
    redirect_url: "https://discover.croesus-asset.management/dashboard",
    wallet_address: "0xa0a5f7d180CB5aa94B3eb9E66842Cb8E9F4852D8",
  };

  try {
    setIsLoading(true);
    const response = await axios.post(url, applicantData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${apiToken}`,
      },
    });
    console.log(response.data);
  } catch (error) {
    console.error(`Error creating applicant: ${error}`);
  } finally {
    setIsLoading(false);
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: window.screen.height + 20,
  },
  paper: {
    width: 300,
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    backgroundColor: "#424242",
  },
  title: {
    fontWeight: "bold",
    textAlign: "center",
  },
  label: {
    margin: theme.spacing(2, 0, 1),
  },
  value: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
  },
  loading: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  checkIcon: {
    color: "green",
    marginRight: theme.spacing(1),
  },
  cancelIcon: {
    color: "red",
    marginRight: theme.spacing(1),
  },
  refreshIcon: {
    marginRight: theme.spacing(1),
  },
}));

const KYCForm = () => {
  const navigate = useNavigate();
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [middleName, setMiddleName] = useState("");
  // const [dob, setDob] = useState("");
  // Add more state variables as needed for other fields
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false); // added new state for update status loading
  const [kyclink, setkyclink] = useState();
  const [status, setStatus] = React.useState(null);
  const { address, connector, isConnected } = useAccount();
  const [user, setUser] = useState(null);
  const [applicantID, setapplicantID] = useState("0");

  const url =
    "https://kyc-api.amlbot.com/forms/5861287f0b54e64cda2ac51751451a242700/urls";
  const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0"; // Replace with your actual API token
  const applicantData = {
    applicant_id: applicantID, // Replace with actual data
    external_applicant_id: "123456",
    redirect_url: "https://discover.croesus-asset.management/dashboard",
  };
  useEffect(() => {
    if (isConnected) {
      console.log("isConnected KYC1");
      console.log("applicantID ", applicantID);
      console.log(user);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${address}`
        )
        .then((response) => {
          console.log(response.data);
          setUser(response.data);
          console.log(response.data.kyc);
          setStatus(response.data.kyc);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, address]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // perform fetch request here
      const response = await axios.post(url, applicantData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${apiToken}`,
        },
      });
      console.log("fetchData", response.data);
      setkyclink(response.data.form_url);

      const metadata = {
        status: user.kyc,
        verificationID: response.data.verification_id,
        userID: user.id,
      };
      console.log("metadata", metadata);
      const response_api = await axios.post(
        "https://test.croesus-asset.management/api/setKYC",
        metadata
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      updateStatus();
      navigate(kyclink);
    }
  };

  const updateStatus = async () => {
    if (isConnected) {
      setIsLoadingStatus(true);
      console.log("isConnected KYC2");
      console.log(user);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${address}`
        )
        .then((response) => {
          console.log(response.data);
          setUser(response.data);
          console.log(response.data.kyc);
          console.log("verificationid", response.data.verificationid);
          setStatus(response.data.kyc);
          setIsLoadingStatus(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoadingStatus(false);
        });
    } else {
      console.log("disconnected");
      setIsLoadingStatus(false);
    }
  };

  const testKYC = async () => {
    const url = "https://kyc-api.amlbot.com/applicants";
    const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0";

    const applicantData = {
      type: "PERSON",
      first_name: "John",
      last_name: "Doe",
      dob: "1970-10-25",
    };

    try {
      const response = await axios.post(url, applicantData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${apiToken}`,
        },
      });
      console.log(response.data);
      setapplicantID(response.data.applicant_id);
      fetchData();
    } catch (error) {
      console.error(`Error creating applicant: ${error}`);
    }
  };

  const getVerification = async () => {
    setIsLoadingStatus(true);
    const apiToken = "9b3961c60f52d6449d18e7a23d91ebefafc0";

    try {
      const response = await axios.get(
        `https://kyc-api.amlbot.com/verifications/${user.verificationid}`,
        {
          headers: {
            Authorization: apiToken,
          },
        }
      );

      console.log("getVerification", response.data);
      // setUser(response.data);
      // console.log(response.data.kyc);
      console.log(response.data.status);
      setStatus(response.data.status);

      const metadata = {
        status: response.data.status,
        verificationID: user.verificationid,
        userID: user.id,
      };
      console.log("metadata", metadata);
      const response_api = await axios.post(
        "https://test.croesus-asset.management/api/setKYC",
        metadata
      );
      setIsLoadingStatus(false);
    } catch (error) {
      console.error(`Error getting verification: ${error}`);
      setIsLoadingStatus(false);
    }
  };

  return (
    <div className="content-body p-0">
      <div className={classes.root}>
        <div className="container-fluid text-center mb-2">
          {/* <Link to={"#"}>
              <img className="logo-abbr dark-logo" width="200" src={logo} alt="" />
              <img className="logo-abbr light-logo text-center m-auto" width="200" src={logo} alt="" />
            </Link> */}

          {/* {status !== 'completed' && (
            <div className="row mt-4">
              <div className="col-xl-8" >
                <div className="card text-center">
                  <div className="card-header text-center pb-0 border-0 flex-wrap mb-3">
                    <Typography variant="h5" className={classes.title}>
                    Get KYC Verification Link
                    </Typography>                
                  </div>	              
                  <div className="card-body text-center pt-0 pb-2">

                    {applicantID === '0' && (

                      <Button style={{ marginBottom: '10px' }} fullWidth={true} variant="contained" onClick={testKYC}>
                        Complete KYC
                      </Button>

                    )}
                    {applicantID !== '0' && (
                    <>
                      Applicant ID : {applicantID}

                      <Button style={{ marginBottom: '10px' }} fullWidth={true} variant="contained" onClick={fetchData} disabled={kyclink}>
                        2. Generate KYC Link
                      </Button>

                    </>
                    )}
                    <div className={classes.loading}>
                      {isLoading && <CircularProgress size={50} />}
                    </div>
                    {kyclink && (
                      <a className="btn btn-dark btn-block mb-1 mt-2" href={kyclink} target="_blank" rel="noreferrer">
                        <Typography variant="subtitle1" className={classes.label}>
                          Open KYC form in a new tab
                        <LinkIcon style={{ marginLeft: '5px' }} />
                        </Typography>
                      </a>
                    )}

                  </div>
                </div>	
              </div>	
            </div> 
            )} */}

          <div className="row mt-4">
            <div className="col-xl-8">
              <div className="card ">
                <div className="card-header  text-center pb-0 border-0 flex-wrap mb-3">
                  <Typography variant="h5" className={classes.title}>
                    KYC Status
                  </Typography>
                </div>
                <div className="card-body text-center pt-0 pb-2">
                  {applicantID === "0" && (
                    <Button
                      style={{ marginBottom: "10px" }}
                      fullWidth={true}
                      variant="contained"
                      onClick={testKYC}
                    >
                      Complete KYC
                    </Button>
                  )}
                  {status ? (
                    <>
                      <Typography
                        variant="body1"
                        className={classes.value}
                        style={{
                          color: status === "completed" ? "#36D7B7" : "#FF5C5C",
                          marginTop: "2rem",
                        }}
                      >
                        {status === "false"
                          ? "UNVERIFIED"
                          : status.toUpperCase()}
                        {status === "completed" ? (
                          <span
                            style={{ color: "#36D7B7", marginRight: "0.5rem" }}
                          >
                            &#10003;
                          </span>
                        ) : (
                          <span
                            style={{ color: "#FF5C5C", marginRight: "0.5rem" }}
                          >
                            &#10007;
                          </span>
                        )}
                      </Typography>

                      <div className={classes.loading}>
                        {isLoadingStatus && (
                          <CircularProgress size={24} className="mb-3" />
                        )}{" "}
                        {/* render CircularProgress for updateStatus loading */}
                      </div>

                      {/* {user.verificationid === 'NULL' ? (
                        <Button style={{ marginBottom: '10px' }} fullWidth={true} variant="contained" onClick={updateStatus} disabled={isLoadingStatus}>
                          {isLoadingStatus ? 'Updating...' : 'Update Status'} SS
                        </Button>
                      ) : (
                        <Button style={{ marginBottom: '10px' }} fullWidth={true} variant="contained" onClick={getVerification} disabled={isLoadingStatus}>
                          {isLoadingStatus ? 'Updating...' : 'Update Status'}
                        </Button>
                      )} */}
                    </>
                  ) : (
                    <>
                      {/* <Typography variant="subtitle1" className={classes.label}>
                        Receiving Status.
                      </Typography>
                      <div className={classes.loading}>
                        <CircularProgress size={24} />
                      </div>
                      <Button style={{ marginBottom: '10px' }} fullWidth={true} variant="contained" onClick={updateStatus} disabled={isLoadingStatus}>
                        {isLoadingStatus ? 'Updating...' : 'Update Status'}
                      </Button> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <Card className={classes.card}>
              <CardHeader
                title="Get KYC Verification Link"
                subheader="Follow these steps to get your KYC link."
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Button variant="outlined" color="primary" className={classes.button} startIcon={<AddCircleOutlineIcon />} onClick={testKYC}>
                      Generate Applicant ID
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    {kyclink ? (
                      <Button variant="outlined" color="primary" className={classes.button} startIcon={<LinkIcon />} disabled>
                        Generate KYC Link
                      </Button>
                    ) : (
                      <Button variant="outlined" color="primary" className={classes.button} startIcon={<LinkIcon />} onClick={fetchData}>
                        Generate KYC Link
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip title="Open KYC form in a new tab" placement="right">
                      <Link to={{ pathname: kyclink }} target="_blank" className={classes.link}>
                        <Button variant="outlined" color="primary" className={classes.button} startIcon={<OpenInNewIcon />} disabled={!kyclink}>
                          Open KYC Form
                        </Button>
                      </Link>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" color="primary" className={classes.button} startIcon={<RefreshIcon />} onClick={fetchData}>
                      Refresh Status
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{color: 'black'}}>
                      KYC Status: 
                      {status === 'completed' ? (
                        <>
                        <Tooltip title="KYC Verified" placement="right">
                          <CheckCircleIcon className={classes.checkIcon} />
                        </Tooltip>
                        </>
                      ) : ( 
                        <>
                        <Tooltip title="KYC Not Verified" placement="right">
                          <CancelIcon className={classes.cancelIcon} />
                        </Tooltip>
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card> */}
        </div>
      </div>
    </div>
  );
};

export default KYCForm;

{
  /* <div className={styles['form-group']}>
                <label htmlFor="firstName">First Name:</label>
                <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Enter your first name" />
                </div>

                <div className={styles['form-group']}>
                <label htmlFor="middleName">Middle Name:</label>
                <input type="text" id="middleName" value={middleName} onChange={(e) => setMiddleName(e.target.value)} placeholder="Enter your middle name" />
                </div>

                <div className={styles['form-group']}>
                <label htmlFor="lastName">Last Name:</label>
                <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Enter your last name" />
                </div>

                <div className={styles['form-group']}>
                <label htmlFor="dob">Date of Birth:</label>
                <input type="date" id="dob" value={dob} onChange={(e) => setDob(e.target.value)} placeholder="Enter your date of birth" />
                </div> */
}

{
  /* Add more input fields as necessary for other data */
}
