import React, { useState, useEffect } from "react";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
} from "wagmi";
import { STANDARD_ABI, STANDARD_CONTRACT } from "../../components/standardnft";
import { PREMIUM_ABI, PREMIUM_CONTRACT } from "../../components/premiumnft";
import { usdtabi } from "../../components/content/usdtabi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { utils } from "ethers";
import { watchContractEvent } from "@wagmi/core";
import axios from "axios";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Link, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { VIP_CONTRACT } from "../vipnft";

const useStyles = makeStyles((theme) => ({
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));

const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

export function MintCardNFTs({
  walletAddress,
  card,
  totalmaxsupply,
  requirementsforpremium,
}) {
  console.log("totalmaxsupply", totalmaxsupply);
  const navigate = useNavigate();
  const [loadingMint, setloadingMint] = useState(false);
  const [mintMessage, setmintMessage] = useState("");
  const [usdtisapproved, setusdtisapproved] = useState(false);
  const [usdtApproved, setusdtApproved] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [usdtBalanceWei, setUsdtBalanceWei] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  console.log("MindCardNFT");
  const classes = useStyles();
  console.log("walletAddress", walletAddress);
  console.log("card", card);

  useEffect(() => {
    if (walletAddress) {
      const fetchUsdtBalance = async () => {
        try {
          const balance = await readContract({
            address: "0x55d398326f99059fF775485246999027B3197955", // USDT Contract Address
            abi: usdtabi,
            functionName: "balanceOf",
            args: [walletAddress],
          });
          console.log("balance", balance);
          setUsdtBalance(Web3.utils.fromWei(balance, "ether"));
          setUsdtBalanceWei(balance);
        } catch (error) {
          console.log("Failed to fetch USDT balance:", error);
        }
      };

      fetchUsdtBalance();
    }
  }, [walletAddress]);

  const isapproved = async () => {
    setloadingMint(true);
    try {
      const allowance = await readContract({
        address: "0x55d398326f99059fF775485246999027B3197955",
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, STANDARD_CONTRACT],
      });
      console.log("allowance:", allowance);
      setusdtApproved(allowance);

      // Return an object with 'allowance' value and 'isSuccess' flag set to true.
      return {
        isSuccess: true,
        allowance: allowance,
      };
    } catch (error) {
      console.log(error);

      // In case of any errors, return an object with 'isSuccess' flag set to false.
      return {
        isSuccess: false,
        allowance: null,
      };
    }
  };

  let NFT_CONTRACT;
  let MINT_AMOUNT;
  if (card === 1) {
    NFT_CONTRACT = STANDARD_CONTRACT;
    MINT_AMOUNT = 100;
    // MINT_AMOUNT = 0.01;
  } else if (card === 2) {
    NFT_CONTRACT = PREMIUM_CONTRACT;
    MINT_AMOUNT = 400;
  } else if (card === 3) {
    NFT_CONTRACT = VIP_CONTRACT;
    MINT_AMOUNT = 2000;
  }

  // const handleMintButton = async () => {
  //   console.log("handleMintButton");
  //   setloadingMint(true);

  //   // Must return a promise for await to work.
  //   const result = await isapproved();

  //   // Now act based on the result of 'isapproved'.
  //   if (result.isSuccess) {
  //     // You now have allowance value directly from the result object
  //     const allowance = result.allowance;

  //     if (allowance <= Web3.utils.toWei(String(MINT_AMOUNT), "ether")) {
  //       approveusdt();
  //     } else {
  //       handlewritefunction();
  //     }
  //   } else {
  //     console.log("Approval failed.");
  //   }
  // };

  const handleMintButton = async () => {
    setloadingMint(true);
    if (!usdtisapproved) {
      await checkAndApproveUSDT();
    } else {
      handlewritefunction();
    }
  };

  useEffect(() => {
    const checkUsdtApproval = async () => {
      const allowance = await readContract({
        address: "0x55d398326f99059fF775485246999027B3197955",
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, NFT_CONTRACT],
      });
      const mintAmountWei = Web3.utils.toWei(String(MINT_AMOUNT), "ether");
      const allowanceWei = Web3.utils.toWei(String(allowance), "ether");
      console.log("allowanceWei", allowanceWei);
      console.log("mintAmountWei", mintAmountWei);
      if (allowanceWei >= mintAmountWei) {
        setusdtisapproved(true);
      } else {
        setusdtisapproved(false);
      }
    };

    checkUsdtApproval();
  }, [walletAddress, MINT_AMOUNT]);

  useEffect(() => {
    if (usdtApproved >= Web3.utils.toWei(String(MINT_AMOUNT), "ether")) {
      console.log("enough usdt approved");
      setusdtisapproved(true);
    } else {
      console.log("not enough usdt approved");
      setusdtisapproved(false);
    }
  }, [usdtApproved]);

  const { config } = usePrepareContractWrite({
    address: NFT_CONTRACT,
    abi: STANDARD_ABI,
    functionName: "mint",
    args: [totalmaxsupply],
    account: walletAddress,
    onError(error) {
      setloadingMint(false);
    },
  });
  const { data, error: mintapprove, write } = useContractWrite(config || {});

  const {
    data: mintdatareceipt,
    isLoading: mintisPending,
    isSuccess: mintisSuccess,
  } = useWaitForTransaction({
    hash: data?.hash,
    onError(error) {
      setloadingMint(false);
    },
    onSuccess(data) {},
  });

  const handlewritefunction = () => {
    try {
      write();
    } catch (error) {
      setloadingMint(false);
    }

    const unwatch = watchContractEvent(
      {
        address: NFT_CONTRACT,
        abi: STANDARD_ABI,
        eventName: "Minted",
      },
      (log) => {
        console.log("EventLog", log);
        const tokenIds = []; // Array to store unique tokenIds
        const users = []; // Array to store unique user values
        const txhashs = []; // Array to store unique user values

        for (let i = 0; i < log.length; i++) {
          const log_ = log[i];
          console.log("log", log_);
          const { args } = log_;
          const { transactionHash } = log_;
          console.log("transactionHash", transactionHash);
          console.log("args", args);
          const { tokenId, user } = args;
          console.log("tokenId", tokenId);
          console.log("user", user);

          if (!tokenIds.includes(tokenId)) {
            tokenIds.push(Number(tokenId));
          }

          if (!users.includes(user)) {
            users.push(user);
          }

          if (!txhashs.includes(transactionHash)) {
            txhashs.push(transactionHash);
          }
        }

        console.log("TokenIds", tokenIds);
        console.log("Users", users);
        unwatch();

        // Send tokenIds and users to your API using Axios POST
        axios
          .post("https://test.croesus-asset.management/api/submit_card_nft", {
            tokenIds,
            users,
            txhashs,
            card,
          })
          .then((response) => {
            console.log("API Response:", response.data);
            enqueueSnackbar("Mint Successful!", {
              variant: "success",
            });
            setloadingMint(false);

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            console.log("API Error:", error);
            setloadingMint(false);
          });
      }
    );
  };

  //WEB3 COMPONENTS
  //PREPARE APPROVE USDT
  const { config: usdtconfig } = usePrepareContractWrite({
    address: "0x55d398326f99059fF775485246999027B3197955",
    abi: usdtabi,
    functionName: "approve",
    args: [NFT_CONTRACT, Web3.utils.toWei(String(MINT_AMOUNT), "ether")],
    account: walletAddress,
    onError(error) {
      let errorstring = JSON.stringify(error);
      setloadingMint(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      // console.log("usdtconfig Success usePrepareContractWrite", data);
    },
  });
  // console.log("usdtconfig", usdtconfig);
  const {
    write: approveusdt,
    data: approvedata,
    error: errorapprove,
    isLoading: isLoadingapprove,
    isError: isErrorapprove,
  } = useContractWrite(usdtconfig);

  // useEffect(() => {
  //   setloadingMint(false);
  // }, [mintapprove]);

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: approvedata?.hash,
    onError(error) {
      setloadingMint(false);
      // console.log("usdtconfig usePrepareContractWrite", errorstring);
    },
    onSuccess(data) {
      enqueueSnackbar("USDT Approval Successful!", {
        variant: "success",
      });
      console.log("Success Approve", data);
      setusdtisapproved(true);
      handlewritefunction();
    },
  });

  useEffect(() => {
    if (approvedata && approvedatareceipt) {
      // Transaction was successful
      setusdtisapproved(true);
      enqueueSnackbar("USDT Approval Successful!", { variant: "success" });
      // handlewritefunction(); // Proceed to mint if approval was the only blocker
    }
  }, [approvedata, approvedatareceipt]);

  const checkAndApproveUSDT = async () => {
    setloadingMint(true);
    try {
      const allowance = await readContract({
        address: "0x55d398326f99059fF775485246999027B3197955",
        abi: usdtabi,
        functionName: "allowance",
        args: [walletAddress, NFT_CONTRACT],
      });
      const mintAmountWei = Web3.utils.toWei(String(MINT_AMOUNT), "ether");
      const allowanceAmountWei = Web3.utils.toWei(String(allowance), "ether");
      setusdtApproved(allowance);
      console.log("allowance:", allowance);
      console.log("mintAmountWei:", mintAmountWei);

      if (allowanceAmountWei < mintAmountWei) {
        // Not enough allowance, need to approve
        await approveusdt();
      } else {
        // Allowance is sufficient
        setusdtisapproved(true);
        handlewritefunction();
        setloadingMint(false);
      }
    } catch (error) {
      console.log("Approval check or transaction failed:", error);
      enqueueSnackbar("Error checking or approving USDT", { variant: "error" });
    }
  };

  return (
    <div>
      <Button
        className={classes.nft_buttons + " mt-3"}
        style={{ marginBottom: "20px", maxHeight: "33px" }}
        fullWidth={true}
        variant="contained"
        onClick={handleMintButton}
        disabled={
          loadingMint ||
          (!usdtisapproved &&
            parseFloat(usdtBalance) < parseFloat(MINT_AMOUNT)) ||
          (card === 2 && !requirementsforpremium)
        }
      >
        {loadingMint ? (
          <CircularProgress size={25} />
        ) : parseFloat(usdtBalance) < parseFloat(MINT_AMOUNT) ? (
          `Insufficient USDT`
        ) : !usdtisapproved ? (
          "Approve USDT"
        ) : (
          "BUY / Mint"
        )}
      </Button>
    </div>
  );
}

export default MintCardNFTs;
