import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Button } from "@material-ui/core";
import ApexLineChart from "./SwipeableChart";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import { Select, MenuItem } from "@material-ui/core";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Web3 from "web3";
import KYCForm from "./KYCFormCard";
import KYCWizard from "./KYCWizard";
import { MintCardNFTs } from "../pages/components/hooks/MintCardNFTs";
import { MintCardVIPNFT } from "../pages/components/hooks/MintCardVIPNFTs";
import CardWidget from "../pages/components/hooks/CardWidget";
import {
  NEWCOIN_ABI,
  NEWCOIN_CONTRACT,
} from "../pages/components/NewCoinContract";
import Spinner from "react-bootstrap/Spinner";
import vip_card from "../pages/components/images/vipcard_rm.jpg";
import premium_card from "../pages/components/images/premium_card_rm.jpg";
import standard_card from "../pages/components/images/standard_card_rm.jpg";
import {
  MARKETPLACE_ABI,
  MARKETPLACE_CONTRACT,
  USDT_CONTRACT,
} from "../marketplacecontracts";
import Alert from "react-bootstrap/Alert";
import { Table, InputGroup, FormControl } from "react-bootstrap";
import WalletOverview from "./components/WalletOverview/index";

function Physical() {
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [isLoading, setisLoading] = useState(true);
  const [userCard, setCard] = useState([]);
  const [card_kyc_data, setcard_kyc_data] = useState([]);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [localCardKycData, setLocalCardKycData] = useState(
    JSON.parse(localStorage.getItem("card_kyc_data")) || null
  );
  console.log("localUser", localUser);
  console.log("localCardKycData", localCardKycData);
  const [activeTab, setActiveTab] = useState("transactions");

  // Add pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDeposit, setCurrentPageDeposit] = useState(1);
  const [itemsPerPage] = useState(10);

  // Calculate indices
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItemDeposit = currentPageDeposit * itemsPerPage;
  const indexOfFirstItemDeposit = indexOfLastItemDeposit - itemsPerPage;

  //GET USER DATA
  useEffect(() => {
    if (isConnected) {
      setisLoading(true); // Start loading
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("Loaded user-data", response.data);
          if (response?.data?.card?.trans_fetch == 0) {
            console.log("Loading Transactions");
            //axios post https://test.croesus-asset.management/api/getUserCardTransactionsLastMonth
            axios
              .post(
                `https://test.croesus-asset.management/api/getUserCardTransactionsLastMonth`,
                {
                  cardKitNumber: response.data.card.cardKitNumber,
                }
              )
              .then((response) => {
                console.log("Loaded transactions", response.data);
                window.location.reload();
              })
              .catch((error) => {
                console.error(error);
              });
          }
          setLocalUser(response.data.user);
          setCard(response.data.card);
          setcard_kyc_data(response.data.card_kyc_data);
          setisLoading(false); // Start loading
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Slice the transactions array
  const currentItems =
    localUser?.transactions
      ?.filter((transaction) => transaction !== null)
      .slice(indexOfFirstItem, indexOfLastItem) || [];

  const currentItemsDeposits =
    localUser?.transactionstopup
      ?.filter((transaction) => transaction !== null)
      .slice(indexOfFirstItemDeposit, indexOfLastItemDeposit) || [];

  const handleTabClick = (tab) => {
    // console.log(tab);
    setActiveTab(tab);
  };

  const nextPageDeposit = () => {
    setCurrentPageDeposit((prev) => prev + 1);
  };

  const prevPageDeposit = () => {
    if (currentPageDeposit > 1) {
      setCurrentPageDeposit((prev) => prev - 1);
    }
  };

  const nextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <div
      className="pt-3"
      style={{ minHeight: window.screen.height + 80, paddingTop: 0 }}
    >
      <div className="container-fluid text-center mb-4">
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-xl-6">
            <div className="card ">
              <div className="card-header text-center p-0 flex-wrap"></div>
              <div className="card-body p-0">
                <WalletOverview />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            <div className="card p-3" style={{ height: "auto" }}>
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Croesus Card Dashboard</h3>
              </div>
              <div className="card-body">
                {userCard && userCard.cardKitNumber !== null ? (
                  <>
                    {/* <h3>Your Croesus Card</h3> */}
                    <CardWidget
                      user={localUser}
                      userCard={userCard}
                      card_kyc_data={card_kyc_data}
                    />
                  </>
                ) : (
                  <>
                    <h3>No Card Yet</h3>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 mb-2 justify-content-md-center">
          <div className="col-12 col-xl-6 col-md-6">
            <div className="card p-3" style={{ height: "auto" }}>
              <div className="card-header text-center p-0 flex-wrap">
                <h3>Card Transactions</h3>
              </div>
              <div className="card-body p-0">
                {userCard ? (
                  <>
                    <div className="card-header border-0 pb-2">
                      <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "transactions" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("transactions")}
                          >
                            Spendings
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "deposits" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("deposits")}
                          >
                            Deposits
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content">
                      {activeTab === "transactions" && (
                        <>
                          <div className="tab-pane fade show active">
                            <Table responsive>
                              {/* <thead>
                              <tr className="text-black text-weight-600">
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Date</th>
                              </tr>
                            </thead> */}
                              <tbody>
                                {currentItems.length > 0 ? (
                                  currentItems.map((transaction) => (
                                    <tr
                                      className="text-black"
                                      key={transaction.id}
                                      style={{ textAlign: "left" }}
                                    >
                                      <td className="text-black">
                                        {transaction.description}
                                      </td>
                                      <td
                                        className="text-black"
                                        style={{ width: "110px" }}
                                      >
                                        -{Number(transaction.amount).toFixed(2)}{" "}
                                        USD
                                      </td>
                                      <td className="text-black">
                                        {transaction.date}
                                      </td>
                                      <td className="text-success">
                                        Completed
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      className="text-black text-center"
                                      colSpan="4"
                                    >
                                      No Transactions available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>{" "}
                            <div className="d-flex justify-content-center mt-4">
                              <button
                                className="btn btn-primary m-2"
                                onClick={prevPage}
                                disabled={currentPage === 1} // disable button when on first page
                              >
                                <i className="fa-solid fa-chevron-left"></i>
                              </button>
                              <button
                                className="btn btn-primary m-2"
                                onClick={nextPage}
                                disabled={
                                  currentPage ===
                                  Math.ceil(
                                    localUser?.transactions.length /
                                      itemsPerPage
                                  )
                                }
                              >
                                <i className="fa-solid fa-chevron-right"></i>
                              </button>
                            </div>
                          </div>
                        </>
                      )}

                      {activeTab === "deposits" && (
                        <>
                          <Table responsive>
                            {/* <thead>
                            <tr className="text-black text-weight-600">
                              <th>Description</th>
                              <th>Amount</th>
                              <th>Date</th>
                            </tr>
                          </thead> */}
                            <tbody>
                              {currentItemsDeposits.map((transaction) => (
                                <tr className="text-black" key={transaction.id}>
                                  <td className="text-black">
                                    {transaction.description}
                                  </td>
                                  <td
                                    className="text-black"
                                    style={{ width: "130px" }}
                                  >
                                    +{Number(transaction.amount).toFixed(2)}{" "}
                                    USDT
                                  </td>
                                  <td className="text-black">
                                    {transaction.date}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>{" "}
                          <div className="d-flex justify-content-center mt-4">
                            <button
                              className="btn btn-primary m-2"
                              onClick={prevPageDeposit}
                              disabled={currentPageDeposit === 1}
                            >
                              <i className="fa-solid fa-chevron-left"></i>
                            </button>
                            <button
                              className="btn btn-primary m-2"
                              onClick={nextPageDeposit}
                              disabled={
                                currentPageDeposit ===
                                Math.ceil(
                                  localUser?.transactionstopup.length /
                                    itemsPerPage
                                )
                              }
                            >
                              <i className="fa-solid fa-chevron-right"></i>
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <h3>No Card Yet</h3>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Physical;
