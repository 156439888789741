import React, { Fragment, useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import axios from "axios";
import { Row, Col, Nav, Table } from "react-bootstrap";

import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
  useContractRead,
} from "wagmi";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { Button } from "@material-ui/core";
import ApexLineChart from "./SwipeableChart";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import { Select, MenuItem } from "@material-ui/core";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Web3 from "web3";
import KYCForm from "./KYCFormCard";
import KYCWizard from "./KYCWizard";
import { MintCardNFTs } from "../pages/components/hooks/MintCardNFTs";
import { MintCardVIPNFT } from "../pages/components/hooks/MintCardVIPNFTs";
import CardWidget from "../pages/components/hooks/CardWidget";
import {
  NEWCOIN_ABI,
  NEWCOIN_CONTRACT,
} from "../pages/components/NewCoinContract";
import Spinner from "react-bootstrap/Spinner";
import vip_card from "../pages/components/images/vipcard_rm.jpg";
import premium_card from "../pages/components/images/premium_card_rm.jpg";
import standard_card from "../pages/components/images/standard_card_rm.jpg";
import {
  MARKETPLACE_ABI,
  MARKETPLACE_CONTRACT,
  USDT_CONTRACT,
} from "../marketplacecontracts";
import Alert from "react-bootstrap/Alert";
import WalletOverview from "./components/WalletOverview/index";
import { BSC_SPLIT_TOPUP_ABI } from "../../../src/contracts/BSC_SPLIT_TOPUP_ABI.js";
import { usdt_abi } from "../../../src/contracts/USDT_ABI.js";

function Virtual() {
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [isLoading, setisLoading] = useState(true);
  const [userCard, setCard] = useState([]);
  const [card_kyc_data, setcard_kyc_data] = useState([]);
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [localCardKycData, setLocalCardKycData] = useState(
    JSON.parse(localStorage.getItem("card_kyc_data")) || null
  );
  const [virtualCards, setvirtualCards] = useState([]);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [web3statusmessage, setweb3statusmessage] = useState("");
  const [tofcfamount, settofcfamount] = useState(0);

  const [transactionsconfirmed, settransactionsconfirmed] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [isLoadingTransaction, setisLoadingTransaction] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [loadingAmount, setLoadingAmount] = useState(0);
  const [AmountToPay, setAmountToPay] = useState(0);
  const [fcftopayaddress, setfcftopayaddress] = useState(0);
  const [ToFcfPercentage, setToFcfPercentage] = useState(0);
  const [toCamPercentage, setToCamPercentage] = useState(0);
  const [toFcf, setToFcf] = useState(0);
  const [network, setNetwork] = useState("");
  const [apialert, setalert] = useState("");
  const [address_in, setaddressin] = useState("");
  const [createdAddress, setCreatedAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [issuecardenabled, setissuecardenabled] = useState(false);
  const [txn_hash, settxn_hash] = useState("");
  const [confirmations, setconfirmations] = useState(0);
  const [buyError, setbuyError] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [isLoadingFCF, setisLoadingFCF] = useState(false);
  const [bb_marketingAmount, setbb_marketingAmount] = useState(0);
  const [bb_crAmount, setbb_crAmount] = useState(0);
  const [bb_teamAmount, setbb_teamAmount] = useState(0);
  const [topupsended, settopupsended] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [net_topup, setnet_topup] = useState(0);
  const [bscBalance, setbscBalance] = useState(0);
  const [bscBalanceWei, setbscBalanceWei] = useState(0);
  const [bscAllowance, setbscAllowance] = useState(0);
  const [isapproved, setisapproved] = useState(false);
  const [card_uuid, setcard_uuid] = useState("");
  const [virtualCardData, setVirtualCardData] = useState(null);
  const [approveamount, setapproveamount] = useState(0);

  const BB_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
  const CR_10_WALLET = "0x593f27504F2bE394C648F2216319Cb7fC9cf37bE";
  const CROESUS_WALLET = "0xaB880Da672Fc00C5CFe6d9561D1185d67DaEEf9c";

  const BSC_USDT_CONTRACT = "0x55d398326f99059fF775485246999027B3197955";
  const BSC_SPLIT_CONTRACT = "0xB124D8a36C5D657eF0801Ff13fBbe7525cebc897";
  const BSC_TOPUP_CONTRACT = "0x42ffD035B5690e0cE8943238124009688FAC8fd2";

  console.log("localUser", localUser);
  console.log("localCardKycData", localCardKycData);

  //WEB3 VARS
  let chainid;
  let currency;
  let usdt_contract;
  let split_contract;
  let approve_amount;
  let buy_amount;
  let cur_fcf;
  if (walletAddress) {
    usdt_contract = BSC_USDT_CONTRACT;
    split_contract = BSC_TOPUP_CONTRACT;

    approve_amount = Web3.utils.toWei(loadingAmount, "ether");
    console.log("approve_amount wei", approve_amount);

    buy_amount = Web3.utils.toWei(loadingAmount, "ether");
    currency = "bsc_usdt";
    cur_fcf = "BSC.USDT";
    console.log("approve_amount", approve_amount);
  }

  const loadingfees = {
    50: [0.055, 0.0525, 0.05, 0.0475],
    100: [0.055, 0.0525, 0.05, 0.0475],
    250: [0.05, 0.0475, 0.045, 0.0425],
    500: [0.045, 0.0425, 0.04, 0.0375],
    1000: [0.0425, 0.04, 0.0375, 0.035],
    1500: [0.04, 0.0375, 0.035, 0.0325],
  };

  const getWei = (amount) => {
    return Web3.utils.toWei(amount, "ether");
  };

  async function sendTopUpData(txid_in) {
    console.log("sendTopUpData");
    if (!topupsended) {
      // let currency = "56";
      // const payload = {
      //   user: localUser.id,
      //   currency: currency,
      //   amount: bb_marketingAmount,
      //   brut_amount: loadingAmount,
      //   net_amount: net_topup,
      //   fcf_amount: toFcf,
      //   bb_amount: bb_marketingAmount,
      //   marketing_amount: bb_marketingAmount,
      //   txn_hash: txid_in,
      // };
      // console.log("Payload:", payload);
      // axios
      //   .post(
      //     "https://test.croesus-asset.management/api/split_virtual_card",
      //     payload
      //   )
      //   .then(async (response) => {
      //     console.log("Response received:", response);
      //     settopupsended(true);
      //   })
      //   .catch((error) => {
      //     console.error("Error encountered while sending topup data:", error);
      //   });
    }
  }

  const handleCardOrderSuccess = async () => {
    console.log("handleCardOrderSuccess");
    const interval = setInterval(() => {
      handlecheckcardstatus();
    }, 15000);

    return () => clearInterval(interval);
  };

  async function handlecheckcardstatus() {
    console.log("handlecheckcardstatus", card_uuid);
    try {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/cards/check-status",
        {
          uuid: card_uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      if (response.data.success === true) {
        // Success handling
        console.log("Update Virtual Card:", response.data);
        console.log("Received redeem link:", response.data.message.redeem_link);
        console.log("Received redeem code:", response.data.message.redeem_code);

        axios
          .post(
            "https://test.croesus-asset.management/api/update_virtualcard",
            {
              // redeem_link: response.data.message.redeem_link,
              // redeem_code: response.data.message.redeem_code,
              redeem_link: response.data.message.redeem_link,
              redeem_code: response.data.message.redeem_code,
              pin: response.data.message.pin,
              expirationDate: response.data.message.expiration_date,
              uuid: card_uuid,
            }
          )
          .then(async (response) => {
            console.log("Response received:", response);
            if (response.data.success === true) {
              console.log("Successfull:", response.data.message);
              const updatedCard = response.data.data;

              setvirtualCards((prevCards) => {
                // Find index of the updated card in the existing array
                const index = prevCards.findIndex(
                  (card) => card.uuid === updatedCard.uuid
                );

                if (index !== -1) {
                  // Replace the old card data with the updated one
                  const updatedCards = [...prevCards];
                  updatedCards[index] = updatedCard;
                  return updatedCards;
                } else {
                  // If for some reason the card wasn't found, just append the updated card to the list
                  return [...prevCards, updatedCard];
                }
              });

              isLoadingTransaction(false);

              setweb3statusmessage("Card successfull created...");
            } else {
              console.error(
                "Request was not successful. Please handle it accordingly."
              );
            }
          })
          .catch((error) => {
            console.error("Error encountered while sending topup data:", error);
          });
      } else {
        // Error handling
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("No response was received", error.request);
      } else {
        console.error("Error setting up the request", error.message);
      }
    }
  }

  async function sendCreateVirtualCard() {
    console.log("sendCreateVirtualCard");
    console.log("virtualCardData:", virtualCardData);
    axios
      .post(
        "https://test.croesus-asset.management/api/create_virtualcard",
        virtualCardData
      )
      .then(async (response) => {
        console.log("Response received:", response);
        if (response.data.success === true) {
          const updatedCard = virtualCardData;

          setvirtualCards((prevCards) => {
            // Find index of the updated card in the existing array
            const index = prevCards.findIndex(
              (card) => card.uuid === updatedCard.uuid
            );

            if (index !== -1) {
              // Replace the old card data with the updated one
              const updatedCards = [...prevCards];
              updatedCards[index] = updatedCard;
              return updatedCards;
            } else {
              // If for some reason the card wasn't found, just append the updated card to the list
              return [...prevCards, updatedCard];
            }
          });

          handleCardOrderSuccess();
          //   sendCreateVirtualCard();
          isLoading(false);
          fetchUserData();
        } else {
          console.error(
            "Request was not successful. Please handle it accordingly."
          );
        }
      })
      .catch((error) => {
        console.error("Error encountered while sending topup data:", error);
      });
  }

  // READ ALLOWANCE
  const { data: readDataAllowance, isLoading: readLoadingAllowance } =
    useContractRead({
      address: usdt_contract,
      abi: usdt_abi,
      functionName: "allowance",
      args: [walletAddress, split_contract],
    });
  console.log("allowance", readDataAllowance);

  useEffect(() => {
    if (readDataAllowance) {
      let bscAllowanceETH = Web3.utils.fromWei(
        String(readDataAllowance),
        "ether"
      );
      console.log("bscAllowanceETH", bscAllowanceETH);
      setbscAllowance(readDataAllowance);
    }
  }, [readDataAllowance]);

  //SEND TO CONTRACT
  const {
    data: topupdata,
    write: topupwrite,
    isLoading: topupisPending,
  } = useContractWrite({
    address: split_contract,
    abi: BSC_SPLIT_TOPUP_ABI,
    functionName: "topUp",
    args: [
      [fcftopayaddress, CROESUS_WALLET, CR_10_WALLET, BB_10_WALLET],
      [
        getWei(tofcfamount),
        getWei(bb_marketingAmount),
        getWei(bb_crAmount),
        getWei(bb_teamAmount),
      ],
    ],
    onError(error) {
      console.log("Error", error);
      setisLoadingTransaction(false);
      setisLoading(false);
    },
  });
  const {
    data: topupdataceipt,
    isLoading: topupdataPending,
    isSuccess: topupdataSuccess,
  } = useWaitForTransaction({
    hash: topupdata?.hash,
    onSuccess(data) {
      console.log("onSuccess", data);
      setweb3statusmessage("Transaction confirmed...");
      sendTopUpData(topupdata?.hash);
      handlecheckcardstatus();
      sendCreateVirtualCard();
    },
    onerror(error) {
      console.log("onError", error);
      setisLoadingTransaction(false);
      isLoading(false);
    },
  });

  const {
    data: txhashapprove,
    write: writeValue,
    isLoading: writeLoading,
  } = useContractWrite({
    // mode: "recklesslyUnprepared",
    address: usdt_contract,
    abi: usdt_abi,
    functionName: "approve",
    args: [split_contract, approveamount],
    onSuccess(data) {
      console.log("Success approve");
      setweb3statusmessage("USDT approved...");
      // window.scrollTo(0, document.body.scrollHeight);
      // setbscAllowance(approve_amount);
      // handleSetValue();
    },
    onError(error) {
      console.log("Error", error);
      setisLoadingTransaction(false);
      setisLoading(false);
    },
  });

  const {
    data: approvedatareceipt,
    isLoading: approveisPending,
    isSuccess: approveisSuccess,
  } = useWaitForTransaction({
    hash: txhashapprove?.hash,
    onSuccess(data) {
      console.log("onSuccess approve", data);
      setweb3statusmessage("Creating Transaction...");

      setbscAllowance(approve_amount);
      setisapproved(true);
      const args = [
        [fcftopayaddress, CROESUS_WALLET, CR_10_WALLET, BB_10_WALLET],
        [
          getWei(tofcfamount),
          getWei(bb_marketingAmount),
          getWei(bb_crAmount),
          getWei(bb_teamAmount),
        ],
      ];
      console.log("TopUp Args", args);
      topupwrite?.();
    },
    onerror(error) {
      console.log("onError", error);
      setisLoadingTransaction(false);
      isLoading(false);
    },
  });

  //GET USER DATA
  useEffect(() => {
    if (isConnected) {
      setisLoading(true); // Start loading
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("Loaded user-data", response.data);
          setLocalUser(response.data.user);
          setCard(response.data.card);
          setcard_kyc_data(response.data.card_kyc_data);
          setvirtualCards(response.data.user.virtual_cards);
          setisLoading(false); // Start loading
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  const fetchUserData = () => {
    console.log("isConnected Mint");
    console.log("address", walletAddress);
    axios
      .get(
        `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
      )
      .then((response) => {
        console.log("Loaded user-data", response.data);
        setLocalUser(response.data.user);
        setCard(response.data.card);
        setcard_kyc_data(response.data.card_kyc_data);
        setvirtualCards(response.data.user.virtual_cards);
        setisLoading(false); // Start loading
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlegetstatus = async (uuid) => {
    setRefreshLoading((prevState) => ({ ...prevState, [uuid]: true }));
    try {
      const response = await axios.post(
        "https://dashboard.croesus-asset.management/api/cards/check-status",
        {
          uuid: uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization:
              "Bearer PtuvxLhFoAAudqR13uhbDNect4UQNqD1kWDz0SYkLhQwDzNcNREgHuVLApSg",
          },
        }
      );

      if (response.data.success === true) {
        // Success handling
        console.log("Update Virtual Card:", response.data);
        console.log("Received redeem link:", response.data.message.redeem_link);
        console.log("Received redeem code:", response.data.message.redeem_code);

        axios
          .post(
            "https://test.croesus-asset.management/api/update_virtualcard",
            {
              // redeem_link: response.data.message.redeem_link,
              // redeem_code: response.data.message.redeem_code,
              redeem_link: response.data.message.redeem_link,
              redeem_code: response.data.message.redeem_code,
              pin: response.data.message.pin,
              expirationDate: response.data.message.expiration_date,
              uuid: uuid,
            }
          )
          .then(async (response) => {
            console.log("Response received:", response);
            if (response.data.success === true) {
              console.log("Successfull:", response.data.message);
              const updatedCard = response.data.data;
              setvirtualCards((prevCards) => {
                // Find index of the updated card in the existing array
                const index = prevCards.findIndex(
                  (card) => card.uuid === updatedCard.uuid
                );

                if (index !== -1) {
                  // Replace the old card data with the updated one
                  const updatedCards = [...prevCards];
                  updatedCards[index] = updatedCard;
                  return updatedCards;
                } else {
                  // If for some reason the card wasn't found, just append the updated card to the list
                  return [...prevCards, updatedCard];
                }
              });
              setweb3statusmessage("Card successfull created...");
            } else {
              console.error(
                "Request was not successful. Please handle it accordingly."
              );
            }
          })
          .catch((error) => {
            console.error("Error encountered while sending topup data:", error);
          });
        setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      } else {
        // Error handling
        console.log("Error message from server:", response.data.message);
        setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      }
    } catch (error) {
      setRefreshLoading((prevState) => ({ ...prevState, [uuid]: false }));
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        console.error("No response was received", error.request);
      } else {
        console.error("Error setting up the request", error.message);
      }
    }
  };

  async function fetchDepositData(amount, tokenSymbol) {
    console.log("amount", amount.toString());
    console.log("tokenSymbol", tokenSymbol);
    const response = await axios.post(
      "https://test.croesus-asset.management/api/create-card-order-depositUSD",
      {
        amount: amount.toString(),
        user_id: localUser?.id,
        currency: "EUR",
        product_id: 18597,
        token_symbol: tokenSymbol,
        email: "info@bridge-bit.com",
        liquidity: 0,
      }
    );

    if (!response.status === 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  }

  const handlecreateaddress = () => {
    setisLoadingTransaction(true);
    if (walletAddress) {
      if (approveamount > bscAllowance) {
        console.log("Need to approve usdt");
        setweb3statusmessage("Approve USDT...");

        async function approveUsdt() {
          try {
            await writeValue();
          } catch (err) {
            console.error(`Error while writing value: ${err}`);
          }
        }

        approveUsdt();
      } else {
        const args = [
          [fcftopayaddress, CROESUS_WALLET, CR_10_WALLET, BB_10_WALLET],
          [
            getWei(tofcfamount),
            getWei(bb_marketingAmount),
            getWei(bb_crAmount),
            getWei(bb_teamAmount),
          ],
        ];
        console.log("TopUp Args", args);
        topupwrite?.();
      }
    }
  };

  const handleLoadingAmount = async (loadingAmount) => {
    if (loadingAmount != 0) {
      setisLoadingFCF(true);
      console.log("loadingAmount useEffect", loadingAmount);
      console.log("network", network);
      console.log("address", walletAddress);
      // if (AmountToPay != 0) {
      //   handlesetNetwork("bep20");
      //   return;
      // }
      setLoadingAmount(loadingAmount);

      if (walletAddress) {
        console.log("address", walletAddress);
        if (localUser?.cards?.cardKitNumber) {
          let fcf_network = "BSC.USDT";
          console.log("loadingAmount", loadingAmount);
          console.log("allowance", bscAllowance);

          const apiResponse = await fetchDepositData(
            loadingAmount,
            fcf_network
          ).then((apiResponse) => {
            console.log("apiResponse", apiResponse);
            let modifiedData = { ...apiResponse.data.data };
            modifiedData.user_id = localUser.id;
            modifiedData.address = apiResponse.data.address;
            modifiedData.crypto_currency_amount =
              apiResponse.data.data.order_amount;
            modifiedData.crypto_currency_amount =
              apiResponse.data.crypto_currency_amount;
            modifiedData.uuid = apiResponse.data.uuid;
            modifiedData.order_id = apiResponse.data.order_id;
            modifiedData.total_fiat_amount = Number(loadingAmount).toFixed(2);
            let today = new Date();
            let date_today = today.toLocaleDateString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
            modifiedData.created_at = date_today;
            setVirtualCardData(modifiedData);
            setfcftopayaddress(apiResponse.data.address);
            setcard_uuid(apiResponse.data.uuid);
            settofcfamount(apiResponse.data.data.order_amount);

            let fcf_amount = Number(apiResponse.data.data.order_amount);
            console.log("fcf_amount", fcf_amount);
            setToFcf(fcf_amount);
            let tofcf_test = fcf_amount;

            let card_fee =
              loadingfees[loadingAmount][localUser.cards.card_type];
            let z =
              ((((fcf_amount - 2) * 100) / (100 + 2.5)) *
                (100 + card_fee * 100)) /
              100;
            console.log("z", z);

            let brut_amount = z;
            console.log("brut_amount", brut_amount);
            brut_amount = (Math.ceil(brut_amount * 100) / 100).toFixed(2);

            console.log("brut_amount", brut_amount);
            setAmountToPay(brut_amount);
            approve_amount = Web3.utils.toWei(brut_amount, "ether");
            console.log("approve_amount wei", approve_amount);
            console.log("bscAllowance", bscAllowance);
            setapproveamount(approve_amount);

            if (approve_amount > bscAllowance) {
              setisapproved(false);
            } else {
              setisapproved(true);
            }

            //TOPUP SPLITTING
            let bb_amount = brut_amount - fcf_amount;
            console.log("loadingAmount", apiResponse.data.data.order_amount);
            console.log("loadingAmount", Number(loadingAmount));
            console.log("bb_amount", bb_amount);
            let bb_marketing = (bb_amount * 0.8).toFixed(10);
            setbb_marketingAmount(bb_marketing);
            console.log("bb_marketing", bb_marketing);
            let bb_cr = (bb_amount * 0.1).toFixed(10);
            setbb_crAmount(bb_cr);
            console.log("bb_cr", bb_cr);
            let bb_team = (bb_amount * 0.1).toFixed(10);
            setbb_teamAmount(bb_team);
            console.log("bb_team", bb_team);

            var to_fcf_percentage = tofcf_test / brut_amount;
            console.log("to_fcf_percentage", to_fcf_percentage);
            setToFcfPercentage(to_fcf_percentage);

            let temp = Math.ceil(to_fcf_percentage * 10000) / 10000;
            let perc_to_fcf = temp.toFixed(4);
            console.log("test", perc_to_fcf);
            var perc_to_cam = (1 - perc_to_fcf).toFixed(4);
            console.log("perc_to_cam", perc_to_cam);
            setToCamPercentage(perc_to_cam);

            setissuecardenabled(true);
            setisLoadingFCF(false);
          });
          // }
        }
      }
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <div
        className="pt-3"
        style={{ minHeight: window.screen.height + 80, paddingTop: 0 }}
      >
        <div className="container-fluid text-center mb-4">
          {walletAddress && (
            <div className="row mt-2 mb-2 justify-content-md-center">
              <div className="col-xl-6">
                <div className="card ">
                  <div className="card-header text-center p-0 flex-wrap"></div>
                  <div className="card-body p-0">
                    <WalletOverview />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row mt-2 mb-2 justify-content-md-center">
            <div className="col-12 col-xl-6 col-md-6">
              <div className="card p-3" style={{ height: "auto" }}>
                <div className="card-header text-center p-0 flex-wrap">
                  <h3>Croesus Virtual Card Dashboard</h3>
                </div>
                <div className="card-body">
                  {localUser?.cards?.card_type !== 0 ? (
                    <>
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-sm-12">
                          <div className="row">
                            <div className="col-xl-12 col-lg-6 col-md-7 col-sm-8">
                              <div
                                className="card card-bx card-body overflow-hidden p-0 mb-2 d-flex rounded"
                                style={{
                                  minWidth: "270px",
                                  minHeight: "320px",
                                }}
                              >
                                <img
                                  // src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/a9354c5a-e6d4-47e1-ea4b-dc3505089800/public"
                                  src="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/709d28fc-e6bb-4f14-7eee-1a940175d900/public"
                                  alt=""
                                  className="mw-100 "
                                  style={{
                                    height: "auto",
                                    minHeight: "200px",
                                    position: "absolute",
                                    width: "100%",
                                    borderRadius: "0.625rem",
                                    objectFit: "fill",
                                  }}
                                />
                                <div
                                  className="card-info text-white d-flex flex-direction-column"
                                  style={{
                                    minHeight: "230px",
                                    flexDirection: "column",
                                    position: "relative",
                                    padding: "15px 30px",
                                  }}
                                >
                                  <div className="d-flex align-items-center mb-sm-1 mb-1"></div>

                                  <p
                                    style={{
                                      marginBottom: "0px",
                                      color: "black",
                                    }}
                                  >
                                    USD
                                  </p>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="input-group mb-3">
                                      <select
                                        className="form-control h-auto text-black"
                                        value={loadingAmount}
                                        onChange={(e) =>
                                          handleLoadingAmount(e.target.value)
                                        }
                                        disabled={
                                          localUser?.settings
                                            ?.virtual_maintenance === 1
                                        }
                                      >
                                        {" "}
                                        <option value="">Select Amount</option>
                                        <option value="100">100</option>
                                        <option value="250">250</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                        {/* <option value="1500">1500</option> */}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="img_container"></div>
                                  <></>
                                </div>
                              </div>
                            </div>
                          </div>

                          {loadingAmount !== 0 && (
                            <div className="row">
                              <div xl="8" sm="8" lg="6">
                                <div>
                                  <div
                                    className="d-flex text-center"
                                    style={{
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <>
                                      {AmountToPay !== 0 && (
                                        <>
                                          <p className="fs-18 mb-0 text-black">
                                            Amount to pay :
                                          </p>
                                          <p className="fs-18 mb-2 text-black">
                                            {AmountToPay} USDT
                                          </p>
                                        </>
                                      )}

                                      {isLoadingFCF && network !== "" && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          className="me-2"
                                        />
                                      )}

                                      {createdAddress && (
                                        <div
                                          className="row d-flex mt-3"
                                          style={{
                                            flexDirection: "column",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className="col-lg-12 d-flex"
                                            style={{
                                              flexDirection: "column",
                                              alignItems: "center",
                                            }}
                                          >
                                            <label className="text-black font-w600 ">
                                              Address
                                            </label>
                                            <div className="input-group mb-3 mt-3">
                                              <input
                                                type="text"
                                                className="form-control text-black"
                                                placeholder="0x38861AF3f588c36560931d50aa45b37c7aD8D8C4"
                                                value={createdAddress}
                                                readOnly
                                              />

                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => {
                                                  navigator.clipboard
                                                    .writeText(createdAddress)
                                                    .then(() =>
                                                      alert(
                                                        "Address copied to clipboard!"
                                                      )
                                                    )
                                                    .catch((error) =>
                                                      console.error(
                                                        "Could not copy text: ",
                                                        error
                                                      )
                                                    );
                                                }}
                                              >
                                                COPY
                                              </button>
                                            </div>
                                            <label className="text-black font-w600 ">
                                              Amount
                                            </label>
                                            <div
                                              className="input-group mb-3 mt-3"
                                              style={{ maxWidth: "200px" }}
                                            >
                                              <input
                                                type="number"
                                                className="form-control text-black"
                                                placeholder="0"
                                                value={AmountToPay}
                                                readOnly
                                              />

                                              <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => {
                                                  navigator.clipboard
                                                    .writeText(AmountToPay)
                                                    .then(() =>
                                                      alert(
                                                        "Amount copied to clipboard!"
                                                      )
                                                    )
                                                    .catch((error) =>
                                                      console.error(
                                                        "Could not copy text: ",
                                                        error
                                                      )
                                                    );
                                                }}
                                              >
                                                COPY
                                              </button>
                                            </div>
                                            {qrCode && (
                                              <div
                                                className="d-flex mb-3"
                                                style={{
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <img
                                                  src={`data:image/png;base64,${qrCode}`}
                                                  alt="QR Code"
                                                  style={{ maxWidth: "220px" }}
                                                />
                                              </div>
                                            )}

                                            {isLoadingTransaction ? (
                                              <>
                                                <Spinner
                                                  as="span"
                                                  animation="border"
                                                  role="status"
                                                  aria-hidden="true"
                                                  className="me-2"
                                                />
                                                {txn_hash ? (
                                                  <>
                                                    <label className="text-black font-w600 mt-3">
                                                      Transaction Hash
                                                    </label>
                                                    <label className="text-black font-w600 mt-3">
                                                      {txn_hash.substring(
                                                        0,
                                                        15
                                                      )}
                                                      ...
                                                    </label>
                                                    <label className="text-black font-w600 mt-3">
                                                      Confirmations :{" "}
                                                      {confirmations}
                                                    </label>
                                                  </>
                                                ) : (
                                                  <>
                                                    <label className="text-black font-w600 mt-3">
                                                      Waiting for Payment
                                                    </label>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <label className="text-black font-w600 mt-3">
                                                  Transaction Hash
                                                </label>
                                                <label className="text-black font-w600">
                                                  {txn_hash.substring(0, 25)}...
                                                </label>
                                                <label className="text-black font-w600 mt-3">
                                                  Confirmations :{" "}
                                                  {confirmations}
                                                </label>
                                                {transactionsconfirmed && (
                                                  <div
                                                    className="alert alert-success"
                                                    role="alert"
                                                  >
                                                    Transaction Confirmed. You
                                                    card details ..
                                                  </div>
                                                )}
                                                {errormessage && (
                                                  <div
                                                    className="alert alert-danger"
                                                    role="alert"
                                                  >
                                                    {errormessage}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {web3statusmessage !== "" && (
                                        <label className="text-black font-w600 ">
                                          {web3statusmessage}
                                        </label>
                                      )}

                                      {isLoadingFCF && walletAddress && (
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="xl"
                                          role="status"
                                          aria-hidden="true"
                                          className="me-2"
                                        />
                                      )}

                                      {!qrCode && !isLoadingFCF && (
                                        <button
                                          type="submit"
                                          className="btn btn-primary mt-3"
                                          //   disabled={!issuecardenabled || isLoadingTransaction}
                                          disabled={
                                            isLoadingTransaction || isLoadingFCF
                                          }
                                          onClick={handlecreateaddress}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            minWidth: "100px",
                                            margin: "0px auto",
                                          }}
                                        >
                                          {isapproved
                                            ? "Issue Card"
                                            : "Approve USDT"}
                                        </button>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      {virtualCards && virtualCards.length > 0 ? (
                        <>
                          <div className="row">
                            <div xl="8" sm="8" lg="6">
                              <div>
                                <div>
                                  <>
                                    <h4>Your Cards</h4>
                                    <h6 className="text-warning">
                                      The issueing process can take up to 5
                                      minutes.{" "}
                                    </h6>
                                    <Table className="m-0" responsive>
                                      <tbody>
                                        {virtualCards
                                          .slice()
                                          .reverse()
                                          .map((card) => (
                                            <tr
                                              className="text-black"
                                              key={card.id}
                                            >
                                              {card.redeem_link ? (
                                                <td
                                                  className="text-black"
                                                  style={{ width: "130px" }}
                                                >
                                                  {card.total_fiat_amount}
                                                  {card.redeem_code
                                                    ? " USD"
                                                    : " EUR"}
                                                </td>
                                              ) : (
                                                <td
                                                  className="text-black"
                                                  style={{ width: "130px" }}
                                                >
                                                  {card.total_fiat_amount}
                                                </td>
                                              )}

                                              <td className="text-black">
                                                {(() => {
                                                  let date = new Date(
                                                    card.created_at
                                                  );
                                                  return `${(
                                                    "0" + date.getDate()
                                                  ).slice(-2)}/${(
                                                    "0" +
                                                    (date.getMonth() + 1)
                                                  ).slice(
                                                    -2
                                                  )}/${date.getFullYear()}`;
                                                })()}
                                              </td>
                                              {card.redeem_link ? (
                                                <>
                                                  <td className="text-black">
                                                    <a
                                                      className="text-blue"
                                                      href={card.redeem_link}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      Activate
                                                    </a>
                                                  </td>
                                                  {card.redeem_code && (
                                                    <td>
                                                      Security Code :{" "}
                                                      {card.redeem_code}
                                                    </td>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <td
                                                    className="text-black"
                                                    // style={{ width: "130px" }}
                                                  >
                                                    Creating
                                                  </td>
                                                  <td>
                                                    <button
                                                      className="btn btn-light ml-2 p-0"
                                                      onClick={() =>
                                                        handlegetstatus(
                                                          card.uuid
                                                        )
                                                      }
                                                      style={{
                                                        background: "none",
                                                        border: "none",
                                                      }}
                                                    >
                                                      <i
                                                        className={`fas fa-sync-alt ${
                                                          refreshLoading[
                                                            card.uuid
                                                          ]
                                                            ? "fa-spin"
                                                            : ""
                                                        }`}
                                                        style={{
                                                          fontSize: "22px",
                                                        }}
                                                      ></i>
                                                    </button>
                                                  </td>
                                                </>
                                              )}
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>{" "}
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>{/* <h3>No Card Yet</h3> */}</>
                      )}
                    </>
                  ) : (
                    <h3>You need at least a Standard Card NFT</h3>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Virtual;
