import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BottomNavigation,
  BottomNavigationAction,
  Menu,
  MenuItem,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import BookIcon from "@material-ui/icons/MenuBook";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import RadioButtonCheckedIcon from "@mui/icons-material/Camera";
import SavingsIcon from "@mui/icons-material/Savings";
import HandshakeIcon from "@mui/icons-material/Handshake";

import { useDisconnect, useAccount } from "wagmi";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import useApiCall from "./hooks/getCroesusUser";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import SpeedIcon from "@mui/icons-material/Speed";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  button: {
    color: "black",
  },
  selected: {}, // Empty object required as an argument for "&$selected" to work
}));

const BottomNavbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // const { disconnect } = useDisconnect()
  const location = useLocation();
  const [value, setValue] = React.useState(location.pathname);
  const { address, connector, isConnected } = useAccount();
  const [isAuthenticated, setIsAuthenticated] = useApiCall(
    isConnected,
    address
  );

  const [menuAnchor, setMenuAnchor] = useState(null); // State to control menu anchor
  const handleOpenMenu = (event) => setMenuAnchor(event.currentTarget);
  const handleCloseMenu = () => setMenuAnchor(null);

  const [menuAnchorCard, setMenuAnchorCard] = useState(null); // State to control menu anchor
  const handleOpenMenuCard = (event) => setMenuAnchorCard(event.currentTarget);
  const handleCloseMenuCard = () => setMenuAnchorCard(null);

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const handleLogout = () => {
    // disconnect();
    navigate("/login");
  };

  return (
    <BottomNavigation
      showLabels
      className={classes.root}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        height: "70px",
        borderTop: "1px solid #e8e8e8",
        backgroundColor: "white",
        zIndex: "10000",
      }}
      sx={{
        "& .Mui-selected, & .Mui-selected > svg": {
          color: "#007A78",
        },
      }}
    >
      <BottomNavigationAction
        className={classes.button}
        style={{ color: "black" }}
        value="/dashboard"
        label="Dashboard"
        icon={<SpeedIcon />}
        onClick={() => navigate("/dashboard")}
      />{" "}
      {/* <BottomNavigationAction
        className={classes.button}
        value="/booknft"
        label="Book NFT"
        icon={<BookIcon />}
        onClick={() => navigate("/booknft")}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : {}}
      /> */}
      {/* <BottomNavigationAction 
        className={classes.button}
        value="/staking"
        label="Staking"
        icon={<SavingsIcon />}
        onClick={() => navigate("/staking")}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : {}}
      /> */}
      <BottomNavigationAction
        className={classes.button}
        value="/partner_dashboard"
        label="Partner"
        icon={<HandshakeIcon />}
        onClick={() => navigate("/partner_dashboard")}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : { color: "black" }}
      />
      <BottomNavigationAction
        className={classes.button}
        value="/series_two"
        label="Series II"
        icon={<LooksTwoIcon />}
        onClick={() => navigate("/series_two")}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : {}}
      />
      <BottomNavigationAction
        className={classes.button}
        value="/cards"
        label="Cards"
        icon={<CreditCardIcon />}
        onClick={handleOpenMenuCard}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : {}}
      />
      <Menu
        anchorEl={menuAnchorCard}
        open={Boolean(menuAnchorCard)}
        onClose={handleCloseMenuCard}
        style={{ zIndex: "20000" }}
      >
        <MenuItem
          style={{
            textTransform: "uppercase",
            borderBottom: "1px solid #d0d0d0",
          }}
          onClick={() => {
            navigate("/cards");
            handleCloseMenuCard();
          }}
        >
          NFTs
        </MenuItem>
        <MenuItem
          style={{
            textTransform: "uppercase",
            borderBottom: "1px solid #d0d0d0",
          }}
          onClick={() => {
            navigate("/physical");
            handleCloseMenuCard();
          }}
        >
          Physical Card
        </MenuItem>
        <MenuItem
          style={{
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/virtual");
            handleCloseMenuCard();
          }}
        >
          Virtual Card
        </MenuItem>
      </Menu>
      <BottomNavigationAction
        className={classes.button}
        value="/more_actions"
        label="More"
        icon={<MoreHorizIcon />}
        onClick={handleOpenMenu}
        disabled={!isAuthenticated} // Optionally disable the button when not connected
        style={!isAuthenticated ? { color: "grey" } : {}}
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
        style={{ zIndex: "20000" }}
      >
        <MenuItem
          style={{
            textTransform: "uppercase",
            borderBottom: "1px solid #d0d0d0",
          }}
          onClick={() => {
            navigate("/pools");
            handleCloseMenu();
          }}
        >
          Pools
        </MenuItem>
        <MenuItem
          style={{
            textTransform: "uppercase",
            borderBottom: "1px solid #d0d0d0",
          }}
          onClick={() => {
            navigate("/marketplace");
            handleCloseMenu();
          }}
        >
          Marketplace
        </MenuItem>
        <MenuItem
          style={{
            borderBottom: "1px solid #d0d0d0",
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/company");
            handleCloseMenu();
          }}
        >
          Company
        </MenuItem>
        <MenuItem
          style={{
            borderBottom: "1px solid #d0d0d0",
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/statistic");
            handleCloseMenu();
          }}
        >
          Statistic
        </MenuItem>
        <MenuItem
          style={{
            borderBottom: "1px solid #d0d0d0",
            textTransform: "uppercase",
          }}
          onClick={() => {
            window.open("https://t.me/CroesusAM_Support", "_blank");

            handleCloseMenu();
          }}
        >
          Support
        </MenuItem>
        <MenuItem
          style={{
            textTransform: "uppercase",
          }}
          onClick={() => {
            navigate("/masterclass");
            handleCloseMenu();
          }}
        >
          Masterclass
        </MenuItem>
      </Menu>
      {/* <BottomNavigationAction
        className={classes.button}
        value="/login"
        label="Logout"
        icon={<ExitToAppIcon />}
        onClick={() => handleLogout()}
      /> */}
    </BottomNavigation>
  );
};

export default BottomNavbar;
