import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Web3 from "web3";
import { contractAbi, contractAddress } from "./components/contractInfo";
import {
  contractAbi as CoinAbi,
  contractAddress as CoinAddress,
} from "./components/CoinContractInfo";
import { ALPHA_ABI, ALPHA_CONTRACT } from "./components/AlphaContractInfo";
import { MINI_ABI, MINI_CONTRACT } from "./components/MiniContractInfo";
import { usdtabi } from "./components/content/usdtabi";
import {
  useAccount,
  useConnect,
  useEnsName,
  useSignMessage,
  useToken,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import {
  prepareWriteContract,
  writeContract,
  readContract,
  waitForTransaction,
} from "@wagmi/core";
import { USDT_CONTRACT } from "./components/marketplacecontracts";
import { NEWCOIN_ABI, NEWCOIN_CONTRACT } from "./components/NewCoinContract";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Select, MenuItem } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import { number } from "yup";
import { Checkbox } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import {
  contractAbi as BOOK_API,
  contractAddress as BOOK_CONTRACT,
} from "./components/contractInfo";

import { MintMiniNFT } from "./components/hooks/MintMiniNFT";

// // Initialize web3
// let web3 = new Web3("https://bsc-testnet.publicnode.com");

// // Your contract instance
// let contract = new web3.eth.Contract(alphaABI, alphaContract);
// console.log("contract", contract);

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // minHeight: "100vh",
  gap: "20px",
};
const textStyle = {
  color: "red",
};
const loadingStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};

const useStyles = makeStyles((theme) => ({
  cardActions: {
    display: "flex",
  },
  fullbuttons: {
    width: "50%",
    borderRadius: "0px",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  nft_buttons: {
    background: "#d8ca93 !important",
    color: "white",
  },
}));

async function sendMetadataToServer(data) {
  try {
    const response = await axios.post(
      "https://test.croesus-asset.management/api/submit_mini_nft",
      data
    );
    console.log("Response:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
}

// Component for the form
function SeriesIIMint(props) {
  const [user, setUser] = useState([]);
  const { kyc } = props; // Destructuring the props object
  const { address: walletAddress, connector, isConnected } = useAccount();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMint, setloadingMint] = useState(false);
  const [minted, setMinted] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = React.useState("false");
  const classes = useStyles();
  const navigate = useNavigate();
  const [usdtBalance, setusdtBalance] = useState(0);
  const [usdtApproved, setusdtApproved] = useState(0);
  const [usdtBalanceEnough, setusdtBalanceEnough] = useState(false);
  const [usdtisapproved, setusdtisapproved] = useState(false);
  const [nftQuantity, setNftQuantity] = useState("");
  const [maxNFTAmount, setmaxNFTAmount] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  //CRG Coin
  const [crgBalance, setcrgBalance] = useState(0);
  const [crgMaxSupply, setcrgMaxSupply] = useState(0);
  const [crgTotalSupply, setcrgTotalSupply] = useState(0);
  const [crgPrice, setcrgPrice] = useState(0);
  const [totalmaxsupply, settotalmaxsupply] = useState(0);
  const [menuItems, setmenuItems] = useState([]);

  const [requirement1Fulfilled, setrequirement1Fulfilled] = useState(false);
  const [requirement2Fulfilled, setrequirement2Fulfilled] = useState(false);
  const [requirement3Fulfilled, setrequirement3Fulfilled] = useState(false);
  const [requirement4Fulfilled, setrequirement4Fulfilled] = useState(false);

  useEffect(() => {
    if (isConnected) {
      console.log("isConnected Mint");
      console.log("address", walletAddress);
      axios
        .get(
          `https://test.croesus-asset.management/api/user-data?address=${walletAddress}`
        )
        .then((response) => {
          console.log("useEffect", response.data);
          console.log(response.data.kyc);
          setStatus(response.data.kyc);
          setUser(response.data.user);
          let profit = response.data.user.equity - response.data.user.amount;
          console.log("profit", profit);
          if (response.data.user.equity - response.data.user.amount > 55) {
            const fullNFTs = Math.floor(
              (response.data.user.equity - response.data.user.amount) / 55
            );
            setmaxNFTAmount(fullNFTs);
            setrequirement4Fulfilled(true);
          } else {
            setrequirement4Fulfilled(false);
          }
          usdt_balance();
          if (setcrgBalance !== 0) {
            fetchCoinDetails();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("disconnected");
    }
  }, [isConnected, walletAddress]);

  useEffect(() => {
    console.log("maxNFTAmount", maxNFTAmount);
    const menuItems = Array.from(
      { length: maxNFTAmount },
      (_, index) => index + 1
    );
    console.log("menuItems", menuItems);
    setmenuItems(menuItems);
  }, [maxNFTAmount]);

  const usdt_balance = async () => {
    try {
      const balance = await readContract({
        address: USDT_CONTRACT,
        abi: usdtabi,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("balance:", Number(balance));
      setusdtBalance(Number(balance));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCoinDetails();
  }, [user]);

  const fetchCoinDetails = async () => {
    try {
      const CoinData = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "checkAirdrop",
        args: [walletAddress],
      });
      console.log("CoinData:", CoinData);

      const CRGBalance = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("CRGBalance:", CRGBalance);
      const CRGBalance_InEther = Web3.utils.fromWei(
        String(CRGBalance),
        "ether"
      );
      setcrgBalance(Number(CRGBalance_InEther));
      if (Number(CRGBalance_InEther) >= 10) {
        setrequirement3Fulfilled(true);
      }

      //MaximBook Balance
      const MaximBookBalance = await readContract({
        address: BOOK_CONTRACT,
        abi: BOOK_API,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("MaximBookBalance:", MaximBookBalance);
      if (Number(MaximBookBalance) >= 1) {
        setrequirement2Fulfilled(true);
      }

      //Series2 Balance
      const SeriesIIBalance = await readContract({
        address: ALPHA_CONTRACT,
        abi: ALPHA_ABI,
        functionName: "balanceOf",
        args: [walletAddress],
      });
      console.log("user.series2count:", user.series2count);
      if (Number(SeriesIIBalance) >= 1) {
        setrequirement1Fulfilled(true);
      }
      if (user.series2count >= 1) {
        setrequirement1Fulfilled(true);
      }

      const CRGMaxSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "maxSupply",
      });
      const CRGMaxSupply_InEther = Web3.utils.fromWei(
        String(CRGMaxSupply),
        "ether"
      );
      console.log("CRGMaxSupply:", CRGMaxSupply);
      setcrgMaxSupply(CRGMaxSupply);

      const CRGTotalSupply = await readContract({
        address: NEWCOIN_CONTRACT,
        abi: NEWCOIN_ABI,
        functionName: "totalSupply",
      });
      const CRGTotalSupply_InEther = Web3.utils.fromWei(
        String(CRGTotalSupply),
        "ether"
      );
      console.log("CRGTotalSupply:", CRGTotalSupply);
      setcrgTotalSupply(CRGTotalSupply);

      // Add checks for division by zero
      if (CRGMaxSupply_InEther - CRGTotalSupply_InEther !== 0) {
        const CRG_Price =
          10000000 / (CRGMaxSupply_InEther - CRGTotalSupply_InEther);
        console.log("CRG_Price:", CRG_Price);
        setcrgPrice(CRG_Price.toFixed(2));
      } else {
        console.log("Cannot divide by zero. Check your inputs.");
      }

      const totalmaxsupply = CRGMaxSupply - CRGTotalSupply;
      settotalmaxsupply(totalmaxsupply);

      console.log("totalmaxsupply ! ", totalmaxsupply);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isConnected) {
      console.log("isConnected Mint");
    } else {
      console.log("disconnected");
    }
  }, [nftQuantity]);

  const handleMintButton = async () => {
    let totalmaxsupply = crgMaxSupply - crgTotalSupply;
    let totalusdt = nftQuantity * 55;
    console.log("totalusdt", totalusdt);
    setloadingMint(true);

    try {
      const { request } = await prepareWriteContract({
        address: MINI_CONTRACT,
        abi: MINI_ABI,
        functionName: "mint",
        args: [nftQuantity, totalmaxsupply],
        account: walletAddress,
      });
      console.info("request:", request);
      const { hash } = await writeContract(request);
      console.info("hash:", hash);
      const waitMint = await waitForTransaction({
        hash: hash,
      });
      console.log("waitMint:", waitMint);
      setloadingMint(false);
      enqueueSnackbar("Mint Successful!", {
        variant: "success",
      });

      //   if (waitMint) {
      //     const metadata = {
      //         tokenIds,
      //         language,
      //         format,
      //     };
      //     console.log("metadata:", metadata);

      //   }
    } catch (error) {
      setloadingMint(false);
      console.error(error);
    }
  };

  return (
    <>
      {" "}
      <div className="row justify-content-md-center p-3">
        <div className="col-lg-6 col-md-8 col-12">
          <div className="card p-3 feature-box pull-up m-0 pt-0">
            <div className="card-body">
              <div className="row justify-content-md-center p-3 mt-3 mb-3">
                <div
                  className="content-body p-0 "
                  style={{
                    minHeight: "100%",
                    overflow: "auto",
                    marginBottom: "50px",
                  }}
                >
                  <div className={classes.container}>
                    <div className={classes.countdownContainer}>
                      {/* Add necessary content here */}
                    </div>
                    <div className="content-body p-0">
                      <div className="container-fluid text-center">
                        <Box className={containerStyle}>
                          <h1 className="text-center">I-CHARGE</h1>
                          <CardMedia
                            component="img"
                            style={{ borderRadius: "0.625rem" }}
                            image="https://imagedelivery.net/0qhJEiI4Vh_ja_4EkR_5Ug/2ce1e825-9dfb-4df3-0171-14c83aeb9700/public"
                            className={classes.media}
                          />
                          <h1
                            className="text-center mt-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span
                              className="crypto-icon--sm crypto-icon--alt"
                              style={{
                                marginRight: "6px",
                                display: "inline-flex",
                                alignItems: "end",
                              }}
                            >
                              <img
                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRi1l5M7-WU-LJcohPi5TuBu4DBR692OqzlvpDbQb2bqd_KiGUHWq62_0Nqyqw4LRyIRDU&amp;usqp=CAU"
                                alt="usdt"
                                style={{ width: "40px" }}
                              />
                              55 USDT
                            </span>
                          </h1>
                          <FormGroup style={{ alignItems: "flex-start" }}>
                            <h6 className="p-3 pb-0">At Least :</h6>
                            <div>
                              <Checkbox
                                checked={requirement4Fulfilled}
                                style={{
                                  color:
                                    requirement4Fulfilled == true
                                      ? "#01b35c"
                                      : "#000000",
                                }}
                              />
                              <span>55 USDT Equity Profit</span>
                            </div>
                            <div>
                              <Checkbox
                                checked={requirement1Fulfilled}
                                style={{
                                  color:
                                    requirement1Fulfilled == true
                                      ? "#01b35c"
                                      : "#000000",
                                }}
                              />
                              <span>1x Series II NFT</span>
                            </div>
                            <div>
                              <Checkbox
                                checked={requirement2Fulfilled}
                                style={{
                                  color:
                                    requirement2Fulfilled == true
                                      ? "#01b35c"
                                      : "#000000",
                                }}
                              />
                              <span>1x The Croesus Maxim NFT</span>
                            </div>
                            <div>
                              <Checkbox
                                checked={requirement3Fulfilled}
                                style={{
                                  color:
                                    requirement3Fulfilled == true
                                      ? "#01b35c"
                                      : "#000000",
                                }}
                              />
                              <span>10x CRG </span>
                            </div>
                          </FormGroup>
                          {loadingMint ? (
                            <Box className={`${loadingStyle} text-center`}>
                              <CircularProgress />
                              <Typography variant="h6">
                                Processing...
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {error && (
                                <Typography variant="h6" className={textStyle}>
                                  {error}
                                </Typography>
                              )}
                              {minted && (
                                <Typography
                                  variant="h6"
                                  color="success"
                                  className="text-center"
                                  style={{ color: "green !important" }}
                                >
                                  NFT minted!
                                </Typography>
                              )}

                              {requirement1Fulfilled &&
                              requirement2Fulfilled &&
                              requirement3Fulfilled ? (
                                <>
                                  <Select
                                    value={nftQuantity}
                                    onChange={(event) =>
                                      setNftQuantity(event.target.value)
                                    }
                                    displayEmpty
                                    fullWidth
                                    className="mt-3"
                                  >
                                    <MenuItem value="" disabled>
                                      Select NFT quantity
                                    </MenuItem>
                                    {menuItems.map((menuItem) => (
                                      <MenuItem value={menuItem}>
                                        {menuItem} I-CHARGE
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <MintMiniNFT
                                    walletAddress={walletAddress}
                                    nftQuantity={nftQuantity}
                                    totalmaxsupply={totalmaxsupply}
                                  />
                                </>
                              ) : (
                                <>
                                  {user.equity - user.amount > 55 ? (
                                    <Button
                                      className={classes.nft_buttons + " mt-3"}
                                      style={{ marginBottom: "20px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      disabled
                                    >
                                      Requirements not fulfilled
                                    </Button>
                                  ) : (
                                    <Button
                                      className={classes.nft_buttons + " mt-3"}
                                      style={{ marginBottom: "20px" }}
                                      fullWidth={true}
                                      variant="contained"
                                      disabled
                                    >
                                      Not Enough Equity
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SeriesIIMint;
