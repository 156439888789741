import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Alert,
} from "@mui/material";
import axios from "axios";
import { CircularProgress, Grid, Box } from "@material-ui/core";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const MANAGE_MASTERCLASS = () => {
  const [reload, setreload] = useState("");
  const [userplans, setuserplans] = useState([]);
  const [isLoadingFetch, setIsLoadingFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserPlan, setSelectedUserPlan] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [masterclassV2_count_overall, setmasterclassV2_count_overall] =
    useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://test.croesus-asset.management/api/getMasterclasses`
      );
      console.log("API getMasterclasses:", response);
      setmasterclassV2_count_overall(
        response.data[0].masterclassV2_count_overall
      );
      setuserplans(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const renderTableRows = (userplans) => {
    // const filteredUserplans = userplans.filter((userplan) =>
    //   userplan.username.includes(searchTerm)
    // );

    if (!Array.isArray(userplans)) {
      // Fallback Component or Null when userplans is not an array
      return null;
    }

    return userplans.map((userplan) => (
      <React.Fragment key={userplan.id}>
        <TableRow
          style={{
            backgroundColor: userplan?.difference < 0 ? "#ff8d8d" : "normal",
          }}
        >
          <TableCell>{userplan?.user_id}</TableCell>
          <TableCell>{userplan?.username}</TableCell>
          <TableCell>{userplan?.fullname}</TableCell>
          <TableCell>{userplan?.masterclassV1_count}</TableCell>
          <TableCell>{userplan?.masterclassV1_token_ids.join(", ")}</TableCell>
          <TableCell>{userplan?.masterclassV2_count}</TableCell>
          <TableCell>{userplan?.masterclassTrading_count}</TableCell>
        </TableRow>
      </React.Fragment>
    ));
  };

  if (isLoading) {
    return (
      <div>
        {" "}
        <Box
          display="flex"
          height="110vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <h4>Loading...</h4>
          </Grid>
        </Box>
        <Box
          display="flex"
          height="100vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid container justifyContent="center" alignContent="center">
            <CircularProgress />
          </Grid>
        </Box>
      </div>
    ); // or some loading spinner
  }

  return (
    <div>
      <h3 className="p-2">
        Masterclass V2 Mint Count : {masterclassV2_count_overall}
      </h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User ID</TableCell>
              <TableCell>UserName</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>V1</TableCell>
              <TableCell>TokenIDs</TableCell>
              <TableCell>V2</TableCell>
              <TableCell>Trading</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableRows(userplans)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MANAGE_MASTERCLASS;
