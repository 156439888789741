import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import axios from "axios";
import {
  useAccount,
  useConnect,
  useEnsName,
  useSignMessage,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Dialog, DialogContent, DialogActions } from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Skeleton } from "@material-ui/lab";
import SeriesIIMint from "./components/SeriesIIMint";
import MiniNFTMint from "./components/MiniNFTMint";

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    background: "#f2f2f2", // Light gray background
  },
  countdownContainer: {
    textAlign: "center",
  },
  countdownText: {
    marginBottom: theme.spacing(2),
    color: "#333", // Dark gray text color
  },
  countdownTimer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  countdownCircle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    margin: theme.spacing(0, 2),
  },
}));

function SeriesTwo() {
  const classes = useStyles();

  // Calculate the remaining time until August 1st, 2023
  const currentDate = new Date();
  const targetDate = new Date("2023-09-02 14:00:00");
  const remainingTime = targetDate - currentDate;

  const endOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    23,
    59,
    59
  );
  const remainingTimeToday = endOfDay.getTime() - currentDate.getTime();
  const hourstoday = Math.floor(
    (remainingTimeToday % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  console.log("endOfDay", endOfDay);
  console.log("remainingTimeToday", remainingTimeToday);

  // Convert remaining milliseconds to days, hours, minutes, and seconds
  const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  console.log("days", days);
  const hours = Math.floor(
    (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  console.log("hours", hours);
  const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
  console.log("minutes", minutes);
  const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
  console.log("seconds", seconds);

  const [isImage1Loaded, setIsImage1Loaded] = useState(false);
  const [isImage2Loaded, setIsImage2Loaded] = useState(false);
  const [isImage3Loaded, setIsImage3Loaded] = useState(false);
  const [isImage4Loaded, setIsImage4Loaded] = useState(false);

  return (
    <div
      className="content-body p-0 "
      style={{
        minHeight: "100%",
        overflow: "auto",
        marginBottom: "50px",
      }}
    >
      <div className={classes.container}>
        <div className={classes.countdownContainer}>
          <div className="row justify-content-md-center p-3">
            <div className="col-lg-6 col-md-8 col-12">
              {" "}
              {/* Adjust these as needed */}
              <div className="card p-3 feature-box pull-up m-0 pt-0">
                <div className="card-body">
                  <div className="row mt-3 mb-3">
                    <SeriesIIMint />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SeriesTwo;
